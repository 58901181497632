import React from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EmployeeForm } from "employee/components/EmployeeForm";
import { EmployeesApi } from "employee/EmployeesApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { RouteParams } from "base/types";
import { useQuery } from "react-query";

export const EditEmployeeScreen: React.FC = () => {
  const { employeeId } = useParams<RouteParams<"employeeId">>();

  const { data, isLoading, isFetching } = useQuery(
    EmployeesApi.itemQuery.build(employeeId)
  );

  useFullScreenLoader(isLoading || isFetching);

  return (
    <RouteContent>
      <Typography variant="route-title">עריכה</Typography>
      <EmployeeForm mode="existing" data={data} />
    </RouteContent>
  );
};
