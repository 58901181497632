import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  DishVEFoodCostChartBGColors,
  DishVEFoodCostChartHoverBGColors,
} from "./constants";
import { DishVEWizardFormValues } from "dish/types";

export const FoodsRatioChart: React.FC<{
  data: Partial<DishVEWizardFormValues>;
}> = ({ data }) => {
  const foods = [...(data.ingredients ?? []), ...(data.materials ?? [])];
  return (
    <Doughnut
      legend={{ display: false }}
      data={{
        labels: foods?.map((x) => x.name),
        datasets: [
          {
            data: foods?.map((x) => x.amount),
            backgroundColor: DishVEFoodCostChartBGColors,
            hoverBackgroundColor: DishVEFoodCostChartHoverBGColors,
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: true,
          text: "יחסי מרכיבים",
          padding: 1,
        },
      }}
    />
  );
};
