import { NutritionalItem } from "nutritional/types";
import { StorageUnitItem } from "storage-unit/types";
import { Dish } from "./Dish";

export type { DishListItem } from "./DishListItem";
export type { Dish } from "./Dish";
export type { DishMaterial } from "./DishMaterial";
export type { DishIngredient } from "./DishIngredient";
export type { EditDishRouteParams } from "./EditDishRouteParams";

export interface DishFormData extends Dish {
  image?: File;
  isDeleteImage?: boolean;
}

export enum DishVEStepType {
  Welcome = "Welcome",
  DishType = "DishType",
  MenuPrice = "MenuPrice",
  VisualEditor = "VisualEditor",
}

export interface DishVEItem extends StorageUnitItem {
  amount: number;
}

export interface DishVE {
  id: number; // GET
  imageName: string; // GET
  menuPrice?: number;
  name?: string;
  image?: File;
  materials: DishVEItem[];
  ingredients: DishVEItem[];
  nutritionals: NutritionalItem[];
  // foodWorks: TreePermanentFoodWork[];
}

export interface DishVEWizardFormValues extends DishVE {
  dishType?: "hot" | "cold";
}
