import {
  SentOrderListItem,
  SentOrder,
  SentOrderViewerInfo,
  SentOrderPost,
  SentOrderSTSimulationListItem,
  SentOrderQuickAddProductFormValues,
  SentOrderItemGet,
  OrderInfluanceFoodCost,
  DailyRecommendListItem,
} from "./types";
import produce from "immer";
import { ApiQuery } from "base/api/ApiQuery";
import Axios from "axios";

export class SentOrdersApi {
  static tags = {
    ListByAllVariants: ApiQuery.buildTag("SentOrdersApi", "ListByAllVariants"),
  };

  static listQuery = new ApiQuery<
    SentOrderListItem[],
    { variant?: "default" | "receivedOnly" }
  >(
    ({ variant = "default" }) => {
      switch (variant) {
        case "default":
          return "/api/sentOrders/items";
        case "receivedOnly":
          return "/api/sentOrders/received";
      }
    },
    [SentOrdersApi.tags.ListByAllVariants]
  );

  static itemQuery = new ApiQuery<SentOrder, number>(
    (id) => `/api/sentOrders/${id}`
  );

  static viewerInfoQuery = new ApiQuery<SentOrderViewerInfo, string>(
    (orderKey) => `/api/sentOrders/${orderKey}/viewer`
  );

  static stSimulationQuery = new ApiQuery<
    SentOrderSTSimulationListItem[],
    number
  >((id) => `/api/sentOrders/${id}/simulateST`);

  static priceReportQuery = new ApiQuery<OrderInfluanceFoodCost, number>(
    (orderId) => `/api/reports/OrderInfluanceFoodCost/${orderId}`
  );

  static dailyRecommendQuery = new ApiQuery<DailyRecommendListItem[]>(
    "/api/ingredients/dailyRecommend"
  );

  static async saveSentOrder(sentOrder: SentOrderPost) {
    await Axios.post(`/api/sentOrders/save`, sentOrder);

    SentOrdersApi.itemQuery.updateCache(
      produce(sentOrder as SentOrder, (s) => {
        s.items = s.items.filter((item) => !item.isDelete);
      }),
      sentOrder.id
    );
  }

  static async closeSentOrder(id: number) {
    await Axios.post(`/api/sentOrders/${id}/close`);
  }

  static async saveOccasional(sentOrder: SentOrderPost) {
    await Axios.post(`/api/sentOrders/occasional`, sentOrder);

    // TODO - figure out if missing keys from SentOrder affect cache source of truth.
    SentOrdersApi.itemQuery.updateCache(sentOrder as SentOrder, sentOrder.id);

    ApiQuery.invalidateByTags([SentOrdersApi.tags.ListByAllVariants]);
  }

  static async quickAddProduct(values: SentOrderQuickAddProductFormValues) {
    const { data: savedItem } = await Axios.post<SentOrderItemGet>(
      `/api/sentOrders/AddToSubmittedOrder`,
      values
    );

    SentOrdersApi.itemQuery.updateCache(
      produce((s) => {
        if (!s) return;

        s.items.push(savedItem);
      }),
      values.orderId
    );
  }

  static async markOrderReceived(id: number) {
    await Axios.post(`/api/sentOrders/${id}/setReceived`);

    ApiQuery.invalidateByTags([SentOrdersApi.tags.ListByAllVariants]);
  }

  static async markOrderSeenBySupplier(orderKey: string) {
    await Axios.post(`/api/sentOrders/${orderKey}/setSupplierSeen`);
  }

  static async cancel(id: number) {
    await Axios.post(`/api/sentOrders/${id}/cancel`);
  }

  static async requestMissingDocument(id: number) {
    await Axios.get(`/api/sentOrders/${id}/requestMissingDocument`);
  }
}
