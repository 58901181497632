import React, { useMemo, useCallback } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { BaseImage } from "base/components/BaseFoodImage";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";
import { IngredientListItem } from "ingredient/types";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { SelectColumnFilter } from "base/components/tables/components/SelectColumnFilter";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const IngredientsTable = () => {
  const { data } = useQuery(IngredientsApi.listQuery.build());

  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(IngredientsRouteBuilder.buildAddIngredientRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: IngredientListItem) => {
      navigate(IngredientsRouteBuilder.buildEditIngredientRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<IngredientListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="מרכיב" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם המרכיב",
        cellProps: {
          className: "name-col",
        },
      },
      {
        // This field is define so it can be searchable.
        id: "AlternativeNames",
        accessor: "alternativeNames",
        Header: "",
        Cell: () => null,
      },
      {
        id: "DefaultPrice",
        accessor: "defaultPrice",
        Header: "מחיר לפני מעמ",
        cellProps: {},
        Cell: TableAmountCell,
      },
      {
        id: "CurrentStock",
        accessor: "currentStock",
        Header: "מלאי",
        cellProps: {},
        Cell: (a, b) => (
          <TableAmountCell
            {...a}
            options={{ fractionDigits: 3, zeroValue: undefined }}
          />
        ),
      },
      {
        id: "DepartmentName",
        accessor: "departmentName",
        Header: "מחלקה",
        cellProps: {},
        Filter: SelectColumnFilter,
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey={LocalStorageKeysHelper.dataTable("ingredients")}
      globalFilterAllowedCols={["Name", "AlternativeNames"]}
    />
  );
};
