import { RouteContent } from "route-content/components/RouteContent";
import React, { useEffect, useRef, useState } from "react";
import { DetailedOrderCard } from "order/components/DetailedOrderCard";
import { OrdersApi } from "order/OrdersApi";
import { useParams } from "react-router-dom";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { usePrevious } from "base/hooks/usePrevious";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { useQuery } from "react-query";
import { RouteContentTopNav } from "route-content/components/RouteContentTopNav";
import { ReactComponent as ShoppingCartIcon } from "route-content/assets/RouteContentShoppingCart.svg";

export type OrdersStatusScreenParams = {
  expandedOrderId?: string;
};

export const OrdersStatusScreen: React.FC = () => {
  const navigate = useNavigateCallback();
  const { data: detailedOrders } = useQuery(
    OrdersApi.detailedListQuery.build()
  );
  const { expandedOrderId: expandedOrderIdParam } = useParams<
    OrdersStatusScreenParams
  >();

  const [expandedOrderId, setExpandedOrderId] = useState<number>();
  const prevExpandedOrderId = usePrevious(expandedOrderId);
  const cardsRef = useRef<HTMLDivElement>(null);
  const expandedOrderIdOriginRef = useRef<"navigation" | "user-click">(
    "navigation"
  );

  // Handle ExpandedOrderId state/url sync.
  useEffect(() => {
    expandedOrderIdOriginRef.current =
      prevExpandedOrderId === expandedOrderId ? "navigation" : "user-click";

    if (expandedOrderIdOriginRef.current === "navigation") {
      // Sync state
      const nextExpandedOrderId = expandedOrderIdParam
        ? Number(expandedOrderIdParam)
        : undefined;
      setExpandedOrderId(nextExpandedOrderId);
    } else {
      // Sync navigation url
      navigate(OrdersRouteBuilder.buildOrdersStatusRoute(expandedOrderId), {
        state: { preventScrollTop: true },
      });
    }
  }, [expandedOrderId, prevExpandedOrderId, expandedOrderIdParam, navigate]);

  // Handle ExpandedOrderId scroll into view.
  useEffect(() => {
    if (!detailedOrders || expandedOrderIdOriginRef.current !== "navigation") {
      return;
    }

    const elementIndex = detailedOrders.findIndex(
      (x) => x.orderId === expandedOrderId
    );

    if (elementIndex === -1) {
      navigate(OrdersRouteBuilder.buildOrdersStatusRoute(undefined));
    } else {
      const element = cardsRef.current!.children[elementIndex];
      const timeoutId = setTimeout(() => {
        element!.scrollIntoView();
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [detailedOrders, expandedOrderId, navigate]);

  return (
    <RouteContent className="OrdersStatusScreen">
      <RouteContentTopBar
        title="סטטוס הזמנות"
        titleIcon={<ShoppingCartIcon height={24} />}
      ></RouteContentTopBar>
      <div ref={cardsRef}>
        {detailedOrders?.map((detailedOrder) => (
          <DetailedOrderCard
            key={detailedOrder.orderId}
            detailedOrder={detailedOrder}
            isExpanded={expandedOrderId === detailedOrder.orderId}
            onToggleExpand={() => {
              setExpandedOrderId(
                expandedOrderId === detailedOrder.orderId
                  ? undefined
                  : detailedOrder.orderId
              );
            }}
          />
        ))}
      </div>
    </RouteContent>
  );
};
