import React from "react";
import { UnitType } from "base/types";
import { FormSelectProps, FormSelect } from "./FormSelect";

export type FormUnitTypeSelectProps = Omit<FormSelectProps, "options"> & {
  isSmallSize?: boolean;
};

export const FormUnitTypeSelect: React.FC<FormUnitTypeSelectProps> = (
  props
) => {
  const data = [
    { value: UnitType.KG, label: props.isSmallSize ? "GR" : "KG" },
    { value: UnitType.Liter, label: props.isSmallSize ? "ML" : "LT" },
    { value: UnitType.Yeh, label: props.isSmallSize ? "Unit" : "PACKAGE" },
  ];

  return <FormSelect emptyOptionLabel="בחר יחידה" {...props} options={data} />;
};
