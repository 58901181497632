import { useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import React, { useMemo, useState, useCallback } from "react";
import { POSApi } from "./POSApi";
import {
  CardsTableColumn,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import { POSHistoryFoodItem } from "./types";
import { POSFoodItemModal } from "./POSFoodItemModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export interface POSHistoryItemScreenParams {
  fileName: string;
}

export const POSHistoryItemScreen = () => {
  const { fileName } = useParams<POSHistoryItemScreenParams>();
  const { data } = useQuery(POSApi.historyItemQuery.build(fileName));

  const [foodModalItem, setFoodModalItem] = useState<POSHistoryFoodItem>();
  const [showFoodModal, onShowFoodModal, onHideFoodModal] = useBooleanState(
    false
  );

  const onEditRowClick = useCallback(
    (row: POSHistoryFoodItem) => {
      setFoodModalItem(row);
      onShowFoodModal();
    },
    [onShowFoodModal]
  );

  const columns = useMemo<CardsTableColumn<POSHistoryFoodItem>[]>(
    () => [
      {
        id: "Name",
        accessor: "Name",
        Header: "שם המנה",
      },
      {
        id: "TotalSold",
        accessor: "TotalSold",
        Header: "נמכר",
      },
      {
        id: "TotalSoldAddon",
        accessor: "TotalSoldAddon",
        Header: "נמכר - משנה",
      },
      {
        id: "Quantity",
        accessor: "Quantity",
        Header: "כמות לחישוב",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <CardsTable
        columns={columns}
        data={data?.Foods}
        onEditRowClick={onEditRowClick}
        localStorageKey={LocalStorageKeysHelper.dataTable("posHistoryItem")}
        globalFilterAllowedCols={["Name"]}
      />
      <POSFoodItemModal
        show={showFoodModal}
        onHide={onHideFoodModal}
        food={foodModalItem}
      />
    </RouteContent>
  );
};
