import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import "order/styles/styles.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { Typography } from "base/components/Typography";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { ReactComponent as SentOrdersIcon } from "order/assets/OrdersHomeSentOrders.svg";
import { ReactComponent as OrdersHistoryIcon } from "order/assets/OrdersHomeOrdersHistory.svg";
import { ReactComponent as ShoppingCartIcon } from "order/assets/OrdersHomeShoppingCart.svg";
import { ReactComponent as CreateOrderIcon } from "order/assets/OrdersHomeCreateOrder.svg";

export const OrdersHomeScreen: React.FC = () => {
  const navigate = useNavigateCallback();

  return (
    <RouteContent className="OrdersHomeScreen">
      <RouteContentTopBar>
        <div className="topBar">
          <Typography variant="title" className="text-white p-0 m-0">
            הזמנות
          </Typography>
        </div>
      </RouteContentTopBar>
      <div className="items justify-content-center">
        <div
          className="item"
          onClick={() => navigate(OrdersRouteBuilder.buildAddOrderHomeRoute())}
        >
          <div
            style={{
              position: "relative",
              height: 140,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <div style={{ position: "absolute" }}>
              <CreateOrderIcon height={190} />
            </div>
          </div>
          <div className="text pt-3">
            יצירת הזמנה
            <br /> חדשה
          </div>
        </div>
        <div
          className="item"
          onClick={() => navigate(OrdersRouteBuilder.buildOrdersStatusRoute())}
        >
          <ShoppingCartIcon height={140} />
          <div className="text pt-3">
            סל קניות
            <br />
            &nbsp;
          </div>
        </div>
        <div style={{ flexBasis: "100%" }} />
        <div
          className="item"
          onClick={() =>
            navigate(SentOrdersRouteBuilder.buildSentOrdersRoute())
          }
        >
          <div
            style={{
              position: "relative",
              height: 140,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SentOrdersIcon height={120} />
          </div>
          <div className="text pt-3">
            קליטת
            <br /> הזמנות
          </div>
        </div>
        <div className="item" style={{ cursor: "not-allowed" }}>
          <OrdersHistoryIcon height={140} />
          <div className="text pt-3">
            היסטורית
            <br /> הזמנות
          </div>
        </div>
      </div>
    </RouteContent>
  );
};
