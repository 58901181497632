import React, { useMemo, useRef } from "react";
import { SuppliersApi } from "supplier/SuppliersApi";
import { Form } from "form/components/Form";
import { noop } from "base/utils/noop";
import { DistributionDaysFormValues, DistributionDay } from "supplier/types";
import { Row, Col } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import {
  SupplierOrderDaysOptions,
  SupplierOrderHoursOptions,
} from "supplier/constants";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormSelect } from "form/components/FormSelect";
import { Cancelable, isEqual, debounce } from "lodash";
import { useQuery } from "react-query";

export type DistributionDaysTabProps = {
  supplierId: number;
};

export const DistributionDaysTab: React.FC<DistributionDaysTabProps> = ({
  supplierId,
}) => {
  const { data: distributionDays } = useQuery(
    SuppliersApi.itemDistributionDaysQuery.build(supplierId)
  );

  const isDaysLoaded = distributionDays !== undefined;

  const initialValues = useMemo<DistributionDaysFormValues>(
    () => ({
      distributionDays: SupplierOrderDaysOptions.map(
        (orderDay) =>
          distributionDays?.find((d) => d.orderDay === orderDay.value) ??
          ({
            isActive: false,
            orderDay: orderDay.value,
            orderDayTime: "08:30",
            supplyDay: (orderDay.value + 1) % 7,
            supplyDayTime: "08:30",
            supplierId,
          } as DistributionDay)
      ),
    }),
    [distributionDays, supplierId]
  );

  const dayFieldName = (property: keyof DistributionDay, orderDay: number) =>
    `distributionDays[${orderDay}].${property}`;

  const saveFn = async (day: DistributionDay) => {
    await SuppliersApi.saveDistributionDay(day);
  };

  const rowsAutoSaveMapRef = useRef<{
    [id: number]: typeof saveFn & Cancelable;
  }>({});

  return (
    <Form onSubmit={noop} initialValues={initialValues}>
      {({ form }) => (
        <>
          <Row noGutters className="my-2">
            <Col xs={3} md={2} lg={1} className="p-1">
              הזמנה ביום
            </Col>
            <Col xs={2} className="p-1">
              עד שעה
            </Col>
            <Col xs={2} className="p-1">
              מועד אספקה
            </Col>
            <Col xs={2} className="p-1">
              עד שעה
            </Col>
          </Row>
          {SupplierOrderDaysOptions.map((day) => {
            const dayId = initialValues.distributionDays.find(
              (d) => d.orderDay === day.value
            )?.id;

            return (
              <>
                <Field
                  key={day.value}
                  name={dayFieldName("isActive", day.value)}
                  render={({ input: { value: isActive } }) => (
                    <Row noGutters>
                      <Col
                        xs={3}
                        md={2}
                        lg={1}
                        className="p-1 d-flex align-items-center"
                      >
                        <FormCheckbox
                          name={dayFieldName("isActive", day.value)}
                          width={30}
                          disabled={!isDaysLoaded}
                        />
                        <Field
                          name={dayFieldName("orderDay", day.value)}
                          render={({ input: { value } }) => (
                            <div className="px-2">
                              {
                                SupplierOrderDaysOptions.find(
                                  (d) => d.value === value
                                )!.label
                              }
                            </div>
                          )}
                        />
                      </Col>
                      <Col xs={2} className="p-1 d-flex align-items-center">
                        <FormSelect
                          name={dayFieldName("orderDayTime", day.value)}
                          options={SupplierOrderHoursOptions}
                          className="w-75"
                          displayEmptyOption={false}
                          disabled={!isActive || dayId === undefined}
                        />
                      </Col>
                      <Col xs={2} className="p-1 d-flex align-items-center">
                        <FormSelect
                          name={dayFieldName("supplyDay", day.value)}
                          options={SupplierOrderDaysOptions}
                          className="w-75"
                          displayEmptyOption={false}
                          disabled={!isActive || dayId === undefined}
                        />
                      </Col>
                      <Col xs={2} className="p-1 d-flex align-items-center">
                        <FormSelect
                          name={dayFieldName("supplyDayTime", day.value)}
                          options={SupplierOrderHoursOptions}
                          className="w-75"
                          displayEmptyOption={false}
                          disabled={!isActive || dayId === undefined}
                        />
                      </Col>
                    </Row>
                  )}
                />
                <FormSpy
                  subscription={{
                    validating: true,
                  }}
                  onChange={async ({ validating }) => {
                    const orderDayValue = day.value;

                    if (!rowsAutoSaveMapRef.current[orderDayValue]) {
                      rowsAutoSaveMapRef.current[orderDayValue] = debounce(
                        saveFn,
                        250
                      );
                    }

                    const formState = form.getState();
                    if (validating || !formState.valid) {
                      rowsAutoSaveMapRef.current[orderDayValue].cancel();
                      return;
                    }

                    const { values, initialValues } = formState;
                    const nextRowValue =
                      values?.distributionDays[orderDayValue];
                    const prevRowValue = initialValues?.distributionDays![
                      orderDayValue
                    ];

                    if (
                      nextRowValue &&
                      prevRowValue &&
                      !isEqual(nextRowValue, prevRowValue)
                    ) {
                      rowsAutoSaveMapRef.current[orderDayValue](nextRowValue);
                    }
                  }}
                />
              </>
            );
          })}
        </>
      )}
    </Form>
  );
};
