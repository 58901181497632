import Axios from "axios";
import { WeekStockRange } from "./types";
import { MaterialsApi } from "material/MaterialsApi";
import { IngredientsApi } from "ingredient/IngredientsApi";
import produce from "immer";
import { ApiQuery } from "base/api/ApiQuery";

export class WeekStockRangesApi {
  static rangesQuery = new ApiQuery<WeekStockRange, number>(
    (id) => `/api/WeekStockRanges/${id}`
  );

  static async saveRanges(weekStockRange: WeekStockRange) {
    const { data: savedWeekStockRange } = await Axios.post<WeekStockRange>(
      `/api/WeekStockRanges`,
      weekStockRange
    );

    WeekStockRangesApi.rangesQuery.updateCache(
      savedWeekStockRange,
      savedWeekStockRange.id
    );

    const {
      id: weekStockRangeId,
      materialId,
      ingredientId,
    } = savedWeekStockRange;

    if (materialId !== undefined) {
      MaterialsApi.itemQuery.updateCache(
        produce((item) => {
          if (!item) return undefined;

          item.weekStockRangeId = weekStockRangeId;
        }),
        materialId
      );
    } else {
      IngredientsApi.itemQuery.updateCache(
        produce((item) => {
          if (!item) return;

          item.weekStockRangeId = weekStockRangeId;
        }),
        ingredientId!
      );
    }
  }
}
