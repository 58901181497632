import React from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EditContractRouteParams } from "contract/types";
import { ContractEditor } from "contract/components/ContractsEditor";

export const EditContractScreen: React.FC = () => {
  const { id } = useParams<EditContractRouteParams>();

  return (
    <RouteContent>
      <Typography variant="route-title">עריכת הסכם</Typography>
      <ContractEditor id={Number(id)} />
    </RouteContent>
  );
};
