import React from "react";
import classNames from "classnames";
import { RouteContentTopBar } from "./RouteContentTopBar";
import "../styles.scss";

const routeTopBarType = (<RouteContentTopBar />).type;

export interface RouteContentProps {
  className?: string;
}

export const RouteContent: React.FC<RouteContentProps> = ({
  children,
  className,
}) => {
  const hasTopBar =
    (React.Children.toArray(children)[0] as React.ReactElement)?.type ===
    routeTopBarType;

  return (
    <div className={classNames("route-content", { hasTopBar }, className)}>
      {children}
    </div>
  );
};
