import Axios from "axios";
import { ScheduleRequest } from "./types";
import { ApiQuery } from "base/api/ApiQuery";

export class ScheduleRequestsApi {
  static myScheduleQuery = new ApiQuery<ScheduleRequest, string>(
    (formattedDate) => `/api/scheduleRequests?date=${formattedDate}`
  );

  static async saveMySchedule(
    formattedDate: string,
    schedule: ScheduleRequest
  ) {
    await Axios.post<ScheduleRequest>(
      `/api/scheduleRequests?date=${formattedDate}`,
      schedule.shifts
    );

    ScheduleRequestsApi.myScheduleQuery.updateCache(schedule, formattedDate);
  }
}
