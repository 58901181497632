import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { SuppliersTable } from "supplier/components/SuppliersTable";

export const SuppliersScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">ספקים</Typography>
      <SuppliersTable />
    </RouteContent>
  );
};
