import React, { useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { Spacer } from "base/components/Spacer";
import { DepartmentFoodWorksApi } from "food-works/department-food-works/DepartmentFoodWorksApi";
import { SingleItemFoodWorkType } from "../types/SingleItemFoodWorkType";
import { SingleItemFoodWorksApi } from "../SingleItemFoodWorksApi";
import { SingleItemFoodWorksFormValues } from "../types/SingleItemFoodWorksFormValues";
import { SingleItemFoodWorksForm } from "./SingleItemFoodWorksForm";
import "food-works/styles.scss";
import { DepartmentFoodWork } from "food-works/department-food-works/types/DepartmentFoodWork";
import { pick } from "lodash";
import { Grid } from "@material-ui/core";
import { skipToken } from "base/api/ApiQuery";
import { useQuery } from "react-query";

export type SingleItemFoodWorksModalProps = {
  show: boolean;
  treeItemType?: SingleItemFoodWorkType;
  treeItemId?: number;
  onCancel: () => void;
};

export const SingleItemFoodWorksModal: React.FC<SingleItemFoodWorksModalProps> = ({
  show,
  treeItemType,
  treeItemId,
  onCancel,
}) => {
  const { data } = useQuery(
    SingleItemFoodWorksApi.itemsQuery.build(
      {
        type: treeItemType ?? skipToken,
        id: treeItemId ?? skipToken,
      },
      { enabled: show }
    )
  );

  const { data: treeItem } = useQuery(
    SingleItemFoodWorksApi.foodItemQuery.build(
      { type: treeItemType ?? skipToken, id: treeItemId ?? skipToken },
      { enabled: show }
    )
  );

  const { data: departmentFoodWorks } = useQuery(
    DepartmentFoodWorksApi.itemsQuery.build(
      treeItem?.departmentId ?? skipToken,
      {
        enabled: show,
      }
    )
  );

  const foodWorksForm = useMemo<
    SingleItemFoodWorksFormValues | undefined
  >(() => {
    if (!treeItemType || !treeItemId || !treeItem) {
      return undefined;
    } else {
      return {
        treeItemType,
        treeItemId,
        foodWorks: data ?? [],
        treeItem,
      };
    }
  }, [data, treeItemId, treeItemType, treeItem]);

  const onAddFoodWorkByDepartment = useCallback(
    async (foodWork: DepartmentFoodWork) => {
      await SingleItemFoodWorksApi.addFoodWork(treeItemType!, treeItemId!, {
        ...pick<DepartmentFoodWork, keyof DepartmentFoodWork>(foodWork, [
          "name",
          "cost",
          "depreciation",
          "duration",
        ]),
        isRequired: false,
      });
    },
    [treeItemId, treeItemType]
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="foodworks-modal"
      centered
      size="lg"
    >
      <div className="foodworks-modal-content">
        <div className="fwm-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול עיבוד מתקדם - {treeItem?.name}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="fwm-body">
          <Form
            initialValues={foodWorksForm}
            onSubmit={() => {}}
            component={SingleItemFoodWorksForm}
          />
          <Spacer units={4} />
          {departmentFoodWorks && departmentFoodWorks?.length > 0 && (
            <>
              <Typography variant="description" fontSize={20} bold>
                הוספת עיבוד ממחלקה
              </Typography>
              <Spacer />
              <Grid container className="available-foodworks" spacing={2}>
                {departmentFoodWorks?.map((dfw) => (
                  <Grid item onClick={() => onAddFoodWorkByDepartment(dfw)}>
                    <div className="grid-item-content">{dfw.name}</div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
