import React, { useCallback, useMemo } from "react";
import { FormControl } from "react-bootstrap";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { TreeType } from "base/types/TreeType";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { MaterialsApi } from "material/MaterialsApi";
import { Select } from "base/components/Select";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { ExtractTagsOfIngredientFn } from "ingredient/constants";
import { useImmer } from "use-immer";
import { TreeInfoHelper } from "tree-info/TreeInfoHelper";
import { SingleItemFoodWorksApi } from "food-works/single-item-food-works/SingleItemFoodWorksApi";
import { useQuery } from "react-query";
import { skipToken } from "base/api/ApiQuery";

export type TreeInfoAddSectionProps = {
  type: TreeType;
  id: number;
  listName: "materials" | "ingredients";
};

export const TreeInfoAddSection: React.FC<TreeInfoAddSectionProps> = ({
  type,
  id,
  listName,
}) => {
  const { data: ingredientsList } = useQuery(
    IngredientsApi.selectListQuery.build({
      enabled: listName === "ingredients",
    })
  );
  const ingredientsListOptions = useSelectOptionsAdapter(
    ingredientsList,
    ExtractTagsOfIngredientFn
  );

  const { data: materialsList } = useQuery(
    MaterialsApi.selectListQuery.build({ enabled: listName === "materials" })
  );
  const materialsListOptions = useSelectOptionsAdapter(materialsList);

  const preFilterOptions =
    listName === "materials" ? materialsListOptions : ingredientsListOptions;

  const options = useMemo(() => {
    return preFilterOptions.filter((item) => item.value !== id);
  }, [id, preFilterOptions]);

  const [state, updateState] = useImmer<{
    listItemId?: number;
    amount?: number;
  }>({});

  const {
    data: selectedItemFoodWorks,
    isLoading: isLoadingSelectedItemFoodWorks,
    isFetching: isFetchingSelectedItemFoodWorks,
  } = useQuery(
    SingleItemFoodWorksApi.itemsQuery.build({
      type: listName === "materials" ? "material" : "ingredient",
      id: state.listItemId ?? skipToken,
    })
  );

  const onAdd = useCallback(async () => {
    updateState({});
    const amountBeforeFoodWorks = Math.round(
      TreeInfoHelper.calcAmountBeforeFoodWorks(
        state.amount!,
        selectedItemFoodWorks!
      )
    );

    if (listName === "materials") {
      await TreeInfoApi.saveMaterial(
        type,
        id,
        state.listItemId!,
        amountBeforeFoodWorks,
        true
      );
    } else {
      await TreeInfoApi.saveIngredient(
        type,
        id,
        state.listItemId!,
        amountBeforeFoodWorks,
        true
      );
    }
  }, [listName, type, id, state, selectedItemFoodWorks, updateState]);

  const selectPlaceholder =
    listName === "materials" ? "בחירת חומר גלם" : "בחירת מרכיב";

  return (
    <div className="d-flex">
      <Select
        options={options}
        variant="react-select"
        value={state.listItemId}
        onChange={(value: any) => {
          updateState((d) => {
            d.listItemId = value;
          });
        }}
        className="w-25 mx-1"
        emptyOptionLabel={selectPlaceholder}
      />
      <FormControl
        value={state.amount ?? ""}
        type="number"
        onChange={(e) => {
          const { value } = e.target;
          updateState((d) => {
            d.amount = value ? parseInt(value) : undefined;
          });
        }}
        className="w-25 mx-1"
        placeholder="כמות לאחר ע."
      />
      <button
        className="btn btn-sm btn-primary mx-1"
        onClick={onAdd}
        disabled={
          !state.amount ||
          state.listItemId === undefined ||
          isLoadingSelectedItemFoodWorks ||
          isFetchingSelectedItemFoodWorks
        }
      >
        הוספה
      </button>
    </div>
  );
};
