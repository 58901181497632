import React from "react";
import { Typography } from "base/components/Typography";
import "modal/styles.scss";
import classNames from "classnames";

export interface ModalTitleBarProps {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  onClose: () => void;
  isDisplayIndent?: boolean;
}

export const ModalTitleBar: React.FC<ModalTitleBarProps> = ({
  icon,
  title = "",
  onClose,
  isDisplayIndent: displayIndent = true,
}) => {
  return (
    <div className={classNames("ModalTitleBar", { displayIndent })}>
      {icon && (
        <div className="mt-3 icon-wrap d-flex align-items-center justify-content-center">
          {icon}
        </div>
      )}
      {typeof title === "string" && (
        <Typography variant="description" className="title" fontSize={24} bold>
          {title}
        </Typography>
      )}
      {typeof title !== "string" && title}
      <i className="close-btn far fa-times-circle" onClick={onClose} />
    </div>
  );
};
