import Axios from "axios";
import {
  NutritionalSelectListItem,
  NutritionalRelationType,
  NutritionalItem,
} from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class NutritionalsApi {
  static typesQuery = new ApiQuery<NutritionalSelectListItem[]>(
    "/api/itemNutritionals/types"
  );

  static nutritionalsQuery = new ApiQuery<
    NutritionalItem[],
    {
      relationType: NutritionalRelationType;
      relationId: number;
    }
  >(({ relationType, relationId }) => {
    switch (relationType) {
      case NutritionalRelationType.Food:
        return `/api/foods/${relationId}/nutritionals`;

      case NutritionalRelationType.Product:
        return `/api/products/${relationId}/nutritionals`;

      case NutritionalRelationType.Material:
        return `/api/materials/${relationId}/nutritionals`;
    }
  });

  static async save(
    relationType: NutritionalRelationType,
    relationId: number,
    nutritional: NutritionalItem
  ) {
    const { data: savedNutritional } = await Axios.post<NutritionalItem>(
      `/api/itemNutritionals/`,
      {
        foodId:
          relationType === NutritionalRelationType.Food
            ? relationId
            : undefined,
        productId:
          relationType === NutritionalRelationType.Product
            ? relationId
            : undefined,
        materialId:
          relationType === NutritionalRelationType.Material
            ? relationId
            : undefined,
        ...nutritional,
      }
    );

    NutritionalsApi.nutritionalsQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedNutritional, "id"),
      { relationType, relationId }
    );
  }

  static async delete(
    relationType: NutritionalRelationType,
    relationId: number,
    idToDelete: number
  ) {
    await Axios.delete(`/api/itemNutritionals/${idToDelete}`);

    NutritionalsApi.nutritionalsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(idToDelete, "id"),
      { relationType, relationId }
    );
  }
}
