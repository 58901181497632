import { useMemo, useRef } from "react";
import { BaseSelectListItem } from "base/types";
import { SelectProps } from "../Select";

export const useSelectOptionsAdapter = <T extends BaseSelectListItem>(
  arr: T[] | undefined,
  extractTags?: (t: T) => string[]
) => {
  const refs = useRef({ extractTags });
  Object.assign(refs.current, { extractTags });

  return useMemo<SelectProps["options"]>(() => {
    return (arr ?? []).map((x) => ({
      value: x.id,
      label: x.name,
      tags: refs.current.extractTags?.(x),
    }));
  }, [arr]);
};
