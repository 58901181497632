import { TreeType } from "base/types";

export class TreeTypeFormatter {
  static format(type: TreeType) {
    switch (type) {
      case TreeType.Material:
        return "חומר גלם מעובד";
      case TreeType.Dish:
        return "מנה";
    }
  }

  static formatRoutePathReverse(path: string) {
    switch (path) {
      case "material":
        return TreeType.Material;
      case "dish":
        return TreeType.Dish;
      default:
        throw new Error("invalid route path");
    }
  }

  static formatRoutePath(type: TreeType) {
    switch (type) {
      case TreeType.Material:
        return "material";
      case TreeType.Dish:
        return "dish";
    }
  }

  static formatApiPath(type: TreeType) {
    switch (type) {
      case TreeType.Material:
        return "materials";
      case TreeType.Dish:
        return "foods";
    }
  }
}
