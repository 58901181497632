import querystring from "query-string";
import { SentOrderEditorMode } from "./types";

export class SentOrdersRouteBuilder {
  static templates = {
    SentOrders: `/sent-orders`,
    Viewer: "/sent-orders/:orderKey/viewer",
    ReceivedOrders: "/sent-orders/received",
    DailyRecommend: "/sent-orders/daily-recommend",
    OrderPriceReport: "/sent-orders/:orderId/price-report",
    OrderAmountReport: "/sent-orders/:orderId/amount-report",
    SentOrderScan: "/sent-orders/:orderId/scan",
    SentOrder: "/sent-orders/:orderId",
    SentOrderEditor: "/sent-orders/:orderId/editor",
    SentOrderOld: "/sent-orders/:orderId",
    SentOrderOldAdmin: "/sent-orders/:orderId/admin",
  };

  static buildSentOrdersRoute() {
    return SentOrdersRouteBuilder.templates.SentOrders;
  }

  static buildReceivedOrdersRoute() {
    return SentOrdersRouteBuilder.templates.ReceivedOrders;
  }

  static buildDailyRecommendRoute() {
    return SentOrdersRouteBuilder.templates.DailyRecommend;
  }

  static buildEditSentOrderRoute(
    orderId: number,
    mode: SentOrderEditorMode = "default"
  ) {
    return `/sent-orders/${orderId}${mode === "advanced" ? "/admin" : ""}`;
  }

  static buildSentOrderRoute(orderId: number) {
    return SentOrdersRouteBuilder.templates.SentOrder.replace(
      ":orderId",
      String(orderId)
    );
  }

  static buildSentOrderEditorRoute(orderId: number, itemId?: number) {
    return querystring.stringifyUrl({
      url: SentOrdersRouteBuilder.templates.SentOrderEditor.replace(
        ":orderId",
        String(orderId)
      ),
      query: { itemId },
    });
  }

  static buildViewerRoute(orderKey: string) {
    return SentOrdersRouteBuilder.templates.Viewer.replace(
      ":orderKey",
      orderKey
    );
  }

  static buildOrderPriceReportRoute(orderId: number) {
    return SentOrdersRouteBuilder.templates.OrderPriceReport.replace(
      ":orderId",
      String(orderId)
    );
  }

  static buildOrderAmountReportRoute(orderId: number) {
    return SentOrdersRouteBuilder.templates.OrderAmountReport.replace(
      ":orderId",
      String(orderId)
    );
  }

  static buildSentOrderScanRoute(orderId: number) {
    return SentOrdersRouteBuilder.templates.SentOrderScan.replace(
      ":orderId",
      String(orderId)
    );
  }
}
