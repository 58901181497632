import React from "react";
import {
  FormSelect,
  FormSelectProps,
} from "base/components/form-controls/FormSelect";
import { DishesApi } from "dish/DishesApi";
import { useQuery } from "react-query";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";

export type FormDishDepartmentsSelectProps<FormValues> = Omit<
  FormSelectProps<FormValues, number | undefined>,
  "data"
>;

export const FormDishDepartmentsSelect = <FormValues,>(
  props: FormDishDepartmentsSelectProps<FormValues>
) => {
  const { data: departments } = useQuery(DishesApi.departmentsQuery.build());
  const data = useSelectOptionsAdapter(departments);

  return <FormSelect emptyOptionLabel="בחר מחלקה" {...props} data={data} />;
};
