import { OrdersApi } from "order/OrdersApi";
import React from "react";
import { Collapse } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import classNames from "classnames";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { useQuery } from "react-query";
import { NumberFormatter } from "base/utils/formatters";

export interface OrderDepartmentInfoCardProps {
  departmentId: number;
  totalOrderPrice: number;
}

export const OrderDepartmentInfoCard: React.FC<OrderDepartmentInfoCardProps> = ({
  departmentId,
  totalOrderPrice,
}) => {
  const [isExpanded, , , onToggle] = useBooleanState(true);
  const { data } = useQuery(OrdersApi.departmentQuery.build(departmentId));

  return (
    <div
      className={classNames("OrderDepartmentInfoCard", {
        isExpanded,
      })}
    >
      <div className={"header d-flex align-items-center"} onClick={onToggle}>
        <i
          className={classNames("fas fa-chevron-left mx-2", {
            "fa-rotate-270": isExpanded,
          })}
        />
        <div>{data?.department.name}</div>
        <ImageBackgroundMix
          key={data?.department.logoFileName}
          className="mr-auto"
        >
          <BaseImage
            fileDir={FileDir.Department}
            fileName={data?.department.logoFileName}
          />
        </ImageBackgroundMix>
      </div>
      <Collapse in={isExpanded}>
        <div>
          <div className="expanded-section-content">
            <div className="info-text">
              סה"כ עלות הזמנה עד כה: {NumberFormatter.format(totalOrderPrice)}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
