import {
  StockTakingFormValues,
  StockRemovalSearchItem,
  StockRemovalRequest,
} from "./types";
import Axios from "axios";
import { ApiQuery } from "base/api/ApiQuery";

export class StocksApi {
  static stockRemovalSearchQuery = new ApiQuery<
    StockRemovalSearchItem[],
    string
  >((searchPhrase) => `/api/StockRemovalRequests/Search/${searchPhrase}`);

  static async removeFromStock(data: StockRemovalRequest) {
    return Axios.post(`/api/StockRemovalRequests/RemoveFromStock`, data);
  }

  static async performStockTaking(values: StockTakingFormValues) {
    return Axios.post("/api/StockTransactions/stocktaking", values);
  }
}
