import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { SimpleGlobalList } from "global-list/components/SimpleGlobalList";
import { SimpleGlobalListRoutePath } from "global-list/types";

export const SystemSettingsListScreen: React.FC = () => {
  return (
    <RouteContent>
      <SimpleGlobalList
        routeApiPath={SimpleGlobalListRoutePath.SystemSettings}
      />
    </RouteContent>
  );
};
