import React from "react";
import { AddOrderStore } from "./AddOrderStore";
import { AddOrderDisplayVariant } from "order/types";

export type AddOrderContextType = {
  store: AddOrderStore;
  setDisplayVariat: (value: AddOrderDisplayVariant) => void;
  setRouteSearchPhrase: (routePathName: string, phrase: string) => void;
  setSpeechPhrase: (phrase: string) => void;
};

export const AddOrderContext = React.createContext<
  AddOrderContextType | undefined
>(undefined);
