import React, { useCallback, useRef } from "react";
import { Form } from "form/components/Form";
import { Product, ProductForm } from "product/types";
import { Button, Row, Col, FormLabel, ButtonGroup } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { ProductsApi } from "product/ProductsApi";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormSpy } from "react-final-form";
import { FormFileViewer } from "form/components/FormFileViewer";
import { AllergensModal } from "allergen/AllergensModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { AllergenRelationType } from "allergen/types";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { BaseSelectListItem, FileDir } from "base/types";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { NutritionalsModal } from "nutritional/NutritionalsModal";
import { NutritionalRelationType } from "nutritional/types";
import { ProductSupplyMethodsEditor } from "product/supply-method/ProductSupplyMethodsEditor";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ProductsRouteBuilder } from "product/ProductsRouteBuilder";
import { SimpleGlobalListItemModal } from "global-list/components/SimpleGlobalListItemModal";
import { useQuery } from "react-query";

export type ProductEditorProps = {
  mode: "add" | "edit";
  data?: Partial<Product>;
  initialIngredientId?: number;
};

export const ProductEditor: React.FC<ProductEditorProps> = ({
  data: product,
  mode,
  initialIngredientId,
}) => {
  const navigate = useNavigateCallback();
  const actionRef = useRef<"save" | "saveAndGoToEdit">("save");

  const { data: ingredients } = useQuery(IngredientsApi.listQuery.build());
  const ingredientsOptions = useSelectOptionsAdapter(ingredients);

  const { data: departments } = useQuery(ProductsApi.departmentsQuery.build());
  const departmentsOptions = useSelectOptionsAdapter(departments);

  const { data: manufacturers } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.Manufacturers)
  );
  const manufacturerOptions = useSelectOptionsAdapter(manufacturers);

  const [showAllergens, onShowAllergens, onHideAllergens] = useBooleanState(
    false
  );

  const [
    showNutritionals,
    onShowNutritionals,
    onHideNutritionals,
  ] = useBooleanState(false);

  const onDelete = useCallback(async () => {
    if (window.confirm("האם אתה בטוח?")) {
      await ProductsApi.deleteItem(product!.id!);
      navigate(ProductsRouteBuilder.buildListRoute());
    }
  }, [navigate, product]);

  return (
    <>
      {mode !== "add" && (
        <div>
          <Button variant="danger" className="mx-1" onClick={onDelete}>
            מחיקה
          </Button>
          <Button
            variant="primary"
            className="mx-1"
            onClick={onShowNutritionals}
          >
            ערכים תזונתיים
          </Button>
          <Button variant="primary" className="mx-1" onClick={onShowAllergens}>
            אלרגנים
          </Button>
        </div>
      )}
      <Form<ProductForm>
        initialValues={product}
        onSubmit={async (values) => {
          const savedProduct = await ProductsApi.saveItem(values);
          if (actionRef.current === "save") {
            navigate(ProductsRouteBuilder.buildListRoute());
          } else {
            navigate(ProductsRouteBuilder.buildEditRoute(savedProduct.id));
          }
        }}
      >
        {({ form, handleSubmit }) => (
          <>
            <Row noGutters className="justify-content-between">
              <Col md={7}>
                <Row noGutters>
                  <Col xs={6} className="p-1">
                    מרכיב
                  </Col>
                  <Col xs={6} className="p-1">
                    <FormSelect
                      variant="react-select"
                      name="ingredientId"
                      emptyOptionLabel="מרכיב"
                      displayEmptyOption={mode === "add"}
                      options={ingredientsOptions}
                      validate={FieldsValidator.required}
                      initialValue={initialIngredientId}
                    />
                  </Col>
                  <Col xs={6} className="p-1">
                    יצרן
                  </Col>
                  <Col xs={6} className="p-1">
                    <FormSelect
                      variant="react-select"
                      name="manufacturerId"
                      emptyOptionLabel="יצרן"
                      options={manufacturerOptions}
                      validate={FieldsValidator.required}
                      withAdd={{
                        customModalRender: (props) => (
                          <SimpleGlobalListItemModal
                            {...props}
                            routeApiPath={
                              SimpleGlobalListRoutePath.Manufacturers
                            }
                          />
                        ),
                      }}
                    />
                  </Col>
                  <Col xs={6} className="p-1">
                    מחלקה
                  </Col>
                  <Col xs={6} className="p-1">
                    <FormSelect
                      variant="react-select"
                      name="departmentId"
                      emptyOptionLabel="מחלקה"
                      options={departmentsOptions}
                      validate={FieldsValidator.required}
                    />
                  </Col>
                  <Col xs={6} className="p-1">
                    פעיל
                  </Col>
                  <Col xs={6} className="p-1">
                    <FormCheckbox name="isActive" />
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <FormLabel>תמונה</FormLabel>
                <FormFileUpload
                  name="image"
                  onUploaded={() => {
                    form.change("isDeleteImage", false);
                  }}
                />
                <div>
                  <FormSpy
                    subscription={{ values: true }}
                    render={({ values }) => (
                      <FormFileViewer
                        pathName="imageName"
                        uploadName="image"
                        fileDir={FileDir.Product}
                        onDelete={() => {
                          if (form.getState().values.image) {
                            form.change("image", undefined);
                          } else {
                            form.change("isDeleteImage", true);
                          }
                        }}
                        isDeleted={values.isDeleteImage && !values.image}
                        imageProps={{
                          style: {
                            objectFit: "contain",
                            maxHeight: 240,
                          },
                        }}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            <FormSpy<Partial<ProductForm>> subscription={{ values: true }}>
              {({ values }) => {
                const { ingredientId, manufacturerId } = values;
                const selectedIngredient = ingredients?.find(
                  (ing) => ing.id === ingredientId
                );
                const selectedManufacturer = manufacturers?.find(
                  (man) => man.id === manufacturerId
                );

                const relationName =
                  selectedIngredient && selectedManufacturer
                    ? TextFormatter.format("$1 ($2)", [
                        selectedIngredient.name,
                        selectedManufacturer.name,
                      ])
                    : "?";

                return (
                  <>
                    {mode !== "add" && product && ingredientId && (
                      <ProductSupplyMethodsEditor
                        productId={product.id!}
                        ingredientId={ingredientId}
                      />
                    )}
                    <AllergensModal
                      show={showAllergens}
                      relationType={AllergenRelationType.Product}
                      relationId={product?.id}
                      relationName={relationName}
                      onCancel={onHideAllergens}
                    />
                    <NutritionalsModal
                      show={showNutritionals}
                      relationType={NutritionalRelationType.Product}
                      relationId={product?.id}
                      relationName={relationName}
                      onCancel={onHideNutritionals}
                    />
                  </>
                );
              }}
            </FormSpy>
            <ButtonGroup className="mt-3">
              {mode === "add" && (
                <div className="mx-2">
                  <Button
                    onClick={() => {
                      actionRef.current = "saveAndGoToEdit";
                      handleSubmit();
                    }}
                  >
                    שמירה ומעבר לעריכת מוצר
                  </Button>
                </div>
              )}
              <div className="mx-2">
                <Button
                  onClick={() => {
                    actionRef.current = "save";
                    handleSubmit();
                  }}
                >
                  שמירה
                </Button>
              </div>
            </ButtonGroup>
          </>
        )}
      </Form>
    </>
  );
};
