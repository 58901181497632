import React, { useMemo } from "react";
import { useLocalStore } from "mobx-react";
import { AddOrderContext, AddOrderContextType } from "./AddOrderContext";
import { AddOrderStore } from "./AddOrderStore";

export const AddOrderContextProvider: React.FC = ({ children }) => {
  const store = useLocalStore(() => new AddOrderStore());

  const context = useMemo<AddOrderContextType>(
    () => ({
      store,
      setDisplayVariat: store.setDisplayVariat,
      setRouteSearchPhrase: store.setRouteSearchPhrase,
      setSpeechPhrase: store.setSpeechPhrase,
    }),
    [store]
  );

  return (
    <AddOrderContext.Provider value={context}>
      {children}
    </AddOrderContext.Provider>
  );
};
