import { Modal } from "react-bootstrap";
import React from "react";
import "./BarcodeModal.scss";
import { useBarcodeListener } from "./useBarcodeListener";

export interface SpeechModalProps {
  show: boolean;
  onHide: () => void;
  onBarcodeReceived: (barcode: number) => void;
}

export const BarcodeModal: React.FC<SpeechModalProps> = ({
  show,
  onHide,
  onBarcodeReceived,
}) => {
  useBarcodeListener((barcode) => {
    onBarcodeReceived(barcode);
  }, show);

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="BarcodeModal">
      <>
        <div className="boxContainer">
          <div className="box box1" />
          <div className="box box2" />
          <div className="box box3" />
          <div className="box box4" />
          <div className="box box5" />
        </div>
        <span className="loadingText">ממתין לסריקה</span>
      </>
    </Modal>
  );
};
