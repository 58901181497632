import React, { useMemo, useState } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { StockTransactionsApi } from "stockTransactions/StockTransactionsApi";
import {
  CardsTableProps,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import {
  StockTransactionListItem,
  StockTransactionsFilters,
} from "stockTransactions/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { isEmpty, every } from "lodash";
import { Form } from "form/components/Form";
import { Row, Col, Button } from "react-bootstrap";
import { FormTextInput } from "form/components/FormTextInput";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const StockTransactionsScreen: React.FC = () => {
  const [filters, setFilters] = useState<StockTransactionsFilters>({});
  const { data } = useQuery(StockTransactionsApi.listQuery.build());
  const {
    data: filteredData,
    isLoading: isSearchLoading,
    isFetching: isSearchFetching,
  } = useQuery(
    StockTransactionsApi.filteredListQuery.build(filters, {
      enabled: !isEmpty(filters),
    })
  );

  const columns = useMemo<CardsTableProps<StockTransactionListItem>["columns"]>(
    () => [
      {
        id: "Id",
        accessor: "id",
        Header: "מזהה",
      },
      {
        id: "TransactionType",
        accessor: (row) =>
          `${row.transactionTypeCode} - ${row.transactionType}`,
        Header: "סוג תנועה",
        cellProps: {},
      },
      {
        id: "ReferenceNo",
        accessor: "referenceNo",
        Header: "אסמכתא",
        cellProps: {},
      },
      {
        id: "itemName",
        accessor: "itemName",
        Header: "פריט",
        cellProps: {},
      },
      {
        id: "SupplierName",
        accessor: "supplierName",
        Header: "ספק",
        cellProps: {},
      },
      {
        id: "amount",
        accessor: "amount",
        Header: "כמות",
        cellProps: {},
        Cell: ({ value, row }) => (
          <span>
            {NumberFormatter.default.formatAmount(value)}{" "}
            {UnitTypeFormatter.default.format(row.original.unitType, true)}
          </span>
        ),
      },
      {
        id: "price",
        accessor: "price",
        Header: "מחיר",
        cellProps: {},
        Cell: TableAmountCell,
      },
      {
        id: "ourOrderId",
        accessor: "ourOrderId",
        Header: "הזמנה",
        cellProps: {},
      },
      {
        id: "transactionDate",
        accessor: "transactionDate",
        Header: "תאריך משלוח",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "dateCreated",
        accessor: "dateCreated",
        Header: "יצירה",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "actions",
        Cell: ({ row }) => (
          <i
            className="fas fa-trash text-danger ml-auto"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              const { id } = row.original;
              StockTransactionsApi.delete(id);
            }}
          />
        ),
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <Typography variant="route-title"> תנועות מלאי</Typography>
      <Form
        onSubmit={(values: StockTransactionsFilters, form) => {
          const isAllFiltersEmpty = every(values, (x) => !x);

          if (isAllFiltersEmpty) {
            setFilters({});
            FormCustomReset.hardReset(form);
          } else {
            setFilters(values);
          }
        }}
        render={({ handleSubmit, form }) => (
          <>
            <Row>
              <Col md={4}>
                <FormTextInput name="referenceNo" placeholder="אסמכתא" />
              </Col>
              <Col xs="auto" className="d-flex justify-content-end">
                <div className="mx-1">
                  <Button
                    onClick={() => {
                      setFilters({});
                      FormCustomReset.hardReset(form);
                    }}
                  >
                    נקה
                  </Button>
                </div>
                <div className="mx-1">
                  <Button onClick={handleSubmit} variant="success">
                    חפש
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
      <CardsTable
        columns={columns}
        getRowProps={(row) =>
          row.original.isCancelled ? { "data-line-through": true } : undefined
        }
        data={filteredData ?? data}
        localStorageKey={LocalStorageKeysHelper.dataTable("stockTransactions")}
        searchable={false}
        isLoading={isSearchLoading || isSearchFetching}
      />
    </RouteContent>
  );
};
