import React, { useCallback } from "react";
import styled from "styled-components";
import { FormGroup, Col, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { RoundButton } from "base/components/RoundButton";
import { Supplier, PaymentTermsOptions } from "supplier/types";
import { SuppliersApi } from "supplier/SuppliersApi";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { SupplierFormData } from "../types";
import { Form } from "form/components/Form";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FormTextInput } from "form/components/FormTextInput";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { OrderAnnouncementTypeOptions } from "order/constants";
import { FileDir } from "base/types";
import { useQuery } from "react-query";

// When rendering, <ImageViewerWrap />, will have max-width: 240.
const ImageViewerWrap = styled.div({
  maxWidth: 240,
});

export type SupplierFormProps = {
  data: Partial<Supplier> | undefined;
};

export const SupplierForm: React.FC<SupplierFormProps> = ({ data }) => {
  const navigate = useNavigateCallback();
  const { data: departments } = useQuery(SuppliersApi.departmentsQuery.build());
  const departmentOptions = useSelectOptionsAdapter(departments);

  const onSave = useCallback(
    async (supplierForm: SupplierFormData) => {
      await SuppliersApi.saveItem(supplierForm);
      navigate(SuppliersRouteBuilder.buildSuppliersRoute());
    },
    [navigate]
  );

  return (
    <Form onSubmit={onSave} initialValues={data}>
      {({ handleSubmit }) => (
        <>
          <FormGroup className="d-flex flex-wrap">
            <Col xs={12} md={9}>
              <>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    שם
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormTextInput name="name" />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    מחלקה
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormSelect
                      emptyOptionLabel="בחר מחלקה"
                      name="departmentId"
                      options={departmentOptions}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    קוד ישות
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormTextInput name="id" />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    תנאי תשלום
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormSelect
                      name={"paymentTerms"}
                      options={PaymentTermsOptions}
                      displayEmptyOption={false}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    מינימום להזמנה
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormNumberInput name="minimunOrderPrice" />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    צורת שליחת הזמנה
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormSelect
                      name="announcementTypes"
                      isMulti
                      options={OrderAnnouncementTypeOptions}
                      variant="react-select"
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex row">
                  <Col xs={5} sm={5} md={4} xl={3}>
                    פעיל
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={6} xl={5}>
                    <FormCheckbox name="isActive" />
                  </Col>
                </FormGroup>
              </>
            </Col>
            <Col xs={12} md={3} className="align-self-start">
              <FormLabel>תמונה</FormLabel>
              <FormFileUpload name="image" />
              <ImageViewerWrap>
                <FormFileViewer
                  pathName="imageName"
                  uploadName="image"
                  fileDir={FileDir.Supplier}
                />
              </ImageViewerWrap>
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col xs={8} sm={7} md={6} lg={5} xl={4} className="text-center">
              <RoundButton onClick={handleSubmit}>
                <FontAwesomeIcon icon={faCheck} size="lg" />
              </RoundButton>
            </Col>
          </FormGroup>
        </>
      )}
    </Form>
  );
};
