import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { WorkSchedule } from "work-schedule/components/WorkSchedule";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";
import { useQuery } from "react-query";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";

export const WorkScheduleScreen = () => {
  const { data, isLoading, isFetching } = useQuery(
    WorkScheduleApi.templateSchedulesQuery.build()
  );

  useFullScreenLoader(isLoading || isFetching);

  return (
    <RouteContent>
      <WorkSchedule schedules={data ?? []} />
    </RouteContent>
  );
};
