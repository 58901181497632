import React, { useMemo } from "react";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import {
  WeekStockRangeEntityRelation,
  WeekStockRange,
  DayStockRange,
} from "./types";
import { WeekStockRangesApi } from "./WeekStockRangesApi";
import { debounce, isEqual } from "lodash";
import { FormSpy } from "react-final-form";
import { UnitTypeFormatter } from "base/utils/formatters";
import { useQuery } from "react-query";
import { skipToken } from "base/api/ApiQuery";

export interface WeekStockRangesTableProps {
  weekStockRangeId: number | undefined;
  entityRelation: WeekStockRangeEntityRelation;
  unitType: number;
}

export const WeekStockRangesTable: React.FC<WeekStockRangesTableProps> = ({
  weekStockRangeId,
  entityRelation,
  unitType,
}) => {
  const weekFieldName = (
    dayProperty: keyof Omit<WeekStockRange, "id">,
    rangeKey: keyof DayStockRange
  ) => `${dayProperty}.${rangeKey}`;
  const { data: weekStockRanges } = useQuery(
    WeekStockRangesApi.rangesQuery.build(weekStockRangeId ?? skipToken)
  );

  const isDataLoaded =
    weekStockRangeId === undefined || weekStockRanges !== undefined;

  const onAutoSave = useMemo(
    () => debounce(WeekStockRangesApi.saveRanges, 500),
    []
  );

  return (
    <Form<WeekStockRange>
      onSubmit={onAutoSave}
      initialValues={{ ...entityRelation, ...weekStockRanges }}
    >
      {({ form }) => (
        <>
          <table style={{ borderCollapse: "separate", borderSpacing: 8 }}>
            <thead>
              <tr>
                <th style={{ width: 150 }} />
                <th style={{ textAlign: "center" }}>א</th>
                <th style={{ textAlign: "center" }}>ב</th>
                <th style={{ textAlign: "center" }}>ג</th>
                <th style={{ textAlign: "center" }}>ד</th>
                <th style={{ textAlign: "center" }}>ה</th>
                <th style={{ textAlign: "center" }}>ו</th>
                <th style={{ textAlign: "center" }}>ש</th>
              </tr>
            </thead>
            {isDataLoaded && (
              <tbody>
                <tr>
                  <td>
                    מלאי מינימום ({UnitTypeFormatter.format(unitType, true)})
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("sunday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("monday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("tuesday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("wednesday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("thursday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("friday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("saturday", "minStock")}
                      selectOnFocus
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    מלאי מקסימום ({UnitTypeFormatter.format(unitType, true)})
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("sunday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("monday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("tuesday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("wednesday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("thursday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("friday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                  <td>
                    <FormNumberInput
                      name={weekFieldName("saturday", "maxStock")}
                      selectOnFocus
                    />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <FormSpy
            subscription={{
              validating: true,
            }}
            onChange={({ validating }) => {
              const formState = form.getState();
              if (validating || !formState.valid) {
                onAutoSave.cancel();
                return;
              }

              const {
                values: nextValues,
                initialValues: prevValues,
              } = formState;

              if (
                nextValues &&
                prevValues &&
                !isEqual(nextValues, prevValues)
              ) {
                onAutoSave(nextValues);
              }
            }}
          />
        </>
      )}
    </Form>
  );
};
