import React, { useCallback, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";
import json2mq from "json2mq";
import { NumpadFormValues } from "./types";
import "./styles.scss";
import { Form } from "form/components/Form";
import { Field, FormSpy } from "react-final-form";
import { FormTextInput } from "form/components/FormTextInput";
import { EnterKeyCodeListener } from "../DocumentKeyupListener";

export type NumpadModalProps = {
  show: boolean;
  title?: string;
  initialValue?: number;
  onDone: (value: number) => void;
  onCancel: () => void;
};

export const NumpadModal: React.FC<NumpadModalProps> = ({
  show,
  title = "הזנת משקל",
  initialValue = 0,
  onDone,
  onCancel,
}) => {
  const isDesktop = useMediaQuery(json2mq({ minWidth: 900 }));

  const onSave = useCallback(
    ({ value }: NumpadFormValues) => {
      if (value.indexOf(".") === value.length - 1) {
        // If value ends with Dot, remove the dot.
        onDone(parseInt(value.substr(0, value.length - 1)));
      } else {
        onDone(parseFloat(value));
      }
    },
    [onDone]
  );

  const [afterFirstButtonClick, setAfterFirstButtonClick] = useState(false);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="numpad-modal"
      centered
      onShow={() => setAfterFirstButtonClick(false)}
    >
      <div className="numpad-modal-content">
        <div className="title">{title}</div>
        <Form
          initialValues={{ value: String(initialValue) }}
          onSubmit={onSave}
          render={({ handleSubmit, form }) => (
            <>
              {!isDesktop && (
                <Field
                  name="value"
                  render={({ input: { value } }) => (
                    <div className="number" dir="ltr">
                      {value}
                    </div>
                  )}
                />
              )}
              {isDesktop && (
                <div style={{ margin: "4px 8px" }}>
                  <FormTextInput name="value" autoFocus selectOnFocus />
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {
                      const { value } = values;
                      const val1 = (value ?? "").replace(/[^0-9.]/g, "");
                      const val2 =
                        val1.split(".").length > 2 || val1.indexOf(".") === 0
                          ? val1.replace(/\.+$/, "")
                          : val1;
                      form.change("value", val2);
                    }}
                  />
                </div>
              )}
              <Row noGutters dir="ltr" className="justify-content-center">
                {([1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "del"] as const).map(
                  (slot) => {
                    if (slot === "del") {
                      return (
                        <Col
                          key={slot}
                          xs={4}
                          className="p-2 d-flex justify-content-center"
                        >
                          <div
                            className="num-slot"
                            role="button"
                            onClick={() => {
                              const {
                                value: currValue,
                              } = form.getState().values;

                              if (currValue === undefined) {
                                // no value. do nothing.
                                return;
                              } else {
                                if (currValue.length === 1) {
                                  form.change("value", "0");
                                } else {
                                  form.change(
                                    "value",
                                    currValue.substr(0, currValue.length - 1)
                                  );
                                }
                              }
                            }}
                          >
                            Del
                          </div>
                        </Col>
                      );
                    } else {
                      return (
                        <Col
                          key={slot}
                          xs={4}
                          className="p-2 d-flex justify-content-center"
                        >
                          <div
                            className="num-slot"
                            role="button"
                            onClick={() => {
                              if (!afterFirstButtonClick) {
                                form.change("value", "0");
                              }
                              setAfterFirstButtonClick(true);
                              const {
                                value: currValue,
                              } = form.getState().values;

                              if (
                                slot === "." &&
                                (currValue === undefined ||
                                  currValue.indexOf(".") !== -1)
                              ) {
                                return;
                              }

                              if (
                                (currValue === undefined ||
                                  currValue === "0") &&
                                slot !== "."
                              ) {
                                form.change("value", String(slot));
                              } else {
                                form.change("value", `${currValue}${slot}`);
                              }
                            }}
                          >
                            {slot}
                          </div>
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
              <div className="text-center p-3">
                <EnterKeyCodeListener active={show} onEnter={handleSubmit}>
                  <Button className="btn-xl" onClick={handleSubmit}>
                    אישור
                  </Button>
                </EnterKeyCodeListener>
              </div>
            </>
          )}
        />
      </div>
    </Modal>
  );
};
