import React, { useCallback, useRef } from "react";
import {
  FormGroup,
  Col,
  FormLabel,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { Material } from "material/types";
import { MaterialsApi } from "material/MaterialsApi";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { MaterialFormFields } from "./MaterialFormFields";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeType } from "base/types";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { Form } from "form/components/Form";
import { FormValuesWithUpload } from "form/types";

export type MaterialFormProps = {
  data: Partial<Material> | undefined;
};

export const MaterialForm: React.FC<MaterialFormProps> = ({ data }) => {
  const navigate = useNavigateCallback();
  const actionRef = useRef<"save" | "saveAndGoToTreeInfo">("save");

  const onSave = useCallback(
    async (values: FormValuesWithUpload<Material>) => {
      const savedMaterial = await MaterialsApi.saveItem(values);

      if (actionRef.current === "save") {
        navigate(MaterialsRouteBuilder.buildMaterialsRoute());
      } else {
        navigate(
          TreeInfoRouteBuilder.buildTreeInfoRoute(
            TreeType.Material,
            savedMaterial.id
          )
        );
      }
    },
    [navigate]
  );

  return (
    <Form onSubmit={onSave} initialValues={data}>
      {({ handleSubmit }) => (
        <>
          <FormGroup className="d-flex flex-wrap">
            <Col xs={12} md={9}>
              <MaterialFormFields />
            </Col>
            <Col xs={12} md={3} className="align-self-start">
              <FormLabel>תמונה</FormLabel>
              <FormFileUpload name="image" />
              <div>
                <FormFileViewer
                  type="image"
                  pathName="imageName"
                  uploadName="image"
                />
              </div>
            </Col>
          </FormGroup>
          <ButtonGroup>
            <div className="mx-2">
              <Button
                onClick={() => {
                  actionRef.current = "saveAndGoToTreeInfo";
                  handleSubmit();
                }}
              >
                שמירה ומעבר לעץ מוצר
              </Button>
            </div>
            <div className="mx-2">
              <Button
                onClick={() => {
                  actionRef.current = "save";
                  handleSubmit();
                }}
              >
                שמירה
              </Button>
            </div>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
};
