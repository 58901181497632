import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { DepartmentList } from "order/components/DepartmentList";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";

export const DepartmentListScreen: React.FC = () => {
  return (
    <RouteContent className="OrderDepartmentListScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      <DepartmentList />
    </RouteContent>
  );
};
