import React from "react";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { Row, Col } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { ProductForm, Product } from "product/types";
import { ProductsApi } from "product/ProductsApi";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { SimpleGlobalListItemModal } from "global-list/components/SimpleGlobalListItemModal";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { BaseSelectListItem } from "base/types";
import { useQuery } from "react-query";

export type ProductSelectListItemEditorProps = {
  show: boolean;
  initialValues?: Product;
  onItemSaved: (savedItem: Product) => void;
};

export const ProductSelectListItemEditor: React.FC<ProductSelectListItemEditorProps> = ({
  show,
  initialValues,
  onItemSaved,
}) => {
  const { data: ingredients } = useQuery(
    IngredientsApi.listQuery.build({
      enabled: show,
    })
  );
  const ingredientsOptions = useSelectOptionsAdapter(ingredients);

  const { data: manufacturers } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.Manufacturers, {
        enabled: show,
      })
  );
  const manufacturerOptions = useSelectOptionsAdapter(manufacturers);

  const onSubmit = async (item: ProductForm) => {
    const savedItem = await ProductsApi.saveItem(item);
    onItemSaved(savedItem);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ isActive: true, ...initialValues }}
    >
      {({ handleSubmit }) => (
        <>
          <Typography variant="title" className="m-2 text-center">
            הוספת מוצר
          </Typography>
          <div className="p-4">
            <Row noGutters>
              <Col md={6} className="p-2">
                <FormSelect
                  variant="react-select"
                  name="ingredientId"
                  emptyOptionLabel="מרכיב"
                  options={ingredientsOptions}
                  validate={FieldsValidator.required}
                />
              </Col>
              <Col md={6} className="p-2">
                <FormSelect
                  variant="react-select"
                  name="manufacturerId"
                  emptyOptionLabel="יצרן"
                  options={manufacturerOptions}
                  validate={FieldsValidator.required}
                  withAdd={{
                    customModalRender: (props) => (
                      <SimpleGlobalListItemModal
                        {...props}
                        routeApiPath={SimpleGlobalListRoutePath.Manufacturers}
                      />
                    ),
                  }}
                />
              </Col>
            </Row>
            <button
              className="my-4 mx-2 btn btn-primary"
              onClick={handleSubmit}
            >
              שמירה
            </button>
          </div>
        </>
      )}
    </Form>
  );
};
