import Axios, { AxiosError } from "axios";
import { UseQueryOptions } from "react-query";
import { Updater } from "react-query/types/core/utils";
import { queryClient } from "app/queryClient";
import {
  SkipTokenType,
  ApiQueryUrlKeyOption,
  ApiQueryBuildFn,
  ApiQueryTagsOption,
} from "./types";
import { QueryCacheHelper } from "./QueryCacheHelper";

export const skipToken: SkipTokenType = "$_$_SKIP_$_$";

export class ApiQuery<Data, Arg = void> {
  private buildUrlKey: (arg: Arg) => string;

  private buildTags: (arg: Arg) => string[];

  buildKey: (arg: Arg) => string[];

  constructor(
    urlKey: ApiQueryUrlKeyOption<Arg>,
    /* Extra keys that will relate to the query key */
    tags: ApiQueryTagsOption<Arg> = [],
    private defaultOptions?: UseQueryOptions<Data, AxiosError>
  ) {
    if (typeof urlKey === "function") {
      this.buildUrlKey = urlKey as any;
    } else {
      this.buildUrlKey = () => urlKey as any;
    }

    if (typeof tags === "function") {
      this.buildTags = tags;
    } else {
      this.buildTags = () => tags;
    }

    this.buildKey = (arg) => [this.buildUrlKey(arg), ...this.buildTags(arg)];
  }

  // Builds the options for useQuery hook.
  build: ApiQueryBuildFn<Data, Arg> = ((
    ...args: any[]
  ): UseQueryOptions<Data, AxiosError> => {
    const arg: Arg | SkipTokenType | undefined =
      args[0] === skipToken ? skipToken : this.parseArg(args[0]);

    const overrideOptions: UseQueryOptions<Data, AxiosError> | undefined =
      args.length === 1 ? args[0] : args[1];

    return {
      ...this.defaultOptions,
      ...overrideOptions,
      queryFn: async (key) => {
        const response = await Axios.get(key.queryKey[0] as string);
        return response.data;
      },
      queryKey: arg === skipToken ? skipToken : this.buildKey(arg!),
      enabled: arg !== skipToken,
    };
  }) as any;

  // Use this when query data doesn't consist of one type.
  dataCast<CastData>(): ApiQuery<CastData, Arg> {
    return this as any;
  }

  // Useful helper method to set the query cache data.
  updateCache(
    updater: Updater<Data | undefined, Data | undefined>,
    ...arg: void extends Arg ? [] : [Arg]
  ) {
    return queryClient.setQueryData<Data>(
      this.buildKey(arg[0] as Arg),
      updater
    );
  }

  // Useful helper method to invalidate the query.
  invalidate(...arg: void extends Arg ? [] : [Arg]) {
    return queryClient.invalidateQueries(this.buildKey(arg[0] as Arg));
  }

  // Useful helper method to invalidate queries by given tags.
  static invalidateByTags(tags: string[]) {
    return queryClient.invalidateQueries({
      predicate: QueryCacheHelper.buildTagsPredicate(tags),
    });
  }

  // Normalize tags representation.
  static buildTag(prefix: string, ...strs: string[]) {
    return `Tag--${prefix}++${strs.join("++")}`;
  }

  /* 
    1. Will convert arg of object shape, for example {x: 1, y: <skipToken>}, to skipToken.
    2. Will convert arg of array shape, for example [x, <skipToken>], to skipToken.
    3. For All other shapes/values, parseArg will return the given arg AS IS.
  */
  private parseArg(
    arg: Arg | SkipTokenType | { [key in keyof Arg]: Arg[key] | SkipTokenType }
  ): Arg | SkipTokenType {
    if (arg === skipToken) return skipToken;

    if (typeof arg !== "object") return arg;

    if (Object.values(arg).some((x) => x === skipToken)) return skipToken;

    return arg as Arg;
  }
}
