import React, { useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { DishVEItem } from "dish/types";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { UnitTypeFormatter } from "base/utils/formatters";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { EnterKeyCodeListener } from "base/components/DocumentKeyupListener";
import { StorageUnitItem } from "storage-unit/types";

export interface DishVEItemModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (values: DishVEItem) => void;
  onDelete: () => void;
  data?: {
    storageUnitItem: StorageUnitItem;
    initialValues?: DishVEItem;
  };
}

export const DishVEItemModal: React.FC<DishVEItemModalProps> = ({
  show,
  onHide,
  onSubmit,
  onDelete: onDeleteProp,
  data,
}) => {
  const fieldName = (property: keyof DishVEItem) => property;
  const p_show = show && data;
  const { storageUnitItem, initialValues } = data ?? {};

  const onSave = useCallback(
    (values: DishVEItem) => {
      onSubmit(values);
      onHide();
    },
    [onHide, onSubmit]
  );

  const onDelete = useCallback(() => {
    onDeleteProp();
    onHide();
  }, [onHide, onDeleteProp]);

  return (
    <Modal show={p_show} onHide={onHide} centered>
      <ModalTitleBar title={storageUnitItem?.name} onClose={onHide} />
      <div className="p-2">
        <Form
          initialValues={{ ...storageUnitItem, ...initialValues }}
          onSubmit={onSave}
        >
          {({ handleSubmit }) => (
            <>
              <div className="d-flex">
                <FormNumberInput
                  name={fieldName("amount")}
                  placeholder="כמות"
                  validate={FieldsValidator.required}
                  displayNumpadOnFocus
                  numpadTitle={`כמות רצויה - ${
                    data?.storageUnitItem.name
                  } (${UnitTypeFormatter.format(
                    data?.storageUnitItem.prepareUnitType,
                    true
                  )})`}
                  autoFocus
                />
                <div className="mx-1 d-flex align-items-center">
                  {UnitTypeFormatter.format(
                    storageUnitItem!.prepareUnitType,
                    true
                  )}
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-end">
                {initialValues && (
                  <div className="mx-1">
                    <Button variant="danger" onClick={onDelete}>
                      מחק
                    </Button>
                  </div>
                )}
                <div className="mx-1">
                  <EnterKeyCodeListener active={show} onEnter={handleSubmit}>
                    <Button onClick={handleSubmit}>שמור</Button>
                  </EnterKeyCodeListener>
                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
};
