import React from "react";
import { Switch, Route } from "react-router-dom";
import { AddOrderContextProvider } from "./context/AddOrderContextProvider";
import { OrdersRouteBuilder } from "./OrdersRouteBuilder";
import { OrderSearchProductsScreen } from "./screens/OrderSearchProductsScreen";
import { SupplierScreen } from "./screens/supplier/SupplierScreen";
import { SupplierListScreen } from "./screens/supplier/SupplierListScreen";
import { DepartmentScreen } from "./screens/department/DepartmentScreen";
import { DepartmentListScreen } from "./screens/department/DepartmentListScreen";
import { AddOrderHomeScreen } from "./screens/AddOrderHomeScreen";
import { OrdersStatusScreen } from "./screens/OrdersStatusScreen";
import { OrdersSimulationScreen } from "./screens/OrdersSimulationScreen";
import { OrdersHomeScreen } from "./screens/OrdersHomeScreen";

export const OrdersRouter: React.FC = () => {
  return (
    <>
      <AddOrderContextProvider>
        <Switch>
          <Route path={OrdersRouteBuilder.templates.OrderSearchProductsRoute}>
            <OrderSearchProductsScreen />
          </Route>
          <Route path={OrdersRouteBuilder.templates.SupplierRoute}>
            <SupplierScreen />
          </Route>
          <Route path={OrdersRouteBuilder.templates.SupplierListRoute}>
            <SupplierListScreen />
          </Route>
          <Route path={OrdersRouteBuilder.templates.DepartmentRoute}>
            <DepartmentScreen />
          </Route>
          <Route path={OrdersRouteBuilder.templates.DepartmentListRoute}>
            <DepartmentListScreen />
          </Route>
          <Route path={OrdersRouteBuilder.templates.AddOrderHomeRoute}>
            <AddOrderHomeScreen />
          </Route>
        </Switch>
      </AddOrderContextProvider>
      <Switch>
        <Route path={OrdersRouteBuilder.templates.OrdersStatusRoute}>
          <OrdersStatusScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.OrdersSimulationRoute}>
          <OrdersSimulationScreen />
        </Route>
        <Route path={OrdersRouteBuilder.templates.OrdersHomeRoute}>
          <OrdersHomeScreen />
        </Route>
      </Switch>
    </>
  );
};
