import { RouteContent } from "route-content/components/RouteContent";
import React from "react";
import { Typography } from "base/components/Typography";
import { StockTakingFormValues, StockTakingItem } from "stock/types";
import { MaterialsApi } from "material/MaterialsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { Row, Col, Button } from "react-bootstrap";
import { FieldArray } from "react-final-form-arrays";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";
import { UnitTypeFormatter } from "base/utils/formatters";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormSpy } from "react-final-form";
import { Form } from "form/components/Form";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { StocksApi } from "stock/StocksApi";
import { success } from "@pnotify/core";
import { FormDatePicker } from "form/components/FormDatePicker";
import { format as dateFormat } from "date-fns";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { useQuery } from "react-query";

export const StockTakingScreen: React.FC = () => {
  const fieldName = (property: keyof StockTakingFormValues) => property;
  const itemFieldName = (property: keyof StockTakingItem, prefix: string) =>
    `${prefix}.${property}`;

  const { data: materialsList } = useQuery(MaterialsApi.listQuery.build());
  const materialsOptions = useSelectOptionsAdapter(materialsList);

  const { data: ingredientsList } = useQuery(IngredientsApi.listQuery.build());
  const ingredientsOptions = useSelectOptionsAdapter(ingredientsList);

  return (
    <RouteContent>
      <Typography variant="route-title" className="mb-4">
        ספירת מלאי
      </Typography>
      <Form
        onSubmit={async (values: StockTakingFormValues, form) => {
          await StocksApi.performStockTaking({
            ...values,
            items: values.items.filter((x) => x.materialId || x.ingredientId),
          });
          success("המלאי עודכן בהצלחה");
          FormCustomReset.hardReset(form);
        }}
        subscription={{ valid: true, submitting: true }}
        initialValues={{
          items: [{} as StockTakingItem],
        }}
      >
        {({ valid, submitting, handleSubmit, form }) => (
          <>
            <Row className="mb-3">
              <Col xs={6} md="auto" className="d-flex align-items-center">
                תאריך הספירה
              </Col>
              <Col xs={6} md={3}>
                <FormDatePicker
                  name={fieldName("transactionDate")}
                  defaultValue={dateFormat(Date.now(), "yyyy-MM-dd")}
                />
              </Col>
            </Row>
            <FieldArray<StockTakingItem>
              name={fieldName("items")}
              subscription={{ value: true, length: true }}
            >
              {({ fields }) => (
                <>
                  {fields.map((prefix, index) => {
                    const isAtLeastFoodOrMaterialSelected =
                      fields.value[index].ingredientId !== undefined ||
                      fields.value[index].materialId !== undefined;

                    return (
                      <Row className="my-2">
                        <Col md={3}>
                          <FormSelect
                            name={itemFieldName("ingredientId", prefix)}
                            options={ingredientsOptions}
                            variant="react-select"
                            emptyOptionLabel="בחירת  מרכיב"
                            disabled={
                              fields.value[index].materialId !== undefined
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <FormSelect
                            name={itemFieldName("materialId", prefix)}
                            options={materialsOptions}
                            variant="react-select"
                            emptyOptionLabel="בחירת חומר גלם"
                            disabled={
                              fields.value[index].ingredientId !== undefined
                            }
                            validate={(...args) => {
                              const allVals = args[1] as StockTakingFormValues;

                              // If deleted, ignore validation(Stupid react final form)
                              if (index >= allVals.items.length) {
                                return Promise.resolve();
                              }

                              const isLastItemAndAmountNotSet =
                                index === allVals.items.length - 1 &&
                                allVals.items[index].currentStock === undefined;

                              if (
                                allVals.items[index].ingredientId !==
                                  undefined ||
                                allVals.items[index].materialId !== undefined ||
                                isLastItemAndAmountNotSet
                              ) {
                                return Promise.resolve();
                              } else {
                                return Promise.resolve(
                                  "יש לבחור חומר גלם או מנה"
                                );
                              }
                            }}
                          />
                        </Col>
                        <Col md={2}>
                          <FormNumberInput
                            name={itemFieldName("currentStock", prefix)}
                            placeholder="כמות עדכנית"
                            validate={(...args) => {
                              /* 
                                This field is required if 
                                  first in array, 
                                  Or in the middle, 
                                  Or isAtLeastFoodOrMaterialSelected.
                                  */

                              const allVals = args[1] as StockTakingFormValues;

                              // If deleted, ignore validation(Stupid react final form)
                              if (index >= allVals.items.length) {
                                return Promise.resolve();
                              }

                              if (
                                allVals.items.length === 1 ||
                                index < allVals.items.length - 1 ||
                                allVals.items[index].ingredientId !==
                                  undefined ||
                                allVals.items[index].materialId !== undefined
                              ) {
                                return FieldsValidator.required(...args);
                              }

                              return Promise.resolve();
                            }}
                            disabled={!isAtLeastFoodOrMaterialSelected}
                            maxDigitsAfterDot={3}
                          />
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                          {(() => {
                            const selectedMaterial = materialsList?.find(
                              (x) => x.id === fields.value[index].materialId
                            );
                            const selectedIngredient = ingredientsList?.find(
                              (x) => x.id === fields.value[index].ingredientId
                            );
                            return (
                              <span>
                                {UnitTypeFormatter.format(
                                  selectedIngredient?.prepareUnitType ??
                                    selectedMaterial?.unitType
                                )}
                              </span>
                            );
                          })()}
                        </Col>
                        {fields.value.length > 1 && (
                          <Col xs="auto" className="d-flex align-items-center">
                            <i
                              className="fas fa-times"
                              role="button"
                              onClick={() => {
                                const { items } = form.getState().values;
                                form.change(
                                  "items",
                                  items.filter((_, indexx) => indexx !== index)
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                  {/* Handle auto add row when form is valid and has amount for last item. */}
                  <FormSpy<StockTakingFormValues>
                    subscription={{ valid: true, values: true }}
                    onChange={() => {
                      const { valid, values } = form.getState();
                      const lastItem = values.items[values.items.length - 1];

                      if (valid && lastItem.currentStock !== undefined) {
                        fields.push({} as StockTakingItem);
                      }
                    }}
                  />
                </>
              )}
            </FieldArray>
            <div className="mt-5">
              <Button disabled={!valid || submitting} onClick={handleSubmit}>
                אישור
              </Button>
            </div>
          </>
        )}
      </Form>
    </RouteContent>
  );
};
