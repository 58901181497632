import React, { useCallback, useMemo, useState } from "react";
import { FormSpy, Field } from "react-final-form";
import { FormGroup, Col, Row, Modal } from "react-bootstrap";
import { debounce } from "lodash";
import { TreeType, UnitType } from "base/types";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { useBooleanState } from "base/hooks/useBooleanState";
import { UnitTypesSelectOptions } from "base/components/Select/constants/UnitTypesSelectOptions";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { FormSelect } from "form/components/FormSelect";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { TreeFoodWorksModal } from "food-works/tree-food-works/components/TreeFoodWorksModal";
import { RecipeModal } from "recipe/components/RecipeModal";
import { MaterialsApi } from "material/MaterialsApi";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import "./../styles.scss";
import { AllergensModal } from "allergen/AllergensModal";
import { AllergenRelationType } from "allergen/types";
import { NutritionalRelationType } from "nutritional/types";
import { NutritionalsModal } from "nutritional/NutritionalsModal";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { WeekStockRangesTable } from "week-stock-range/WeekStockRangesTable";
import { TreeInfoHelper } from "tree-info/TreeInfoHelper";
import { TreeInfo } from "tree-info/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import Big from "big.js";
import ReactInputMask from "react-input-mask";
import { FormErrorMessage } from "form/components/FormErrorMessage";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { useQuery } from "react-query";

export type TreeInfoMaterialHeaderProps = {
  itemId: number;
};

export const TreeInfoMaterialHeader: React.FC<TreeInfoMaterialHeaderProps> = ({
  itemId,
}) => {
  const navigate = useNavigateCallback();
  const [totalPrice, setTotalPrice] = useState(0);
  const { data } = useQuery(MaterialsApi.itemQuery.build(itemId));
  const { data: departments } = useQuery(MaterialsApi.departmentsQuery.build());
  const departmentOptions = useSelectOptionsAdapter(departments);

  const [showFoodWorks, onShowFoodWorks, onHideFoodWorks] = useBooleanState(
    false
  );

  const [showRecipe, onShowRecipe, onHideRecipe] = useBooleanState(false);

  const [showAllergens, onShowAllergens, onHideAllergens] = useBooleanState(
    false
  );

  const [
    showNutritonals,
    onShowNutritonals,
    onHideNutritonals,
  ] = useBooleanState(false);

  const [
    showWeekStockRanges,
    onShowWeekStockRanges,
    onHideWeekStockRanges,
  ] = useBooleanState(false);

  const onAddRecipe = useCallback(async () => {
    await MaterialsApi.addRecipe(itemId);
    onShowRecipe();
  }, [itemId, onShowRecipe]);

  const onSubmit = useMemo(
    () => debounce(MaterialsApi.saveItem, 200, { maxWait: 350 }),
    []
  );

  const onDelete = useCallback(async () => {
    if (window.confirm("האם למחוק?")) {
      onSubmit.cancel();
      try {
        await MaterialsApi.deleteItem(itemId);
        navigate(MaterialsRouteBuilder.buildMaterialsRoute());
      } catch (e) {
        alert(`לא ניתן למחוק פריט זה: \n${e?.response?.data}`);
      }
    }
  }, [itemId, navigate, onSubmit]);

  return (
    <>
      {/* Calculate the totalPrice, and save to state. */}
      <FormSpy<Partial<TreeInfo>>
        subscription={{ values: true }}
        onChange={({ values }) => {
          const totalPrice = TreeInfoHelper.calculateTotalPrice([
            ...(values.ingredients ?? []),
            ...(values.materials ?? []),
          ]);

          setTotalPrice(totalPrice);
        }}
      />
      <Form onSubmit={onSubmit} initialValues={data}>
        {({ handleSubmit }) => (
          <>
            <FormGroup className="d-flex flex-wrap tree-info-header-container">
              <Col sm={8}>
                <FormGroup className="d-flex flex-wrap mb-0">
                  <Col md={4} className="p-1">
                    <FormTextInput name="name" placeholder="שם המרכיב" />
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        כמות תוצר כוללת
                      </label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="totalAmount"
                          placeholder="כמות תוצר כוללת"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">מלאי</label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="currentStock"
                          placeholder="מלאי"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <Col sm={1} /> {/* Placeholder */}
                    <FormSelect
                      name="departmentId"
                      emptyOptionLabel="בחר מחלקה"
                      options={departmentOptions}
                    />
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        יח' מידה הכנה
                      </label>
                      <div className="col-sm-5">
                        <FormSelect
                          name="unitType"
                          emptyOptionLabel="יח מידה הכנה"
                          options={UnitTypesSelectOptions}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        מלאי מינימום
                      </label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="minStock"
                          placeholder="מלאי מינימום"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <div className="d-flex align-items-center">
                      <span>פעיל:&nbsp;&nbsp;</span>
                      <div style={{ width: 40 }}>
                        <FormCheckbox name="isActive" />
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        משך טריות (שעות.ימים)
                        <br /> [0-23].[0-999]
                      </label>
                      <div className="col-sm-5">
                        <Field
                          name="freshnessPeriod"
                          validate={FieldsValidator.timeSpanRange(
                            "999.23:00:00"
                          )}
                          format={(x: string | undefined) => x?.slice(0, 6)}
                          parse={(x: string | undefined) =>
                            x ? `${x}:00:00` : undefined
                          }
                          render={({ input, meta }) => (
                            <>
                              <ReactInputMask
                                mask={"999.99"}
                                alwaysShowMask
                                dir="ltr"
                                className="form-control"
                                {...input}
                              />
                              <FormErrorMessage>
                                {meta.touched ? meta.error : meta.submitError}
                              </FormErrorMessage>
                            </>
                          )}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        מלאי מקסימום
                      </label>
                      <div className="col-sm-5">
                        <FormNumberInput
                          name="maxStock"
                          placeholder="מלאי מקסימום"
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        עלות חומרים
                      </label>
                      <div className="col-sm-5 d-flex align-items-center">
                        {NumberFormatter.default.formatPrice(totalPrice)}
                      </div>
                    </Row>
                  </Col>
                  <Col md={8} />
                  <Col md={4} className="p-1">
                    <Row>
                      <label className="col-sm-7 col-form-label">
                        מחיר ל{UnitTypeFormatter.format(data?.unitType)}
                      </label>
                      <div className="col-sm-5 d-flex align-items-center">
                        {data?.unitType === UnitType.Yeh &&
                          NumberFormatter.default.formatPrice(totalPrice)}
                        {data?.unitType !== UnitType.Yeh && (
                          <Field
                            name="totalAmount"
                            render={({ input: { value: totalAmount } }) =>
                              totalAmount &&
                              totalPrice &&
                              NumberFormatter.default.formatPrice(
                                Number(
                                  Big(1000).div(totalAmount * totalPrice)
                                ) * 100
                              )
                            }
                          />
                        )}
                      </div>
                    </Row>
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormFileUpload name="image" />
                <div className="w-75">
                  <FormFileViewer uploadName="image" pathName="imageName" />
                </div>
              </Col>
              <Col sm={2}>
                <FormGroup className="d-flex flex-wrap mb-0">
                  {data?.recipeId === undefined && (
                    <div
                      className="btn w-100 m-1 btn-success"
                      onClick={onAddRecipe}
                    >
                      הוספת מתכון
                    </div>
                  )}
                  {data?.recipeId !== undefined && (
                    <div
                      className="btn w-100 m-1 btn-primary"
                      onClick={onShowRecipe}
                    >
                      ניהול מתכון
                    </div>
                  )}
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowFoodWorks}
                  >
                    ניהול עיבודים
                  </div>
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowAllergens}
                  >
                    ניהול אלרגנים
                  </div>
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowNutritonals}
                  >
                    ניהול ערכים תזונתיים
                  </div>
                  <div
                    className="btn btn-primary w-100 m-1"
                    onClick={onShowWeekStockRanges}
                  >
                    טבלת מלאי שבועית
                  </div>
                  <div className="btn btn-danger w-100 m-1" onClick={onDelete}>
                    מחיקה
                  </div>
                </FormGroup>
              </Col>
            </FormGroup>
            <FormSpy
              subscription={{
                values: true,
                dirty: true,
                dirtySinceLastSubmit: true,
                submitSucceeded: true,
              }}
              onChange={({ dirty, dirtySinceLastSubmit, submitSucceeded }) => {
                if (dirty && (!submitSucceeded || dirtySinceLastSubmit)) {
                  handleSubmit();
                }
              }}
            />
            <TreeFoodWorksModal
              show={showFoodWorks}
              treeId={itemId}
              treeType={TreeType.Material}
              onCancel={onHideFoodWorks}
            />
            <RecipeModal
              treeType={TreeType.Material}
              show={showRecipe}
              onHide={onHideRecipe}
              id={data?.recipeId}
              materialData={{
                id: itemId,
              }}
            />
            <AllergensModal
              show={showAllergens}
              relationType={AllergenRelationType.Material}
              relationId={itemId}
              relationName={data?.name}
              onCancel={onHideAllergens}
            />
            <NutritionalsModal
              show={showNutritonals}
              relationType={NutritionalRelationType.Material}
              relationId={itemId}
              relationName={data?.name}
              onCancel={onHideNutritonals}
            />
            <Modal
              show={showWeekStockRanges}
              onHide={onHideWeekStockRanges}
              size="lg"
            >
              <ModalTitleBar
                title="טבלת מלאי שבועית"
                onClose={onHideWeekStockRanges}
              />
              <div className="p-2">
                {data && (
                  <WeekStockRangesTable
                    weekStockRangeId={data.weekStockRangeId}
                    entityRelation={{ materialId: itemId }}
                    unitType={data.unitType}
                  />
                )}
              </div>
            </Modal>
          </>
        )}
      </Form>
    </>
  );
};
