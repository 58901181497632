import "./style.scss";
import React, { useMemo, useCallback } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { SupplierListItem } from "supplier/types";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { SuppliersApi } from "supplier/SuppliersApi";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { PaymentTermsFormatter } from "supplier/utils/PaymentTermsFormatter";
import { CheckboxColumnFilter } from "base/components/tables/components/CheckboxColumnFilter";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const SuppliersTable = () => {
  const { data } = useQuery(SuppliersApi.listQuery.build());
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(SuppliersRouteBuilder.buildAddSupplierRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: SupplierListItem) => {
      navigate(SuppliersRouteBuilder.buildEditSupplierRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<SupplierListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <FileViewer
            type="image"
            fileDir={FileDir.Supplier}
            fileName={value}
          />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם היצרן",
      },
      {
        id: "Id",
        accessor: "id",
        Header: "קוד ישות",
      },
      {
        id: "DepartmentName",
        accessor: "departmentName",
        Header: "מחלקה",
        cellProps: {},
      },
      {
        id: "PaymentTerms",
        accessor: (row) => PaymentTermsFormatter.format(row.paymentTerms),
        Header: "תנאי תשלום",
        cellProps: {},
      },
      {
        id: "DistributionDays",
        accessor: "distributionDays",
        Header: "ימי חלוקה",
        cellProps: {},
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        Filter: CheckboxColumnFilter,
        sortType: "boolean",
      },
      {
        id: "DateCreated",
        accessor: (row) => row.dateCreated,
        Header: "נוצר",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey={LocalStorageKeysHelper.dataTable("suppliers")}
      globalFilterAllowedCols={["Name"]}
      initialFilters={[
        {
          id: "IsActive",
          value: "true",
        },
      ]}
    />
  );
};
