import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { IngredientsTable } from "ingredient/components/IngredientsTable";

export const IngredientsScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title"> מרכיבים</Typography>
      <IngredientsTable />
    </RouteContent>
  );
};
