import axios from "axios";
import { DepartmentFoodWork } from "./types/DepartmentFoodWork";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class DepartmentFoodWorksApi {
  static itemsQuery = new ApiQuery<DepartmentFoodWork[], number>(
    (departmentId) => `/api/departments/${departmentId}/foodworks`
  );

  static async addFoodWork(
    departmentId: number,
    departmentFoodWork: Omit<DepartmentFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks`,
      departmentFoodWork
    );

    DepartmentFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildAddListItemUpdater(newFoodWork),
      departmentId
    );
  }

  static async updateFoodWork(
    departmentId: number,
    departmentFoodWork: DepartmentFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks`,
      departmentFoodWork
    );

    DepartmentFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildUpdateListItemUpdater(modifiedFoodWork, "id"),
      departmentId
    );
  }

  static async deleteFoodWork(departmentId: number, foodWorkId: number) {
    await axios.delete<DepartmentFoodWork>(
      `/api/departments/${departmentId}/foodworks/${foodWorkId}`
    );

    DepartmentFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(foodWorkId, "id"),
      departmentId
    );
  }
}
