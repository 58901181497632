import { BaseSelectListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { DishListItem, Dish, DishFormData } from "./types";
import { Recipe } from "recipe/types";
import { RecipeApi } from "recipe/RecipeApi";
import { ApiQuery } from "base/api/ApiQuery";
import produce from "immer";
import Axios from "axios";

export class DishesApi {
  static listQuery = new ApiQuery<DishListItem[]>("/api/foods/items");

  static itemQuery = new ApiQuery<Dish, number>((id) => `/api/foods/${id}`);

  static itemDefaultsQuery = new ApiQuery<Partial<Dish>>("/api/foods/new");

  static departmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/foods/departments"
  );

  static async saveItem(item: WithPartialProperties<DishFormData, "id">) {
    const { data: savedItem } = await Axios.post<Dish>(
      "/api/foods",
      BodyFormDataBuilder.build(item),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    DishesApi.itemQuery.updateCache(savedItem, savedItem.id);

    DishesApi.listQuery.invalidate();

    return savedItem;
  }

  static async addMaterial(dishId: number, materialId: number, amount: number) {
    await Axios.post(`/api/foods/${dishId}/addItem`, {
      itemId: materialId,
      isIngred: false,
      amount,
    });

    DishesApi.itemQuery.invalidate(dishId);
  }

  static async addIngredient(
    dishId: number,
    ingredientId: number,
    amount: number
  ) {
    await Axios.post(`/api/foods/${dishId}/addItem`, {
      itemId: ingredientId,
      isIngred: true,
      amount,
    });

    DishesApi.itemQuery.invalidate(dishId);
  }

  static async addEmptyRecipe(id: number) {
    const { data: createdRecipe } = await Axios.post<Recipe>(
      `/api/foods/${id}/recipe/add`
    );

    RecipeApi.itemQuery.updateCache(createdRecipe, createdRecipe.id);

    DishesApi.itemQuery.invalidate(id);

    DishesApi.itemQuery.updateCache(
      produce((item) => {
        if (!item) return;

        item.recipeId = createdRecipe.id;
      }),
      id
    );
  }

  static async detachMaterial(dishId: number, materialId: number) {
    await Axios.post(`/api/foods/${dishId}/deleteItem`, {
      itemId: materialId,
      isIngred: false,
    });

    DishesApi.itemQuery.updateCache((item) => {
      return {
        ...item!,
        materials: item?.materials?.filter(
          (item) => item.materialId !== materialId
        ),
      };
    }, dishId);
  }

  static async detachIngredient(dishId: number, ingredientId: number) {
    await Axios.post(`/api/foods/${dishId}/deleteItem`, {
      itemId: ingredientId,
      isIngred: true,
    });

    DishesApi.itemQuery.updateCache((item) => {
      return {
        ...item!,
        ingredients: item?.ingredients?.filter(
          (item) => item.ingredientId !== ingredientId
        ),
      };
    }, dishId);
  }

  static async deleteItem(id: number) {
    await Axios.delete<void>(`/api/foods/${id}`);

    DishesApi.listQuery.invalidate();
  }
}
