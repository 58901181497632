import React, { useState, useEffect } from "react";
import { FieldArray } from "react-final-form-arrays";
import { useForm, Field, FormSpy } from "react-final-form";
import InputMask from "react-input-mask";
import { isEqual } from "lodash";
import { Spacer } from "base/components/Spacer";
import { Typography } from "base/components/Typography";
import { Select } from "base/components/Select";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { FormSelect } from "form/components/FormSelect";
import { WorkScheduleFormValues, ShiftItem, Shift } from "work-schedule/types";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";
import "./style.scss";
import { ShiftItemDuplicator } from "work-schedule/util/ShiftItemDuplicator";
import { ShiftDuplicator } from "work-schedule/util/ShiftDuplicator";
import { WorkScheduleRoleTypeEditor } from "select-list-item-editor/components/WorkScheduleRoleTypeEditor";
import { WorkScheduleRoleNameEditor } from "select-list-item-editor/components/WorkScheduleRoleNameEditor";
import { useQuery } from "react-query";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";

export const WorkScheduleForm = () => {
  const { getState, submit, subscribe } = useForm<WorkScheduleFormValues>();
  const [departmentIndex, setDepartmentIndex] = useState<number | undefined>(
    undefined
  );
  const [departmentId, setDepartmentId] = useState<number | undefined>(
    undefined
  );

  const { data: roleTypes } = useQuery(WorkScheduleApi.roleTypesQuery.build());
  const roleTypesOptions = useSelectOptionsAdapter(roleTypes);

  const { data: roleNames } = useQuery(WorkScheduleApi.roleNamesQuery.build());
  const roleNamesOptions = useSelectOptionsAdapter(roleNames);

  const { data: shiftDepartments } = useQuery(
    WorkScheduleApi.shiftDepartmentsQuery.build()
  );
  const shiftDepartmentsOptions = useSelectOptionsAdapter(shiftDepartments);

  useEffect(() => {
    if (departmentId === undefined) {
      setDepartmentIndex(undefined);
      return;
    }

    const unsubscribe = subscribe(
      ({ values }) => {
        if (values.isDataready) {
          setDepartmentIndex(
            values.schedules.findIndex((s) => s.departmentId === departmentId)
          );
          setTimeout(() => unsubscribe());
        }
      },
      { values: true }
    );
    return unsubscribe;
  }, [departmentId, subscribe]);

  return (
    <div className="workScheduleForm">
      <div className="d-flex justify-content-center align-items-center mb-5 formHeader">
        <Typography variant="route-title">סידור שבועי</Typography>
        <Select
          options={shiftDepartmentsOptions}
          value={departmentId}
          onChange={setDepartmentId as any}
          className="w-25 mx-2 mb-1"
          displayEmptyOption={false}
        />
      </div>
      <FieldArray<Shift>
        name={`schedules[${departmentIndex}].shifts`}
        key={departmentIndex}
        isEqual={isEqual}
        subscription={{ length: true }}
      >
        {({ fields }) => (
          <>
            {fields.map((shiftFieldName, shiftIndex) => (
              <div key={shiftIndex}>
                <FieldArray<ShiftItem>
                  name={`${shiftFieldName}.items`}
                  isEqual={isEqual}
                  subscription={{ length: true }}
                >
                  {({ fields: shiftFields }) => (
                    <>
                      <div className="d-flex">
                        <Field
                          className="form-control w-auto"
                          name={`${shiftFieldName}.name`}
                          component="input"
                        />
                        <div
                          onClick={() =>
                            fields.push(
                              ShiftDuplicator.duplicate(
                                getState().values.schedules[departmentIndex!]
                                  .shifts[shiftIndex]
                              )
                            )
                          }
                          role="button"
                          className="d-flex align-items-center mx-1"
                        >
                          <i className="fa fa-copy text-white fa-lg" />
                        </div>
                        <div
                          onClick={() => fields.remove(shiftIndex)}
                          role="button"
                          className="d-flex align-items-center mx-1"
                        >
                          <i className="fa fa-trash text-danger fa-lg" />
                        </div>
                      </div>
                      <div className="table-container">
                        <table className="table table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <th style={{ minWidth: 120, width: "15%" }}>
                                סוג תפקיד
                              </th>
                              <th style={{ minWidth: 120, width: "15%" }}>
                                שם תפקיד
                              </th>
                              <th className="dayHeader">שעות</th>
                              <th className="dayHeader">א</th>
                              <th className="dayHeader">ב</th>
                              <th className="dayHeader">ג</th>
                              <th className="dayHeader">ד</th>
                              <th className="dayHeader">ה</th>
                              <th className="dayHeader">ו</th>
                              <th className="dayHeader">ש</th>
                              <th style={{ width: 60 }} />
                            </tr>
                          </thead>
                          <tbody>
                            {shiftFields.map(
                              (shiftItemFieldName, shiftItemIndex) => (
                                <tr key={shiftItemIndex}>
                                  <td>
                                    <FormSelect
                                      name={`${shiftItemFieldName}.roleTypeId`}
                                      options={roleTypesOptions}
                                      emptyOptionLabel="בחר סוג תפקיד"
                                      withAdd={{
                                        component: WorkScheduleRoleTypeEditor,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <FormSelect
                                      name={`${shiftItemFieldName}.roleNameId`}
                                      options={roleNamesOptions}
                                      emptyOptionLabel="בחר שם תפקיד"
                                      withAdd={{
                                        component: WorkScheduleRoleNameEditor,
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <div>התחלה</div>
                                      <div className="mt-2">סיום</div>
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.sunday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.sunday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.monday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.monday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.tuesday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.tuesday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.wednesday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.wednesday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.thursday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.thursday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.friday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.friday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td className="dayCell">
                                    <div>
                                      <Field
                                        render={({ input }) => (
                                          <InputMask
                                            className="form-control"
                                            mask="99:99"
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.saturday.fromTime`}
                                      />
                                    </div>
                                    <div>
                                      <Field
                                        render={({ input, meta }) => (
                                          <InputMask
                                            className="form-control"
                                            mask={"99:99"}
                                            alwaysShowMask
                                            dir="ltr"
                                            {...input}
                                          />
                                        )}
                                        name={`${shiftItemFieldName}.saturday.toTime`}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-around align-items-center">
                                      <div
                                        role="button"
                                        onClick={() =>
                                          shiftFields.push(
                                            ShiftItemDuplicator.duplicate(
                                              getState().values.schedules[
                                                departmentIndex!
                                              ].shifts[shiftIndex].items[
                                                shiftItemIndex
                                              ]
                                            )
                                          )
                                        }
                                      >
                                        <i className="fa fa-copy fa-lg text-white" />
                                      </div>
                                      <div
                                        role="button"
                                        onClick={() =>
                                          shiftFields.remove(shiftItemIndex)
                                        }
                                      >
                                        <i className="fa fa-trash fa-lg text-danger" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="mr-auto btn btn-primary"
                        onClick={() => shiftFields.push({} as ShiftItem)}
                      >
                        הוספת תפקיד
                      </div>
                    </>
                  )}
                </FieldArray>
                <Spacer units={7} />
              </div>
            ))}
            <Spacer units={4} />
            <div
              className="btn btn-primary"
              onClick={() =>
                fields.push({ name: "משמרת חדשה", items: [{}] } as Shift)
              }
            >
              הוספת משמרת
            </div>
          </>
        )}
      </FieldArray>
      <Spacer units={6} />
      <FormSpy
        subscription={{ submitting: true }}
        render={({ submitting }) => (
          <RoundCheckButton onClick={submit} disabled={submitting} />
        )}
      />
    </div>
  );
};
