import { Query } from "react-query";

export class QueryCacheHelper {
  // returns a function(Updater) that receives a list and updates or adds the given item into list.
  static buildSaveListItemUpdater<T>(listItem: T, idAccessor: keyof T) {
    return (list: T[] | undefined) => {
      return QueryCacheHelper.saveListItem(listItem, idAccessor, list);
    };
  }

  // returns a function(Updater) that receives a list and updates or adds the given item into list.
  static buildAddListItemUpdater<T>(listItem: T) {
    return (list: T[] | undefined) => {
      if (!list) return undefined;

      return [...list, listItem];
    };
  }

  // returns a function(Updater) that receives a list and updates the given item into list.
  static buildUpdateListItemUpdater<T>(listItem: T, idAccessor: keyof T) {
    return (list: T[] | undefined) => {
      if (!list) return undefined;

      return list.map((x) =>
        x[idAccessor] === listItem[idAccessor] ? listItem : x
      );
    };
  }

  // returns a function(Updater) that receives a list and deletes an item with the given id.
  static buildDeleteListItemUpdater<T>(id: any, idAccessor: keyof T) {
    return (list: T[] | undefined) =>
      QueryCacheHelper.deleteListItem(id, idAccessor, list);
  }

  // returns a function(Predicate) that picks queries that has some of the given tags
  static buildTagsPredicate(tags: string[]) {
    return (query: Query) => tags.some((t) => query.queryKey.includes(t));
  }

  // Receives a list and returns a new list updated or added with given item.
  static saveListItem<T, List extends T[] | undefined>(
    listItem: T,
    idAccessor: keyof T,
    list: List
  ): List extends undefined ? T[] | undefined : T[] {
    if (!list) return undefined as any;

    const index = list.findIndex((x) => x[idAccessor] === listItem[idAccessor]);

    if (index === -1) return [...list!, listItem] as any;

    return list.map((x) =>
      x[idAccessor] === listItem[idAccessor] ? listItem : x
    ) as any;
  }

  // Receives a list and returns a new list without given id.
  static deleteListItem<T, List extends T[] | undefined>(
    id: any,
    idAccessor: keyof T,
    list: List
  ): List extends undefined ? T[] | undefined : T[] {
    return list?.filter((a) => a[idAccessor] !== id) as any;
  }
}
