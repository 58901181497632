import React, { useMemo, useCallback } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import {
  CardsTable,
  CardsTableColumn,
} from "base/components/tables/components/CardsTable";
import { POSHistoryListItem } from "./types";
import { POSApi } from "./POSApi";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { POSRouteBuilder } from "./POSRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const POSHistoryScreen: React.FC = () => {
  const { data } = useQuery(POSApi.historyListQuery.build());
  const navigate = useNavigateCallback();

  const onEditRowClick = useCallback(
    ({ fileName }: POSHistoryListItem) => {
      navigate(POSRouteBuilder.buildHistoryItemRoute(fileName));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableColumn<POSHistoryListItem>[]>(
    () => [
      {
        id: "zReference",
        accessor: "zReference",
        Header: "קוד מסמך",
      },
      {
        id: "date",
        accessor: "date",
        Header: "תאריך",
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <Typography variant="route-title">היסטוריית קליטת דוחות מכר</Typography>
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        localStorageKey={LocalStorageKeysHelper.dataTable("posHistory")}
        globalFilterAllowedCols={["Name"]}
      />
    </RouteContent>
  );
};
