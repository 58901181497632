import { Modal } from "react-bootstrap";
import React from "react";
import { useSpeechService } from "base/hooks/useSpeechService";
import "./SpeechModal.scss";

export interface SpeechModalProps {
  show: boolean;
  onHide: () => void;
  onSpeech: (text: string) => void;
  loadingText: string;
}

export const SpeechModal: React.FC<SpeechModalProps> = ({
  show,
  onHide,
  onSpeech,
  loadingText,
}) => {
  useSpeechService((text: string) => {
    onHide();
    onSpeech(text.trim());
  }, show);

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="SpeechModal">
      <>
        <div className="boxContainer">
          <div className="box box1" />
          <div className="box box2" />
          <div className="box box3" />
          <div className="box box4" />
          <div className="box box5" />
        </div>
        <span className="loadingText">{loadingText}</span>
      </>
    </Modal>
  );
};
