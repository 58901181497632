import { TreeType } from "base/types/TreeType";

export class TreeApiPathFormatter {
  static format(treeType: TreeType) {
    switch (treeType) {
      case TreeType.Material:
        return "materials";
      case TreeType.Dish:
        return "foods";
    }
  }
}
