import React from "react";
import classNames from "classnames";
import { NumberFormatter } from "base/utils/formatters";

export interface PriceReportChangeProps {
  value: number;
  variant?: "default" | "percentage";
  zeroValue?: number;
  useSign?: boolean;
}

export const PriceReportChange: React.FC<PriceReportChangeProps> = ({
  value,
  variant = "percentage",
  zeroValue = 0,
  useSign = true,
}) => {
  const plusSign = value > 0 ? "+" : "";

  return (
    <span
      dir="ltr"
      className={classNames({
        "text-success": value < zeroValue,
        "text-danger": value > zeroValue,
      })}
    >
      {useSign && `${plusSign}`}
      {`${NumberFormatter.format(value, { fractionDigits: 2 })}`}
      {variant === "percentage" && "%"}
      {value > zeroValue && <i className="fas fa-arrow-up" />}
      {value < zeroValue && <i className="fas fa-arrow-down" />}
    </span>
  );
};
