import React from "react";
import { DishHelper } from "dish/DishHelper";
import { Doughnut } from "react-chartjs-2";
import {
  DishVESpeedometerPointerBGColors,
  DishVESpeedometerBorderColors,
  DishVESpeedometerChartBGColors,
} from "./constants";
import { NumberFormatter } from "base/utils/formatters";
import { DishVEWizardFormValues } from "dish/types";

export const FoodCostChart: React.FC<{
  data: Partial<DishVEWizardFormValues>;
}> = ({ data }) => {
  let res = data.ingredients?.reduce((result, item) => {
    return result + (item.amount / 1000) * item.price;
  }, 0);

  const foodCost = DishHelper.calcFoodCost(data.menuPrice, res ?? 0);
  const chartOptions = {
    responsive: true,
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    cutoutPercentage: 80,
  };
  return (
    <div className="FoodcostChart">
      <Doughnut
        data={{
          datasets: [
            {
              data: [25, 45, 30],
              backgroundColor: DishVESpeedometerChartBGColors,
              borderWidth: 5,
            },
          ],
        }}
        options={chartOptions}
      />
      <Doughnut
        data={{
          datasets: [
            {
              data: [
                Math.min(foodCost, 100) - 1,
                1,
                100 - Math.min(foodCost, 100) - 1,
              ],
              backgroundColor: DishVESpeedometerPointerBGColors,
              borderColor: DishVESpeedometerBorderColors,
              borderWidth: 2,
            },
          ],
        }}
        options={chartOptions}
      />
      <div className="foodcost-text">
        {NumberFormatter.default.formatPrice(foodCost)}%
      </div>
    </div>
  );
};
