import React, { useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { pick } from "lodash";
import { Spacer } from "base/components/Spacer";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { SingleItemFoodWork } from "food-works/single-item-food-works/types/SingleItemFoodWork";
import { SingleItemFoodWorksApi } from "food-works/single-item-food-works/SingleItemFoodWorksApi";
import "food-works/styles.scss";
import { DepartmentAddonFoodWorksApi } from "../DepartmentAddonFoodWorksApi";
import { DepartmentAddonFoodWorksFormValues } from "../types/DepartmentAddonFoodWorksFormValues";
import { DepartmentAddonFoodWork } from "../types/DepartmentAddonFoodWork";
import { DepartmentAddonFoodWorksForm } from "./DepartmentAddonFoodWorksForm";
import { DepartmentAddons } from "department/types";
import { FormApi } from "final-form";
import { DepartmentFoodWorksApi } from "food-works/department-food-works/DepartmentFoodWorksApi";
import { DepartmentFoodWork } from "food-works/department-food-works/types/DepartmentFoodWork";
import { useQuery } from "react-query";
import { skipToken } from "base/api/ApiQuery";

export type DepartmentAddonFoodWorksModalProps = {
  show: boolean;
  addonId?: number;
  addonName?: string;
  foodWorks?: DepartmentAddonFoodWork[];
  departmentId?: number;
  listName?: keyof DepartmentAddons;
  itemId?: number;
  itemDepartmentId?: number;
  onCancel: () => void;
};

export const DepartmentAddonFoodWorksModal: React.FC<DepartmentAddonFoodWorksModalProps> = ({
  show,
  addonId,
  addonName,
  foodWorks,
  departmentId,
  listName,
  itemId,
  itemDepartmentId,
  onCancel,
}) => {
  const p_show =
    show &&
    foodWorks &&
    addonId !== undefined &&
    departmentId !== undefined &&
    listName !== undefined;

  const { data: singleItemFoodWorks } = useQuery(
    SingleItemFoodWorksApi.itemsQuery.build(
      {
        type: listName === "ingredients" ? "ingredient" : "material",
        id: itemId ?? skipToken,
      },
      { enabled: p_show }
    )
  );

  const { data: itemDepartmentFoodWorksData } = useQuery(
    DepartmentFoodWorksApi.itemsQuery.build(itemDepartmentId ?? skipToken, {
      enabled: show,
    })
  );

  const itemDepartmentFoodWorks = useMemo(() => {
    // hide departmentFoodWorks with same name of singleItemFoodWorks.
    return itemDepartmentFoodWorksData?.filter(
      (item) =>
        (singleItemFoodWorks ?? []).findIndex(
          (otherItem) => otherItem.name.trim() === item.name.trim()
        ) === -1
    );
  }, [itemDepartmentFoodWorksData, singleItemFoodWorks]);

  const foodWorksForm = useMemo<
    DepartmentAddonFoodWorksFormValues | undefined
  >(() => {
    if (!p_show) {
      return undefined;
    } else {
      return {
        foodWorks: foodWorks!,
        addonId: addonId!,
        listName: listName!,
        departmentId: departmentId!,
      };
    }
  }, [p_show, foodWorks, addonId, listName, departmentId]);

  const onAddFoodWorkBySingleItem = useCallback(
    async (
      foodWork: SingleItemFoodWork,
      formApi: FormApi<DepartmentAddonFoodWorksFormValues>
    ) => {
      const newFoodWorks = await DepartmentAddonFoodWorksApi.addFoodWork(
        addonId!,
        departmentId!,
        listName!,
        pick<SingleItemFoodWork, keyof SingleItemFoodWork>(foodWork, [
          "name",
          "cost",
          "depreciation",
          "duration",
        ])
      );
      const { foodWorks } = formApi.getState().values;
      formApi.change("foodWorks", [...foodWorks, newFoodWorks]);
    },
    [addonId, departmentId, listName]
  );

  const onAddFoodWorkByDepartment = useCallback(
    async (
      foodWork: DepartmentFoodWork,
      formApi: FormApi<DepartmentAddonFoodWorksFormValues>
    ) => {
      const newFoodWorks = await DepartmentAddonFoodWorksApi.addFoodWork(
        addonId!,
        departmentId!,
        listName!,
        {
          ...pick<DepartmentFoodWork, keyof DepartmentFoodWork>(foodWork, [
            "name",
            "cost",
            "depreciation",
            "duration",
          ]),
        }
      );
      const { foodWorks } = formApi.getState().values;
      formApi.change("foodWorks", [...foodWorks, newFoodWorks]);
    },
    [addonId, departmentId, listName]
  );

  return (
    <Modal
      show={p_show}
      onHide={onCancel}
      dialogClassName="foodworks-modal"
      centered
      size="lg"
    >
      <div className="foodworks-modal-content">
        <div className="fwm-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול עיבוד מתקדם - {addonName}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="fwm-body">
          <Form initialValues={foodWorksForm} onSubmit={() => {}}>
            {({ form: formApi }) => (
              <>
                <DepartmentAddonFoodWorksForm />
                <Spacer units={4} />
                {singleItemFoodWorks && singleItemFoodWorks?.length > 0 && (
                  <>
                    <Typography variant="description" fontSize={20} bold>
                      הוספת עיבוד מפריט
                    </Typography>
                    <Spacer />
                    <Grid container className="available-foodworks" spacing={2}>
                      {singleItemFoodWorks?.map((fw) => (
                        <Grid
                          key={fw.id}
                          item
                          onClick={() => onAddFoodWorkBySingleItem(fw, formApi)}
                        >
                          <div className="grid-item-content">{fw.name}</div>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <Spacer units={4} />
                {itemDepartmentFoodWorks &&
                  itemDepartmentFoodWorks?.length > 0 && (
                    <>
                      <Typography variant="description" fontSize={20} bold>
                        הוספת עיבוד ממחלקה
                      </Typography>
                      <Spacer />
                      <Grid
                        container
                        className="available-foodworks"
                        spacing={2}
                      >
                        {itemDepartmentFoodWorks?.map((dfw) => (
                          <Grid
                            item
                            onClick={() =>
                              onAddFoodWorkByDepartment(dfw, formApi)
                            }
                          >
                            <div className="grid-item-content">{dfw.name}</div>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
              </>
            )}
          </Form>
          <Spacer units={4} />
        </div>
      </div>
    </Modal>
  );
};
