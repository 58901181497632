import { FieldValidator } from "final-form";
import { parse, isValid } from "date-fns";

export class FieldsValidator {
  static compose = (
    ...validators: FieldValidator<any>[]
  ): FieldValidator<any> => {
    return async (...args) => {
      for (let i = 0; i < validators.length; i++) {
        const result = await validators[i](...args);
        if (result) {
          return result;
        }
      }
    };
  };

  static minValue = (min: number): FieldValidator<any> => {
    return (x, _, __) => {
      if (typeof x === "number" && x < min) {
        return Promise.resolve(`ערך השדה המינימאלי הוא ${min}`);
      } else {
        return Promise.resolve();
      }
    };
  };

  static maxValue = (max: number): FieldValidator<any> => {
    return (x, _, __) => {
      if (typeof x === "number" && x > max) {
        return Promise.resolve(`ערך השדה המקסימאלי הוא ${max}`);
      } else {
        return Promise.resolve();
      }
    };
  };

  static integer: FieldValidator<any> = (x, _, meta) => {
    if (typeof x === "number" && x !== Math.round(x)) {
      return Promise.resolve(`ערך השדה לא חוקי`);
    } else {
      return Promise.resolve();
    }
  };

  static required: FieldValidator<any> = (x, _, meta) => {
    if (x === undefined || x === "") {
      return Promise.resolve("שדה חובה");
    } else {
      return Promise.resolve();
    }
  };

  // pattern -> maxDigit:maxDigit
  // Example: 99.23:00:00 => 99.23:00:00 GOOD, 100:23:00:00 BAD, 99:24:00:00 BAD
  static timeSpanRange = (pattern: string): FieldValidator<any> => {
    return (value, _, meta) => {
      if (FieldsValidator.isTimeMaskEmpty(value)) {
        return Promise.resolve();
      }

      const values = (value as string).split(/:|\./);
      const patternArr = pattern.split(/:|\./);

      if (values.length !== patternArr.length) {
        return Promise.resolve("ערך השדה לא חוקי");
      }

      const isOK = values.every((x, i) => Number(x) <= Number(patternArr[i]));

      if (!isOK) {
        return Promise.resolve("ערך השדה לא חוקי");
      }
    };
  };

  static timeMaskRequired: FieldValidator<any> = (x, _, meta) => {
    if (x === undefined || x === "" || x === "__:__") {
      return Promise.resolve("שדה חובה");
    } else {
      return Promise.resolve();
    }
  };

  static timeMask: FieldValidator<any> = (value, _, meta) => {
    if (FieldsValidator.isTimeMaskEmpty(value)) {
      return Promise.resolve();
    }

    if (!isValid(parse(value, "HH:mm", new Date()))) {
      return Promise.resolve("ערך השדה לא חוקי");
    }
  };

  private static isEmpty(value: string) {
    return value === undefined || value === "";
  }

  private static isTimeMaskEmpty(value: string) {
    return FieldsValidator.isEmpty(value) || value === "__:__";
  }
}
