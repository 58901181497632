import React, { useState } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { RouteContentTopNav } from "route-content/components/RouteContentTopNav";
import { useQuery } from "react-query";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { SentOrderCard } from "sent-order/components/SentOrderCard";
import { ReactComponent as SentOrderIcon } from "route-content/assets/RouteContentSentOrder.svg";

export const SentOrdersScreen: React.FC = () => {
  const { data } = useQuery(
    SentOrdersApi.listQuery.build(
      { variant: "default" },
      {
        refetchInterval: 20 * 1000,
      }
    )
  );

  const [expandedId, setExpandedId] = useState<number>();

  return (
    <RouteContent>
      <RouteContentTopBar
        title="קליטת הזמנה"
        titleIcon={<SentOrderIcon height={24} />}
      ></RouteContentTopBar>
      {data?.map((data) => (
        <SentOrderCard
          key={data.id}
          data={data}
          isExpanded={data.id === expandedId}
          onToggleExpand={() =>
            setExpandedId(expandedId === data.id ? undefined : data.id)
          }
        />
      ))}
    </RouteContent>
  );
};
