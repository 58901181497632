import React, { useCallback } from "react";
import { Col, FormGroup, FormLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { RoundButton } from "base/components/RoundButton";
import { Form } from "form/components/Form";
import { FormFileUpload } from "form/components/FormFileUpload";
import {
  Employee,
  EmployeeFormValues,
  SalaryTypeSelectOptions,
} from "employee/types";
import { EmployeesApi } from "employee/EmployeesApi";
import { EmployeesRouteBuilder } from "employee/EmployeesRouteBuilder";
import { useFieldNameFormatFN } from "form/hooks/useFieldNameFormatFN";
import { FormTextInput } from "form/components/FormTextInput";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { MobileCollapseControl } from "base/components/MobileCollapseControl";
import { format } from "date-fns";
import { FormSelect } from "form/components/FormSelect";
import { ContractsApi } from "contract/ContractsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FileDir } from "base/types";
import { FormFileViewer } from "form/components/FormFileViewer";
import { useQuery } from "react-query";

export type EmployeeFormProps = {
  mode: "new" | "existing";
  data: Partial<Employee> | undefined;
};

export const EmployeeForm: React.FC<EmployeeFormProps> = ({ data, mode }) => {
  const navigate = useNavigateCallback();
  const formatFieldName = useFieldNameFormatFN<EmployeeFormValues>();

  const { data: contractsList } = useQuery(ContractsApi.listQuery.build());
  const contractsOptions = useSelectOptionsAdapter(contractsList);

  const onSave = useCallback(
    async (EmployeeForm: EmployeeFormValues) => {
      const { image, ...nextEmployee } = EmployeeForm;
      await EmployeesApi.saveItem(nextEmployee, image);
      navigate(EmployeesRouteBuilder.buildEmployeesRoute());
    },
    [navigate]
  );

  return (
    <Form initialValues={data} onSubmit={onSave}>
      {({ handleSubmit }) => (
        <>
          <FormGroup className="d-flex flex-wrap">
            <Col xs={12} md={9}>
              <>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    שם פרטי
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormTextInput name={formatFieldName("firstName")} />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    שם משפחה
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormTextInput name={formatFieldName("lastName")} />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    שם משתמש
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormTextInput
                      required
                      name={formatFieldName("username")}
                      disabled={mode === "existing"}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    מספר עובד
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormNumberInput
                      name={formatFieldName("employeeKey")}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    תעודת זהות
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormNumberInput
                      name={formatFieldName("personalId")}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    חוזה
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormSelect
                      name={formatFieldName("employeeContractId")}
                      options={contractsOptions}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex">
                  <Col sm={5} md={4} xl={3}>
                    טלפון
                  </Col>
                  <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                    <FormNumberInput name={formatFieldName("phoneNumber")} />
                  </Col>
                </FormGroup>
                <MobileCollapseControl>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      אימייל
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput name={formatFieldName("email")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      סיסמא
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput
                        required={mode === "new"}
                        name={formatFieldName("newPassword")}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      קוד גישה מהירה
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput name={formatFieldName("pinCode")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      שכר לשעה
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormNumberInput name={formatFieldName("hourlyRate")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      סוג שכר
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      {
                        <FormSelect
                          name={formatFieldName("salaryType")}
                          options={SalaryTypeSelectOptions}
                        />
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      מקבל טיפים
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormCheckbox name={formatFieldName("isPartOfTips")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      חשבון בנק
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormNumberInput name={formatFieldName("bankAccount")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      תאריך לידה
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput
                        type="date"
                        format={(val) => {
                          if (!val) {
                            return val;
                          }

                          return format(new Date(val), "yyyy-MM-dd");
                        }}
                        name={formatFieldName("birthday")}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      עיר
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput name={formatFieldName("addressCity")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      רחוב
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput name={formatFieldName("addressStreet")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      מספר בית
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput name={formatFieldName("addressHome")} />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      מיקוד
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormNumberInput
                        name={formatFieldName("addressZipCode")}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      תאריך תחילת עבודה
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormTextInput
                        type="date"
                        format={(val) => {
                          if (!val) {
                            return val;
                          }

                          return format(new Date(val), "yyyy-MM-dd");
                        }}
                        name={formatFieldName("workingStartDate")}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex">
                    <Col sm={5} md={4} xl={3}>
                      פעיל
                    </Col>
                    <Col xs={9} sm={7} md={7} lg={6} xl={5}>
                      <FormCheckbox name={formatFieldName("isActive")} />
                    </Col>
                  </FormGroup>
                </MobileCollapseControl>
              </>
            </Col>
            <Col xs={12} md={3} className="align-self-start">
              <FormLabel>תמונה</FormLabel>
              <FormFileUpload name="image" />
              <FormFileViewer
                pathName={formatFieldName("imageName")}
                uploadName={formatFieldName("image")}
                fileDir={FileDir.User}
              />
            </Col>
          </FormGroup>
          <FormGroup className="d-flex">
            <Col xs={8} sm={7} md={6} lg={5} xl={4} className="text-center">
              <RoundButton onClick={handleSubmit}>
                <FontAwesomeIcon icon={faCheck} size="lg" />
              </RoundButton>
            </Col>
          </FormGroup>
        </>
      )}
    </Form>
  );
};
