import * as yup from "yup";

export const DishFormSchema = yup
  .object<any>()
  .shape<any>({
    id: yup.number(),
    name: yup.string().required(),
    imageName: yup.string(),
    departmentId: yup.number(),
    isActive: yup.boolean().required(),
    dateCreated: yup.string(),
    menuPrice: yup.number(),
    assemblingTime: yup.number(),
    image: yup.mixed(), // TODO add generic yup.test method to ensure FileList
  })
  .asForm<any>();
