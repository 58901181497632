import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { SimpleGlobalList } from "global-list/components/SimpleGlobalList";

export const UnitKindsListScreen: React.FC = () => {
  return (
    <RouteContent>
      <SimpleGlobalList routeApiPath={SimpleGlobalListRoutePath.UnitKinds} />
    </RouteContent>
  );
};
