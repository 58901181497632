import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Do Not refetch requests once tab gains focus.
      refetchOnReconnect: false, // Do Not refetch requests once internet connection reastablishes.
      retry: false, // Do Not retry once query errors.
    },
    mutations: {
      retry: false, // Do Not retry once query errors.
    },
  },
});
