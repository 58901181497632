import React from "react";
import { Field } from "react-final-form";
import { TextInput, TextInputProps } from "base/components/TextInput";
import { FormErrorMessage } from "form/components/FormErrorMessage";

export type FormTextInputProps = {
  name: string;
  required?: boolean;
  format?: (value: any) => any;
} & TextInputProps;

export const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  required,
  format,
  ...textInputProps
}) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <>
          <TextInput {...textInputProps} {...input} />
          <div>
            <FormErrorMessage>
              {meta.touched ? meta.error : meta.submitError}
            </FormErrorMessage>
          </div>
        </>
      )}
      validate={(x, _, meta) => {
        if (required && !x) {
          return Promise.resolve("שדה חובה");
        }
      }}
      format={format}
      {...textInputProps}
    />
  );
};
