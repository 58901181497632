import { FoodWork, TreeInfoFoodListItem } from "./types";
import { UnitType } from "base/types";
import Big from "big.js";

export class TreeInfoHelper {
  static calcAmountAfterFoodWorks(amount: number, foodWorks: FoodWork[]) {
    let amountAfterFoodWorks = amount;
    foodWorks.forEach((item) => {
      amountAfterFoodWorks =
        amountAfterFoodWorks - (amountAfterFoodWorks * item.depreciation) / 100;
    });

    return amountAfterFoodWorks;
  }

  static calcPriceAfterFoodWorks(basicPrice: number, foodWorks: FoodWork[]) {
    let priceAfterFoodWorks = basicPrice;

    foodWorks.forEach((item) => {
      priceAfterFoodWorks =
        priceAfterFoodWorks * (100 / (100 - item.depreciation)) + item.cost;
    });

    return priceAfterFoodWorks;
  }

  static calculateTotalPrice(items: TreeInfoFoodListItem[]) {
    return items.reduce((total, item) => {
      const { basicPrice = 0, amount = 0, foodWorks, unitType } = item;

      const amountAfterFoodWorks = TreeInfoHelper.calcAmountAfterFoodWorks(
        amount,
        foodWorks
      );
      const priceAfterFoodWorks = TreeInfoHelper.calcPriceAfterFoodWorks(
        basicPrice,
        foodWorks
      );

      const value = amountAfterFoodWorks * priceAfterFoodWorks;

      const normalizedValue = unitType !== UnitType.Yeh ? value / 1000 : value;

      return total + normalizedValue;
    }, 0);
  }

  static calcAmountBeforeFoodWorks(
    amountAfterFoodWorks: number,
    foodWorks: FoodWork[]
  ) {
    const amountAfterFoodWorksTag = TreeInfoHelper.calcAmountAfterFoodWorks(
      amountAfterFoodWorks,
      foodWorks
    );

    const percentageDiff = Big(amountAfterFoodWorks).div(
      amountAfterFoodWorksTag
    );

    return Number(percentageDiff.mul(amountAfterFoodWorks));
  }
}
