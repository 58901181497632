import React, { useMemo } from "react";
import { useLocalStore } from "mobx-react";
import { AppContext, AppContextType } from "./AppContext";
import { AppStore } from "./AppStore";

export const AppContextProvider: React.FC = ({ children }) => {
  const store = useLocalStore(() => new AppStore());

  const context = useMemo<AppContextType>(
    () => ({
      store,
      onSignIn: store.setAuth,
      onSignOut: () => store.setAuth(undefined),
      setIsSidebarEnabled: (val: boolean) => {
        store.setIsSidebarEnabled(val);
        document.body.classList.toggle("isSidebarEnabled", val);
      },
      setIsBottomNavbarActive: (val: boolean) => {
        store.setIsBottomNavbarActive(val);
        document.body.classList.toggle("isBottomNavbarActive", val);
      },
    }),
    [store]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
