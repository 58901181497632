import React, { useCallback, useRef } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { IngredientEditTabFields } from "ingredient/components/IngredientEditTabFields";
import { IngredientsApi } from "ingredient/IngredientsApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { ButtonGroup, Button } from "react-bootstrap";
import { Form } from "form/components/Form";
import { IngredientFormData } from "ingredient/types";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { useQuery } from "react-query";

export const AddIngredientScreen: React.FC = () => {
  const navigate = useNavigateCallback();

  const actionRef = useRef<"save" | "saveAndGoToEdit">("save");

  const { data, isLoading, isFetching } = useQuery(
    IngredientsApi.itemDefaultsQuery.build()
  );

  useFullScreenLoader(isLoading || isFetching);

  const onSave = useCallback(
    async (values: IngredientFormData) => {
      try {
        const savedIng = await IngredientsApi.saveItem(values);
        if (actionRef.current === "save") {
          navigate(IngredientsRouteBuilder.buildIngredientsRoute());
        } else {
          navigate(
            IngredientsRouteBuilder.buildEditIngredientRoute(savedIng.id)
          );
        }
      } catch (error) {
        alert(error.response.data?.error ?? "לא ניתן לשמור את הפריט");
      }
    },
    [navigate]
  );

  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <Form initialValues={data} onSubmit={onSave}>
        {({ handleSubmit }) => (
          <>
            <IngredientEditTabFields isNew />
            <ButtonGroup>
              <div className="mx-2">
                <Button
                  onClick={() => {
                    actionRef.current = "saveAndGoToEdit";
                    handleSubmit();
                  }}
                >
                  שמירה ומעבר לעריכת מרכיב
                </Button>
              </div>
              <div className="mx-2">
                <Button
                  onClick={() => {
                    actionRef.current = "save";
                    handleSubmit();
                  }}
                >
                  שמירה
                </Button>
              </div>
            </ButtonGroup>
          </>
        )}
      </Form>
    </RouteContent>
  );
};
