import { SupplyUnitType, UnitType } from "base/types";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { SentOrderItemGet } from "sent-order/types";

export class OrderHelper {
  static calcTotalPrice(amount: number, defaultPrice: number) {
    return amount * defaultPrice;
  }
  static requestedAmount(sentOrderItem: SentOrderItemGet) {
    return OrderHelper.calcTotalAmount(
      sentOrderItem.documentAmount,
      sentOrderItem.receivedSupplyMethod,
      sentOrderItem.prepareUnitType
    );
  }
  static totalGapPrice(type: string, sentOrderItem: SentOrderItemGet) {
    if (type === "price")
      return (
        (OrderHelper.calcNormalizedKGPrice(
          sentOrderItem.requestedSupplyMethod,
          sentOrderItem.requestedPrice,
          sentOrderItem.prepareUnitType
        ) -
          OrderHelper.calcNormalizedKGPrice(
            sentOrderItem.receivedSupplyMethod,
            sentOrderItem.receivedPrice,
            sentOrderItem.prepareUnitType
          )) *
        OrderHelper.calcTotalAmount(
          sentOrderItem.documentAmount,
          sentOrderItem.receivedSupplyMethod,
          sentOrderItem.prepareUnitType
        )
      );

    if (type === "amount" && OrderHelper.isWeightable(sentOrderItem))
      return (
        OrderHelper.calcDocumentedAmountGap(sentOrderItem) *
        OrderHelper.calcNormalizedKGPrice(
          sentOrderItem.requestedSupplyMethod,
          sentOrderItem.requestedPrice,
          sentOrderItem.prepareUnitType
        )
      );
    if (type === "amount" && !OrderHelper.isWeightable(sentOrderItem))
      return (
        (sentOrderItem.receivedAmount - sentOrderItem.documentAmount) *
        sentOrderItem.receivedPrice
      );
  }
  static receivedAmount(type: string, sentOrderItem: SentOrderItemGet) {
    if (type === "amount")
      if (OrderHelper.isWeightable(sentOrderItem))
        return sentOrderItem.receivedWeight;
      else
        return OrderHelper.calcTotalAmount(
          sentOrderItem.receivedAmount,
          sentOrderItem.receivedSupplyMethod,
          sentOrderItem.prepareUnitType
        );
  }
  static requestedPrice(sentOrderItem: SentOrderItemGet) {
    return OrderHelper.calcNormalizedKGPrice(
      sentOrderItem.requestedSupplyMethod,
      sentOrderItem.requestedPrice,
      sentOrderItem.prepareUnitType
    );
  }

  static calcTotalAmount(
    amount: number, // 4
    sm: BaseSupplyMethod | undefined, // Amount, Package, SingleItem
    prepareUnitType: UnitType, // GR, ML, UNIT
    isShowInSmallUnits = false
  ) {
    switch (sm?.supplyUnitType) {
      case SupplyUnitType.SingleItem:
        if (prepareUnitType !== UnitType.Yeh) {
          // get total in large units
          let totalLargeWeight = sm?.unitWeight! / (sm?.isLargeUnit ? 1 : 1000);
          amount *= totalLargeWeight;
        }
        break;
      case SupplyUnitType.Package:
        amount *= sm.unitsInPackage!;

        if (prepareUnitType !== UnitType.Yeh) {
          let totalLargeWeight = sm?.unitWeight! / (sm?.isLargeUnit ? 1 : 1000);

          amount *= totalLargeWeight;
        }
        break;
      default:
        break;
    }
    if (isShowInSmallUnits && prepareUnitType !== UnitType.Yeh) {
      amount *= 1000;
    }
    return amount;
  }

  static calcTotalUnits(
    amount: number,
    supplyMethod: BaseSupplyMethod | undefined
  ) {
    switch (supplyMethod?.supplyUnitType) {
      case SupplyUnitType.Amount:
        return undefined;
      case SupplyUnitType.SingleItem:
        return amount;
      case SupplyUnitType.Package:
        return amount * supplyMethod?.unitsInPackage!;
      default:
        return 0;
    }
  }

  static hasAmountGap(item: SentOrderItemGet) {
    if (
      OrderHelper.isWeightable(item) &&
      item.receivedSupplyMethod.supplyUnitType !== SupplyUnitType.Amount
    ) {
      return OrderHelper.calcDocumentedAmountGap(item) < 0;
    } else {
      return item.receivedAmount - item.documentAmount < 0;
    }
  }

  static hasPriceGap(item: SentOrderItemGet) {
    if (OrderHelper.isWeightable(item)) {
      return (
        OrderHelper.calcNormalizedKGPrice(
          item.receivedSupplyMethod,
          item.receivedPrice,
          item.prepareUnitType
        ) -
          OrderHelper.calcNormalizedKGPrice(
            item.requestedSupplyMethod,
            item.requestedPrice,
            item.prepareUnitType
          ) >
        0
      );
    } else {
      return item.receivedPrice - item.requestedPrice > 0;
    }
  }

  static isWeightable(item: SentOrderItemGet) {
    return item.isWeightable;
  }

  static calcRequestedAmountGap(item: SentOrderItemGet) {
    if (OrderHelper.isWeightable(item)) {
      return (
        item.receivedWeight -
        OrderHelper.calcTotalAmount(
          item.requestedAmount,
          item.requestedSupplyMethod,
          item.prepareUnitType
        )
      );
    } else {
      return item.receivedAmount - item.requestedAmount;
    }
  }

  static calcDocumentedAmountGap(item: SentOrderItemGet) {
    let documentAmount = OrderHelper.calcTotalAmount(
      item.documentAmount,
      item.receivedSupplyMethod,
      item.prepareUnitType
    );

    if (OrderHelper.isWeightable(item)) {
      return item.receivedWeight - documentAmount;
    } else {
      var ra = OrderHelper.calcTotalAmount(
        item.receivedAmount,
        item.receivedSupplyMethod,
        item.prepareUnitType
      );
      return ra - documentAmount;
    }
  }

  static calcNormalizedKGPrice(
    supplyMethod: BaseSupplyMethod,
    price: number,
    prepareUnitType: UnitType
  ) {
    switch (supplyMethod?.supplyUnitType) {
      case SupplyUnitType.Amount:
        return price;
      case SupplyUnitType.SingleItem: {
        const unitMultiplier = supplyMethod.isLargeUnit ? 1 : 1000;
        return (unitMultiplier / supplyMethod?.unitWeight!) * price;
      }
      case SupplyUnitType.Package: {
        if (prepareUnitType === UnitType.Yeh) {
          return price / supplyMethod.unitsInPackage!;
        } else {
          const unitMultiplier = supplyMethod.isLargeUnit ? 1 : 1000;
          return (
            (unitMultiplier /
              (supplyMethod.unitWeight! * supplyMethod.unitsInPackage!)) *
            price
          );
        }
      }
      default:
        return 0;
    }
  }

  static isFilled(item: SentOrderItemGet, weightDeviation = 0) {
    if (OrderHelper.isWeightable(item)) {
      const total = OrderHelper.calcTotalAmount(
        item.requestedAmount,
        item.requestedSupplyMethod,
        item.prepareUnitType
      );

      const deviationAmount = total * (weightDeviation / 100);

      return item.receivedWeight >= total - deviationAmount;
    } else {
      return OrderHelper.calcRequestedAmountGap(item) >= 0;
    }
  }
}
