import axios from "axios";
import { BaseFood } from "base/types";
import { TreeType } from "base/types/TreeType";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { TreeItemFoodWork } from "./types/TreeItemFoodWork";
import { TreeItemFoodWorkType } from "./types/TreeItemFoodWorkType";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

/* TODO - Foodworks structural refactor is required. */
export class TreeItemFoodWorksApi {
  static itemsQuery = new ApiQuery<
    TreeItemFoodWork[],
    {
      treeType: TreeType;
      treeItemType: TreeItemFoodWorkType;
      treeItemId: number;
    }
  >(
    ({ treeType, treeItemType, treeItemId }) =>
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`
  );

  // Todo - Move to separate Api file, maybe add a generic BaseFoodApi.
  // Equals to MaterialsApi.itemQuery / DishesApi.itemQuery.
  static foodItemQuery = new ApiQuery<
    BaseFood,
    { itemType: TreeItemFoodWorkType; itemId: number }
  >(
    ({ itemType, itemId }) =>
      `/api/${TreeItemFoodWorksApi.getTreeItemApiPath(itemType)}/${itemId}`
  );

  static async addFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    foodWork: Omit<TreeItemFoodWork, "id" | "isRequired">
  ) {
    const { data: newFoodWork } = await axios.post<TreeItemFoodWork>(
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`,
      foodWork
    );

    TreeItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildAddListItemUpdater(newFoodWork),
      {
        treeType,
        treeItemType,
        treeItemId,
      }
    );

    ApiQuery.invalidateByTags([TreeInfoApi.tags.ItemByTreeType(treeType)]);
  }

  static async updateFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    departmentFoodWork: Omit<TreeItemFoodWork, "isRequired">
  ) {
    const { data: modifiedFoodWork } = await axios.post<TreeItemFoodWork>(
      `/api/${TreeApiPathFormatter.format(
        treeType
      )}/${TreeItemFoodWorksApi.getTreeItemApiPath(
        treeItemType
      )}/${treeItemId}/foodworks`,
      departmentFoodWork
    );

    TreeItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildUpdateListItemUpdater(modifiedFoodWork, "id"),
      {
        treeType,
        treeItemType,
        treeItemId,
      }
    );

    ApiQuery.invalidateByTags([TreeInfoApi.tags.ItemByTreeType(treeType)]);
  }

  static async deleteFoodWork(
    treeType: TreeType,
    treeItemType: TreeItemFoodWorkType,
    treeItemId: number,
    foodWorkId: number
  ) {
    await axios.delete<TreeItemFoodWork>(
      `/api/foodworks/treeitems/${foodWorkId}`
    );

    TreeItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(foodWorkId, "id"),
      {
        treeType,
        treeItemType,
        treeItemId,
      }
    );

    ApiQuery.invalidateByTags([TreeInfoApi.tags.ItemByTreeType(treeType)]);
  }

  static getTreeItemApiPath = (type: TreeItemFoodWorkType): string => {
    switch (type) {
      case "ingredient":
        return "ingredients";
      case "material":
        return "materials";
    }
  };

  static getTreeApiPath = (type: TreeType): string => {
    switch (type) {
      case TreeType.Dish:
        return "foods";
      case TreeType.Material:
        return "materials";
    }
  };
}
