import { UnitType } from "base/types";

export interface StockItemToAddFormValues {
  productId: number;
  productSupplyMethodId: number;
  amount: number;
}

/* StockTaking */

export interface StockTakingItem {
  materialId?: number;
  ingredientId?: number;
  currentStock: number;
}

export interface StockTakingFormValues {
  transactionDate: string;
  items: StockTakingItem[];
}

/* StockTaking */

/* StockRemovalRequest */
export interface StockRemovalRequest {
  materialId?: number;
  ingredientId?: number;
  amount: number;
  reason: StockRemovalRequestReason;
}

export enum StockRemovalRequestReason {
  Rotten,
  Expired,
  Faulted,
}

export interface StockRemovalSearchItem {
  materialId?: number;
  ingredientId?: number;
  name: string;
  imageName: string;
  prepareUnitType: UnitType;
}

/* StockRemovalRequest */
