import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { WorkScheduleType } from "./types";
import { ApiQuery } from "base/api/ApiQuery";

export class WorkScheduleApi {
  static templateSchedulesQuery = new ApiQuery<WorkScheduleType[]>(
    "/api/workSchedules/templates"
  );

  static roleTypesQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/workSchedules/roleTypes"
  );

  static roleNamesQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/workSchedules/roleNames"
  );

  static shiftDepartmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/workSchedules/shiftDepartments"
  );

  static async saveTemplateSchedules(schedules: WorkScheduleType[]) {
    const { data: savedSchedules } = await Axios.post<WorkScheduleType[]>(
      "/api/workSchedules/templates",
      schedules
    );

    WorkScheduleApi.templateSchedulesQuery.updateCache(savedSchedules);
  }
}
