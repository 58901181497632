import { ContractListItem, Contract } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import Axios from "axios";

export class ContractsApi {
  static listQuery = new ApiQuery<ContractListItem[]>("/api/contracts/items");

  static itemQuery = new ApiQuery<Contract, number>(
    (id) => `/api/contracts/${id}`
  );

  static itemDefaultsQuery = new ApiQuery<Partial<Contract>>(
    `/api/contracts/new`
  );

  static async saveItem(data: WithPartialProperties<Contract, "id">) {
    const { data: savedItem } = await Axios.post<Contract>(
      "/api/contracts",
      data
    );

    ContractsApi.itemQuery.updateCache(savedItem, savedItem.id);
    ContractsApi.listQuery.invalidate();

    return savedItem;
  }
}
