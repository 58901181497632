import { BalancePeriodType, DashboardData } from "./types";
import { ApiRequestHelper } from "base/api/ApiRequestHelper";
import { ApiQuery } from "base/api/ApiQuery";

export class ManagersApi {
  static dashboardQuery = new ApiQuery<
    DashboardData,
    { periodType: BalancePeriodType; date: string }
  >(
    ({ periodType, date }) =>
      `/api/managerDashboard?date=${ApiRequestHelper.formatDateQueryParam(
        date
      )}&periodType=${periodType}`
  );
}
