import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  FormGroup,
  Col,
  FormLabel,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DevTool as ReactHookFormDevTool } from "react-hook-form-devtools";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { FormFileUpload } from "base/components/form-controls/FormFileUpload";
import { FormImageViewer } from "base/components/form-controls/FormImageViewer";
import { Dish } from "dish/types";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { DishesApi } from "dish/DishesApi";
import { DishFormData } from "dish/types";
import { DishFormSchema } from "../schema";
import { DishFormFields } from "./DishFormFields";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeType } from "base/types";

const ImageViewerWrap = styled.div({});

export type DishFormProps = {
  data: Partial<Dish> | undefined;
};

export const DishForm: React.FC<DishFormProps> = ({ data }) => {
  const navigate = useNavigateCallback();
  const formInstance = useForm<DishFormData>({
    validationSchema: DishFormSchema,
    defaultValues: data,
    mode: "onBlur",
  });
  const { reset } = formInstance;
  const actionRef = useRef<"save" | "saveAndGoToTreeInfo">("save");

  const onSave = useCallback(
    async (nextValues: DishFormData) => {
      const { image, ...nextDishValues } = nextValues;
      const savedDish = await DishesApi.saveItem({
        ...nextDishValues,
        image: image?.[0],
      });
      if (actionRef.current === "save") {
        navigate(DishesRouteBuilder.buildDishesRoute());
      } else {
        navigate(
          TreeInfoRouteBuilder.buildTreeInfoRoute(TreeType.Dish, savedDish.id)
        );
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      <FormGroup className="d-flex flex-wrap">
        <Col xs={12} md={9}>
          <DishFormFields form={formInstance} />
        </Col>
        <Col xs={12} md={3} className="align-self-start">
          <FormLabel>תמונה</FormLabel>
          <FormFileUpload formInstance={formInstance} name="image" />
          <ImageViewerWrap>
            <FormImageViewer
              formInstance={formInstance}
              pathName="imageName"
              uploadName="image"
            />
          </ImageViewerWrap>
        </Col>
      </FormGroup>
      <ButtonGroup>
        <div className="mx-2">
          <Button
            onClick={() => {
              actionRef.current = "saveAndGoToTreeInfo";
              formInstance.handleSubmit(onSave)();
            }}
          >
            שמירה ומעבר לעץ מוצר
          </Button>
        </div>
        <div className="mx-2">
          <Button
            onClick={() => {
              actionRef.current = "save";
              formInstance.handleSubmit(onSave)();
            }}
          >
            שמירה
          </Button>
        </div>
      </ButtonGroup>
      {process.env.NODE_ENV === "development" && (
        <ReactHookFormDevTool control={formInstance.control} />
      )}
    </>
  );
};
