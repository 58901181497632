import React, {
  TdHTMLAttributes,
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  useTable,
  Column,
  usePagination,
  useGlobalFilter,
  useSortBy,
  SortByFn,
  useFilters,
  SortingRule,
  TableState,
  ActionType,
  IdType,
  Filters,
  Row,
} from "react-table";
import Sticky from "react-stickynode";
import "./style.scss";
import { CardsTableTopBar } from "./CardsTableTopBar";
import { TablePagination, TablePaginationProps } from "../TablePagination";
import { Spinner } from "react-bootstrap";

export type CardsTableColumn<D extends object> = Column<D> & {
  cellProps?: TdHTMLAttributes<HTMLTableCellElement>;
};

export type CardsTableProps<D extends object> = {
  searchPlaceholder?: string;
  columns: CardsTableColumn<D>[];
  getRowProps?: (row: Row<D>) => any;
  data: D[] | undefined;
  globalFilterAllowedCols?: IdType<D>[];
  pageSizes?: readonly (number | "All")[];
  onEditRowClick?: (row: D, index: number) => void;
  onAddRowClick?: () => void;
  topBarCustomActions?: JSX.Element;
  localStorageKey?: string;
  searchable?: boolean;
  hasPagination?: boolean;
  initialFilters?: Filters<D>;
  isLoading?: boolean;
};

const defaultArray = [];
const defaultPageSizes = [10, 25, 50, 100, "All"] as const;
export const CardsTable = <D extends object>({
  columns,
  searchPlaceholder,
  data = defaultArray,
  onEditRowClick,
  onAddRowClick,
  pageSizes = defaultPageSizes,
  localStorageKey,
  globalFilterAllowedCols,
  topBarCustomActions,
  searchable,
  hasPagination = true,
  initialFilters,
  getRowProps,
  isLoading = data === defaultArray,
}: CardsTableProps<D>) => {
  const bottomClassNameRef = useRef(`b_${Date.now()}`);
  const sortTypes = useMemo<Record<string, SortByFn<D>>>(
    () => ({
      datetime: (rowA, rowB, columnId) => {
        const valA = rowA.values[columnId];
        const valB = rowB.values[columnId];

        if (!valA || !valB) {
          return 1000000;
        }

        return new Date(valA).getTime() - new Date(valB).getTime();
      },
      boolean: (rowA, rowB, columnId) => {
        const valA = rowA.values[columnId];
        const valB = rowB.values[columnId];

        return (valA ? 1 : 0) - (valB ? 1 : 0);
      },
    }),
    []
  );

  const [lsData, setLSData] = useState<{ sortBy?: SortingRule<any>[] }>(() => {
    if (!localStorageKey) return {};
    const strData = localStorage.getItem(localStorageKey);
    if (!strData) return {};

    try {
      return JSON.parse(strData);
    } catch (e) {
      localStorage.removeItem(localStorageKey);
      return {};
    }
  });

  const stateReducer = useCallback(
    (newState: TableState<any>, action: ActionType, _: TableState<D>) => {
      if (localStorageKey && action.type === "toggleSortBy") {
        setLSData((currLSData) => {
          const nextLSData = {
            ...currLSData,
            sortBy: newState.sortBy,
          };
          localStorage.setItem(localStorageKey, JSON.stringify(nextLSData));
          return nextLSData;
        });
      }
      return newState;
    },
    [localStorageKey]
  );

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        sortBy: lsData.sortBy ?? [],
        filters: initialFilters ?? [],
      },
      sortTypes,
      stateReducer,
      globalFilter: globalFilterAllowedCols ? "allowedColsOnly" : undefined,
      filterTypes: {
        allowedColsOnly: (rows: any[], _, filterValue) => {
          return rows.filter((r, i) => {
            return globalFilterAllowedCols!.some((colId) =>
              r.values[colId]?.toLowerCase().includes(filterValue.toLowerCase())
            );
          });
        },
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    globalFilteredRows,
    state: { pageIndex, pageSize, globalFilter },
    gotoPage: onGoToPage,
    setPageSize,
    setGlobalFilter,
  } = instance;

  const onPageSizeChange = useCallback<
    TablePaginationProps["onPageSizeChange"]
  >(
    (nextSize) => {
      if (nextSize === "All") {
        setPageSize(data.length);
      } else {
        setPageSize(nextSize);
      }
    },
    [data, setPageSize]
  );

  const pageSizeIndex = useMemo(() => {
    const indexOfAll = pageSizes.indexOf("All");

    if (pageSize === data.length && indexOfAll !== -1) {
      return indexOfAll;
    } else {
      return pageSizes.indexOf(pageSize);
    }
  }, [pageSize, data.length, pageSizes]);

  const hasIndevidualFilters = useMemo(() => {
    return headerGroups.some((headerGroup) =>
      headerGroup.headers.some((col) => col.canFilter && col.Filter)
    );
  }, [headerGroups]);

  useEffect(() => {
    if (!hasPagination && data.length > 0) {
      setPageSize(data.length);
    }
  }, [data, hasPagination, setPageSize]);

  // Render the UI for your table
  return (
    <div className="CardsTable">
      <CardsTableTopBar
        searchPlaceholder={searchPlaceholder}
        searchPhrase={globalFilter}
        searchable={searchable}
        onSearchPhraseChange={setGlobalFilter}
        onAddRowClick={onAddRowClick}
        customActions={topBarCustomActions}
      />
      <div className={`cardsTableBodyWrap ${bottomClassNameRef.current}`}>
        <table {...getTableProps()} className="cards-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    data-col={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span style={{ visibility: "hidden", height: 0 }}>
                      {column.render("Header")}
                    </span>
                    <span style={{ visibility: "hidden", height: 0 }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    <Sticky
                      innerClass="sticky-node"
                      bottomBoundary={`.${bottomClassNameRef.current}`}
                    >
                      <div>
                        <span>&#8203;{column.render("Header")}</span>
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                        {hasIndevidualFilters && (
                          <div
                            style={{ height: 38 }}
                            className="d-flex justify-content-center"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {column.canFilter &&
                              column.Filter &&
                              column.render("Filter")}
                          </div>
                        )}
                      </div>
                    </Sticky>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...getRowProps?.(row)}
                  {...row.getRowProps()}
                  onClick={
                    onEditRowClick
                      ? () => onEditRowClick(row.original, row.index)
                      : undefined
                  }
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        data-col={columns[index].id}
                        {...cell.getCellProps()}
                        {...columns[index].cellProps}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {hasPagination && (
        <TablePagination
          pageCount={Math.ceil(globalFilteredRows.length / pageSize)}
          pageIndex={pageIndex}
          pageSizeIndex={pageSizeIndex}
          pageSizes={pageSizes}
          onGoToPage={onGoToPage}
          onPageSizeChange={onPageSizeChange}
        />
      )}
      {isLoading && (
        <div className="loadingWrap">
          <Spinner animation="border" className="text-primary" />
        </div>
      )}
    </div>
  );
};
