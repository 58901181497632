import { StockRemovalRequestReason } from "./types";

export const StockRemovalRequestReasonOptions = [
  { label: "מקולקל", value: StockRemovalRequestReason.Rotten },
  { label: "פג תוקף", value: StockRemovalRequestReason.Expired },
  {
    label: "תקלה (נפל, נהרס, שימוש לא נכון)",
    value: StockRemovalRequestReason.Faulted,
  },
];
