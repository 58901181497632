import produce from "immer";
import { TreeInfo, TreeInfoFoodListItem } from "./types";
import { TreeType } from "base/types/TreeType";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";
import Axios from "axios";

export class TreeInfoApi {
  static tags = {
    ItemByTreeType: (type: TreeType) =>
      ApiQuery.buildTag(
        "TreeInfoApi",
        "ItemByTreeType",
        TreeApiPathFormatter.format(type)
      ),
  };

  static itemQuery = new ApiQuery<TreeInfo, { type: TreeType; id: number }>(
    ({ type, id }) => `/api/${TreeApiPathFormatter.format(type)}/${id}/tree`,
    ({ type }) => [TreeInfoApi.tags.ItemByTreeType(type)]
  );

  static async saveMaterial(
    type: TreeType,
    id: number,
    listItemId: number,
    listItemAmount: number,
    isNew: boolean
  ) {
    const { data: savedListItem } = await Axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/material`,
      {
        addedItemId: isNew ? listItemId : undefined,
        id: isNew ? undefined : listItemId,
        amount: listItemAmount,
      }
    );

    TreeInfoApi.itemQuery.updateCache(
      produce((treeInfo) => {
        if (!treeInfo) return;

        const saveMaterialUpdater = QueryCacheHelper.buildSaveListItemUpdater(
          savedListItem,
          "id"
        );

        treeInfo.materials = saveMaterialUpdater(treeInfo.materials)!;
      }),
      { type, id }
    );
  }

  static async saveIngredient(
    type: TreeType,
    id: number,
    listItemId: number,
    listItemAmount: number,
    isNew: boolean
  ) {
    const { data: savedListItem } = await Axios.post<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/ingredient`,
      {
        addedItemId: isNew ? listItemId : undefined,
        id: isNew ? undefined : listItemId,
        amount: listItemAmount,
      }
    );

    TreeInfoApi.itemQuery.updateCache(
      produce((treeInfo) => {
        if (!treeInfo) return;

        const saveIngredientUpdater = QueryCacheHelper.buildSaveListItemUpdater(
          savedListItem,
          "id"
        );

        treeInfo.ingredients = saveIngredientUpdater(treeInfo.ingredients)!;
      }),
      { type, id }
    );
  }

  static async removeMaterial(type: TreeType, id: number, materialId: number) {
    await Axios.delete<TreeInfoFoodListItem>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/material/${materialId}`
    );

    TreeInfoApi.itemQuery.updateCache(
      produce((treeInfo) => {
        if (!treeInfo) return;

        const deleteMaterialUpdater = QueryCacheHelper.buildDeleteListItemUpdater<
          TreeInfoFoodListItem
        >(materialId, "id");

        treeInfo.materials = deleteMaterialUpdater(treeInfo.materials)!;
      }),
      { type, id }
    );
  }

  static async removeIngredient(
    type: TreeType,
    id: number,
    ingredientId: number
  ) {
    await Axios.delete(
      `/api/${TreeApiPathFormatter.format(
        type
      )}/${id}/ingredient/${ingredientId}`
    );

    TreeInfoApi.itemQuery.updateCache(
      produce((treeInfo) => {
        if (!treeInfo) return;

        const deleteIngredientUpdater = QueryCacheHelper.buildDeleteListItemUpdater<
          TreeInfoFoodListItem
        >(ingredientId, "id");

        treeInfo.ingredients = deleteIngredientUpdater(treeInfo.ingredients)!;
      }),
      { type, id }
    );
  }
}
