import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { ProductLitItem, ProductForm, ProductGet } from "./types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { ApiQuery } from "base/api/ApiQuery";

export class ProductsApi {
  static listQuery = new ApiQuery<ProductLitItem[]>("/api/products/items");

  static itemQuery = new ApiQuery<ProductGet, number>(
    (id: number) => `/api/products/${id}`
  );

  static departmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/products/departments"
  );

  static async saveItem(item: WithPartialProperties<ProductForm, "id">) {
    const { data: savedItem } = await Axios.post<ProductGet>(
      "/api/products",
      BodyFormDataBuilder.build2(item),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    ProductsApi.itemQuery.updateCache(savedItem, savedItem.id);

    ProductsApi.listQuery.invalidate();

    return savedItem;
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/products/${id}`);

    ProductsApi.listQuery.invalidate();
  }
}
