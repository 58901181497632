import React, { useMemo } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Form } from "form/components/Form";
import { Typography } from "base/components/Typography";
import { FormSelect } from "form/components/FormSelect";
import { FormCustomReset } from "form/utils/FormCustomReset";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { AllergenItem, AllergenStatus, AllergenRelationType } from "./types";
import { AllergensApi } from "./AllergensApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { isEqual, debounce } from "lodash";
import { FieldArray } from "react-final-form-arrays";
import { getIn } from "final-form";
import { FormSpy } from "react-final-form";
import { noop } from "base/utils/noop";
import { SelectProps } from "base/components/Select";
import { EnterKeyCodeListener } from "base/components/DocumentKeyupListener";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { useQuery } from "react-query";
import { skipToken } from "base/api/ApiQuery";

export type AllergensModalProps = {
  show: boolean;
  onCancel: () => void;
  relationType: AllergenRelationType;
  relationId?: number;
  relationName?: string;
};

export const AllergensModal: React.FC<AllergensModalProps> = ({
  show,
  relationType,
  relationId,
  relationName,
  onCancel,
}) => {
  const p_show = Boolean(show && relationId);

  const { data: allergensTypes } = useQuery(
    AllergensApi.typesQuery.build({
      enabled: p_show,
    })
  );
  const allergensOptions = useSelectOptionsAdapter(allergensTypes);

  const allergenStatusOptions: SelectProps["options"] = [
    { value: AllergenStatus.NotContains, label: "לא מכיל" },
    { value: AllergenStatus.MightContain, label: "עלול להכיל" },
    { value: AllergenStatus.Contains, label: "מכיל" },
  ];

  const { data: existingAllergens } = useQuery(
    AllergensApi.itemsQuery.build(
      {
        relationType,
        relationId: relationId ?? skipToken,
      },
      {
        enabled: p_show,
      }
    )
  );

  const onRowChanged = useMemo(
    () =>
      debounce(async (allergen: AllergenItem) => {
        await AllergensApi.saveItem(relationType, relationId!, allergen);
      }, 500),
    [relationId, relationType]
  );

  return (
    <Modal show={show} onHide={onCancel} centered size="lg">
      <div>
        <ModalTitleBar
          title={`ניהול אלרגנים - ${relationName}`}
          onClose={onCancel}
        />
        <div className="p-2">
          <Typography variant="description" fontSize={20} bold>
            הוספת אלרגן חדש
          </Typography>
          <Form
            onSubmit={async (values: AllergenItem, form) => {
              await AllergensApi.saveItem(relationType, relationId!, values);
              FormCustomReset.hardReset(form);
            }}
            subscription={{ submitting: true }}
          >
            {({ handleSubmit, submitting }) => (
              <Row noGutters className="pt-2">
                <Col xs={4} className="px-1">
                  <FormSelect
                    name="allergen"
                    emptyOptionLabel="בחירת אלרגן"
                    options={allergensOptions}
                    validate={FieldsValidator.required}
                  />
                </Col>
                <Col xs={4} className="px-1">
                  <FormSelect
                    name="status"
                    displayEmptyOption={false}
                    options={allergenStatusOptions}
                    validate={FieldsValidator.required}
                    defaultSelectedValue={2} // מכיל
                  />
                </Col>
                <Col xs="auto">
                  <EnterKeyCodeListener active={p_show} onEnter={handleSubmit}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={submitting}
                      size="sm"
                      style={{ height: 38 }}
                    >
                      הוספה
                    </Button>
                  </EnterKeyCodeListener>
                </Col>
              </Row>
            )}
          </Form>
          <div className="pt-2">
            <Typography variant="description" fontSize={20} bold>
              אלרגנים קיימים
            </Typography>
          </div>
          <Form
            initialValues={{
              allergens: existingAllergens,
            }}
            onSubmit={noop}
          >
            {({ form }) => (
              <FieldArray
                name="allergens"
                isEqual={isEqual}
                subscription={{ length: true }}
              >
                {({ fields }) => (
                  <div>
                    {fields.map((fieldPrefix) => {
                      const allergenId: number = getIn(
                        form.getState().values,
                        `${fieldPrefix}.id`
                      );
                      return (
                        <Row noGutters key={`${fieldPrefix}.id`}>
                          <Col xs={4} className="p-1">
                            <FormSelect
                              name={`${fieldPrefix}.allergen`}
                              emptyOptionLabel="בחירת אלרגן"
                              options={allergensOptions}
                              validate={FieldsValidator.required}
                            />
                          </Col>
                          <Col xs={4} className="p-1">
                            <FormSelect
                              name={`${fieldPrefix}.status`}
                              displayEmptyOption={false}
                              options={allergenStatusOptions}
                              validate={FieldsValidator.required}
                            />
                          </Col>
                          <Col
                            xs="auto"
                            className="p-1 d-flex align-items-center"
                          >
                            <i
                              className="fas fa-trash text-danger ml-auto"
                              aria-hidden="true"
                              role="button"
                              onClick={async () => {
                                if (window.confirm("האם אתה בטוח?")) {
                                  await AllergensApi.deleteItem(
                                    relationType,
                                    relationId!,
                                    allergenId
                                  );
                                }
                              }}
                            />
                          </Col>
                          <FormSpy
                            subscription={{
                              validating: true,
                            }}
                            onChange={({ validating }) => {
                              const formState = form.getState();
                              if (validating || !formState.valid) {
                                onRowChanged.cancel();
                                return;
                              }

                              const nextRowValue = formState.values.allergens?.find(
                                (x) => x.id === allergenId
                              );
                              const prevRowValue = formState.initialValues.allergens?.find(
                                (x) => x.id === allergenId
                              );

                              if (
                                nextRowValue &&
                                prevRowValue &&
                                !isEqual(nextRowValue, prevRowValue)
                              ) {
                                onRowChanged(nextRowValue!);
                              }
                            }}
                          />
                        </Row>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
