import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { BaseImage } from "base/components/BaseFoodImage";
import { SentOrderItemGet } from "sent-order/types";
import { NumpadModal } from "base/components/NumpadModal/NumpadModal";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { BaseSelectListItem, SupplyUnitType } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SupplyMethodPicker } from "supply-methods/SupplyMethodPicker";
import { useBooleanState } from "base/hooks/useBooleanState";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { OrderHelper } from "order/OrderHelper";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { useImmer } from "use-immer";
import { PackageWeightsPicker } from "package-weights/PackageWeightsPicker";
import { WritableDraft } from "immer/dist/internal";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { RemoteWeightModal } from "scales/RemoteWeightModal";
import { LabelsPrinterModal } from "labels-printer/LabelsPrinterModal";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { ScalesButton } from "base/components/ScalesButton";
import { useQuery } from "react-query";
import { useTokenPayload } from "auth/hooks/useTokenPayload";

export type SentOrderFoodItemModalProps = {
  show: boolean;
  item: SentOrderItemGet | undefined;
  onDone: (modifedItem: SentOrderItemGet) => void;
  onCancel: () => void;
  disabled?: boolean;
};

export const SentOrderFoodItemModal: React.FC<SentOrderFoodItemModalProps> = ({
  item,
  show,
  onCancel,
  onDone,
  disabled = false,
}) => {
  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds, {
        enabled: show,
      })
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds, {
        enabled: show,
      })
  );

  const [draftItem, updateDraftItem] = useImmer(item);
  const [showNumpad, onOpenNumpad, onHideNumpad] = useBooleanState(false);
  const [showRemoteWeight, onOpenRemoteWeight, onHideRemoteWeight] =
    useBooleanState(false);
  const [
    showSupplyMethodPicker,
    onDisplaySupplyMethodPicker,
    onHideSupplyMethodPicker,
  ] = useBooleanState(false);
  const [
    showPackageWeightsPicker,
    onDisplayPackageWeightsPicker,
    onHidePackageWeightsPicker,
  ] = useBooleanState(false);
  const [showLabelsPrinter, onShowLabelsPrinter, onHideLablesPrinter] =
    useBooleanState(false);

  const makeWeightUpdates = useCallback(
    (
      draft: SentOrderItemGet | WritableDraft<SentOrderItemGet>,
      nextWeight: number
    ) => {
      draft.receivedWeight = nextWeight;

      if (draft.receivedSupplyMethod.supplyUnitType === SupplyUnitType.Amount) {
        draft.receivedAmount = nextWeight;
      }
    },
    []
  );

  const onNumpadSave = useCallback(
    (value: number) => {
      updateDraftItem((curr) => {
        curr!.receivedAmount = value;
        if (
          OrderHelper.isWeightable(curr!) &&
          curr!.receivedSupplyMethod.supplyUnitType === SupplyUnitType.Amount
        ) {
          curr!.receivedWeight = value;
        }
      });
      onHideNumpad();
    },
    [onHideNumpad, updateDraftItem]
  );

  const onWeightReceived = useCallback(
    (value: number) => {
      updateDraftItem((curr) => {
        makeWeightUpdates(curr!, curr!.receivedWeight + value);
        if (!curr!.weights) {
          curr!.weights = [];
        }

        curr!.weights.push(value);
      });
    },
    [makeWeightUpdates, updateDraftItem]
  );

  const onSupplyMethodPicked = useCallback(
    (supplyMethod: BaseSupplyMethod) => {
      updateDraftItem((curr) => {
        curr!.receivedSupplyMethod = supplyMethod;
      });
      onHideSupplyMethodPicker();
    },
    [onHideSupplyMethodPicker, updateDraftItem]
  );

  const onSave = useCallback(() => {
    onDone(draftItem!);
  }, [onDone, draftItem]);

  const authTokenPayload = useTokenPayload();

  useEffect(() => {
    updateDraftItem(item);
  }, [item, updateDraftItem]);

  if (!draftItem || !show) {
    return null;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onCancel}
        dialogClassName="SentOrderFoodItemModal"
        centered
        size="xl"
        backdrop="static"
      >
        <ModalTitleBar
          title={
            <div className="d-flex justify-content-center position-relative">
              <h1>{draftItem.name}</h1>
            </div>
          }
          onClose={onCancel}
        />
        <div className="mt-2">
          <Row>
            <Col md={4}>
              <BaseImage
                fileName={draftItem.imageName}
                style={{ maxHeight: 400 }}
                containerStyle={{ justifyContent: "flex-start" }}
              />
            </Col>
            <Col md={5}>
              <div className="d-flex align-items-baseline mb-5">
                <div className="h2 flex-shrink-0 m-0 ml-3">כמות נדרשת:</div>
                <div>
                  <div className="d-flex align-items-baseline">
                    {OrderHelper.isWeightable(draftItem) && (
                      <>
                        <div className="h3 m-0 ml-2">
                          {NumberFormatter.default.formatAmount(
                            OrderHelper.calcTotalAmount(
                              draftItem.requestedAmount,
                              draftItem.requestedSupplyMethod,
                              draftItem.prepareUnitType
                            )
                          )}
                        </div>
                        <div className="h5 m-0">
                          {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                        </div>
                      </>
                    )}
                    {!OrderHelper.isWeightable(draftItem) && (
                      <>
                        <div className="h3 m-0 ml-2">
                          {NumberFormatter.default.formatAmount(
                            draftItem.requestedAmount
                          )}
                        </div>
                        <div className="h5 m-0">
                          {SupplyMethodDescFormatter.formatTopPackage(
                            draftItem.requestedSupplyMethod,
                            draftItem.prepareUnitType,
                            unitKinds,
                            packageKinds
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <span>
                      {SupplyMethodDescFormatter.format(
                        draftItem.requestedSupplyMethod,
                        draftItem.prepareUnitType,
                        unitKinds,
                        packageKinds
                      )}
                    </span>
                    {draftItem.requestedSupplyMethod.supplyUnitType !==
                      SupplyUnitType.Amount &&
                      OrderHelper.isWeightable(draftItem) && (
                        <div className="h5 m-0">
                          סה״כ כמות:{" "}
                          <span className="font-weight-bold">
                            {NumberFormatter.default.formatAmount(
                              draftItem.requestedAmount
                            )}{" "}
                            {SupplyMethodDescFormatter.formatTopPackage(
                              draftItem.requestedSupplyMethod,
                              draftItem.prepareUnitType,
                              unitKinds,
                              packageKinds
                            )}
                          </span>
                        </div>
                      )}
                    {draftItem.requestedSupplyMethod.supplyUnitType !==
                      SupplyUnitType.Amount &&
                      !OrderHelper.isWeightable(draftItem) && (
                        <div className="h5 m-0">
                          סה״כ כמות:{" "}
                          <span className="font-weight-bold">
                            {NumberFormatter.default.formatAmount(
                              OrderHelper.calcTotalAmount(
                                draftItem.requestedAmount,
                                draftItem.requestedSupplyMethod,
                                draftItem.prepareUnitType
                              )
                            )}{" "}
                            {UnitTypeFormatter.format(
                              draftItem.prepareUnitType
                            )}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-baseline">
                <div className="h2 flex-shrink-0 m-0 ml-3">כמות התקבלה:</div>
                <div>
                  <div className="d-flex align-items-baseline">
                    {OrderHelper.isWeightable(draftItem) && (
                      <>
                        <div className="h3 m-0 ml-2">
                          {NumberFormatter.formatAmount(
                            draftItem.receivedWeight
                          )}
                        </div>
                        <div className="h5 m-0">
                          {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                        </div>
                      </>
                    )}
                    {!OrderHelper.isWeightable(draftItem) && (
                      <>
                        <div className="h3 m-0 ml-2">
                          {NumberFormatter.formatAmount(
                            draftItem.receivedAmount
                          )}
                        </div>
                        <div className="h5 m-0">
                          {SupplyMethodDescFormatter.formatTopPackage(
                            draftItem.receivedSupplyMethod,
                            draftItem.prepareUnitType,
                            unitKinds,
                            packageKinds
                          )}
                        </div>
                        <i
                          className={classNames("fas fa-pen editAmount", {
                            disabled,
                          })}
                          onClick={onOpenNumpad}
                          role="button"
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {" "}
                    {draftItem.supplyMethods.length > 0 && (
                      <i
                        className={classNames("fas fa-cog", { disabled })}
                        role="button"
                        onClick={onDisplaySupplyMethodPicker}
                      />
                    )}{" "}
                    {SupplyMethodDescFormatter.format(
                      draftItem.receivedSupplyMethod,
                      draftItem.prepareUnitType,
                      unitKinds,
                      packageKinds
                    )}
                    {OrderHelper.isWeightable(draftItem) &&
                      draftItem.receivedSupplyMethod.supplyUnitType !==
                        SupplyUnitType.Amount && (
                        <h5>
                          סה״כ כמות:{" "}
                          <span className="font-weight-bold">
                            {NumberFormatter.default.formatAmount(
                              draftItem.receivedAmount
                            )}{" "}
                            {SupplyMethodDescFormatter.formatTopPackage(
                              draftItem.receivedSupplyMethod,
                              draftItem.prepareUnitType,
                              unitKinds,
                              packageKinds
                            )}
                          </span>
                          <i
                            className={classNames("fas fa-pen edit-amount", {
                              disabled,
                            })}
                            onClick={onOpenNumpad}
                          />
                        </h5>
                      )}
                    {!OrderHelper.isWeightable(draftItem) &&
                      draftItem.receivedSupplyMethod.supplyUnitType !==
                        SupplyUnitType.Amount && (
                        <h5>
                          סה"כ כמות:{" "}
                          <span className="font-weight-bold">
                            {NumberFormatter.default.formatAmount(
                              OrderHelper.calcTotalAmount(
                                draftItem.receivedAmount,
                                draftItem.receivedSupplyMethod,
                                draftItem.prepareUnitType
                              )
                            )}{" "}
                            {UnitTypeFormatter.format(
                              draftItem.prepareUnitType
                            )}
                          </span>
                        </h5>
                      )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3} className="d-flex flex-column align-items-center">
              {OrderHelper.isWeightable(draftItem) && (
                <>
                  <ScalesButton
                    disabled={disabled}
                    text={
                      <span>
                        הנח הסחורה <br /> ולחץ לשקילה
                      </span>
                    }
                    onClick={onOpenRemoteWeight}
                  />
                  <div className="font-weight-bold mt-2">משקלים שנקלטו</div>
                  {draftItem.weights?.map((weight, index) => (
                    <div className="d-flex my-1" key={index}>
                      <div className="ml-2">
                        <span>{weight}</span>
                        <span>
                          {UnitTypeFormatter.format(draftItem.prepareUnitType)}
                        </span>
                      </div>
                      <div>
                        <i
                          className={classNames("fa fa-minus-circle", {
                            disabled,
                          })}
                          onClick={() => {
                            updateDraftItem((curr) => {
                              const nextWeight = curr!.receivedWeight - weight;
                              curr!.receivedWeight = nextWeight;
                              curr!.weights! = curr!.weights!.filter(
                                (_, i) => index !== i
                              );
                            });
                          }}
                          role="button"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="mt-auto p-2">
                    <Button onClick={onDisplayPackageWeightsPicker}>
                      הפחתת משקל אריזה
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <div className="text-center mb-2">
            <Button onClick={onShowLabelsPrinter} className="mx-2">
              <span className="h3">הדפסת מדבקות</span>
            </Button>
            <Button onClick={onSave} disabled={disabled} className="mx-2">
              <span className="h3 ">
                <i className="fas fa-save" /> שמירה
              </span>
            </Button>
          </div>
        </div>
      </Modal>
      <RemoteWeightModal
        show={showRemoteWeight}
        prepareUnitType={draftItem.prepareUnitType}
        onClose={onHideRemoteWeight}
        onWeightReceived={onWeightReceived}
      />
      <NumpadModal
        show={showNumpad}
        title={`בחירת משקל: ${draftItem.name || ""}`}
        initialValue={draftItem.receivedAmount}
        onCancel={onHideNumpad}
        onDone={onNumpadSave}
      />
      <SupplyMethodPicker
        show={showSupplyMethodPicker}
        supplyMethods={draftItem.supplyMethods}
        prepareUnitType={draftItem.prepareUnitType}
        onPick={onSupplyMethodPicked}
        onHide={onHideSupplyMethodPicker}
      />
      <PackageWeightsPicker
        show={showPackageWeightsPicker}
        onPick={(weightItem, amount) => {
          const weightToReduceGR = amount * weightItem.weight;
          onWeightReceived(-weightToReduceGR / 1000);
        }}
        onHide={onHidePackageWeightsPicker}
      />
      <LabelsPrinterModal
        show={showLabelsPrinter}
        onHide={onHideLablesPrinter}
        itemName={item?.name}
        variant="sentOrders"
      />
    </>
  );
};
