import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Spacer } from "base/components/Spacer";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { TreeInfoApi } from "tree-info/TreeInfoApi";
import { TreeType } from "base/types/TreeType";
import { TreeInfoFoodsTable } from "./TreeInfoFoodsTable";
import { TreeInfoHeader } from "./TreeInfoHeader";
import { MaterialTreesTable } from "./MaterialTreesTable";
import {
  TreeInfoIngredientsChartBGColors,
  TreeInfoIngredientsChartHoverBGColors,
} from "tree-info/constants";
import { useQuery } from "react-query";

export type TreeInfoProps = {
  itemId: number;
  type: TreeType;
};

export const TreeInfo: React.FC<TreeInfoProps> = ({ itemId, type }) => {
  const { data } = useQuery(
    TreeInfoApi.itemQuery.build({
      type,
      id: itemId,
    })
  );

  const options = {
    responsive: true,
    title: {
      display: true,
      text: "חלוקת כמויות במנה",
    },
  };

  const chartData = useMemo(() => {
    const all = data?.ingredients?.concat(data.materials);

    return {
      labels: (all ?? []).map((x) => x.name),
      datasets: [
        {
          data: all?.map((x) => x.amount),
          backgroundColor: TreeInfoIngredientsChartBGColors,
          hoverBackgroundColor: TreeInfoIngredientsChartHoverBGColors,
        },
      ],
    };
  }, [data]);

  return (
    <>
      <Typography variant="route-title">{data?.itemName}</Typography>
      <Form onSubmit={() => {}} initialValues={data}>
        {() => (
          <>
            <TreeInfoHeader type={type} itemId={itemId} />
            <div className="table-pie-container">
              <div className="tables-container">
                <TreeInfoFoodsTable
                  type={type}
                  id={itemId}
                  listName="ingredients"
                />
                <Spacer />
                <TreeInfoFoodsTable
                  type={type}
                  id={itemId}
                  listName="materials"
                />
                {type === TreeType.Material && (
                  <>
                    <Typography variant="title" className="pt-5 pb-0 mb-0">
                      עצי מוצר
                    </Typography>
                    <MaterialTreesTable id={itemId} />
                  </>
                )}
              </div>
              <div className="chartContainer">
                <Spacer />
                <Doughnut data={chartData} options={options} />
              </div>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
