import React from "react";
import { Modal } from "react-bootstrap";
import { POSHistoryFoodItem } from "./types";
import { Typography } from "base/components/Typography";
import { ModalTitleBar } from "modal/ModalTitleBar";

export interface POSFoodItemModalProps {
  show: boolean;
  onHide: () => void;
  food: POSHistoryFoodItem | undefined;
}
export const POSFoodItemModal: React.FC<POSFoodItemModalProps> = ({
  food,
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} size="xl">
      <ModalTitleBar title={food?.Name} onClose={onHide} />
      <div className="p-2">
        <div className="d-flex">
          <div className="px-2">כמות לחישוב: {food?.Quantity}</div>
          <div className="px-2">סה"כ נמכר: {food?.TotalSold}</div>
          <div className="px-2">סה"כ נמכר-משנה: {food?.TotalSoldAddon}</div>
        </div>
        <div className="my-2">
          <Typography variant="description" fontSize={18}>
            מרכיבים
          </Typography>
        </div>
        <table className="w-100">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>שם</th>
              <th>סוג</th>
              <th style={{ width: "25%" }}>משנה</th>
              <th>כמות</th>
              <th>כמות לחישוב</th>
              <th>סה״כ כמות</th>
            </tr>
          </thead>
          <tbody>
            {food?.TreeItemsSet.Ingredients.map((ing, index) => (
              <tr key={`ingredient_${index}`}>
                <td>{ing.Name}</td>
                <td>מרכיב</td>
                <td />
                <td dir="ltr">
                  {ing.Amount} {ing.PrepareUnitType}
                </td>
                <td>{food?.TotalSold}</td>
                <td dir="ltr">
                  {food.Quantity * ing.Amount} {ing.PrepareUnitType}
                </td>
              </tr>
            ))}
            {food?.TreeItemsSet.Materials.map((mat, index) => (
              <tr key={`material_${index}`}>
                <td>{mat.Name}</td>
                <td>חומר גלם</td>
                <td />
                <td dir="ltr">
                  {mat.Amount} {mat.PrepareUnitType}
                </td>
                <td>{food?.TotalSold}</td>
                <td dir="ltr">
                  {food.Quantity * mat.Amount} {mat.PrepareUnitType}
                </td>
              </tr>
            ))}
            {food?.Addons.map((addon, index) => [
              ...addon.TreeItemsSet.Ingredients.map((ing) => (
                <tr key={`addon${index}`}>
                  <td>{ing.Name}</td>
                  <td>מרכיב</td>
                  <td>{addon.Name}</td>
                  <td dir="ltr">
                    {ing.Amount} {ing.PrepareUnitType}
                  </td>
                  <td>{addon?.SoldAddon}</td>
                  <td dir="ltr">
                    {addon.Quantity * ing.Amount} {ing.PrepareUnitType}
                  </td>
                </tr>
              )),
              ...addon.TreeItemsSet.Materials.map((mat) => (
                <tr key={`addon${index}`}>
                  <td>{mat.Name}</td>
                  <td>חומר גלם</td>
                  <td>{addon.Name}</td>
                  <td dir="ltr">
                    {mat.Amount} {mat.PrepareUnitType}
                  </td>
                  <td>{addon?.SoldAddon}</td>
                  <td dir="ltr">
                    {addon.Quantity * mat.Amount} {mat.PrepareUnitType}
                  </td>
                </tr>
              )),
            ])}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
