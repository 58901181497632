import { BaseFood, BaseFoodListItem } from "base/types";
import { ProductSupplyMethod } from "product/supply-method/types";

export type EditSupplierRouteParams = {
  supplierId: string;
};

export interface Supplier extends BaseFood {
  paymentTerms: number;
  idNumber: string;
  announcementTypes: number[];
  submitOrderMessage?: string;
  minimunOrderPrice: number | undefined;
}

export interface SupplierListItem extends BaseFoodListItem {
  prepareUnitType: number;
  paymentTerms: number;
  distributionDays: string | undefined;
}

export type SupplierFormData = WithPartialProperties<Supplier, "id"> & {
  image?: File;
};

export enum PaymentTerms {
  Term0 = 0,
  Term45,
  Term30,
  Term60,
  Term90,
}

export const PaymentTermsOptions = [
  { value: PaymentTerms.Term0, label: "שוטף" },
  { value: PaymentTerms.Term45, label: "שוטף + 45" },
  { value: PaymentTerms.Term30, label: "שוטף + 30" },
  { value: PaymentTerms.Term60, label: "שוטף + 60" },
  { value: PaymentTerms.Term90, label: "שוטף + 90" },
];

export interface SupplierOrderListItem {
  id: number;
  dateCreated: string;
  totalItems: number;
  status: number;
  receiveDate: string;
  submitDate: string;
  referenceNo: string;
  orderDocumentType: number;
  totalPrice: number;
  submitterName: string;
}

export interface SupplierContact {
  id: number;
  name: string;
  contactTypeId: number;
  phone: string;
  email: string;
  isDefault: boolean;
}

export interface DistributionDay {
  id: number;
  isActive: boolean;
  orderDay: number;
  orderDayTime: string;
  supplyDay: number;
  supplyDayTime: string;
  supplierId: number;
}

export interface DistributionDaysFormValues {
  distributionDays: DistributionDay[];
}

export interface IngredientPriceAgreement {
  id: number;
  taklitPercentage: number;
  fixedPrice: number;
  supplierId: number;
  ingredientId: number;
}

export interface SupplierProductSupplyListItem {
  productId: number;
  imageName: string;
  supplierCatalogId: string;
  name: string;
  isActive: boolean;
  supplyMethod: ProductSupplyMethod;
  price: number;
  discountPercentage: number;
  prepareUnitType: number;
}
