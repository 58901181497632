import Axios from "axios";

let concurrent = Promise.resolve();

export class PushNotificationsHandler {
  static async start() {
    concurrent = concurrent.then(async () => {
      const reg = await navigator.serviceWorker.getRegistration();
      if (!reg) {
        console.warn("Service Worker Registration Not Found");
        return;
      }

      const sub = await reg.pushManager.getSubscription();

      if (sub === null) {
        try {
          const newSub = await reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              "BNobaXi3unskqIob2sv-SSJ0JBkli_znfT-OV8P9zVw9os_Hyw3KItFNcAlATWBhuzwI2pvC7NH2jkO0J3db1w8",
          });
          await PushNotificationsHandler.sendPushDataToServer(newSub);
        } catch (e) {
          console.error("Unable to subscribe to push", e);
        }
      } else {
        await PushNotificationsHandler.sendPushDataToServer(sub);
      }
    });
  }

  static async stop() {
    concurrent = concurrent.then(async () => {
      const reg = await navigator.serviceWorker.getRegistration();

      if (!reg) {
        return;
      }

      const sub = await reg.pushManager.getSubscription();
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  private static async onNotification(e: any) {
    const reg = await navigator.serviceWorker.getRegistration();
    if (!reg) {
      console.warn("Service Worker Registration Not Found");
      return;
    }

    var body;

    if (e.data) {
      body = e.data.text();
    } else {
      body = "Standard Message";
    }

    var options = {
      body: body,
      icon: "images/icon-512x512.png",
      vibrate: [100, 50, 100],
      data: {
        dateOfArrival: Date.now(),
      },
      actions: [
        {
          action: "explore",
          title: "Go interact with this!",
          icon: "images/checkmark.png",
        },
        {
          action: "close",
          title: "Ignore",
          icon: "images/red_x.png",
        },
      ],
    };

    e.waitUntil(reg.showNotification("Push Notification", options));
  }

  private static arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private static async sendPushDataToServer(sub: PushSubscription) {
    Axios.post("/api/webpush/register", {
      endpoint: sub.endpoint,
      p256dh: PushNotificationsHandler.arrayBufferToBase64(
        sub.getKey("p256dh")
      ),
      auth: PushNotificationsHandler.arrayBufferToBase64(sub.getKey("auth")),
    });
  }
}
