import React, { useEffect } from "react";
import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { useAppContext } from "app/context/hooks/useAppContext";
import { ReactComponent as OrdersHomeIcon } from "order/assets/OrdersBottomNavBarOrdersHome.svg";
import { ReactComponent as OrdersHomeSelectedIcon } from "order/assets/OrdersBottomNavBarOrdersHomeSelected.svg";
import { ReactComponent as CreateOrderIcon } from "order/assets/OrdersBottomNavBarCreateOrder.svg";
import { ReactComponent as CreateOrderSelectedIcon } from "order/assets/OrdersBottomNavBarCreateOrderSelected.svg";
import { ReactComponent as OrdersStatusIcon } from "order/assets/OrdersBottomNavBarOrdersStatus.svg";
import { ReactComponent as OrdersStatusSelectedIcon } from "order/assets/OrdersBottomNavBarOrdersStatusSelected.svg";
import { ReactComponent as SentOrdersIcon } from "order/assets/OrdersBottomNavBarSentOrders.svg";
import { ReactComponent as SentOrdersSelectedIcon } from "order/assets/OrdersBottomNavBarSentOrdersSelected.svg";
import { faList } from "@fortawesome/free-solid-svg-icons";

import "../styles/OrdersBottomNavbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const OrdersBottomNavbar: React.FC = () => {
  const { setIsBottomNavbarActive } = useAppContext();
  const navigate = useNavigateCallback();
  const isHomeSelected =
    useRouteMatch([SentOrdersRouteBuilder.templates.ReceivedOrders])?.isExact ??
    false;
  const isAddOrderSelected =
    useRouteMatch([OrdersRouteBuilder.templates.AddOrderHomeRoute])?.isExact ??
    false;
  const isOrdersStatusSelected =
    useRouteMatch([OrdersRouteBuilder.templates.OrdersStatusRoute])?.isExact ??
    false;
  const isSentOrdersSelected =
    useRouteMatch([
      SentOrdersRouteBuilder.templates.SentOrderScan,
      SentOrdersRouteBuilder.templates.SentOrderEditor,
      SentOrdersRouteBuilder.templates.SentOrder,
      SentOrdersRouteBuilder.templates.SentOrders,
    ])?.isExact ?? false;

  const isHidden = [
    isHomeSelected,
    isAddOrderSelected,
    isOrdersStatusSelected,
    isSentOrdersSelected,
  ].every((x) => !x);

  const isActive = !isHidden;

  useEffect(() => {
    setIsBottomNavbarActive(isActive);

    // Cleanup, if for some reason this is no longer rendered.
    return () => {
      setIsBottomNavbarActive(false);
    };
  }, [isActive, setIsBottomNavbarActive]);

  return (
    <div
      className={classNames("OrdersBottomNavbar", {
        isHidden,
      })}
    >
      <div
        className={classNames("item", { selected: isAddOrderSelected })}
        role="button"
        onClick={() => navigate(OrdersRouteBuilder.buildAddOrderHomeRoute())}
      >
        {!isAddOrderSelected && <CreateOrderIcon height={36} />}
        {isAddOrderSelected && <CreateOrderSelectedIcon height={36} />}
      </div>
      <div
        className={classNames("item", { selected: isOrdersStatusSelected })}
        role="button"
        onClick={() => navigate(OrdersRouteBuilder.buildOrdersStatusRoute())}
      >
        {!isOrdersStatusSelected && <OrdersStatusIcon height={36} />}
        {isOrdersStatusSelected && <OrdersStatusSelectedIcon height={36} />}
      </div>
      <div
        className={classNames("item", { selected: isSentOrdersSelected })}
        role="button"
        onClick={() => navigate(SentOrdersRouteBuilder.buildSentOrdersRoute())}
      >
        {!isSentOrdersSelected && <SentOrdersIcon height={36} />}
        {isSentOrdersSelected && <SentOrdersSelectedIcon height={36} />}
        {/*<div className="counter">3</div>*/}
      </div>
      <div
        className={classNames("item", { selected: isHomeSelected })}
        role="button"
        onClick={() =>
          navigate(SentOrdersRouteBuilder.buildReceivedOrdersRoute())
        }
      >
        {!isHomeSelected && (
          <FontAwesomeIcon icon={faList} color="lightGray" size="2x" />
        )}
        {isHomeSelected && (
          <FontAwesomeIcon icon={faList} color="#0B62A9" size="2x" />
        )}
      </div>
    </div>
  );
};
