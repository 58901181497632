import Axios from "axios";
import { ProductSupplyMethod, ProductSupplyMethodSupplier } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";
import produce from "immer";

export class ProductSupplyMethodsApi {
  static itemsQuery = new ApiQuery<ProductSupplyMethod[], number>(
    (productId) => `/api/products/${productId}/supplyMethods`
  );

  static async add(item: ProductSupplyMethod) {
    const { data: savedItem } = await Axios.post<ProductSupplyMethod>(
      `/api/productSupplyMethods`,
      item
    );

    ProductSupplyMethodsApi.itemsQuery.updateCache(
      QueryCacheHelper.buildAddListItemUpdater(savedItem),
      item.productId
    );

    return savedItem;
  }

  static async update(item: ProductSupplyMethod) {
    const { data: savedItem } = await Axios.post<ProductSupplyMethod>(
      `/api/productSupplyMethods`,
      item
    );

    ProductSupplyMethodsApi.itemsQuery.updateCache(
      QueryCacheHelper.buildUpdateListItemUpdater(savedItem, "id"),
      item.productId
    );
  }

  static async remove(productId: number, supplyMethodId: number) {
    await Axios.delete<ProductSupplyMethod>(
      `/api/productSupplyMethods/${supplyMethodId}`
    );

    ProductSupplyMethodsApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(supplyMethodId, "id"),
      productId
    );
  }

  static async saveSupplyMethodSupplier(
    productId: number,
    supplyMethodSupplier: WithPartialProperties<
      ProductSupplyMethodSupplier,
      "id"
    >
  ) {
    const { data: savedItem } = await Axios.post<ProductSupplyMethodSupplier>(
      `/api/ProductSupplyMethodSuppliers`,
      supplyMethodSupplier
    );

    ProductSupplyMethodsApi.itemsQuery.updateCache(
      produce((supplyMethods) => {
        const matchingItem = supplyMethods?.find(
          (x) => x.id === supplyMethodSupplier.productSupplyMethodId
        );

        if (matchingItem) {
          matchingItem.suppliers = QueryCacheHelper.saveListItem(
            savedItem,
            "id",
            matchingItem.suppliers
          );
        }
      }),
      productId
    );
  }

  static async deleteSupplyMethodSupplier(
    productId: number,
    supplyMethodId: number,
    itemId: number
  ) {
    await Axios.delete<ProductSupplyMethodSupplier>(
      `/api/ProductSupplyMethodSuppliers/${itemId}`
    );

    ProductSupplyMethodsApi.itemsQuery.updateCache(
      produce((supplyMethods) => {
        const matchingItem = supplyMethods?.find(
          (x) => x.id === supplyMethodId
        );

        if (matchingItem) {
          matchingItem.suppliers = QueryCacheHelper.deleteListItem(
            itemId,
            "id",
            matchingItem.suppliers
          );
        }
      }),
      productId
    );
  }
}
