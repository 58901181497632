import axios from "axios";
import { BaseSelectListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { Recipe } from "recipe/types";
import { MaterialListItem, Material, MaterialRelatedTree } from "./types";
import { RecipeApi } from "recipe/RecipeApi";
import { FormValuesWithUpload } from "form/types";
import { ApiQuery } from "base/api/ApiQuery";
import produce from "immer";

export class MaterialsApi {
  static tags = {
    Lists: ApiQuery.buildTag("MaterialsApi", "Lists"),
  };

  static listQuery = new ApiQuery<MaterialListItem[]>("/api/materials/items", [
    MaterialsApi.tags.Lists,
  ]);

  static itemQuery = new ApiQuery<Material, number>(
    (id) => `/api/materials/${id}`
  );

  static selectListQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/materials/selectlist",
    [MaterialsApi.tags.Lists]
  );

  static itemDefaultsQuery = new ApiQuery<Partial<Material>>(
    `/api/materials/new`
  );

  static departmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/materials/departments"
  );

  static itemTreesQuery = new ApiQuery<MaterialRelatedTree[], number>(
    (id) => `/api/materials/${id}/trees`
  );

  static async addRecipe(id: number) {
    const { data: createdRecipe } = await axios.post<Recipe>(
      `/api/materials/${id}/recipe/add`
    );

    RecipeApi.itemQuery.updateCache(createdRecipe, createdRecipe.id);
    MaterialsApi.itemQuery.updateCache(
      produce((item) => {
        if (!item) return;

        item.recipeId = createdRecipe.id;
      }),
      id
    );
  }

  static async saveItem(
    data: WithPartialProperties<FormValuesWithUpload<Material>, "id">
  ) {
    const { data: savedItem } = await axios.post<Material>(
      "/api/materials",
      BodyFormDataBuilder.build(data),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    MaterialsApi.itemQuery.updateCache(savedItem, savedItem.id);
    ApiQuery.invalidateByTags([MaterialsApi.tags.Lists]);

    return savedItem;
  }

  static async deleteItem(id: number) {
    await axios.delete<void>(`/api/materials/${id}`);

    ApiQuery.invalidateByTags([MaterialsApi.tags.Lists]);
  }
}
