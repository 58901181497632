import React, { useMemo } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { useParams } from "react-router-dom";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { success } from "@pnotify/core";
import {
  CardsTable,
  CardsTableColumn,
} from "base/components/tables/components/CardsTable";
import { SentOrderViewerItem } from "sent-order/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem, UnitType } from "base/types";
import { OrderHelper } from "order/OrderHelper";
import {
  UnitTypeFormatter,
  OrderStatusFormatter,
  NumberFormatter,
} from "base/utils/formatters";
import { Button } from "react-bootstrap";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";
import "sent-order/styles/styles.scss";

export type SentOrderViewerScreenParams = {
  orderKey: string;
};

export const SentOrderViewerScreen: React.FC = () => {
  const { orderKey } = useParams<SentOrderViewerScreenParams>();
  const { data: viewerInfo } = useQuery(
    SentOrdersApi.viewerInfoQuery.build(orderKey)
  );

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const columns = useMemo<CardsTableColumn<SentOrderViewerItem>[]>(
    () => [
      {
        id: "name",
        accessor: "name",
        Header: "שם הפריט",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "requestedAmount",
        accessor: (r) =>
          `${r.requestedAmount} ${SupplyMethodDescFormatter.formatSupply(
            r.requestedSupplyMethod,
            unitKinds,
            packageKinds
          )}`,
        Header: "כמות",
      },
      {
        id: "description",
        accessor: (r) =>
          SupplyMethodDescFormatter.format(
            r.requestedSupplyMethod,
            r.prepareUnitType,
            unitKinds,
            packageKinds
          ),
        Header: "תיאור פריט",
      },
      {
        id: "totalUnits",
        accessor: (r) =>
          `${
            OrderHelper.calcTotalUnits(
              r.requestedAmount,
              r.requestedSupplyMethod
            ) ?? ""
          } ${
            unitKinds?.find((x) => x.id === r.requestedSupplyMethod.unitKindId)
              ?.name ?? "פריטים"
          } `,
        Header: 'סה"כ פריטים',
      },
      {
        id: "totalAmount",
        accessor: (r) =>
          [
            NumberFormatter.format(
              OrderHelper.calcTotalAmount(
                r.requestedAmount,
                r.requestedSupplyMethod,
                r.prepareUnitType
              )
            ),
            UnitTypeFormatter.format(
              r.prepareUnitType,
              r.prepareUnitType === UnitType.Yeh
            ),
          ].join(" "),
        Header: 'סה"כ כמות',
      },
    ],
    [packageKinds, unitKinds]
  );

  return (
    <RouteContent className="SentOrderViewerScreen">
      <div
        className="contentWrap"
        style={{ width: "100%", maxWidth: 1200, margin: "0 auto" }}
      >
        <div className="d-flex justify-content-between">
          <div>
            <div className="h3 font-weight-bold">הזמנה {viewerInfo?.id}</div>
            <div className="h6">ספק: {viewerInfo?.supplierName}</div>
            <div className="h6">
              סטטוס: {OrderStatusFormatter.format(viewerInfo?.status)}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Button
              onClick={async () => {
                await SentOrdersApi.markOrderSeenBySupplier(orderKey);
                success("אישור הזמנה נשלח בהצלחה");
              }}
              variant="success"
              size="lg"
            >
              אשר הזמנה
            </Button>
          </div>
          <img
            src="/content/images/logo_fiori.png"
            alt="לוגו"
            style={{ width: 150 }}
          />
        </div>
        <div>
          {" "}
          <div className="h3 d-flex">
            <div>הערות:</div>
            <div style={{ whiteSpace: "pre" }} className="mx-1">
              {viewerInfo?.notes ?? ""}
            </div>
          </div>
        </div>
        <CardsTable
          columns={columns}
          data={viewerInfo?.items}
          localStorageKey={LocalStorageKeysHelper.dataTable("SentOrderViewer")}
          globalFilterAllowedCols={[]}
          searchable={false}
          hasPagination={false}
        />
      </div>
    </RouteContent>
  );
};
