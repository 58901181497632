import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { Form } from "react-final-form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { ModalTitleBar } from "modal/ModalTitleBar";
import "./styles.scss";
import { LabelsPrinterApi, PrintVariant } from "./LabelsPrinterApi";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { SentOrderItemGet } from "sent-order/types";

type FormValues = {
  amount: number;
};

export type LabelsPrinterModalProps = {
  show: boolean;
  onHide: () => void;
  itemName?: string;
  variant: PrintVariant;
};

export const LabelsPrinterModal: React.FC<LabelsPrinterModalProps> = ({
  show,
  onHide,
  itemName,
  variant,
}) => {
  const [isPrintSuccess, setIsPrintSuccess] = useState(false);
  const [error, setError] = useState("");
  const authTokenPayload = useTokenPayload();

  // Handle error state reset.
  useEffect(() => {
    if (!show) {
      setIsPrintSuccess(false);
      setError("");
    }
  }, [show]);

  // Handle success state reset if error received.
  useEffect(() => {
    if (error) {
      setIsPrintSuccess(false);
    }
  }, [error]);

  return (
    <Modal
      dialogClassName="LabelsPrinterModal"
      show={show}
      onHide={onHide}
      centered
      size="sm"
    >
      <ModalTitleBar title="הדפסת מדבקות" onClose={onHide} />
      {isPrintSuccess && (
        <Alert variant="success" className="text-center">
          <div style={{ fontSize: 22 }}>פקודה נשלחה בהצלחה</div>
          <div style={{ fontSize: 16 }}>*ניתן להדפיס מדבקות נוספות</div>
        </Alert>
      )}
      {error && (
        <Alert style={{ fontSize: 22 }} variant="danger">
          {error}
        </Alert>
      )}
      <Row noGutters>
        <div id="divcontents" style={{ display: "none" }}>
          <style>
            {
              "@media print { @page {margin-top: 0;margin-bottom: 0;}body {padding-top: 0px;	padding-bottom: 0px ;direction: rtl;}}"
            }
          </style>
          <p
            style={{
              fontSize: "13vw",
              fontWeight: "bold",
              pageBreakAfter: "always",
              whiteSpace: "nowrap",
              textOverflow: "clip",
            }}
          >
            {itemName}
            <br />
            {new Date().toLocaleString("he-IL", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })}{" "}
            {authTokenPayload?.name}
          </p>
        </div>
        <iframe
          id="ifmcontentstoprint"
          style={{ height: 0, width: 0, position: "absolute" }}
        ></iframe>
        <Form
          onSubmit={async ({ amount }: FormValues) => {
            if (amount < 10 || window.confirm("האם אתה בטוח?")) {
              try {
                var content = document.getElementById("divcontents");
                var pri =
                  document.getElementById("ifmcontentstoprint")![
                    "contentWindow"
                  ];
                pri.document.open();
                for (let index = 0; index < amount; index++) {
                  pri.document.write(content!.innerHTML);
                }
                pri.document.close();
                pri.focus();
                pri.print();
                setIsPrintSuccess(true);
              } catch (e) {
                setError(e?.response?.data?.error ?? "הפעולה נכשלה");
              }
            }
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Col xs={12} className="p-1">
                <FormNumberInput
                  name="amount"
                  inputMode="numeric"
                  placeholder="כמות מדבקות"
                  defaultValue={1}
                  validate={FieldsValidator.compose(
                    FieldsValidator.required,
                    FieldsValidator.integer,
                    FieldsValidator.minValue(1),
                    FieldsValidator.maxValue(99)
                  )}
                />
              </Col>
              <Col xs={12} className="p-1 mt-2">
                <Button
                  size="lg"
                  color="primary"
                  onClick={handleSubmit}
                  className="w-100"
                >
                  הדפסה
                </Button>
              </Col>
            </>
          )}
        </Form>
      </Row>
    </Modal>
  );
};
