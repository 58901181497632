export class OrdersRouteBuilder {
  static templates = {
    OrdersRouter: "/orders/",
    OrdersHomeRoute: "/orders/home",
    AddOrderHomeRoute: `/orders/new/home`,
    SupplierListRoute: `/orders/new/suppliers`,
    SupplierRoute: "/orders/new/suppliers/:supplierId",
    DepartmentListRoute: `/orders/new/departments`,
    DepartmentRoute: "/orders/new/departments/:departmentId",
    OrderSearchProductsRoute: "/orders/new/search-products",
    OrdersStatusRoute: "/orders/status/:expandedOrderId?",
    OrdersSimulationRoute: "/orders/simulation",
  };

  static buildOrdersStatusRoute(expandedOrderId?: number) {
    return OrdersRouteBuilder.templates.OrdersStatusRoute.replace(
      ":expandedOrderId?",
      String(expandedOrderId ?? "")
    );
  }

  static buildAddOrderHomeRoute() {
    return OrdersRouteBuilder.templates.AddOrderHomeRoute;
  }

  static buildSupplierListRoute() {
    return OrdersRouteBuilder.templates.SupplierListRoute;
  }
  static buildDepartmentListRoute() {
    return OrdersRouteBuilder.templates.DepartmentListRoute;
  }

  static buildSupplierRoute(id: number) {
    return OrdersRouteBuilder.templates.SupplierRoute.replace(
      ":supplierId",
      String(id)
    );
  }

  static buildDepartmentRoute(id: number) {
    return OrdersRouteBuilder.templates.DepartmentRoute.replace(
      ":departmentId",
      String(id)
    );
  }

  static buildOrderProductsSearchRoute() {
    return OrdersRouteBuilder.templates.OrderSearchProductsRoute;
  }

  static buildOrdersSimulationRoute() {
    return OrdersRouteBuilder.templates.OrdersSimulationRoute;
  }

  static buildOrdersHomeRoute() {
    return OrdersRouteBuilder.templates.OrdersHomeRoute;
  }
}
