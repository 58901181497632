import React from "react";
import "../styles/ScalesButton.scss";
import classNames from "classnames";

export interface ScalesButtonProps {
  text: string | React.ReactNode;
  onClick: () => void;
  variant?: "small" | "big";
  disabled?: boolean;
}

export const ScalesButton: React.FC<ScalesButtonProps> = ({
  text,
  onClick,
  variant = "big",
  disabled = false,
}) => {
  return (
    <div
      className={classNames("ScalesButton", variant, { disabled })}
      onClick={onClick}
    >
      <h3>{text}</h3>
      <img src="/content/images/scale.png" alt="פתיחת פופ אפ שקילה" />
    </div>
  );
};
