import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import "order/styles/styles.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ReactComponent as OrderBySupplier } from "order/assets/OrderBySupplier.svg";
import { ReactComponent as OrderByProduct } from "order/assets/OrderByProduct.svg";
import { ReactComponent as OrderByDepartment } from "order/assets/OrderByDepartment.svg";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";

export const AddOrderHomeScreen: React.FC = () => {
  const navigate = useNavigateCallback();

  return (
    <RouteContent className="AddOrderHomeScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      <div className="items">
        <div
          className="item"
          onClick={() => navigate(OrdersRouteBuilder.buildSupplierListRoute())}
        >
          <OrderBySupplier height={140} />
          <div className="text pt-2">
            הזמנה
            <br /> לפי ספק
          </div>
        </div>
        <div
          className="item"
          onClick={() =>
            navigate(OrdersRouteBuilder.buildDepartmentListRoute())
          }
        >
          <OrderByDepartment height={140} />
          <div className="text pt-2">
            הזמנה
            <br /> לפי מחלקה
          </div>
        </div>
        <div
          className="item"
          onClick={() =>
            navigate(OrdersRouteBuilder.buildOrderProductsSearchRoute())
          }
        >
          <OrderByProduct height={140} />
          <div className="text pt-2">
            הזמנה
            <br /> לפי מוצר
          </div>
        </div>
      </div>
    </RouteContent>
  );
};
