import axios from "axios";
import { DepartmentAddonFoodWork } from "./types/DepartmentAddonFoodWork";
import { DepartmentsApi } from "department/DepartmentsApi";
import { DepartmentAddons } from "department/types";
import produce from "immer";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class DepartmentAddonFoodWorksApi {
  static async addFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    foodWork: Omit<DepartmentAddonFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<DepartmentAddonFoodWork>(
      `/api/departmentAddons/${addonId}/foodwork`,
      foodWork
    );

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((departmentAddons) => {
        if (!departmentAddons) return;

        const matchingAddon = departmentAddons[listName].find(
          (x) => x.id === addonId
        );

        matchingAddon?.foodWorks.push(newFoodWork);
      }),
      departmentId
    );

    return newFoodWork;
  }

  static async updateFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    modifiedFoodWork: DepartmentAddonFoodWork
  ) {
    await axios.post(
      `/api/departmentAddons/foodworks/${modifiedFoodWork.id}`,
      modifiedFoodWork
    );

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((departmentAddons) => {
        if (!departmentAddons) return;

        const matchingAddon = departmentAddons[listName].find(
          (x) => x.id === addonId
        );

        if (matchingAddon) {
          const saveUpdater = QueryCacheHelper.buildSaveListItemUpdater(
            modifiedFoodWork,
            "id"
          );
          matchingAddon.foodWorks = saveUpdater(matchingAddon.foodWorks)!;
        }
      }),
      departmentId
    );
  }

  static async deleteFoodWork(
    addonId: number,
    departmentId: number,
    listName: keyof DepartmentAddons,
    FoodWorkToDeleteId: number
  ) {
    await axios.delete(`/api/departmentAddons/foodworks/${FoodWorkToDeleteId}`);

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((departmentAddons) => {
        if (!departmentAddons) return;

        const matchingAddon = departmentAddons[listName].find(
          (x) => x.id === addonId
        );

        if (matchingAddon) {
          const deleteUpdater = QueryCacheHelper.buildDeleteListItemUpdater<
            DepartmentAddonFoodWork
          >(FoodWorkToDeleteId, "id");

          matchingAddon.foodWorks = deleteUpdater(matchingAddon.foodWorks)!;
        }
      }),
      departmentId
    );
  }
}
