import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";

export type FullScreenLoaderApi = {
  onShow: () => void;
  onHide: () => void;
};

export type FullScreenLoaderProps = {
  apiRef: React.MutableRefObject<FullScreenLoaderApi | null>;
};

export const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({
  apiRef,
}) => {
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  apiRef.current = { onShow, onHide };

  // useEffect(() => {
  //   apiRef.current = { onShow, onHide };
  // }, [apiRef, onShow, onHide]);

  return (
    <Modal show={show} centered dialogClassName="full-screen-loader">
      <div className="spinner round" />
    </Modal>
  );
};
