import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { SupplierList } from "order/components/SupplierList";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";

export const SupplierListScreen: React.FC = () => {
  return (
    <RouteContent className="OrderSupplierListScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      <SupplierList />
    </RouteContent>
  );
};
