import React, { useCallback, useMemo } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { SentOrderListItem } from "sent-order/types";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { StockRouteBuilder } from "stock/StockRouteBuilder";
import { OrderStatusFormatter } from "base/utils/formatters";
import { format } from "date-fns";
import classNames from "classnames";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";

export interface SentOrdersTableProps {
  variant?: "default" | "receivedOnly";
}

export const SentOrdersTable: React.FC<SentOrdersTableProps> = ({
  variant = "default",
}) => {
  const { data } = useQuery(
    SentOrdersApi.listQuery.build(
      { variant },
      {
        refetchInterval: 20 * 1000,
      }
    )
  );

  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(OrdersRouteBuilder.buildAddOrderHomeRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: SentOrderListItem) => {
      navigate(
        SentOrdersRouteBuilder.buildEditSentOrderRoute(
          data.id,
          variant === "default" ? "default" : "advanced"
        )
      );
    },
    [navigate, variant]
  );

  const columns = useMemo<CardsTableProps<SentOrderListItem>["columns"]>(
    () => [
      {
        id: "Id",
        accessor: "id",
        Header: "#",
      },
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <FileViewer
            type="image"
            fileDir={FileDir.Supplier}
            fileName={value}
          />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "SupplierName",
        accessor: "supplierName",
        Header: "שם",
      },
      {
        id: "Status",
        accessor: "status",
        Header: "סטטוס",
        Cell: ({ value, row }) => (
          <div className="d-flex align-items-center">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`button-tooltip-${row.original.id}`}>
                  אושרה ע"י הספק בתאריך:
                  <br />
                  {format(
                    new Date(row.original.supplierSeenDate ?? 0),
                    "HH:mm dd/MM/yyyy"
                  )}
                </Tooltip>
              }
            >
              {
                <i
                  className={classNames("fa  fa-check-circle-o px-1", {
                    invisible: !row.original.supplierSeenDate,
                  })}
                />
              }
            </OverlayTrigger>
            <div>{`${OrderStatusFormatter.format(value)}`}</div>
          </div>
        ),
      },
      {
        id: "ItemsCount",
        accessor: "itemsCount",
        Header: "פריטים",
      },
      {
        id: "Amount",
        accessor: "amount",
        Header: "מחיר צפוי",
        Cell: TableAmountCell,
      },
      {
        id: "SubmitterName",
        accessor: "submitterName",
        Header: "שם השולח",
      },
      {
        id: "DateCreated",
        accessor: (row) => row.dateCreated,
        Header: "תאריך הזמנה",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "OrderDocumentChecked",
        accessor: "orderDocumentChecked",
        Header: "מסמך נבדק?",
        Cell: ({ value }) => (value ? "✔" : ""),
        sortType: "boolean",
        minWidth: 500,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      searchPlaceholder="חיפוש לפי שם ספק"
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey={LocalStorageKeysHelper.dataTable(
        `sentOrder__${variant}`
      )}
      globalFilterAllowedCols={["Name"]}
      topBarCustomActions={
        <div className="mr-auto d-flex align-items-center">
          <Button
            onClick={() => navigate(StockRouteBuilder.buildAddToStockRoute())}
          >
            קליטת סחורה ללא הזמנה
          </Button>
        </div>
      }
    />
  );
};
