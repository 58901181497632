import Axios from "axios";
import {
  AuthCredentials,
  PinAuthCredentials,
  Auth,
  AccountUserListItem,
} from "./types";
import { ApiQuery } from "base/api/ApiQuery";

export class AuthApi {
  static accountUsersQuery = new ApiQuery<AccountUserListItem[]>(
    "/api/account/users"
  );

  static async login(credentials: AuthCredentials) {
    const { data } = await Axios.post<Auth>(`/api/account/auth`, credentials);

    return data;
  }

  static async pinCodeAuth(credentials: PinAuthCredentials) {
    const { data } = await Axios.post<Auth>(
      `/api/account/pinAuth`,
      credentials
    );

    return data;
  }
}
