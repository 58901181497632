import axios from "axios";
import { BaseFood } from "base/types";
import { SingleItemFoodWork } from "./types/SingleItemFoodWork";
import { SingleItemFoodWorkType } from "./types/SingleItemFoodWorkType";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class SingleItemFoodWorksApi {
  static itemsQuery = new ApiQuery<
    SingleItemFoodWork[],
    { type: SingleItemFoodWorkType; id: number }
  >(
    ({ type, id }) =>
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`
  );

  // Todo - Move to separate Api file, maybe add a generic BaseFoodApi.
  // Equals to MaterialsApi.itemQuery / IngredientsApi.itemQuery.
  static foodItemQuery = new ApiQuery<
    BaseFood,
    { type: SingleItemFoodWorkType; id: number }
  >(
    ({ type, id }) =>
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(type)}/${id}`
  );

  static async addFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    departmentFoodWork: Omit<SingleItemFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`,
      departmentFoodWork
    );

    SingleItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildAddListItemUpdater(newFoodWork),
      { type, id }
    );
  }

  static async updateFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    departmentFoodWork: SingleItemFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks`,
      departmentFoodWork
    );

    SingleItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildUpdateListItemUpdater(modifiedFoodWork, "id"),
      { type, id }
    );
  }

  static async deleteFoodWork(
    type: SingleItemFoodWorkType,
    id: number,
    foodWorkId: number
  ) {
    await axios.delete<SingleItemFoodWork>(
      `/api/${SingleItemFoodWorksApi.getSingleItemApiPath(
        type
      )}/${id}/foodworks/${foodWorkId}`
    );

    SingleItemFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(foodWorkId, "id"),
      { type, id }
    );
  }

  static getSingleItemApiPath = (type: SingleItemFoodWorkType): string => {
    switch (type) {
      case "ingredient":
        return "ingredients";
      case "material":
        return "materials";
    }
  };
}
