import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { DailyRecommendTable } from "sent-order/components/DailyRecommendTable";

export const DailyRecommendScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">המלצה יומית</Typography>
      <DailyRecommendTable />
    </RouteContent>
  );
};
