import { UnitType, SupplyUnitType, BaseSelectListItem } from "base/types";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";

export class SupplyMethodDescFormatter {
  static formatSupply(
    item: BaseSupplyMethod,
    unitKinds: BaseSelectListItem[] | undefined,
    packageKinds: BaseSelectListItem[] | undefined,
    prepareUnitType?: UnitType
  ) {
    const { supplyUnitType, packageKindId, unitKindId } = item;

    switch (supplyUnitType) {
      case SupplyUnitType.Amount:
        return prepareUnitType !== undefined
          ? UnitTypeFormatter.format(prepareUnitType)
          : "כמות";
      case SupplyUnitType.Package:
        return (
          packageKinds?.find((pk) => pk.id === packageKindId)?.name ?? "מארז"
        );
      case SupplyUnitType.SingleItem:
        return unitKinds?.find((pk) => pk.id === unitKindId)?.name ?? "פריטים";
    }
  }

  static format(
    item: BaseSupplyMethod | undefined,
    prepareUnitType: UnitType | undefined,
    unitKinds: BaseSelectListItem[] | undefined,
    packageKinds: BaseSelectListItem[] | undefined
  ) {
    if (!item || prepareUnitType === undefined) return "";

    switch (item.supplyUnitType) {
      case SupplyUnitType.SingleItem:
        if (prepareUnitType === UnitType.Yeh) {
          return (
            unitKinds?.find((x) => x.id === item.unitKindId)?.name ?? "יחידות"
          );
        } else {
          return TextFormatter.format(`$1 של $2 $3`, [
            unitKinds?.find((x) => x.id === item.unitKindId)?.name ?? "פריטים",
            NumberFormatter.formatAmount(item.unitWeight!, false, {
              applyUSLocaleStr: true,
            }),
            UnitTypeFormatter.format(prepareUnitType, !item.isLargeUnit),
          ]);
        }
      case SupplyUnitType.Amount:
        return TextFormatter.format(`$1`, [
          UnitTypeFormatter.format(prepareUnitType, false),
        ]);
      case SupplyUnitType.Package:
        if (prepareUnitType === UnitType.Yeh) {
          return TextFormatter.format(`$1 של $2 $3`, [
            packageKinds?.find((x) => x.id === item.packageKindId)?.name ??
              "מארז",
            NumberFormatter.formatAmount(item.unitsInPackage!, false, {
              applyUSLocaleStr: true,
            }),
            unitKinds?.find((x) => x.id === item.unitKindId)?.name ?? "פריטים",
          ]);
        } else {
          return TextFormatter.format(`$1 של $2 $3 ב$4 של $5 $6`, [
            packageKinds?.find((x) => x.id === item.packageKindId)?.name ??
              "מארז",
            NumberFormatter.formatAmount(item.unitsInPackage!, false, {
              applyUSLocaleStr: true,
            }),
            unitKinds?.find((x) => x.id === item.unitKindId)?.name ?? "פריטים",
            prepareUnitType === UnitType.KG ? "משקל" : "נפח",
            NumberFormatter.formatAmount(item.unitWeight!, false, {
              applyUSLocaleStr: true,
            }),
            UnitTypeFormatter.format(prepareUnitType, !item.isLargeUnit),
          ]);
        }
    }
  }

  static formatTopPackage(
    item: BaseSupplyMethod,
    prepareUnitType: UnitType,
    unitKinds: BaseSelectListItem[] | undefined,
    packageKinds: BaseSelectListItem[] | undefined
  ) {
    switch (item.supplyUnitType) {
      case SupplyUnitType.SingleItem:
        return (
          unitKinds?.find((x) => x.id === item.unitKindId)?.name ?? "פריטים"
        );
      case SupplyUnitType.Amount:
        return UnitTypeFormatter.format(prepareUnitType, false);
      case SupplyUnitType.Package:
        return (
          packageKinds?.find((x) => x.id === item.packageKindId)?.name ?? "מארז"
        );
    }
  }

  static formatBaseSelectListItem(
    itemId: number | undefined,
    selectList: BaseSelectListItem[] | undefined,
    text: string
  ) {
    return selectList?.find((pk) => pk.id === itemId)?.name ?? text;
  }
}
