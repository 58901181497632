import React, { useEffect, useState } from "react";
import { PickIngredientItem } from "./PickIngredientItem/PickIngredientItem";
import { OrderIngredientItem } from "order/types";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { OrderHelper } from "order/OrderHelper";
import { useRouteMatch } from "react-router-dom";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import "order/styles/styles.scss";
import { Spinner } from "react-bootstrap";

type ItemToTotalPrices = {
  [ingId: number]: number;
};

export type PickIngredientsProps = {
  ingredients: OrderIngredientItem[] | undefined;
  onTotalOrderPriceCalc?: (price: number) => void;
  isLoading: boolean;
};

export const PickIngredients: React.FC<PickIngredientsProps> = ({
  ingredients,
  onTotalOrderPriceCalc,
  isLoading,
}) => {
  const isInOrderSupplierRoute = useRouteMatch(
    OrdersRouteBuilder.templates.SupplierRoute
  )?.isExact;
  const [itemToTotalPrice, setItemToTotalPrice] = useState<ItemToTotalPrices>(
    {}
  );

  const onIngredientChanged = (
    ingId: any,
    details: {
      amount: number;
      supplyMethod?: BaseSupplyMethod;
    }
  ) => {
    const { amount } = details;
    const ing = ingredients!.find((x) => x.productId === ingId)!;
    setItemToTotalPrice((currItemToTotalPrice) => {
      return {
        ...currItemToTotalPrice,
        [ingId]: OrderHelper.calcTotalPrice(amount, ing.requestedPrice),
      };
    });
  };

  useEffect(() => {
    if (onTotalOrderPriceCalc && ingredients) {
      const totalPrice = ingredients.reduce((result, item) => {
        return (
          result +
          (itemToTotalPrice[item.productId] ??
            OrderHelper.calcTotalPrice(
              item.requestedAmount,
              item.requestedPrice
            ))
        );
      }, 0);

      onTotalOrderPriceCalc(totalPrice);
    } else {
      onTotalOrderPriceCalc?.(0);
    }
  }, [ingredients, itemToTotalPrice, onTotalOrderPriceCalc]);

  return (
    <div className="PickIngredients">
      {!isLoading &&
        (ingredients === undefined || ingredients.length === 0) &&
        "לא נמצאו מוצרים תואמים"}
      {ingredients?.map((item: any) => (
        <PickIngredientItem
          item={item}
          key={item.productId}
          onChanged={({ amount, supplyMethod }) => {
            onIngredientChanged(item.productId, { amount, supplyMethod });
          }}
          showSupplierSelect={!isInOrderSupplierRoute}
          // Not ideal, solely for speech behavior, implemnted badly due to wrong separation of concerns.
          allItems={ingredients}
        />
      ))}
      {isLoading && (
        <div className="loadingWrap">
          <Spinner animation="border" className="text-primary" />
        </div>
      )}
    </div>
  );
};
