import React from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { MaterialsApi } from "material/MaterialsApi";
import { useCallback, useMemo } from "react";
import { MaterialRelatedTree } from "material/types";
import {
  CardsTable,
  CardsTableColumn,
} from "base/components/tables/components/CardsTable";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { TreeTypeFormatter } from "base/utils/formatters/TreeTypeFormatter";
import { UnitTypeFormatter } from "base/utils/formatters";
import { useQuery } from "react-query";

export const MaterialTreesTable: React.FC<{ id: number }> = ({ id }) => {
  const navigate = useNavigateCallback();

  const { data } = useQuery(MaterialsApi.itemTreesQuery.build(id));

  const onEditRowClick = useCallback(
    (data: MaterialRelatedTree) => {
      navigate(
        TreeInfoRouteBuilder.buildTreeInfoRoute(data.treeType, data.treeId)
      );
    },
    [navigate]
  );

  const columns = useMemo<CardsTableColumn<MaterialRelatedTree>[]>(
    () => [
      {
        id: "treeName",
        accessor: "treeName",
        Header: "שם העץ",
      },
      {
        id: "treeType",
        accessor: "treeType",
        Header: "סוג העץ",
        Cell: ({ value }) => TreeTypeFormatter.format(value),
      },
      {
        id: "amount",
        Header: "כמות",
        Cell: ({ row }) => (
          <span>
            {row.original.amount}{" "}
            {UnitTypeFormatter.default.format(
              row.original.prepareUnitType,
              true
            )}
          </span>
        ),
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      globalFilterAllowedCols={["treeName"]}
      onEditRowClick={onEditRowClick}
      searchable={false}
    />
  );
};
