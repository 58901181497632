import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { AllergenRelationType, AllergenItem } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class AllergensApi {
  static typesQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/itemAllergens/types"
  );

  static itemsQuery = new ApiQuery<
    AllergenItem[],
    { relationType: AllergenRelationType; relationId: number }
  >(({ relationType, relationId }) => {
    switch (relationType) {
      case AllergenRelationType.Food:
        return `/api/foods/${relationId}/allergens`;
      case AllergenRelationType.Product:
        return `/api/products/${relationId}/allergens`;
      case AllergenRelationType.Material:
        return `/api/materials/${relationId}/allergens`;
    }
  });

  static async saveItem(
    relationType: AllergenRelationType,
    relationId: number,
    allergen: AllergenItem
  ) {
    const { data: savedAllergen } = await Axios.post(`/api/itemAllergens/`, {
      foodId:
        relationType === AllergenRelationType.Food ? relationId : undefined,
      productId:
        relationType === AllergenRelationType.Product ? relationId : undefined,
      materialId:
        relationType === AllergenRelationType.Material ? relationId : undefined,
      ...allergen,
    });

    AllergensApi.itemsQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedAllergen, "id"),
      { relationType, relationId }
    );
  }

  static async deleteItem(
    relationType: AllergenRelationType,
    relationId: number,
    idToDelete: number
  ) {
    await Axios.delete(`/api/itemAllergens/${idToDelete}`);

    AllergensApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(idToDelete, "id"),
      { relationType, relationId }
    );
  }
}
