import React, { useMemo } from "react";
import { startOfWeek } from "date-fns";
import { RouteContent } from "route-content/components/RouteContent";
import { WeeklyWorkSchedule } from "weekly-work-schedule/components/WeeklyWorkSchedule";
import { WeeklyWorkScheduleApi } from "weekly-work-schedule/WeeklyWorkScheduleApi";
import { useQueryParams } from "base/api/hooks/useQueryParams";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { WeeklyWorkScheduleType } from "weekly-work-schedule/types";
import { WeeklyScheduleBuilder } from "weekly-work-schedule/utils/WeeklySchedulesBuilder";
import { WeeklyWSStartOfWeekAdapter } from "weekly-work-schedule/utils/WeeklyWSStartOfWeekAdapter";
import { useQuery } from "react-query";

export const WeeklyWorkScheduleScreen = () => {
  const queryParams = useQueryParams();
  const resolvedStartOfWeekDay = startOfWeek(
    WeeklyWSStartOfWeekAdapter.parse(queryParams.get("startOfWeekDay")!)
  );

  const {
    data: templateSchedules,
    isLoading: isLoadingTemplates,
    isFetching: isFetchingTemplates,
  } = useQuery(WorkScheduleApi.templateSchedulesQuery.build());

  const {
    data: weeklyWorkSchedules,
    isLoading: isLoadingWeeklySchedules,
    isFetching: isFetchingWeeklySchedules,
  } = useQuery(
    WeeklyWorkScheduleApi.itemsQuery.build(
      WeeklyWSStartOfWeekAdapter.format(resolvedStartOfWeekDay)
    )
  );

  const resolvedWeeklyWorkSchedules = useMemo<WeeklyWorkScheduleType[]>(() => {
    if (weeklyWorkSchedules && weeklyWorkSchedules.length > 0) {
      return weeklyWorkSchedules;
    } else {
      return WeeklyScheduleBuilder.build(templateSchedules ?? []);
    }
  }, [weeklyWorkSchedules, templateSchedules]);

  const isDisplayLoader =
    isLoadingTemplates ||
    isFetchingTemplates ||
    isLoadingWeeklySchedules ||
    isFetchingWeeklySchedules;
  useFullScreenLoader(isDisplayLoader);

  return (
    <RouteContent>
      <WeeklyWorkSchedule
        schedules={resolvedWeeklyWorkSchedules}
        startOfWeekDay={resolvedStartOfWeekDay}
      />
    </RouteContent>
  );
};
