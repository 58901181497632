export class LocalStorageKeysHelper {
  static token() {
    LocalStorageKeysHelper.tempMerge("token", "app/token");
    return "app/token";
  }

  static standType() {
    LocalStorageKeysHelper.tempMerge("standType", "app/standType");
    return "app/standType";
  }

  static scaleType() {
    LocalStorageKeysHelper.tempMerge("scaleType", "app/scaleType");
    return "app/scaleType";
  }

  static notificationPermission() {
    LocalStorageKeysHelper.tempMerge(
      "notificationPermission",
      "app/notificationPermission"
    );
    return "app/notificationPermission";
  }

  static dataTable(name: string) {
    LocalStorageKeysHelper.tempMerge(`${name}DT`, `app/datatables/${name}`);
    LocalStorageKeysHelper.tempMerge(name, `app/datatables/${name}`);

    return `app/datatable/${name}`;
  }

  static sidebar() {
    return "app/sidebar";
  }

  // TODO - remove this fn and calls after a few weeks in production.
  private static tempMerge(from: string, to: string) {
    const data = localStorage.getItem(from);
    if (data !== null) {
      localStorage.setItem(to, data);
      localStorage.removeItem(from);
    }
  }
}
