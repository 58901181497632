import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import { EditSupplierRouteParams, SupplierFormData } from "supplier/types";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { Tabs, Tab } from "react-bootstrap";
import { Form } from "form/components/Form";
import { FormSpy } from "react-final-form";
import { debounce, isEqual } from "lodash";
import { GeneralTab } from "supplier/components/GeneralTab";
import { SupplierOrdersTab } from "supplier/components/SupplierOrdersTab";
import { SupplierContactsTab } from "supplier/components/SupplierContactsTab";
import { FormFileViewer } from "form/components/FormFileViewer";
import { FileDir } from "base/types";
import "supplier/styles.scss";
import { DistributionDaysTab } from "supplier/components/DistributionDaysTab";
import { SupplierIngredientPriceAgreementsTab } from "supplier/components/SupplierIngredientPriceAgreementsTab";
import { SupplierProductsSupplyTab } from "supplier/components/SupplierProductsSupplyTab";
import { useQuery } from "react-query";

enum ETab {
  General = "General",
  Orders = "Orders",
  Contacts = "Contacts",
  DeliveryTimes = "DeliveryTimes",
  IngredientPriceAgreements = "IngredientPriceAgreements",
  ProductsSupply = "ProductsSupply",
}

export const EditSupplierScreen: React.FC = () => {
  const { supplierId: supplierIdStr } = useParams<EditSupplierRouteParams>();
  const supplierId = Number(supplierIdStr);
  const fieldName = (property: keyof SupplierFormData) => property;

  const [selectedTab, setSelectedTab] = useState(ETab.General);

  const { data: supplier, isLoading, isFetching } = useQuery(
    SuppliersApi.itemQuery.build(supplierId)
  );

  useFullScreenLoader(isLoading || isFetching);

  const onAutoSave = useMemo(
    () =>
      debounce(async (supplierForm: SupplierFormData) => {
        await SuppliersApi.saveItem(supplierForm);
      }, 250),
    []
  );

  return (
    <RouteContent>
      <Form initialValues={supplier} onSubmit={() => {}}>
        {({ form }) => (
          <>
            <FormFileViewer
              pathName={fieldName("imageName")}
              uploadName={fieldName("image")}
              fileDir={FileDir.Supplier}
              imageProps={{
                style: {
                  width: 250,
                  height: 250,
                },
                className: "mb-4",
              }}
            />
            <Tabs
              activeKey={selectedTab}
              id="EditSupplierScreen"
              onSelect={setSelectedTab as any}
              className="mt-2 edit-supplier-screen-tabs"
              style={{ fontSize: 34 }}
            >
              <Tab title="כללי" eventKey={ETab.General} className="p-4">
                <GeneralTab supplierId={supplierId} />
              </Tab>
              <Tab
                title="הזמנות ותעודות"
                eventKey={ETab.Orders}
                className="p-4"
              >
                <SupplierOrdersTab supplierId={supplierId} />
              </Tab>
              <Tab title="אנשי קשר" eventKey={ETab.Contacts} className="p-4">
                <SupplierContactsTab supplierId={supplierId} />
              </Tab>
              <Tab
                title="זמני משלוח"
                eventKey={ETab.DeliveryTimes}
                className="p-4"
              >
                <DistributionDaysTab supplierId={supplierId} />
              </Tab>
              <Tab
                title="הסכמי מחיר"
                eventKey={ETab.IngredientPriceAgreements}
                className="p-4"
              >
                <SupplierIngredientPriceAgreementsTab supplierId={supplierId} />
              </Tab>
              <Tab
                title="מוצרים"
                eventKey={ETab.ProductsSupply}
                className="p-4"
              >
                <SupplierProductsSupplyTab supplierId={supplierId} />
              </Tab>
            </Tabs>
            <FormSpy
              subscription={{
                validating: true,
              }}
              onChange={({ validating }) => {
                const formState = form.getState();
                if (validating || !formState.valid) {
                  onAutoSave.cancel();
                  return;
                }

                const {
                  values: nextValues,
                  initialValues: prevValues,
                } = formState;

                if (
                  nextValues &&
                  prevValues &&
                  !isEqual(nextValues, prevValues)
                ) {
                  onAutoSave(nextValues);
                }
              }}
            />
          </>
        )}
      </Form>
    </RouteContent>
  );
};
