import React from "react";
import { Col, FormGroup } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import { Field } from "react-final-form";
import { FormTextInput } from "form/components/FormTextInput";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { MaterialsApi } from "material/MaterialsApi";
import { FormSelect } from "form/components/FormSelect";
import { UnitTypesSelectOptions } from "base/components/Select/constants/UnitTypesSelectOptions";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { FormErrorMessage } from "form/components/FormErrorMessage";
import { useQuery } from "react-query";

export const MaterialFormFields = () => {
  const { data: departments } = useQuery(MaterialsApi.departmentsQuery.build());
  const departmentOptions = useSelectOptionsAdapter(departments);

  return (
    <>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          שם
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormTextInput name="name" required />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          מחלקה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormSelect
            name="departmentId"
            emptyOptionLabel="בחר מחלקה"
            options={departmentOptions}
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          קוד ישות
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormTextInput name="id" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          יח׳ מידה הכנה
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormSelect
            name="unitType"
            emptyOptionLabel="בחר יחידה"
            options={UnitTypesSelectOptions}
            validate={FieldsValidator.required}
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          משך טריות (שעות.ימים) - [0-23].[0-999]
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <Field
            name="freshnessPeriod"
            validate={FieldsValidator.timeSpanRange("999.23:00:00")}
            format={(x: string | undefined) => x?.slice(0, 6)}
            parse={(x: string | undefined) => (x ? `${x}:00:00` : undefined)}
            render={({ input, meta }) => (
              <>
                <InputMask
                  mask={"999.99"}
                  alwaysShowMask
                  dir="ltr"
                  className="form-control"
                  {...input}
                />
                <FormErrorMessage>
                  {meta.touched ? meta.error : meta.submitError}
                </FormErrorMessage>
              </>
            )}
          />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          מלאי מינימום
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormNumberInput name="minStock" step="0.001" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          מלאי מקסימום
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormNumberInput name="maxStock" step="0.001" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          מלאי
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormNumberInput name="currentStock" step="0.001" />
        </Col>
      </FormGroup>
      <FormGroup className="d-flex">
        <Col sm={5} md={4} xl={3} className="d-flex align-items-center">
          פעיל
        </Col>
        <Col xs={9} sm={7} md={7} lg={6} xl={5}>
          <FormCheckbox name="isActive" />
        </Col>
      </FormGroup>
    </>
  );
};
