import React from "react";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { Form } from "form/components/Form";
import { Contract } from "contract/types";
import { ContractsApi } from "contract/ContractsApi";
import { useSaveContractCallback } from "contract/hooks/useSaveContractCallback";
import { ContractForm } from "./ContractForm";
import { useQuery } from "react-query";

export const ContractNewItemEditor: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery(
    ContractsApi.itemDefaultsQuery.build()
  );

  useFullScreenLoader(isLoading || isFetching);

  const onSave = useSaveContractCallback();

  return (
    <Form<Contract>
      onSubmit={onSave}
      initialValues={data}
      component={ContractForm}
    />
  );
};
