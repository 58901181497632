import React from "react";
import "../styles.scss";

export interface RouteContentTopBarProps {
  title?: string;
  titleIcon?: React.ReactNode;
}

export const RouteContentTopBar: React.FC<RouteContentTopBarProps> = ({
  children,
  title,
  titleIcon,
}) => {
  return (
    <div className="RouteContentTopBar">
      {title && (
        <div className="title">
          <span>{title}</span>
          {titleIcon && <div className="iconWrap">{titleIcon}</div>}
        </div>
      )}
      {children}
    </div>
  );
};
