import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { MaterialsTable } from "material/components/MaterialsTable";

export const MaterialsScreen: React.FC = () => {
  return (
    <RouteContent>
      <Typography variant="route-title">חומרי גלם מעובדים</Typography>
      <MaterialsTable />
    </RouteContent>
  );
};
