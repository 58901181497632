import { Form } from "form/components/Form";
import React, { useState } from "react";
import {
  POSUploadFormValues,
  POSUploadResult,
  POSErrorType,
  POSFixErrorsFormValues,
} from "./types";
import { FormFileUpload } from "form/components/FormFileUpload";
import { Field } from "react-final-form";
import { DatePicker } from "base/components/DatePicker";
import { FormMetaError } from "form/components/FormMetaError";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { Button, Alert } from "react-bootstrap";
import { RouteContent } from "route-content/components/RouteContent";
import { POSApi } from "./POSApi";
import { noop } from "base/utils/noop";
import { FormSelect } from "form/components/FormSelect";
import { DishesApi } from "dish/DishesApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import classNames from "classnames";
import { FormTextInput } from "form/components/FormTextInput";
import { useQuery } from "react-query";

export const POSImportScreen: React.FC = () => {
  const fieldName = (property: keyof POSUploadFormValues) => property;

  const [uploadResult, setUploadResult] = useState<POSUploadResult>();

  const { data: dishesList } = useQuery(DishesApi.listQuery.build());
  const dishesOptions = useSelectOptionsAdapter(dishesList);

  return (
    <RouteContent>
      <Form<POSUploadFormValues>
        onSubmit={async (values) => {
          setUploadResult(await POSApi.upload(values));
        }}
      >
        {({ handleSubmit }) => (
          <>
            <div className="d-flex justify-content-center">
              <div style={{ width: 250 }} className="mx-2">
                <FormFileUpload
                  name={fieldName("file")}
                  validate={FieldsValidator.required}
                  type="excel"
                />
              </div>
              <div style={{ width: 250 }} className="mx-2">
                <Field
                  name={fieldName("date")}
                  render={({ input, meta }) => (
                    <>
                      <DatePicker placeholder="תאריך מסמך" {...input} />
                      <FormMetaError meta={meta} />
                    </>
                  )}
                />
              </div>
              <div style={{ width: 250 }} className="mx-2">
                <Field
                  name={fieldName("zReference")}
                  render={({ input, meta }) => (
                    <>
                      <FormTextInput placeholder="קוד המסמך (Z)" {...input} />
                      <FormMetaError meta={meta} />
                    </>
                  )}
                  // validate={FieldsValidator.required}
                />
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button variant="success" onClick={handleSubmit}>
                טעינת נתונים
              </Button>
            </div>
          </>
        )}
      </Form>
      {!uploadResult && (
        <Alert variant="success" className="text-center mt-2">
          {" "}
          יש למלא את השדות "תאריך" ו"קוד מסמך" או להשאירם ריקים והמערכת תנסה
          להבין אותם משם הקובץ לפי המבנה הבא:
          <br />
          DDMMYYYY_ZZZZZZZZZ.xlsx
          <br />
          לדוגמא:
          <br />
          <div className="font-weight-bold" style={{ direction: "ltr" }}>
            19042021_12345.xslx
          </div>
        </Alert>
      )}

      <div>
        {uploadResult && uploadResult.errors.length === 0 && (
          <Alert variant="success" className="text-center mt-2">
            <h5 className="m-0">מלאי עודכן בהצלחה</h5>
          </Alert>
        )}
        {uploadResult && uploadResult.errors.length > 0 && (
          <Alert variant="danger" className="text-center mt-5">
            <h5 className="m-0">
              התגלו {uploadResult.errors.length} שגיאות, מלאי לא עודכן
            </h5>
          </Alert>
        )}
      </div>
      {uploadResult && (
        <Form<POSFixErrorsFormValues>
          onSubmit={noop}
          initialValues={{ errors: [] }}
          key={JSON.stringify(uploadResult.errors)}
        >
          {({ form }) => (
            <table>
              <thead>
                <tr>
                  <th>שם מנה</th>
                  <th>שם משנה\תוספת</th>
                  <th>שגיאה</th>
                  <th>
                    <span style={{ margin: "0 38px" }}>פעולות</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {uploadResult.errors.map((e, index) => (
                  <tr>
                    <td>{e.foodName || e.itemName}</td>
                    <td>{e.foodName ? e.itemName : ""}</td>
                    <td>
                      {e.errorType === POSErrorType.FoodNotFound &&
                        "מנה לא נמצאה"}
                      {e.errorType === POSErrorType.FoodAddonCloseToFood &&
                        "חשד לכפילות עם שם המנה"}
                      {e.errorType === POSErrorType.FoodAddonNotFound &&
                        "תוספת לא נמצאה"}
                      {e.errorType ===
                        POSErrorType.FoodCorrespondedAddonRowNotFound &&
                        "לא נמצא משנה הנושא את שם המנה"}
                    </td>
                    <td className="d-flex">
                      {e.errorType === POSErrorType.FoodNotFound && (
                        <>
                          <Field
                            name={`errors[${index}].isMapped`}
                            render={({ input: { value: isMapped } }) => (
                              <i
                                className={classNames(
                                  "fa fa-check-circle text-success mx-2 d-flex align-items-center",
                                  { invisible: !isMapped }
                                )}
                              />
                            )}
                          />
                          <FormSelect
                            name={`errors[${index}].foodId`}
                            options={dishesOptions}
                            emptyOptionLabel="בחירת מנה לשיוך"
                          />
                          <Button
                            className="mx-2"
                            onClick={async () => {
                              const cell = form.getState().values.errors[index];
                              if (!cell || !cell.foodId) return;

                              const { foodId } = cell;
                              await POSApi.mapItem(foodId, e.itemName);
                              form.change(
                                `errors[${index}].isMapped` as any,
                                true
                              );
                            }}
                          >
                            שיוך
                          </Button>
                        </>
                      )}
                      {e.errorType ===
                        POSErrorType.FoodCorrespondedAddonRowNotFound &&
                        "יש לבצע תיקון בקובץ או במערכת המכר"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Form>
      )}
    </RouteContent>
  );
};
