import { SelectProps } from "base/components/Select";
import { FileDir } from "base/types";

export type SimpleGlobalListFieldDef = {
  accessor: string;
  title: string;
  tableTitle?: string;
  type: "text" | "number" | "date" | "boolean" | "select" | "image" | "enum";
  isTableColumnHidden?: boolean;
  isFormColumnHidden?: boolean;
  isIdColumn?: boolean;
  isReadOnly?: boolean;
  ListProvider?: React.FC<{
    render: (options: SelectProps["options"]) => JSX.Element;
  }>;
  enumOptions?: Array<{ value: number | string; label: string }>;
  imageDir?: FileDir; // default: FileDir.BaseFood
  imageUploadName?: string; // default: "image"
  imageDeleteName?: string; // default: "isDeleteImage"
};

export enum SimpleGlobalListRoutePath {
  SpecialDates = "specialDates",
  Manufacturers = "manufacturers",
  ContactTypes = "contactTypes",
  UnitKinds = "unitKinds",
  PackageKinds = "packageKinds",
  POSItemMappings = "POSItemMappings",
  RoleNames = "roleNames",
  RoleTypes = "roleTypes",
  ShiftDepartments = "shiftDepartments",
  SystemSettings = "systemSettings",
  Suppliers = "Suppliers",
  Contacts = "Contacts",
  StorageUnits = "StorageUnits",
  PackageWeights = "PackageWeights",
}

export const SPECIAL_DATES_FORMAT = "yyyy-MM-dd'T'00:00:00";

export interface SpecialDatelistItem {
  id: number;
  date: string;
  caption: string;
}

export interface PackageWeightListItem {
  id: number;
  name: string;
  weight: number;
  imageName?: string;
}

export enum StorageCondition {
  RoomTemperature,
  CoolDisplay,
  Friedge,
  Freezer,
  DeepFreezer,
}
