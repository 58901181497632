import React, { useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { pick } from "lodash";
import { Spacer } from "base/components/Spacer";
import { Typography } from "base/components/Typography";
import { TreeType } from "base/types/TreeType";
import { Form } from "form/components/Form";
import { SingleItemFoodWork } from "food-works/single-item-food-works/types/SingleItemFoodWork";
import { SingleItemFoodWorksApi } from "food-works/single-item-food-works/SingleItemFoodWorksApi";
import { TreeItemFoodWorkType } from "../types/TreeItemFoodWorkType";
import { TreeItemFoodWorksApi } from "../TreeItemFoodWorksApi";
import { TreeItemFoodWorksFormValues } from "../types/TreeItemFoodWorksFormValues";
import { TreeItemFoodWorksForm } from "./TreeItemFoodWorksForm";
import "food-works/styles.scss";
import { DepartmentFoodWorksApi } from "food-works/department-food-works/DepartmentFoodWorksApi";
import { DepartmentFoodWork } from "food-works/department-food-works/types/DepartmentFoodWork";
import { skipToken } from "base/api/ApiQuery";
import { useQuery } from "react-query";

export type TreeItemFoodWorksModalProps = {
  show: boolean;
  treeItemType?: TreeItemFoodWorkType;
  treeType?: TreeType;
  treeItemId?: number;
  itemId?: number;
  departmentId?: number;
  onCancel: () => void;
};

export const TreeItemFoodWorksModal: React.FC<TreeItemFoodWorksModalProps> = ({
  show,
  treeItemType,
  treeItemId,
  treeType,
  itemId,
  departmentId,
  onCancel,
}) => {
  const { data } = useQuery(
    TreeItemFoodWorksApi.itemsQuery.build(
      {
        treeType: treeType ?? skipToken,
        treeItemType: treeItemType ?? skipToken,
        treeItemId: treeItemId ?? skipToken,
      },
      { enabled: show }
    )
  );

  const { data: item } = useQuery(
    SingleItemFoodWorksApi.foodItemQuery.build(
      { type: treeItemType ?? skipToken, id: itemId ?? skipToken },
      { enabled: show }
    )
  );

  const { data: singleItemFoodWorks } = useQuery(
    SingleItemFoodWorksApi.itemsQuery.build(
      {
        type: treeItemType ?? skipToken,
        id: itemId ?? skipToken,
      },
      {
        enabled: show,
      }
    )
  );

  const { data: departmentFoodWorksData } = useQuery(
    DepartmentFoodWorksApi.itemsQuery.build(departmentId ?? skipToken, {
      enabled: show,
    })
  );

  const departmentFoodWorks = useMemo(() => {
    // hide departmentFoodWorks with same name of singleItemFoodWorks.
    return departmentFoodWorksData?.filter(
      (item) =>
        (singleItemFoodWorks ?? []).findIndex(
          (otherItem) => otherItem.name.trim() === item.name.trim()
        ) === -1
    );
  }, [departmentFoodWorksData, singleItemFoodWorks]);

  const foodWorksForm = useMemo<TreeItemFoodWorksFormValues | undefined>(() => {
    if (!treeItemType || !treeItemId || !treeType || !item) {
      return undefined;
    } else {
      return {
        treeType,
        treeItemType,
        treeItemId,
        foodWorks: data ?? [],
        item,
      };
    }
  }, [data, item, treeItemId, treeItemType, treeType]);

  const onAddFoodWorkBySingleItem = useCallback(
    async (foodWork: SingleItemFoodWork) => {
      await TreeItemFoodWorksApi.addFoodWork(
        treeType!,
        treeItemType!,
        treeItemId!,
        pick<SingleItemFoodWork, keyof SingleItemFoodWork>(foodWork, [
          "name",
          "cost",
          "depreciation",
          "duration",
        ])
      );
    },
    [treeItemId, treeItemType, treeType]
  );

  const onAddFoodWorkByDepartment = useCallback(
    async (foodWork: DepartmentFoodWork) => {
      await TreeItemFoodWorksApi.addFoodWork(
        treeType!,
        treeItemType!,
        treeItemId!,
        pick<DepartmentFoodWork, keyof DepartmentFoodWork>(foodWork, [
          "name",
          "cost",
          "depreciation",
          "duration",
        ])
      );
    },
    [treeItemId, treeItemType, treeType]
  );
  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="foodworks-modal"
      centered
      size="lg"
    >
      <div className="foodworks-modal-content">
        <div className="fwm-title-bar">
          <Typography variant="description" fontSize={24} bold>
            ניהול עיבוד מתקדם - {item?.name}
          </Typography>
          <div className="close-btn" onClick={onCancel} />
        </div>
        <div className="fwm-body">
          <Form
            initialValues={foodWorksForm}
            onSubmit={() => {}}
            component={TreeItemFoodWorksForm}
          />
          <Spacer units={4} />
          {singleItemFoodWorks && singleItemFoodWorks?.length > 0 && (
            <>
              <Typography variant="description" fontSize={20} bold>
                הוספת עיבוד מפריט
              </Typography>
              <Spacer />
              <Grid container className="available-foodworks" spacing={2}>
                {singleItemFoodWorks?.map((fw) => (
                  <Grid item onClick={() => onAddFoodWorkBySingleItem(fw)}>
                    <div className="grid-item-content">{fw.name}</div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {departmentFoodWorks && departmentFoodWorks?.length > 0 && (
            <>
              <Typography variant="description" fontSize={20} bold>
                הוספת עיבוד ממחלקה
              </Typography>
              <Spacer />
              <Grid container className="available-foodworks" spacing={2}>
                {departmentFoodWorks?.map((dfw) => (
                  <Grid item onClick={() => onAddFoodWorkByDepartment(dfw)}>
                    <div className="grid-item-content">{dfw.name}</div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
