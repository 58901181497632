import {
  SimpleGlobalListRoutePath,
  SimpleGlobalListFieldDef,
  StorageCondition,
} from "./types";
import { DishesApi } from "dish/DishesApi";
import { DepartmentsApi } from "department/DepartmentsApi";
import { ContactTypesApi } from "contactTypes/ContactsApi";
import { FileDir } from "base/types";
import { useQuery } from "react-query";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";

export type GlobalListsDefinitionsType = {
  [key in SimpleGlobalListRoutePath]: {
    title: string;
    isDisableDelete?: boolean;
    fields: SimpleGlobalListFieldDef[];
    isSaveMethodFormData?: boolean;
  };
};

export const GlobalListsDefinitions: GlobalListsDefinitionsType = {
  [SimpleGlobalListRoutePath.SpecialDates]: {
    title: "תאריכים מיוחדים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "caption",
        title: "שם",
        type: "text",
      },
      {
        accessor: "date",
        title: "תאריך",
        type: "date",
      },
    ],
  },
  [SimpleGlobalListRoutePath.Manufacturers]: {
    title: "יצרנים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.ContactTypes]: {
    title: "סוגי אנשי קשר",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.Contacts]: {
    title: "אנשי קשר",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
      {
        accessor: "contactTypeId",
        title: "סוג",
        type: "select",
        isTableColumnHidden: true,
        ListProvider: ({ render }) => {
          const { data: list } = useQuery(ContactTypesApi.listQuery.build());
          const options = useSelectOptionsAdapter(list);
          return render(options);
        },
      },
      {
        accessor: "phone",
        title: "טלפון",
        type: "text",
      },
      {
        accessor: "email",
        title: "אימייל",
        type: "text",
      },
      {
        accessor: "isDefault",
        title: "האם ראשי",
        type: "boolean",
        isTableColumnHidden: true,
      },
    ],
  },
  [SimpleGlobalListRoutePath.UnitKinds]: {
    title: "סוגי אריזות פריטים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.PackageKinds]: {
    title: "סוגי מארזים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.POSItemMappings]: {
    title: "טבלת המרה מכר",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם במערכת המכר",
        type: "text",
      },
      {
        accessor: "foodId",
        title: "מנה",
        type: "select",
        isTableColumnHidden: true,
        ListProvider: ({ render }) => {
          const { data: list } = useQuery(DishesApi.listQuery.build());
          const options = useSelectOptionsAdapter(list);
          return render(options);
        },
      },
      {
        accessor: "foodName",
        title: "מנה",
        type: "text",
        isFormColumnHidden: true,
      },
    ],
  },
  [SimpleGlobalListRoutePath.RoleNames]: {
    title: "שמות תפקידים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.RoleTypes]: {
    title: "סוגי תפקידים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.ShiftDepartments]: {
    title: "מחלקות משמרת",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.SystemSettings]: {
    title: "הגדרות מערכת",
    isDisableDelete: true,
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "key",
        title: "שם",
        type: "text",
        isReadOnly: true,
      },
      {
        accessor: "value",
        title: "ערך",
        type: "text",
      },
      {
        accessor: "notes",
        title: "הערה",
        type: "text",
      },
    ],
  },
  [SimpleGlobalListRoutePath.Suppliers]: {
    title: "ספקים",
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
      {
        accessor: "department",
        title: "מחלקה",
        type: "select",
        ListProvider: ({ render }) => {
          const { data: list } = useQuery(DepartmentsApi.listQuery.build());
          const options = useSelectOptionsAdapter(list);
          return render(options);
        },
      },
    ],
  },
  [SimpleGlobalListRoutePath.StorageUnits]: {
    title: "יחידות אחסון",
    isSaveMethodFormData: true,
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "imageName",
        title: "תמונה",
        type: "image",
        tableTitle: "",
        imageDir: FileDir.StorageUnit,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
      {
        accessor: "storageCondition",
        title: "תנאי אחסון",
        type: "enum",
        enumOptions: [
          { value: StorageCondition.RoomTemperature, label: "טמפ' חדר" },
          { value: StorageCondition.CoolDisplay, label: "ויטרינה" },
          { value: StorageCondition.Friedge, label: "מקרר" },
          { value: StorageCondition.Freezer, label: "מקפיא" },
          { value: StorageCondition.DeepFreezer, label: "הקפאה עמוקה" },
        ],
      },
      {
        accessor: "temperature",
        title: "טמפרטורה",
        type: "number",
      },
    ],
  },
  [SimpleGlobalListRoutePath.PackageWeights]: {
    title: "משקלי אריזה",
    isSaveMethodFormData: true,
    fields: [
      {
        accessor: "id",
        title: "מזהה",
        type: "text",
        isTableColumnHidden: true,
        isFormColumnHidden: true,
        isIdColumn: true,
      },
      {
        accessor: "imageName",
        title: "תמונה",
        type: "image",
        tableTitle: "",
        imageDir: FileDir.PackageWeight,
      },
      {
        accessor: "name",
        title: "שם",
        type: "text",
      },
      {
        accessor: "weight",
        title: "משקל (גרם)",
        type: "number",
      },
    ],
  },
};
