import React, { useEffect } from "react";
import { Alert, Button, Collapse, Modal } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import classNames from "classnames";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";
import { BaseImage } from "base/components/BaseFoodImage";
import { BaseSelectListItem, FileDir, UnitType } from "base/types";
import { DetailedOrder, DetailedOrderItem } from "order/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { OrderInlineAmounter } from "./PickIngredientItem/OrderInlineAmounter/OrderInlineAmounter";
import {
  DateFormatter,
  NumberFormatter,
  UnitTypeFormatter,
} from "base/utils/formatters";
import { Form } from "form/components/Form";
import { noop } from "base/utils/noop";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { isEqual } from "lodash";
import { getIn } from "final-form";
import { Fields } from "form/components/Fields";
import { Checkbox } from "base/components/Checkbox";
import { useImmer } from "use-immer";
import { FormTextArea } from "form/components/FormTextArea";
import "order/styles/styles.scss";
import { useDebouncePool } from "base/hooks/useDebouncePool";
import { OrdersApi } from "order/OrdersApi";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { OrderHelper } from "order/OrderHelper";
import { useQuery } from "react-query";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";

export interface DetailedOrderCardProps {
  detailedOrder: DetailedOrder;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

type State = {
  sendToSupplier: boolean;
  mode: "default" | "trash" | "submitting";
  trashList: number[];
  computed: { totalRequestedPrice: number };
};

export const DetailedOrderCard: React.FC<DetailedOrderCardProps> = ({
  detailedOrder,
  isExpanded,
  onToggleExpand,
}) => {
  const navigate = useNavigateCallback();

  const [showDeleteModal, onShowDeleteModal, onHideDeleteModal] =
    useBooleanState(false);

  const [showSubmitModal, onShowSubmitModal, onHideSubmitModal] =
    useBooleanState(false);

  const [
    {
      sendToSupplier,
      mode,
      trashList,
      computed: { totalRequestedPrice },
    },
    updateState,
  ] = useImmer<State>({
    sendToSupplier: true,
    mode: "default",
    trashList: [],
    computed: {
      totalRequestedPrice: 0,
    },
  });

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const saveProductDebouncePool = useDebouncePool(
    (item: DetailedOrderItem) => {
      OrdersApi.updateProduct(detailedOrder.orderId, {
        productId: item.productId,
        amount: item.requestedAmount,
        requestedPrice: item.requestedPrice,
        supplyMethod: item.supplyMethod,
        supplierId: detailedOrder.supplierModel.id,
      });
    },
    [detailedOrder.orderId, detailedOrder.supplierModel.id]
  );

  const saveNotesDebouncePool = useDebouncePool(
    (nextNotes: string) => {
      OrdersApi.updateNotes(detailedOrder.orderId, nextNotes);
    },
    [detailedOrder.orderId]
  );

  const fieldName = (property: keyof DetailedOrder) => property;
  const itemFieldName = (
    property: keyof DetailedOrder["items"][number],
    prefix: string
  ) => `${prefix}${property}`;
  const supplierFieldName = (property: keyof DetailedOrder["supplierModel"]) =>
    `${fieldName("supplierModel")}.${property}`;

  useEffect(() => {
    if (mode === "default") {
      updateState((draft) => {
        draft.trashList = [];
      });
    }
  }, [mode, updateState]);

  useEffect(() => {
    if (mode === "submitting") {
      let active = true;
      (async () => {
        await OrdersApi.submitOrder(detailedOrder.orderId, sendToSupplier);
        if (!sendToSupplier) {
          navigate(
            SentOrdersRouteBuilder.buildSentOrderRoute(detailedOrder.orderId)
          );
        }
        if (active) {
          updateState((draft) => {
            draft.mode = "default";
          });
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [detailedOrder.orderId, mode, updateState, sendToSupplier]);

  return (
    <div
      className={classNames("DetailedOrderCard", {
        isExpanded,
      })}
    >
      <Form initialValues={detailedOrder} onSubmit={noop}>
        {({ form }) => (
          <>
            <div
              className={"header d-flex align-items-start"}
              onClick={onToggleExpand}
            >
              <i
                className={classNames("fas fa-chevron-left mt-1 mt-md-3 mr-3", {
                  "fa-rotate-270": isExpanded,
                })}
                style={{
                  position: "relative",
                  top: 4,
                }}
              />
              <div className="mx-2">
                <Field
                  name={supplierFieldName("name")}
                  render={({ input: { value: supplierName } }) => (
                    <div className="font-weight-bolder h4 mt-1 mt-md-3 mb-0">
                      {supplierName}
                    </div>
                  )}
                />
                <Field
                  name={fieldName("orderId")}
                  render={({ input: { value: orderId } }) => (
                    <div>
                      <span>מספר הזמנה:</span>{" "}
                      <span className="font-weight-bolder">{orderId}</span>
                    </div>
                  )}
                />
              </div>
              <Field
                name={supplierFieldName("logoFileName")}
                render={({ input: { value: logoFileName } }) => (
                  <ImageBackgroundMix key={logoFileName} className="mr-auto">
                    <BaseImage
                      fileDir={FileDir.Supplier}
                      fileName={logoFileName}
                    />
                  </ImageBackgroundMix>
                )}
              />
            </div>
            <Collapse in={isExpanded}>
              <div>
                <div className="expandedSectionContent">
                  <FieldArray
                    name={fieldName("items")}
                    isEqual={isEqual}
                    subscription={{ length: true }}
                  >
                    {({ fields }) => (
                      <>
                        <table className="itemsTable">
                          <thead>
                            <tr>
                              <th className="name">שם מוצר</th>
                              <th className="imageName">{/* imageName */}</th>
                              <th className="amount">כמות</th>
                              <th className="totalAmount">כמות כוללת</th>
                              <th className="totalCost">עלות</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((prefix, index) => {
                              const productId: number = getIn(
                                form.getState().values,
                                itemFieldName("productId", prefix)
                              );
                              return (
                                <>
                                  <tr key={productId}>
                                    <td className="name">
                                      <div className="d-flex">
                                        <div className="d-flex flex-column justify-content-between">
                                          {mode === "trash" && (
                                            <Checkbox
                                              value={trashList.includes(
                                                productId
                                              )}
                                              width={16}
                                              height={16}
                                              onChange={(nextChecked) => {
                                                const trashIndex =
                                                  trashList.indexOf(productId);
                                                updateState((draft) => {
                                                  if (nextChecked) {
                                                    draft.trashList.push(
                                                      productId
                                                    );
                                                  } else {
                                                    draft.trashList.splice(
                                                      trashIndex,
                                                      1
                                                    );
                                                  }
                                                });
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div className="mx-1 mx-md-2 d-flex flex-column justify-content-between">
                                          <span className="font-weight-bolder ">
                                            <Field
                                              name={itemFieldName(
                                                "name",
                                                prefix
                                              )}
                                              render={({
                                                input: { value: name },
                                              }) => name}
                                            />
                                          </span>
                                          <Fields
                                            names={[
                                              itemFieldName(
                                                "supplyMethod",
                                                prefix
                                              ),
                                              itemFieldName(
                                                "prepareUnitType",
                                                prefix
                                              ),
                                            ]}
                                            render={([
                                              supplyMethod,
                                              prepareUnitType,
                                            ]) => (
                                              <span style={{ lineHeight: 1 }}>
                                                {SupplyMethodDescFormatter.format(
                                                  supplyMethod,
                                                  prepareUnitType,
                                                  unitKinds,
                                                  packageKinds
                                                )}
                                              </span>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="imageName">
                                      <Field
                                        name={itemFieldName(
                                          "imageName",
                                          prefix
                                        )}
                                        render={({
                                          input: { value: imageName },
                                        }) => (
                                          <BaseImage
                                            fileDir={FileDir.BaseFood}
                                            fileName={imageName}
                                            containerStyle={{
                                              width: 60,
                                              height: 60,
                                            }}
                                          />
                                        )}
                                      />
                                    </td>
                                    <td className="requestedAmount">
                                      <Fields
                                        names={[
                                          itemFieldName("supplyMethod", prefix),
                                          itemFieldName(
                                            "prepareUnitType",
                                            prefix
                                          ),
                                          itemFieldName(
                                            "requestedAmount",
                                            prefix
                                          ),
                                        ]}
                                        render={([
                                          supplyMethod,
                                          prepareUnitType,
                                          requestedAmount,
                                        ]) => (
                                          <OrderInlineAmounter
                                            packageKinds={packageKinds}
                                            unitKinds={unitKinds}
                                            supplyMethod={supplyMethod}
                                            prepareUnitType={prepareUnitType}
                                            onChange={(nextAmount) => {
                                              form.change(
                                                itemFieldName(
                                                  "requestedAmount",
                                                  prefix
                                                ) as any,
                                                nextAmount
                                              );
                                            }}
                                            value={requestedAmount}
                                          />
                                        )}
                                      />
                                    </td>
                                    <td className="totalAmount">
                                      <Fields
                                        names={[
                                          itemFieldName(
                                            "requestedAmount",
                                            prefix
                                          ),
                                          itemFieldName("supplyMethod", prefix),
                                          itemFieldName(
                                            "prepareUnitType",
                                            prefix
                                          ),
                                        ]}
                                        render={([
                                          requestedAmount,
                                          supplyMethod,
                                          prepareUnitType,
                                        ]) => (
                                          <span>
                                            {NumberFormatter.default.formatAmount(
                                              OrderHelper.calcTotalAmount(
                                                requestedAmount,
                                                supplyMethod,
                                                prepareUnitType
                                              )
                                            )}{" "}
                                            {UnitTypeFormatter.format(
                                              prepareUnitType,
                                              prepareUnitType === UnitType.Yeh
                                            )}
                                          </span>
                                        )}
                                      />
                                    </td>
                                    <td className="totalAmount">
                                      ₪
                                      <Fields
                                        names={[
                                          itemFieldName(
                                            "requestedPrice",
                                            prefix
                                          ),
                                          itemFieldName(
                                            "requestedAmount",
                                            prefix
                                          ),
                                        ]}
                                        render={([
                                          requestedPrice,
                                          requestedAmount,
                                        ]) =>
                                          NumberFormatter.format(
                                            requestedPrice * requestedAmount
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  {/* Handle items requestedAmount changes.  */}
                                  <FormSpy
                                    subscription={{
                                      values: true,
                                    }}
                                    onChange={() => {
                                      const { values, initialValues } =
                                        form.getState();

                                      const nextItem = values.items.find(
                                        (x: DetailedOrderItem) =>
                                          x.productId === productId
                                      );
                                      const prevItem =
                                        initialValues.items?.find(
                                          (x: DetailedOrderItem) =>
                                            x.productId === productId
                                        );

                                      if (
                                        nextItem &&
                                        prevItem &&
                                        !isEqual(
                                          nextItem.requestedAmount,
                                          prevItem.requestedAmount
                                        )
                                      ) {
                                        saveProductDebouncePool.invoke(
                                          productId,
                                          nextItem
                                        );
                                      }
                                    }}
                                  />
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                        <div className="expandFooterSection">
                          {mode === "default" && false && (
                            <i
                              className="fas fa-trash"
                              role="button"
                              onClick={() =>
                                updateState((draft) => {
                                  draft.mode = "trash";
                                })
                              }
                            />
                          )}
                          {mode === "default" && (
                            <span>{fields.length} מוצרים</span>
                          )}
                          {mode === "trash" && (
                            <>
                              <Button onClick={onShowDeleteModal}>
                                <i className="fas fa-trash" />
                                <span className="mr-4">
                                  מחיקת פריטים נבחרים
                                </span>
                              </Button>
                              <Button
                                className="mx-2"
                                onClick={() =>
                                  updateState((draft) => {
                                    draft.mode = "default";
                                  })
                                }
                              >
                                <i className="fas fa-times" />
                                <span className="mr-4">בטל פעולה</span>
                              </Button>
                            </>
                          )}
                          <span className="mr-2">
                            סה"כ מחיר: ₪
                            {NumberFormatter.format(totalRequestedPrice)}
                          </span>
                        </div>
                        <div className="expandFooterSection">
                          <Field
                            name={fieldName("supplyDateTime")}
                            render={({ input: { value: supplyDateTime } }) =>
                              supplyDateTime && (
                                <span>
                                  תאריך אספקה להזמנה:{" "}
                                  {DateFormatter.format(supplyDateTime)}
                                </span>
                              )
                            }
                          />
                          <FormTextArea
                            placeholder="הערות לספק"
                            name={fieldName("notes")}
                          />
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>
            </Collapse>
            <div className="footer">
              <div>
                <div>
                  <span className="footerLabel">סך עלות ההזמנה:</span>{" "}
                  <span className="footerLabel font-weight-bolder">
                    ₪{NumberFormatter.format(totalRequestedPrice)}
                  </span>
                </div>
                <Field
                  name={fieldName("creatorName")}
                  render={({ input: { value: creatorName } }) => (
                    <div>
                      <span className="footerLabel">שם המזמין:</span>{" "}
                      <span className="footerLabel font-weight-bolder">
                        {creatorName}
                      </span>
                    </div>
                  )}
                />
              </div>
              <Button
                className="mr-auto"
                onClick={() => {
                  updateState((draft) => {
                    draft.sendToSupplier = false;
                  });
                  onShowSubmitModal();
                }}
              >
                קליטת הזמנה ללא שליחה
              </Button>
              <Button
                className="mr-auto"
                onClick={() => {
                  updateState((draft) => {
                    draft.sendToSupplier = true;
                  });
                  onShowSubmitModal();
                }}
              >
                שליחת הזמנה
              </Button>
            </div>
            {/* Hanlde Notes chages */}
            <FormSpy<DetailedOrder>
              subscription={{ values: true }}
              onChange={() => {
                const { values, initialValues } = form.getState();

                if (
                  values &&
                  initialValues &&
                  values.notes !== initialValues.notes
                ) {
                  saveNotesDebouncePool.invoke("updateNotes", values.notes!);
                }
              }}
            />
            {/* Handle state's computed fields */}
            <FormSpy<Partial<DetailedOrder>>
              subscription={{ values: true }}
              onChange={({ values }) => {
                updateState((draft) => {
                  draft.computed.totalRequestedPrice = 0;
                  values.items?.forEach((item) => {
                    draft.computed.totalRequestedPrice +=
                      item.requestedPrice * item.requestedAmount;
                  });
                });
              }}
            />
          </>
        )}
      </Form>
      <Modal show={showDeleteModal} onHide={onHideDeleteModal} centered>
        <ModalTitleBar
          title="האם למחוק פריטים אלו?"
          onClose={onHideDeleteModal}
          isDisplayIndent={false}
          icon={<i className="fas fa-trash-alt" />}
        />
        <div className="p-2 mt-1 mt-md-3 d-flex">
          <Button
            onClick={() => {
              updateState((draft) => {
                draft.mode = "default";
              });
              onHideDeleteModal();
            }}
          >
            <i className="fas fa-times" />
            <span className="mr-2">ביטול</span>
          </Button>
          <Button
            onClick={async () => {
              OrdersApi.deleteProducts(detailedOrder.orderId, trashList);

              updateState((draft) => {
                draft.mode = "default";
              });
              onHideDeleteModal();
            }}
            variant="danger"
            className="mr-auto"
          >
            <i className="fas fa-trash" />
            <span className="mr-2">מחיקה</span>
          </Button>
        </div>
      </Modal>
      <Modal show={showSubmitModal} onHide={onHideSubmitModal} centered>
        <ModalTitleBar
          title="אישור הזמנה"
          onClose={onHideSubmitModal}
          isDisplayIndent={
            Boolean(detailedOrder.supplierModel.submitOrderMessage) ||
            Boolean(detailedOrder.notes) ||
            detailedOrder.items.filter((x) => x.notes).length > 0
          }
        />
        {detailedOrder.supplierModel.submitOrderMessage && (
          <Alert variant="warning">
            {detailedOrder.supplierModel.submitOrderMessage}
          </Alert>
        )}
        {!detailedOrder.notes &&
          detailedOrder.items.filter((x) => x.notes).length === 0 && (
            <div className="text-center h5 m-3 color-blue1">
              האם {sendToSupplier ? "לשגר" : "לקלוט ללא שליחה לספק"}?
            </div>
          )}
        {detailedOrder.notes && (
          <div className="text-center h5 m-3 color-blue1">
            {detailedOrder.notes}
          </div>
        )}
        <div className="p-2">
          {detailedOrder.items.filter((x) => x.notes).length > 0 && (
            <div>
              <div className="text-center h5">הערות פריטים</div>
              <ul>
                {detailedOrder.items
                  .filter((x) => x.notes)
                  .map((item, index) => (
                    <li key={index}>{`${item.name} - ${item.notes}`}</li>
                  ))}
              </ul>
            </div>
          )}
          <div className="d-flex justify-content-center" style={{ gap: "8px" }}>
            <Button
              variant="danger"
              onClick={() => {
                onHideSubmitModal();
              }}
              disabled={mode === "submitting"}
            >
              <i className="fas fa-times" />
              <span className="mr-2">לא</span>
            </Button>
            <Button
              onClick={() => {
                updateState((draft) => {
                  draft.mode = "submitting";
                });
                onHideSubmitModal();
              }}
              disabled={mode === "submitting"}
            >
              <i className="fas fa-paper-plane" />
              <span className="mr-2">
                {detailedOrder.items.filter((x) => x.notes).length > 0 ||
                detailedOrder.notes
                  ? `הבנתי, אפשר ${sendToSupplier ? "לשגר" : "לקלוט"}`
                  : "כן"}
              </span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
