import React, { useState } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { Row, Col, Button } from "react-bootstrap";
import {
  OrdersSimulationFormValues,
  OrdersSimulationResultItem,
  OrdersSimulationItem,
} from "order/types";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormSelect } from "form/components/FormSelect";
import { MaterialsApi } from "material/MaterialsApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { DishesApi } from "dish/DishesApi";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir, UnitType } from "base/types";
import { UnitTypeFormatter, NumberFormatter } from "base/utils/formatters";
import { FieldArray } from "react-final-form-arrays";
import { FormSpy } from "react-final-form";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { OrdersApi } from "order/OrdersApi";
import { useQuery } from "react-query";

export const OrdersSimulationScreen: React.FC = () => {
  const fieldName = (property: keyof OrdersSimulationFormValues) => property;
  const itemFieldName = (
    property: keyof OrdersSimulationItem,
    prefix: string
  ) => `${prefix}.${property}`;

  const { data: materialsList } = useQuery(MaterialsApi.listQuery.build());
  const materialsOptions = useSelectOptionsAdapter(materialsList);

  const { data: dishSelectList } = useQuery(DishesApi.listQuery.build());
  const dishesOptions = useSelectOptionsAdapter(dishSelectList);

  const [results, setResults] = useState<OrdersSimulationResultItem[]>();

  return (
    <RouteContent>
      <Typography variant="route-title" className="mb-4">
        סימולציית הזמנות
      </Typography>
      <Form
        onSubmit={async (values: OrdersSimulationFormValues) => {
          setResults(undefined);

          const nextResults = await OrdersApi.calculateOrdersSimulation(
            values.items.filter((x) => x.materialId || x.foodId)
          );

          setResults(nextResults);
        }}
        subscription={{ valid: true, submitting: true }}
        initialValues={{
          items: [{} as OrdersSimulationItem],
        }}
      >
        {({ valid, submitting, handleSubmit, form }) => (
          <>
            <FieldArray<OrdersSimulationItem>
              name={fieldName("items")}
              subscription={{ value: true, length: true }}
            >
              {({ fields }) => (
                <>
                  {fields.map((prefix, index) => {
                    const isAtLeastFoodOrMaterialSelected =
                      fields.value[index].foodId !== undefined ||
                      fields.value[index].materialId !== undefined;

                    return (
                      <Row className="my-2">
                        <Col md={3}>
                          <FormSelect
                            name={itemFieldName("materialId", prefix)}
                            options={materialsOptions}
                            variant="react-select"
                            emptyOptionLabel="בחירת חומר גלם"
                            disabled={fields.value[index].foodId !== undefined}
                            validate={(...args) => {
                              const allVals = args[1] as OrdersSimulationFormValues;

                              // If deleted, ignore validation(Stupid react final form)
                              if (index >= allVals.items.length) {
                                return Promise.resolve();
                              }

                              const isLastItemAndAmountNotSet =
                                index === allVals.items.length - 1 &&
                                allVals.items[index].amount === undefined;

                              if (
                                allVals.items[index].foodId !== undefined ||
                                allVals.items[index].materialId !== undefined ||
                                isLastItemAndAmountNotSet
                              ) {
                                return Promise.resolve();
                              } else {
                                return Promise.resolve(
                                  "יש לבחור חומר גלם או מנה"
                                );
                              }
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <FormSelect
                            name={itemFieldName("foodId", prefix)}
                            options={dishesOptions}
                            variant="react-select"
                            emptyOptionLabel="בחירת  מנה"
                            disabled={
                              fields.value[index].materialId !== undefined
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <FormNumberInput
                            name={itemFieldName("amount", prefix)}
                            placeholder={(() => {
                              if (
                                fields.value[index].materialId !== undefined
                              ) {
                                const selectedMaterial = materialsList!.find(
                                  (x) => x.id === fields.value[index].materialId
                                )!;
                                return `?כמה ${UnitTypeFormatter.format(
                                  selectedMaterial.unitType
                                )}`;
                              }

                              return "?כמה מנות";
                            })()}
                            validate={(...args) => {
                              /* 
                                This field is required if 
                                  first in array, 
                                  Or in the middle, 
                                  Or isAtLeastFoodOrMaterialSelected.
                                  */

                              const allVals = args[1] as OrdersSimulationFormValues;

                              // If deleted, ignore validation(Stupid react final form)
                              if (index >= allVals.items.length) {
                                return Promise.resolve();
                              }

                              if (
                                allVals.items.length === 1 ||
                                index < allVals.items.length - 1 ||
                                allVals.items[index].foodId !== undefined ||
                                allVals.items[index].materialId !== undefined
                              ) {
                                return FieldsValidator.required(...args);
                              }

                              return Promise.resolve();
                            }}
                            disabled={!isAtLeastFoodOrMaterialSelected}
                            maxDigitsAfterDot={3}
                          />
                        </Col>
                        {fields.value.length > 1 && (
                          <Col xs="auto" className="d-flex align-items-center">
                            <i
                              className="fas fa-times"
                              role="button"
                              onClick={() => {
                                const { items } = form.getState().values;
                                form.change(
                                  "items",
                                  items.filter((_, indexx) => indexx !== index)
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                  {/* Handle auto add row when form is valid and has amount for last item. */}
                  <FormSpy<OrdersSimulationFormValues>
                    subscription={{ valid: true, values: true }}
                    onChange={() => {
                      const { valid, values } = form.getState();
                      const lastItem = values.items[values.items.length - 1];

                      if (valid && lastItem.amount !== undefined) {
                        fields.push({} as OrdersSimulationItem);
                      }
                    }}
                  />
                </>
              )}
            </FieldArray>
            <div className="mt-5">
              <Button disabled={!valid || submitting} onClick={handleSubmit}>
                חישוב כמויות
              </Button>
            </div>
          </>
        )}
      </Form>
      {results && (
        <div>
          <Typography variant="title" className="my-5" fontSize={28}>
            תוצאות
          </Typography>
          <div className="d-flex flex-wrap justify-content-center">
            {results.map((resultItem) => (
              <div
                className="m-2"
                style={{
                  background: "white",
                  borderRadius: 10,
                  paddingTop: 15,
                  boxShadow: "1px 3px 3px 0px #80808094",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    fontSize: 24,
                    height: 70,
                    width: 200,
                    lineHeight: "1.2em",
                  }}
                >
                  {resultItem.name}
                </div>
                <div className="position-relative">
                  <BaseImage
                    fileDir={FileDir.BaseFood}
                    fileName={resultItem.imageName}
                    style={{ width: 200, height: 200 }}
                  />
                  <div
                    className="position-absolute d-flex align-items-center"
                    style={{
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  >
                    <div
                      className="text-white w-100 p-2 text-center"
                      style={{
                        background:
                          "linear-gradient(rgb(118 188 0 / 70%) 0%, rgb(58 140 37 / 70%) 100%)",
                      }}
                    >
                      <div className="h3 m-0 font-weight-bolder">
                        {NumberFormatter.formatAmount(
                          resultItem.prepareUnitType === UnitType.Yeh
                            ? resultItem.amount
                            : resultItem.amount / 1000
                        )}{" "}
                        {UnitTypeFormatter.format(resultItem.prepareUnitType)}
                      </div>
                      <div className="h6 m-0">
                        מלאי נוכחי:{" "}
                        {NumberFormatter.formatAmount(
                          resultItem.prepareUnitType === UnitType.Yeh
                            ? resultItem.currentStock
                            : resultItem.currentStock / 1000
                        )}{" "}
                        {UnitTypeFormatter.format(resultItem.prepareUnitType)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </RouteContent>
  );
};
