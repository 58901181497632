import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import { usePrevious } from "base/hooks/usePrevious";
import { NumpadModal } from "./NumpadModal/NumpadModal";
import { useBooleanState } from "base/hooks/useBooleanState";

export type NumberInputProps = {
  value: number | "" | undefined;
  onChange: (nextValue: number | undefined) => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  step?: string;
  maxDigitsAfterDot?: number;
  displayNumpadOnFocus?: boolean;
  numpadTitle?: string;
  isInt?: boolean;
} & Omit<Partial<FormControlProps>, "value"> &
  Omit<HTMLAttributes<HTMLInputElement>, "value">;

export const NumberInput: React.FC<NumberInputProps> = ({
  placeholder,
  className,
  autoFocus,
  selectOnFocus,
  step,
  maxDigitsAfterDot = 2,
  value: valueProp,
  onChange: onChangeProp,
  onFocus,
  onBlur,
  isInt = false,
  displayNumpadOnFocus = false,
  numpadTitle = placeholder,
  ...numberInputProps
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const [showNumpad, onOpenNumpad, onHideNumpad] = useBooleanState(false);

  const [value, setValue] = useState<string>(String(valueProp ?? ""));
  const prevValue = usePrevious(value);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = e.target.value;
      const isInProgress =
        nextValue === "-" ||
        (!isInt &&
          nextValue.endsWith(".") &&
          nextValue.split(".").length === 2);
      const isValueValid = e.target.validity.valid;

      if (!isValueValid && !isInProgress) {
        return;
      }

      setValue(nextValue);
    },
    [isInt]
  );

  const onNumpadDone = useCallback(
    (nextValue: number) => {
      setValue(String(nextValue));
      onHideNumpad();
    },
    [onHideNumpad]
  );

  const p_onOnFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      onFocus?.(event);
      if (selectOnFocus) {
        const inputElement = event.target;
        setTimeout(() => inputElement.select());
      }

      if (displayNumpadOnFocus) {
        event.target.blur();
        onOpenNumpad();
      }
    },
    [displayNumpadOnFocus, onFocus, onOpenNumpad, selectOnFocus]
  );

  // Handle updates that originates from within the component
  useEffect(() => {
    if (value === prevValue) {
      return;
    }

    if (value.trim() === "") {
      onChangeProp(undefined);
    } else if (!isNaN(Number(value))) {
      onChangeProp(Number(value));
    }
  }, [value, prevValue, onChangeProp]);

  // Handle updates that originates from outside the component
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setValue((curr) => {
        if (
          Number(curr) !== Number(valueProp) ||
          (curr === "" && valueProp !== "")
        ) {
          return String(valueProp ?? "");
        } else {
          return curr;
        }
      });
    });

    return () => clearTimeout(timeoutId);
  }, [valueProp]);

  // A fix added for text input inside modals.
  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  return (
    <>
      <FormControl
        ref={ref}
        autoComplete="off"
        placeholder={placeholder}
        className={className}
        autoFocus={autoFocus}
        step={step}
        dir="ltr"
        {...numberInputProps}
        pattern={
          // eslint-disable-next-line no-useless-escape
          isInt ? "^-?[0-9]+$" : `^-?[0-9]+(\.[0-9]{1,${maxDigitsAfterDot}})?$`
        }
        value={value}
        onChange={onChange}
        onFocus={p_onOnFocus}
        onBlur={onBlur}
        style={{ textAlign: "right" }}
      />
      <NumpadModal
        show={showNumpad}
        initialValue={valueProp === "" ? undefined : valueProp}
        onCancel={onHideNumpad}
        onDone={onNumpadDone}
        title={numpadTitle}
      />
    </>
  );
};
