import React, { useCallback, useState, useEffect, useRef } from "react";
import { FormContextValues, ErrorMessage } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import { FormControl } from "react-bootstrap";

export type FormFileUploadProps<FormValues> = {
  name: KeysByType<FormValues, File | undefined>;
  formInstance: FormContextValues<FormValues>;
};

export const FormFileUpload = <FormValues,>({
  name,
  formInstance,
}: FormFileUploadProps<FormValues>) => {
  const { errors, setValue, register } = formInstance;
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [path, setPath] = useState("");

  useEffect(() => {
    register(name as any);
  }, [name, register]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPath(e.target.value);
  }, []);

  useEffect(() => {
    setValue(name as any, (fileUploadRef.current!.files as any) ?? undefined);
  }, [setValue, name, path]);

  return (
    <>
      <FormControl
        type="file"
        value={path}
        onChange={onChange}
        ref={fileUploadRef}
      />
      <div>
        {
          <ErrorMessage
            as={FormErrorMessage}
            name={name as string}
            errors={errors}
          />
        }
      </div>
    </>
  );
};
