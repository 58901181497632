import { observable, action } from "mobx";
import { AddOrderDisplayVariant } from "order/types";

export class AddOrderStore {
  @observable
  displayVariant: AddOrderDisplayVariant = "list";

  @observable
  routeSearchPhrases: {
    [routeTemplate: string]: string | undefined;
  } = {};

  @observable
  itemSpeechPhrase: string | undefined;

  @action
  setDisplayVariat = (displayVariant: AddOrderDisplayVariant) => {
    this.displayVariant = displayVariant;
  };

  @action
  setRouteSearchPhrase = (routeTemplate: string, phrase: string) => {
    this.routeSearchPhrases[routeTemplate] = phrase;
  };

  @action
  setSpeechPhrase = (phrase: string | undefined) => {
    this.itemSpeechPhrase = phrase;
  };
}
