import axios from "axios";
import { StockTransactionListItem, StockTransactionsFilters } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";
import querystring from "query-string";

export class StockTransactionsApi {
  static listQuery = new ApiQuery<StockTransactionListItem[]>(
    "/api/stockTransactions"
  );

  static filteredListQuery = new ApiQuery<
    StockTransactionListItem[],
    StockTransactionsFilters
  >((filters) =>
    querystring.stringifyUrl({
      url: "/api/stockTransactions/search",
      query: { ...filters },
    })
  );

  static async delete(id: number) {
    await axios.delete(`/api/stockTransactions/${id}`);

    StockTransactionsApi.listQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(id, "id")
    );
  }
}
