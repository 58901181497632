import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { ApiQuery } from "base/api/ApiQuery";
import { SimpleGlobalListRoutePath } from "./types";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";
import Axios from "axios";

export class SimpleGlobalListApi {
  static itemQuery = new ApiQuery<any[], SimpleGlobalListRoutePath>(
    (routePath: SimpleGlobalListRoutePath) => `/api/${routePath}`
  );

  static async saveItem(
    routeApiPath: SimpleGlobalListRoutePath,
    item: any,
    idAccessor = "id",
    isFormData = false
  ) {
    const { data: savedItem } = await Axios.post<any>(
      `/api/${routeApiPath}`,
      isFormData ? BodyFormDataBuilder.build2(item) : item,
      isFormData
        ? {
            headers: { "Content-Type": "multipart/form-data" },
          }
        : undefined
    );

    SimpleGlobalListApi.itemQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedItem, idAccessor),
      routeApiPath
    );

    return savedItem;
  }

  static async deleteItem(
    routeApiPath: SimpleGlobalListRoutePath,
    id: string | number,
    idAccessor = "id"
  ) {
    await Axios.delete(`/api/${routeApiPath}/${id}`);

    SimpleGlobalListApi.itemQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(id, idAccessor),
      routeApiPath
    );
  }
}
