export class IngredientsHelper {
  static concatNameAndAlternativeNames(ing: {
    name?: string;
    alternativeNames?: string;
  }) {
    const alternativeNamesStr = ing.alternativeNames?.split(",").join("");

    return `${ing.name ?? ""}     ${alternativeNamesStr}`.trim();
  }
}
