/* Warning this is used in local storage. So avoid changing number values. */
export enum SideBarSection {
  Orders = 0,
  Acquisitions = 1,
  Sales = 2,
  HumanResources = 3,
  General = 4,
}

/* Warning this is used in local storage. So avoid modifying existing structure. */
export interface SideBarLocalStorage {
  expandedSections: { [key in SideBarSection]?: boolean };
}
