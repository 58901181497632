import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { ProductSupplyMethod } from "product/supply-method/types";
import { UnitType } from "base/types";
import { OrderSupplier } from "order/types";

export type { SentOrderListItem } from "./SentOrderListItem";
export type { EditSentOrderRouteParams } from "./EditSentOrderRouteParams";
export type { SentOrderEditorMode } from "./SentOrderEditorMode";

export enum OrderDocumentType {
  // תעודת משלוח
  TM = 0,
  // חשבונית מס
  HM,
  // חשבונית מס-קבלה
  HMK,
  // חשבונית עסקה
  HI,
  // תעודת משלוח זיכוי
  TMZ,
  // חשבונית זיכוי
  HZ,
  // תעודת החזרה
  TH,
}

export interface SentOrderItemPost {
  id: number;
  imageName?: string;
  prepareUnitType: number;
  defaultPrice: number;
  requestedSupplyMethod: BaseSupplyMethod;
  receivedSupplyMethod: BaseSupplyMethod;
  name: string;
  productId: number;
  documentAmount: number;
  requestedAmount: number;
  receivedAmount: number;
  requestedPrice: number;
  packageWeight?: number;
  receivedPrice: number;
  isRemovePriceGap: boolean;
  isRemoveAmountGap: boolean;
  priceGapReason?: number;
  amountGapReason?: number;
  receivedWeight: number;
  isDelete?: boolean;
}

export interface SentOrderItemGet extends SentOrderItemPost {
  supplyMethods: ProductSupplyMethod[];
  isWeightable: boolean;
  isAddedAfterSubmit: boolean;
}

export interface SentOrder {
  // SentOrderGet
  id: number;
  key: string; // For Viewer
  supplierName: string;
  supplierImageName: string;
  isOccasional?: boolean;
  supplierId?: number;
  dateCreated: string;
  referenceNo?: string;
  receiveDate?: string;
  orderDocumentType?: OrderDocumentType;
  orderDocumentChecked?: boolean;
  items: SentOrderItemGet[];
  status: number;
  submitterName: string;
  receiverName: string;
}

export interface SentOrderItemPost {
  id: number;
  imageName?: string;
  prepareUnitType: number;
  defaultPrice: number;
  requestedSupplyMethod: BaseSupplyMethod;
  receivedSupplyMethod: BaseSupplyMethod;
  name: string;
  productId: number;
  requestedAmount: number;
  receivedAmount: number;
  documentAmount: number;
  requestedPrice: number;
  packageWeight?: number;
  receivedPrice: number;
  isRemovePriceGap: boolean;
  isRemoveAmountGap: boolean;
  isRemoveWeightGap: boolean;
  priceGapReason?: number;
  amountGapReason?: number;
  weightGapReason?: number;
  receivedWeight: number;
  weights?: number[];
}

export interface SentOrderPost {
  id: number;
  supplierName: string;
  isOccasional?: boolean;
  supplierId?: number;
  dateCreated: string;
  referenceNo?: string;
  receiveDate?: string;
  orderDocumentType?: OrderDocumentType;
  items: SentOrderItemPost[];
  status: number;
  receiptPhoto?: File;
}

export type SentOrderFormValues = SentOrderPost;

export interface SentOrderViewerInfo {
  id: number;
  status: number;
  supplierName: string;
  items: SentOrderViewerItem[];
  notes?: string;
}

export interface SentOrderViewerItem {
  id: number;
  name: string;
  requestedAmount: number;
  prepareUnitType: number;
  requestedPrice: number;
  requestedSupplyMethod: BaseSupplyMethod;
}

export enum SentOrderItemGapReason {
  NoGap = 0,
  Shortage,
  Defect,
  PriceGap,
}

export interface SentOrderSTSimulationListItem {
  name: string;
  amount: number;
  unitType: UnitType;
  price: number;
  referenceNo: string;
  transactionType: string;
  transactionDate: string;
}

export interface SentOrderQuickAddProductFormValues {
  orderId: number;
  productId: number;
  supplyMethodId: number; // Product supply method id
  amount: number;
}

/* OrderPriceReport */
export interface OrderInfluanceFoodCost {
  supplier: OrderSupplier;
  orderDateCreated: string;
  ingredients: IngredientInfluance[];
}

export interface IngredientInfluance {
  ingredientName: string;
  imageName: string;
  receivedSupplyMethod: BaseSupplyMethod;
  receivedPrice: number;
  requestedPrice: number;
  prepareUnitType: number;
  foods: FoodInfluance[];
}

export interface FoodInfluance {
  foodName: string;
  previousFC: number;
  updatedFC: number;
}
/* OrderPriceReport */

/* OrderAmountReport */
export interface OrderAmountReport {
  supplierModel: OrderSupplier;
  orderDateCreated: string;
  products: OrderAmountReportProduct[];
}

export interface OrderAmountReportProduct {
  name: string;
  imageName: string;
  receivedSupplyMethod: BaseSupplyMethod;
  receivedAmount: number;
  requestedAmount: number;
  chargedAmount: number;
  totalRefundAmount: number;
  refundStatus: number;
  currentStock: number;
  prepareUnitType: number;
}
/* OrderAmountReport */

/* DailyRecommend */
export interface DailyRecommendListItem {
  id: number;
  imageName: string;
  amount: number;
  name: string;
  suppliers: DailyReommendSupplier[];
  unitType: number;
}

export interface DailyReommendSupplier {
  name: string;
  id: number;
  price: number;
  imageName: string;
  rate: number;
}
/* DailyRecommend */
