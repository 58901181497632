import { ApiQuery } from "base/api/ApiQuery";
import { BaseSelectListItem } from "base/types";
import { StorageUnit } from "./types";

export class StorageUnitsApi {
  static listWithItemsQuery = new ApiQuery<StorageUnit[]>(
    "/api/storageUnits/getWithItems"
  );

  static selectListQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/storageUnits/selectList"
  );
}
