import React, { useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { Typography } from "../../../Typography";
import { Spacer } from "base/components/Spacer";
import { EnterKeyCodeListener } from "base/components/DocumentKeyupListener";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";

type AmountInputModalFormValues = {
  value: number;
};

export type AmountInputModalProps = {
  show: boolean;
  title?: string;
  initialValue?: number;
  isInt?: boolean;
  onDone: (value: number) => void;
  onCancel: () => void;
};

export const AmountInputModal: React.FC<AmountInputModalProps> = ({
  show,
  title = "בחירת משקל",
  initialValue = 0,
  isInt = false,
  onDone,
  onCancel,
}) => {
  const onSave = useCallback(
    ({ value }: AmountInputModalFormValues) => {
      onDone(value);
    },
    [onDone]
  );

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="numpad-modal"
      centered
    >
      <Form onSubmit={onSave} initialValues={{ value: initialValue }}>
        {({ handleSubmit }) => (
          <div className="numpad-moda-content">
            <div className="text-center">
              <Typography variant="description" fontSize={18}>
                {title}
              </Typography>
            </div>
            <Spacer units={4} />
            <div className="px-4">
              <FormNumberInput
                name="value"
                inputMode={isInt ? "numeric" : "decimal"}
                autoFocus
                selectOnFocus
                isInt={isInt}
              />
            </div>
            <Spacer units={4} />
            <div className="text-center">
              <EnterKeyCodeListener active={show} onEnter={handleSubmit}>
                <Button onClick={handleSubmit}>שמור</Button>
              </EnterKeyCodeListener>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};
