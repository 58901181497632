import React, { useEffect, useMemo, useState } from "react";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { DateFormatter } from "base/utils/formatters";
import { debounce } from "lodash";
import { format } from "date-fns";
import { SentOrder } from "sent-order/types";
import { OrderDocumentTypeOptions } from "sent-order/constants";
import { DatePicker } from "base/components/DatePicker";
import { usePrevious } from "base/hooks/usePrevious";
import { Select } from "base/components/Select";
import { Col, FormControl, Row } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import { NumpadModal } from "base/components/NumpadModal/NumpadModal";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { Link } from "react-router-dom";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useQuery } from "react-query";

export type SentOrderInfoProps = {
  id: number;
};

export const SentOrderInfo: React.FC<SentOrderInfoProps> = ({ id }) => {
  const buildReceiveDate = (data: SentOrder | undefined) =>
    data?.receiveDate ?? format(Date.now(), "yyyy-MM-dd");

  const { data } = useQuery(SentOrdersApi.itemQuery.build(id));

  const isOrderClosed = data?.status === 5;
  const [referenceNo, setReferenceNo] = useState<string | undefined>(
    data?.referenceNo
  );
  const [orderDocChecked, setOrderDocChecked] = useState<boolean | undefined>(
    data?.orderDocumentChecked
  );
  const [receiveDate, setReceiveDate] = useState(() => buildReceiveDate(data));
  const [orderDocType, setOrderDocType] = useState(data?.orderDocumentType);
  const [showRefNoModal, onShowRefNoModal, onHideRefNoModal] =
    useBooleanState(false);
  const previousData = usePrevious(data);
  const prevReceiveDate = usePrevious(receiveDate);
  const prevReferenceNo = usePrevious(referenceNo);
  const prevOrderDocType = usePrevious(orderDocType);
  const prevOrderDocChecked = usePrevious(orderDocChecked);

  const save = useMemo(
    () =>
      debounce(async (sentOrder: SentOrder) => {
        await SentOrdersApi.saveSentOrder(sentOrder);
      }, 400),
    []
  );

  useEffect(() => {
    if (data) {
      setReferenceNo(data.referenceNo);
      setReceiveDate(buildReceiveDate(data));
      setOrderDocType(data.orderDocumentType);
      setOrderDocChecked(data.orderDocumentChecked);
    }
  }, [data]);

  useEffect(() => {
    if (
      data &&
      previousData &&
      (data.referenceNo !== referenceNo ||
        data.receiveDate !== receiveDate ||
        data.orderDocumentType !== orderDocType ||
        data.orderDocumentChecked !== orderDocChecked) &&
      (referenceNo !== prevReferenceNo ||
        receiveDate !== prevReceiveDate ||
        orderDocType !== prevOrderDocType ||
        orderDocChecked !== prevOrderDocChecked)
    ) {
      save({
        ...data,
        referenceNo,
        orderDocumentChecked: orderDocChecked,
        receiveDate,
        orderDocumentType: orderDocType,
      });
    }
  }, [
    data,
    previousData,
    receiveDate,
    referenceNo,
    save,
    prevReceiveDate,
    prevReferenceNo,
    orderDocType,
    prevOrderDocType,
    orderDocChecked,
    prevOrderDocChecked,
  ]);

  return (
    <>
      <h5 className="text-center d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <div>
          ספק:
          <div className="d-inline-flex ml-3 align-content-center justify-content-center align-items-center">
            <span className="ml-2" style={{ color: "orange" }}>
              {data?.supplierName}
            </span>
            <BaseImage
              fileName={data?.supplierImageName}
              fileDir={FileDir.Supplier}
              width={50}
              height={50}
              rounded
            />
          </div>
        </div>
        <div>
          תאריך:
          <span style={{ color: "orange" }} className="ml-5">
            {DateFormatter.default.format(data?.dateCreated)}
          </span>
        </div>
        <div>
          שם השולח:
          <span style={{ color: "orange" }} className="ml-5">
            {data?.submitterName}
          </span>
        </div>
        <div>
          שם הקולט:
          <span style={{ color: "orange" }} className="ml-5">
            {data?.receiverName}
          </span>
        </div>
        {data && (
          <Link
            to={SentOrdersRouteBuilder.buildViewerRoute(data.key)}
            style={{ color: "orange" }}
            target="_blank"
            className="ml-5"
          >
            לצפיה בקישור שנשלח לספק
          </Link>
        )}
      </h5>
      <Row noGutters>
        <Col className="px-1 d-flex align-items-center" xs={12} md={3}>
          <span className="flex-grow-1 flex-shrink-0 mx-2">סוג מסמך:</span>
          <Select
            options={OrderDocumentTypeOptions}
            value={orderDocType}
            onChange={setOrderDocType}
            variant="react-select"
            disabled={isOrderClosed}
          />
        </Col>
        <Col className="px-1 d-flex align-items-center" xs={12} md={3}>
          <span className="flex-grow-1 flex-shrink-0 mx-2">אסמכתא:</span>
          <input
            className="form-control"
            placeholder="אסמכתא"
            value={referenceNo}
            onClick={onShowRefNoModal}
            disabled={isOrderClosed}
          />
          <NumpadModal
            show={showRefNoModal}
            title="הזנת אסמכתא"
            initialValue={referenceNo ? Number(referenceNo) : undefined}
            onCancel={onHideRefNoModal}
            onDone={(nextRefNo) => {
              setReferenceNo(nextRefNo ? String(nextRefNo) : undefined);
              onHideRefNoModal();
            }}
          />
        </Col>
        <Col className="px-1 d-flex align-items-center" xs={12} md={3}>
          <span className="flex-grow-1 flex-shrink-0 mx-2">מסמך נבדק:</span>
          <input
            type="checkbox"
            className="form-control"
            checked={orderDocChecked}
            disabled={isOrderClosed}
            onChange={(e) => setOrderDocChecked(e.target.checked)}
          />
        </Col>
        <Col className="px-1 d-flex align-items-center" xs={12} md={3}>
          <span className="flex-grow-1 flex-shrink-0 mx-2">תאריך קליטה:</span>
          <DatePicker
            value={receiveDate}
            onChange={setReceiveDate}
            disabled={isOrderClosed}
          />
        </Col>
      </Row>
    </>
  );
};
