import axios from "axios";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { EmployeeListItem, Employee, EmployeeOngoingShiftItem } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import produce from "immer";

export class EmployeesApi {
  static listQuery = new ApiQuery<EmployeeListItem[]>("/api/employees/items");

  static itemQuery = new ApiQuery<Employee, string>(
    (id) => `/api/employees/${id}`
  );

  static shiftsPresenceQuery = new ApiQuery<EmployeeOngoingShiftItem[]>(
    "/api/ShiftsPrensence/items"
  );

  static async saveItem(
    data: WithPartialProperties<Employee, "id">,
    attachment?: File
  ) {
    const { data: savedItem } = await axios.post<Employee>(
      "/api/employees",
      BodyFormDataBuilder.build({ ...data, image: attachment }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    EmployeesApi.itemQuery.updateCache(savedItem, savedItem.id);
    EmployeesApi.listQuery.invalidate();
  }

  static async reportEnterShift(employeeId: string) {
    await axios.post("/api/ShiftsPrensence/enterShift", { employeeId });

    EmployeesApi.shiftsPresenceQuery.invalidate();
  }

  static async reportLeaveShift(employeeId: string) {
    await axios.post("/api/ShiftsPrensence/exitShift", { employeeId });

    EmployeesApi.shiftsPresenceQuery.updateCache(
      produce((shiftsPresence) => {
        const shift = shiftsPresence?.find((x) => x.employeeId === employeeId);

        if (shift) {
          shift.entranceTime = undefined;
        }
      })
    );
  }
}
