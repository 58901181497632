import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Select, SelectProps } from "base/components/Select";
import { FormErrorMessage } from "../../FormErrorMessage";
import {
  SelectWithAddProps,
  SelectWithAdd,
} from "base/components/SelectWithAdd";

export type SelectFieldInnerProps = FieldRenderProps<any> & {
  options: Array<{
    value: string | number;
    label: string;
  }>;
  displayEmptyOption?: boolean;
  emptyOptionLabel?: string;
  applyCustomSelectClass?: boolean;
  className?: string;
  variant?: SelectProps["variant"];
  withAdd?: Pick<
    SelectWithAddProps,
    "render" | "component" | "customModalRender" | "initialValues"
  >;
  disabled?: boolean;
  sortAsc?: boolean;
};

export const SelectFieldInner: React.FC<SelectFieldInnerProps> = ({
  input,
  meta,
  withAdd,
  ...selectProps
}) => {
  const { value, onChange, onFocus } = input;

  return (
    <>
      {withAdd ? (
        <SelectWithAdd
          {...selectProps}
          {...withAdd}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
        />
      ) : (
        <Select
          {...selectProps}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
        />
      )}
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
