import Axios from "axios";
import {
  BasicPickListItem,
  Order,
  OrderIngredientItem,
  DepartmentListItem,
  DetailedOrder,
  OrdersSimulationResultItem,
  OrdersSimulationItem,
} from "order/types";
import produce from "immer";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class OrdersApi {
  static detailedListQuery = new ApiQuery<DetailedOrder[]>(
    `/api/orders/detailedItems`
  );

  static itemQuery = new ApiQuery<Order, number>((id) => `/api/orders/${id}`);

  static itemBySupplierQuery = new ApiQuery<Order, number>(
    (id) => `/api/orders/supplier/${id}`
  );

  static supplierListQuery = new ApiQuery<BasicPickListItem[]>(
    "/api/orders/suppliers"
  );

  static departmentListQuery = new ApiQuery<DepartmentListItem[]>(
    "/api/orders/departments"
  );

  static departmentQuery = new ApiQuery<any, number>(
    (id) => `/api/orders/department/${id}`
  );

  static searchIngredientsQuery = new ApiQuery<OrderIngredientItem[], string>(
    (phrase) => `/api/orders/search/${phrase}`
  );

  static async submitOrder(id: number, sendToSupplier: boolean) {
    await Axios.post(`/api/orders/submitOrder/${id}`, { sendToSupplier });

    OrdersApi.detailedListQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(id, "orderId")
    );
  }

  static async updateNotes(id: number, notes: string) {
    await Axios.post(`/api/orders/${id}/notes`, { notes });

    OrdersApi.itemQuery.updateCache((order) => {
      if (!order) return;

      return { ...order, notes };
    }, id);

    OrdersApi.detailedListQuery.updateCache(
      produce((detailedOrders) => {
        detailedOrders?.forEach((o) => {
          if (o.orderId === id) {
            o.notes = notes;
          }
        });
      })
    );
  }

  static async updateProduct(
    id: number,
    productDetails: {
      productId: number;
      amount: number;
      supplyMethod: BaseSupplyMethod;
      requestedPrice: number | undefined;
      supplierId: number;
    }
  ) {
    await Axios.post(`/api/orders/addToOrder`, productDetails);

    OrdersApi.itemQuery.invalidate(id);

    OrdersApi.detailedListQuery.updateCache(
      produce((detailedOrders) => {
        detailedOrders?.forEach((o) => {
          if (o.orderId === id) {
            o.items.forEach((item) => {
              if (item.productId === productDetails.productId) {
                item.requestedAmount = productDetails.amount;
                Object.assign(item.supplyMethod, productDetails.supplyMethod);
                item.requestedPrice =
                  productDetails.requestedPrice ?? item.requestedPrice;
              }
            });
          }
        });
      })
    );
  }

  static async deleteProducts(id: number, productIdsToDelete: number[]) {
    await Axios.delete(`/api/orders/${id}/items`, {
      data: productIdsToDelete,
    });

    OrdersApi.itemQuery.invalidate(id);

    OrdersApi.detailedListQuery.updateCache(
      produce((detailedOrders) => {
        detailedOrders?.forEach((o) => {
          if (o.orderId === id) {
            o.items = o.items.filter((item) => {
              return !productIdsToDelete.includes(item.productId);
            });
          }
        });
      })
    );
  }

  static async calculateOrdersSimulation(data: OrdersSimulationItem[]) {
    const { data: result } = await Axios.post<OrdersSimulationResultItem[]>(
      "/api/orders/calculateOrdersSimulation",
      data
    );

    return result;
  }
}
