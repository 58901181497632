import React, { useMemo, useState } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { OrderAmountReport, OrderAmountReportProduct } from "sent-order/types";
import { Typography } from "base/components/Typography";
import {
  CardsTableColumn,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import { BaseImage } from "base/components/BaseFoodImage";
import { Modal } from "react-bootstrap";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { FileDir, BaseSelectListItem } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { PriceReportChange } from "sent-order/components/PriceReportChange";
import { format as dateFormat } from "date-fns";
import { useQuery } from "react-query";

export const OrderAmountReportScreen: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<
    OrderAmountReportProduct
  >();

  const reportData: OrderAmountReport | undefined = {
    orderDateCreated: String(new Date()),
    products: [
      {
        requestedAmount: 50,
        receivedAmount: 52,
        imageName: "",
        receivedSupplyMethod: {
          id: 777,
          supplyUnitType: 1,
          supplierId: 3000,
          isDefault: false,
        },
        name: "שם מרכיב בדיקה",
        prepareUnitType: 1,
        chargedAmount: 100,
        currentStock: 10,
        refundStatus: 1,
        totalRefundAmount: 20,
      },
    ],
    supplierModel: {
      id: 3000,
      logoFileName: "",
      minimunOrderPrice: 50,
      name: "ספק בדיקה",
      submitOrderMessage: "",
    },
  };

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const columns = useMemo<CardsTableColumn<OrderAmountReportProduct>[]>(
    () => [
      {
        id: "imageName",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="מרכיב" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "name",
        accessor: "name",
        Header: "שם המוצר",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "diff",
        accessor: ({ requestedAmount, receivedAmount }) => {
          return (receivedAmount / requestedAmount) * 100 - 100;
        },
        Cell: PriceReportChange,
        Header: "% פער בכמות",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const modalTopColumns = useMemo<CardsTableColumn<OrderAmountReportProduct>[]>(
    () => [
      {
        id: "requestedAmount",
        accessor: "requestedAmount",
        Header: "כמות שהוזמנה",
      },
      {
        id: "receivedAmount",
        accessor: "receivedAmount",
        Header: "כמות שהתקבלה",
      },
      {
        id: "currentStock",
        accessor: "currentStock",
        Header: "מלאי עדכני לאחר קליטת הזמנה",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );
  const modalBodyColumns = useMemo<
    CardsTableColumn<OrderAmountReportProduct>[]
  >(
    () => [
      {
        id: "chargedAmount",
        accessor: "chargedAmount",
        Header: "כמות שחויבה",
      },
      {
        id: "totalRefundAmount",
        accessor: "totalRefundAmount",
        Header: 'סה"כ בקשה לזיכוי',
        cellProps: {},
      },
      {
        id: "refundStatus",
        accessor: "refundStatus",
        Header: "מצב בקשת זיכוי",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <Typography variant="route-title">
        דוח פערים בהזמנה - {reportData?.supplierModel.name}
      </Typography>
      <Typography variant="description" className="text-center my-2">
        לתשומת ה❤! בהזמנה שהתקבלה בתאריך{" "}
        {reportData?.orderDateCreated &&
          dateFormat(new Date(reportData?.orderDateCreated), "dd/MM/yyyy")}{" "}
        יש פערים בכמות
      </Typography>
      <CardsTable
        columns={columns}
        data={reportData?.products}
        globalFilterAllowedCols={["ingredientName"]}
        onEditRowClick={(row) => {
          setSelectedProduct(row);
        }}
      />
      <Modal
        show={selectedProduct !== undefined}
        onHide={() => setSelectedProduct(undefined)}
        centered
      >
        <div style={{ backgroundColor: "#F2F2FD" }}>
          <ModalTitleBar
            icon={
              <BaseImage
                fileDir={FileDir.BaseFood}
                fileName={selectedProduct?.imageName}
              />
            }
            title={
              <>
                <Typography variant="description" fontSize={16}>
                  {selectedProduct?.name}
                </Typography>
                <br />
                <Typography variant="description" fontSize={16}>
                  {SupplyMethodDescFormatter.format(
                    selectedProduct?.receivedSupplyMethod,
                    selectedProduct?.prepareUnitType,
                    unitKinds,
                    packageKinds
                  )}
                </Typography>
              </>
            }
            onClose={() => setSelectedProduct(undefined)}
          />
          <div className="p-2">
            <CardsTable
              columns={modalTopColumns}
              data={selectedProduct ? [selectedProduct] : []}
              searchable={false}
              hasPagination={false}
            />
            <CardsTable
              columns={modalBodyColumns}
              data={selectedProduct ? [selectedProduct] : []}
              searchable={false}
              hasPagination={false}
            />
          </div>
        </div>
      </Modal>
    </RouteContent>
  );
};
