import React, { useMemo } from "react";
import { startOfWeek, format } from "date-fns";
import { debounce } from "lodash";
import { RouteContent } from "route-content/components/RouteContent";
import { MyScheduleRequestForm } from "schedule-requests/components/MyScheduleRequestForm";
import { Form } from "form/components/Form";
import { useQueryParams } from "base/api/hooks/useQueryParams";
import { ScheduleRequest } from "schedule-requests/types";
import { ScheduleRequestsApi } from "schedule-requests/ScheduleRequestsApi";
import { ScheduleRequestsRouteAdapter } from "schedule-requests/ScheduleRequestsRouteAdapter";
import { useQuery } from "react-query";

export const MyScheduleRequestScreen: React.FC = () => {
  const queryParams = useQueryParams();
  const resolvedStartOfWeekDay = startOfWeek(
    ScheduleRequestsRouteAdapter.parse(queryParams.get("startOfWeekDay")!)
  );
  const formattedDateStr = ScheduleRequestsRouteAdapter.format(
    resolvedStartOfWeekDay
  );

  const { data, error: queryError, isLoading, isFetching } = useQuery(
    ScheduleRequestsApi.myScheduleQuery.build(formattedDateStr)
  );

  const onSubmit = useMemo(
    () =>
      debounce(async (schedule: ScheduleRequest) => {
        await ScheduleRequestsApi.saveMySchedule(formattedDateStr, schedule);
      }, 500),
    [formattedDateStr]
  );

  const error =
    queryError && !isLoading && !isFetching
      ? queryError.response?.data?.error ??
        "אירעה שגיאה כללית, אנא נסה שוב מאוחר יותר"
      : undefined;

  return (
    <RouteContent>
      <Form
        onSubmit={onSubmit}
        initialValues={
          data ?? {
            startDate: format(resolvedStartOfWeekDay, "yyyy-MM-dd"),
          }
        }
        render={() => (
          <MyScheduleRequestForm
            startOfWeekDay={resolvedStartOfWeekDay}
            error={error}
          />
        )}
      />
    </RouteContent>
  );
};
