import React, { useMemo, useCallback } from "react";
import "./../style.scss";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { ContractListItem } from "contract/types";
import { ContractsRouteBuilder } from "contract/ContractsRouteBuilder";
import { ContractsApi } from "contract/ContractsApi";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const ContractsTable = () => {
  const { data } = useQuery(ContractsApi.listQuery.build());
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(ContractsRouteBuilder.buildAddContractRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: ContractListItem) => {
      navigate(ContractsRouteBuilder.buildEditContractRoute(data.id));
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<ContractListItem>["columns"]>(
    () => [
      {
        id: "Id",
        accessor: "id",
        Header: "מזהה",
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <div className="contracts-table">
      <CardsTable
        columns={columns}
        data={data}
        onEditRowClick={onEditRowClick}
        onAddRowClick={onAddRowClick}
        localStorageKey={LocalStorageKeysHelper.dataTable("contracts")}
        globalFilterAllowedCols={["Name"]}
      />
    </div>
  );
};
