import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ProductEditor } from "./components/ProductEditor";
import { useQueryParams } from "base/api/hooks/useQueryParams";

export const AddProductScreen: React.FC = () => {
  const queryParams = useQueryParams();
  const initialIngredientIdParam = queryParams.get("initialIngredientId");

  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <ProductEditor
        mode="add"
        initialIngredientId={
          initialIngredientIdParam
            ? parseInt(initialIngredientIdParam)
            : undefined
        }
      />
    </RouteContent>
  );
};
