import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { SelectFieldInner, SelectFieldInnerProps } from "./SelectFieldInner";
import { FieldValidator } from "final-form";
import { isEqual as lodashIsEqual } from "lodash";

export type FormSelectProps = {
  name: string;
  validate?: FieldValidator<any>;
  // inner select props - START
  options: SelectFieldInnerProps["options"];
  displayEmptyOption?: SelectFieldInnerProps["displayEmptyOption"];
  emptyOptionLabel?: SelectFieldInnerProps["emptyOptionLabel"];
  applyCustomSelectClass?: SelectFieldInnerProps["applyCustomSelectClass"];
  className?: SelectFieldInnerProps["className"];
  withAdd?: SelectFieldInnerProps["withAdd"];
  variant?: SelectFieldInnerProps["variant"];
  defaultSelectedValue?: any;
  isMulti?: boolean;
  disabled?: boolean;
  autoClearValueIfNotFound?: boolean;
  initialValue?: any;
  sortAsc?: boolean;
  // inner select props - END
};

export const FormSelect: React.FC<FormSelectProps> = ({
  name,
  validate,
  initialValue,
  ...innerSelectProps
}) => {
  const { options, displayEmptyOption, isMulti } = innerSelectProps;

  const isEqual = useCallback(
    (a: any, b: any) => {
      // Since displayEmptyOption is true by default..
      const resolvedDisplayEmptyOption = displayEmptyOption ?? true;

      if (isMulti) {
        return lodashIsEqual(a ?? [], b ?? []);
      } else if (a === b) {
        return true;
      } else if (a === undefined && !resolvedDisplayEmptyOption) {
        return b === options[0]?.value;
      } else if (b === undefined && !resolvedDisplayEmptyOption) {
        return a === options[0]?.value;
      } else {
        return false;
      }
    },
    [displayEmptyOption, isMulti, options]
  );

  return (
    <Field
      name={name}
      component={SelectFieldInner}
      isEqual={isEqual}
      type="select"
      validate={validate}
      initialValue={initialValue}
      {...innerSelectProps}
    />
  );
};
