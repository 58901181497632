import { useEffect, useState, useRef } from "react";

export const useBarcodeListener = (
  onBarcodeReceived: (barcode: number) => void,
  active: boolean
) => {
  const refs = useRef({ onBarcodeReceived });
  Object.assign(refs.current, { onBarcodeReceived });

  const [barcode, setBarcode] = useState<number>();

  useEffect(() => {
    if (!active) {
      setBarcode(undefined);
      return;
    }

    const onKeydown = (e: KeyboardEvent) => {
      const data = e.key;
      const targetName = (e.target as Element | null)?.localName;

      if (
        data &&
        targetName !== "input" &&
        data !== "Enter" &&
        data !== "Alt"
      ) {
        if (data.length === 1) {
          setBarcode((curr) => Number(`${curr ?? 0}${data}`));
        } else {
          setBarcode(Number(data));
        }
      }
    };

    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [active]);

  useEffect(() => {
    if (barcode !== undefined) {
      const timerId = setTimeout(() => {
        refs.current.onBarcodeReceived(barcode);
        setBarcode(undefined);
      }, 200);

      return () => clearTimeout(timerId);
    }
  }, [barcode]);
};
