import Axios from "axios";
import { PreparationTaskListItem, AddPreparationTaskFormValues } from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class PreparationTasksApi {
  static listQuery = new ApiQuery<PreparationTaskListItem[]>(
    "/api/preparationTasks/current"
  );

  static async addItem(values: AddPreparationTaskFormValues) {
    await Axios.post<PreparationTaskListItem[]>(
      "/api/preparationTasks",
      values
    );

    PreparationTasksApi.listQuery.invalidate();
  }

  static async setStart(id: number) {
    const { data: savedListItem } = await Axios.post<PreparationTaskListItem>(
      `/api/preparationTasks/${id}/setStart`
    );

    PreparationTasksApi.listQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedListItem, "id")
    );
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/preparationTasks/${id}`);

    PreparationTasksApi.listQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(id, "id")
    );
  }

  static async setDone(id: number, itemFianlAmount: number) {
    await Axios.post(`/api/preparationTasks/${id}/setDone/${itemFianlAmount}`);

    PreparationTasksApi.listQuery.invalidate();
  }
}
