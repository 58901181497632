import "./styles.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { IngredientsRouteBuilder } from "ingredient/IngredientsRouteBuilder";
import { DishesRouteBuilder } from "dish/DishesRouteBuilder";
import { EmployeesRouteBuilder } from "employee/EmployeesRouteBuilder";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { StockTransactionsRouteBuilder } from "stockTransactions/StockTransactionsRouteBuilder";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { useAppContext } from "app/context/hooks/useAppContext";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";
import { DepartmentsRouteBuilder } from "department/DepartmentsRouteBuilder";
import { SimpleGlobalListRouteBuilder } from "global-list/SimpleGlobalListRouteBuilder";
import { StockRouteBuilder } from "stock/StockRouteBuilder";
import { ChefsRouteBuilder } from "chefs/ChefsHomeRouteBuilder";
import { useStandType } from "auth/hooks/useStandType";
import { POSRouteBuilder } from "pos/POSRouteBuilder";
import { ProductsRouteBuilder } from "product/ProductsRouteBuilder";
import { AdminRouteBuilder } from "admin/AdminRouteBuilder";
import { ManagerRouteBuilder } from "manager/ManagerRouteBuilder";
import { BaseImage } from "base/components/BaseFoodImage";
import { SideBarLocalStorage, SideBarSection } from "./types";
import { useImmer } from "use-immer";
import { Collapse } from "@material-ui/core";
import { WorkSchedulesRouteBuilder } from "work-schedule/WorkSchedulesRouteBuilder";
import { WeeklyWorkSchedulesRouteBuilder } from "weekly-work-schedule/WeeklyWorkSchedulesRouteBuilder";
import { startOfWeek } from "date-fns";
import { ScheduleRequestsRouteBuilder } from "schedule-requests/ScheduleRequestsRouteBuilder";
import { ContractsRouteBuilder } from "contract/ContractsRouteBuilder";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { FileDir } from "base/types";
import { ReactComponent as MenuIcon } from "./assets/SideBarMenu.svg";
import { ReactComponent as SentOrderIcon } from "route-content/assets/RouteContentSentOrder.svg";
import { ReactComponent as CreateOrderIcon } from "route-content/assets/RouteContentCreateOrder.svg";
import { ReactComponent as OrdersHistoryIcon } from "route-content/assets/RouteContentOrdersHistory.svg";
import { ReactComponent as EmployeesIcon } from "route-content/assets/RouteContentEmployees.svg";
import { ReactComponent as ShoppingCartIcon } from "route-content/assets/RouteContentShoppingCart.svg";

export const SideBar: React.FC = () => {
  const [isLocalStorageLoaded, setIsLocalStorageLoaded] = useState(false);
  const [expandedSections, updateExpandedSections] = useImmer<
    { [key in SideBarSection]?: boolean }
  >({});

  const isInRouteWithoutSidebar = useRouteMatch([
    AuthRouteBuilder.buildLoginRoute(),
    SentOrdersRouteBuilder.templates.Viewer,
  ]);

  const { setIsSidebarEnabled, onSignOut } = useAppContext();
  const tokenPayload = useTokenPayload();
  const standType = useStandType();

  const shouldSidebarBeEnabled =
    Boolean(tokenPayload) &&
    !isInRouteWithoutSidebar &&
    standType !== "receiveOrder" &&
    standType !== "chef";

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const onToggleNavButtonClick = useCallback(() => {
    setIsMobileOpen((curr) => !curr);
  }, []);

  const onToggleSection = useCallback(
    (section: SideBarSection) => {
      updateExpandedSections((draft) => {
        draft[section] = !draft[section];
      });
    },
    [updateExpandedSections]
  );

  useEffect(() => {
    setIsSidebarEnabled(shouldSidebarBeEnabled);
  }, [setIsSidebarEnabled, shouldSidebarBeEnabled]);

  // Handle Load from Local Storage
  useEffect(() => {
    const defaultData: SideBarLocalStorage = {
      expandedSections: {},
    };

    const localStorageData: SideBarLocalStorage = JSON.parse(
      localStorage.getItem(LocalStorageKeysHelper.sidebar()) ??
        JSON.stringify(defaultData)
    );

    updateExpandedSections(localStorageData.expandedSections);

    setTimeout(() => setIsLocalStorageLoaded(true), 25);
  }, [updateExpandedSections]);

  // Handle Save to Local Storage
  useEffect(() => {
    const nextData: SideBarLocalStorage = {
      expandedSections,
    };
    localStorage.setItem(
      LocalStorageKeysHelper.sidebar(),
      JSON.stringify(nextData)
    );
  }, [expandedSections]);

  if (!shouldSidebarBeEnabled) {
    return null;
  }

  return (
    <div className={classNames("sidebar", { opened: isMobileOpen })}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="toggleNavButton"
        onClick={onToggleNavButtonClick}
        role="button"
      >
        {isMobileOpen && (
          <i className="fas fa-2x text-white fa-chevron-right" />
        )}
        {!isMobileOpen && <MenuIcon width={36} height={36} />}
      </a>
      <div className="items mt-3">
        <Link onClick={() => setIsMobileOpen(false)} to="/">
          <div className="d-flex align-items-center" role="button">
            <MenuIcon width={36} height={36} className="mx-4" />
            <span className="h5 m-0 text-white">מסעדת פיורי</span>
          </div>
        </Link>
        <div className="divider" />
        <div className="d-flex align-items-center">
          <BaseImage
            fileDir={FileDir.User}
            containerStyle={{
              borderRadius: "50%",
              border: "1px solid white",
              width: 48,
              height: 48,
            }}
            containerClassName="mx-4"
          />
          <div className="align-self-stretch flex-grow-1 d-flex flex-column justify-content-between py-1">
            <span className="h6 m-0 text-white">
              שלום, {tokenPayload?.name}{" "}
            </span>
            <span className="h7 m-0 userTextButton" onClick={onSignOut}>
              התנתקות
            </span>
          </div>
        </div>
        <div className="divider" />
        <div
          className="d-flex align-items-center"
          role="button"
          onClick={() => onToggleSection(SideBarSection.Orders)}
        >
          <span className="h5 m-0 text-white px-4">הזמנות</span>
          <i
            className={classNames(
              "fas fa-chevron-left text-white fa-2x mr-auto px-3",
              {
                "fa-rotate-270": expandedSections[SideBarSection.Orders],
              }
            )}
          />
        </div>
        <div className="divider" />
        <Collapse
          timeout={isLocalStorageLoaded ? 300 : 0}
          in={expandedSections[SideBarSection.Orders]}
        >
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={OrdersRouteBuilder.buildOrdersHomeRoute()}
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">הזמנות ראשי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={OrdersRouteBuilder.buildAddOrderHomeRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <CreateOrderIcon width={36} height={36} className="mx-4" />
              <span className="h6 m-0 text-white">יצירת הזמנה</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={OrdersRouteBuilder.buildOrdersStatusRoute()}
          >
            <div className="d-flex align-items-center">
              <ShoppingCartIcon width={36} height={36} className="mx-4" />
              <span className="h6 m-0 text-white">סל קניות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={SentOrdersRouteBuilder.buildSentOrdersRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <SentOrderIcon width={36} height={36} className="mx-4" />
              <span className="h6 m-0 text-white">קליטת הזמנות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={SentOrdersRouteBuilder.buildReceivedOrdersRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">אישור הזמנות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={SentOrdersRouteBuilder.buildDailyRecommendRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">המלצה יומית</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={OrdersRouteBuilder.buildOrdersSimulationRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">סימולציית הזמנות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={(e) => {
              // setIsMobileOpen(false);
              e.preventDefault();
            }}
            to={OrdersRouteBuilder.buildAddOrderHomeRoute()}
            style={{ cursor: "not-allowed" }}
          >
            <div className="d-flex align-items-center">
              <OrdersHistoryIcon width={36} height={36} className="mx-4" />
              <span className="h6 m-0 text-white">היסטוריית הזמנות</span>
            </div>
          </Link>
          <div className="divider" />
        </Collapse>
        <div
          className="d-flex align-items-center"
          role="button"
          onClick={() => onToggleSection(SideBarSection.Acquisitions)}
        >
          <span className="h5 m-0 text-white px-4">רכש ומלאי</span>
          <i
            className={classNames(
              "fas fa-chevron-left text-white fa-2x mr-auto px-3",
              {
                "fa-rotate-270": expandedSections[SideBarSection.Acquisitions],
              }
            )}
          />
        </div>
        <div className="divider" />
        <Collapse
          timeout={isLocalStorageLoaded ? 300 : 0}
          in={expandedSections[SideBarSection.Acquisitions]}
        >
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={IngredientsRouteBuilder.buildIngredientsRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">מרכיבים</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={ProductsRouteBuilder.buildListRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">מוצרים</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={SuppliersRouteBuilder.buildSuppliersRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">ספקים</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={StockRouteBuilder.buildAddToStockRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h7 m-0 text-white">קליטה למלאי ללא הזמנה</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={StockRouteBuilder.buildStockTakingRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">ספירת מלאי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={StockRouteBuilder.buildStockRemovalRequestRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">זריקת מלאי</span>
            </div>
          </Link>
          <div className="divider" />
        </Collapse>
        <div
          className="d-flex align-items-center"
          role="button"
          onClick={() => onToggleSection(SideBarSection.Sales)}
        >
          <span className="h5 m-0 text-white px-4">מכר</span>
          <i
            className={classNames(
              "fas fa-chevron-left text-white fa-2x mr-auto px-3",
              {
                "fa-rotate-270": expandedSections[SideBarSection.Sales],
              }
            )}
          />
        </div>
        <div className="divider" />
        <Collapse
          timeout={isLocalStorageLoaded ? 300 : 0}
          in={expandedSections[SideBarSection.Sales]}
        >
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={DishesRouteBuilder.buildDishesRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">מנות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={MaterialsRouteBuilder.buildMaterialsRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">חומרי גלם מעובדים</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={POSRouteBuilder.buildImportRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">קליטת קובץ מכר</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={POSRouteBuilder.buildHistoryRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h7 m-0 text-white">היסטוריית קליטת דוח מכר</span>
            </div>
          </Link>
          <div className="divider" />
        </Collapse>
        <div
          className="d-flex align-items-center"
          role="button"
          onClick={() => onToggleSection(SideBarSection.HumanResources)}
        >
          <span className="h5 m-0 text-white px-4">כח אדם</span>
          <i
            className={classNames(
              "fas fa-chevron-left text-white fa-2x mr-auto px-3",
              {
                "fa-rotate-270":
                  expandedSections[SideBarSection.HumanResources],
              }
            )}
          />
        </div>
        <div className="divider" />
        <Collapse
          timeout={isLocalStorageLoaded ? 300 : 0}
          in={expandedSections[SideBarSection.HumanResources]}
        >
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={EmployeesRouteBuilder.buildEmployeesRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <EmployeesIcon width={36} height={36} className="mx-4" />
              <span className="h6 m-0 text-white">עובדים</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={WorkSchedulesRouteBuilder.editTemplates()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">הגדרות סידור שבועי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={WeeklyWorkSchedulesRouteBuilder.buildWeeklyWorkScheduleRoute(
              startOfWeek(Date.now())
            )}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">סידור שבועי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={ScheduleRequestsRouteBuilder.buildMyScheduleRequestsRoute(
              startOfWeek(Date.now())
            )}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">המשמרות שלי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={EmployeesRouteBuilder.buildshiftsPresenceRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">דיווח נוכחות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={ContractsRouteBuilder.buildContractsRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">הסכמי שכר</span>
            </div>
          </Link>
          <div className="divider" />
        </Collapse>
        <div
          className="d-flex align-items-center"
          role="button"
          onClick={() => onToggleSection(SideBarSection.General)}
        >
          <span className="h5 m-0 text-white px-4">ניהול כללי</span>
          <i
            className={classNames(
              "fas fa-chevron-left text-white fa-2x mr-auto px-3",
              {
                "fa-rotate-270": expandedSections[SideBarSection.General],
              }
            )}
          />
        </div>
        <div className="divider" />
        <Collapse
          timeout={isLocalStorageLoaded ? 300 : 0}
          in={expandedSections[SideBarSection.General]}
        >
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={DepartmentsRouteBuilder.buildDepartmentsRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">מחלקות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={StockTransactionsRouteBuilder.buildStockTransactionsRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">תנועות מלאי</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={SimpleGlobalListRouteBuilder.buildListsIndexRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">רשימות מהירות</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={ManagerRouteBuilder.buildDashboardRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">דאשבורד מנהל</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={AdminRouteBuilder.buildStandSettingsScreen()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">הגדרות עמדה</span>
            </div>
          </Link>
          <div className="divider" />
          <Link
            onClick={() => setIsMobileOpen(false)}
            to={ChefsRouteBuilder.buildChefsHomeRoute()}
            className="text-decoration-none"
          >
            <div className="d-flex align-items-center">
              <i
                className="fas fa-question text-white fa-2x mx-4"
                style={{
                  fontSize: 36,
                  height: 36,
                  width: 36,
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <span className="h6 m-0 text-white">עמדת טבחים</span>
            </div>
          </Link>
          <div className="divider" />
        </Collapse>
        <div className="h6 m-0 text-white" style={{ textAlign: "center" }}>
          גרסה {process.env.REACT_APP_VERSION}
        </div>
      </div>
    </div>
  );
};
