import Axios from "axios";
import { BaseSelectListItem } from "base/types";
import { WorkScheduleApi } from "work-schedule/WorkScheduleApi";

export class SelectListItemEditorApi {
  static async saveWorkScheduleRoleType(
    data: WithPartialProperties<BaseSelectListItem, "id">
  ) {
    const { data: savedItem } = await Axios.post<BaseSelectListItem>(
      "/api/workSchedules/roleTypes",
      data
    );

    WorkScheduleApi.roleTypesQuery.invalidate();

    return savedItem;
  }

  static async saveWorkScheduleRoleName(
    data: WithPartialProperties<BaseSelectListItem, "id">
  ) {
    const { data: savedItem } = await Axios.post<BaseSelectListItem>(
      "/api/workSchedules/roleNames",
      data
    );

    WorkScheduleApi.roleNamesQuery.invalidate();
    return savedItem;
  }
}
