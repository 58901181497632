import React, { useCallback } from "react";
import { Row, Col, FormLabel, Button } from "react-bootstrap";
import { Field } from "react-final-form";
import { FormTextInput } from "form/components/FormTextInput";
import { FormSelect } from "form/components/FormSelect";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormFileViewer } from "form/components/FormFileViewer";
import { SupplierFormData } from "supplier/types";
import { FormCheckbox } from "form/components/FormCheckbox";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { SuppliersApi } from "supplier/SuppliersApi";
import { FileDir } from "base/types";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SuppliersRouteBuilder } from "supplier/SuppliersRouteBuilder";
import { useQuery } from "react-query";

export const GeneralTab: React.FC<{ supplierId: number }> = ({
  supplierId,
}) => {
  const { data: departments } = useQuery(SuppliersApi.departmentsQuery.build());
  const departmentOptions = useSelectOptionsAdapter(departments);
  const navigate = useNavigateCallback();

  const fieldName = (property: keyof SupplierFormData) => property;

  const onDelete = useCallback(async () => {
    if (window.confirm("האם אתה בטוח?")) {
      await SuppliersApi.deleteItem(supplierId);
      navigate(SuppliersRouteBuilder.buildSuppliersRoute(), { replace: true });
    }
  }, [navigate, supplierId]);

  return (
    <Row noGutters>
      <Col xs={12} sm={7} md={9}>
        <Row noGutters>
          <Col xs={6} sm={4} className="p-1 d-flex align-items-center">
            קוד ספק
          </Col>
          <Col xs={6} sm={6} className="p-1">
            <Field
              name={fieldName("id")}
              render={({ input: { value: id } }) => id}
            />
          </Col>
          <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
            שם
          </Col>
          <Col xs={12} sm={6} className="p-1">
            <FormTextInput name={fieldName("name")} />
          </Col>
          <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
            מספר ח"פ
          </Col>
          <Col xs={12} sm={6} className="p-1">
            <FormTextInput name={fieldName("idNumber")} />
          </Col>
          <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
            מחלקה
          </Col>
          <Col xs={12} sm={6} className="p-1">
            <FormSelect
              emptyOptionLabel="מחלקה"
              name={fieldName("departmentId")}
              options={departmentOptions}
            />
          </Col>
          <Col xs={12} sm={4} className="p-1 d-flex align-items-center">
            פעיל
          </Col>
          <Col xs={12} sm={6} className="p-1">
            <FormCheckbox name={fieldName("isActive")} />
          </Col>
        </Row>
        <Button variant="danger" className="my-1" onClick={onDelete}>
          מחיקה
        </Button>
      </Col>
      <Col xs={12} sm={5} md={3}>
        <FormLabel>תמונה</FormLabel>
        <FormFileUpload name={fieldName("image")} />
        <div style={{ maxWidth: 240 }}>
          <FormFileViewer
            pathName={fieldName("imageName")}
            uploadName={fieldName("image")}
            fileDir={FileDir.Supplier}
          />
        </div>
      </Col>
    </Row>
  );
};
