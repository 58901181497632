import axios from "axios";
import {
  Department,
  DepartmentListItem,
  DepartmentInfoFormValues,
  DepartmentAddons,
  AddDepartmentAddonFormValues,
  DepartmentAddon,
} from "./types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { omit } from "lodash";
import { ApiQuery } from "base/api/ApiQuery";
import produce from "immer";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

export class DepartmentsApi {
  static listQuery = new ApiQuery<DepartmentListItem[]>(
    "/api/departments/items"
  );

  static itemQuery = new ApiQuery<Department, number>(
    (id) => `/api/departments/${id}`
  );

  static itemAddonsQuery = new ApiQuery<DepartmentAddons, number>(
    (id) => `/api/departments/${id}/addons`
  );

  static async saveChildren(parentId: number, children: Department[]) {
    const { data: savedParentDepartment } = await axios.post<Department>(
      `/api/departments/${parentId}`,
      children
    );

    DepartmentsApi.itemQuery.updateCache(savedParentDepartment, parentId);
  }

  static async saveItemInfo(item: DepartmentInfoFormValues) {
    await axios.post(
      `/api/departments/single/${item.id}`,
      BodyFormDataBuilder.build2(item)
    );

    const department: Department = omit<DepartmentInfoFormValues, "photoFile">(
      item,
      "photoFile"
    );

    DepartmentsApi.itemQuery.updateCache(department, item.id);
    DepartmentsApi.itemQuery.invalidate(department.parentId);
  }

  static async addAddon(
    departmentId: number,
    item: AddDepartmentAddonFormValues,
    list: "materials" | "ingredients"
  ) {
    const routeByList = list === "materials" ? "material" : "ingredient";
    const { data: addedItem } = await axios.post<DepartmentAddon>(
      `/api/departments/${departmentId}/addons/${routeByList}`,
      item
    );

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((addons) => {
        if (!addons) return;

        addons[list].push(addedItem);
      }),
      departmentId
    );
  }

  static async updateAddon(
    departmentId: number,
    addon: DepartmentAddon,
    list: "materials" | "ingredients"
  ) {
    await axios.post(`/api/departmentAddons`, addon);

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((addons) => {
        if (!addons) return;

        const saveItemUpdater = QueryCacheHelper.buildSaveListItemUpdater(
          addon,
          "id"
        );

        addons[list] = saveItemUpdater(addons[list])!;
      }),
      departmentId
    );
  }

  static async deleteAddon(
    departmentId: number,
    idToDelete: number,
    list: "materials" | "ingredients"
  ) {
    await axios.delete(`/api/departmentAddons/${idToDelete}`);

    DepartmentsApi.itemAddonsQuery.updateCache(
      produce((addons) => {
        if (!addons) return;

        const deleteUpdater = QueryCacheHelper.buildDeleteListItemUpdater<
          DepartmentAddon
        >(idToDelete, "id");

        addons[list] = deleteUpdater(addons[list])!;
      }),
      departmentId
    );
  }
}
