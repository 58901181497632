import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScaleRight } from "@fortawesome/free-solid-svg-icons";
import Swiper, { ReactIdSwiperProps } from "react-id-swiper";
import { BaseImage } from "base/components/BaseFoodImage";
import { Typography } from "base/components/Typography";
import { RoundButton } from "base/components/RoundButton";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { SentOrderItemGet } from "sent-order/types";
import { SentOrderFoodItemModal } from "./SentOrderFoodItemModal";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { useBooleanState } from "base/hooks/useBooleanState";
import { Button, Spinner } from "react-bootstrap";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem, UnitType } from "base/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { OrderHelper } from "order/OrderHelper";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { BarcodeModal } from "barcode/BarcodeModal";
import produce from "immer";
import { error, success } from "@pnotify/core";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { useQuery } from "react-query";

export type SentOrderDefaultEditorEditorProps = {
  id: number;
};

type FoodItemModalState =
  | {
      show: false;
      item: undefined;
    }
  | { show: true; item: SentOrderItemGet };

export const SentOrderDefaultEditorEditor: React.FC<
  SentOrderDefaultEditorEditorProps
> = ({ id }) => {
  const navigate = useNavigateCallback();

  const { data } = useQuery(SentOrdersApi.itemQuery.build(id));

  const isOrderClosed = data?.status === 5;

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const { data: systemSettings } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<Array<{ key: string; value: number }>>()
      .build(SimpleGlobalListRoutePath.SystemSettings)
  );

  const allowedWeightDeviation = systemSettings?.find(
    (x) => x.key === "AllowedWeightDeviation"
  )?.value;

  const [foodItemModalState, setFoodItemModalState] =
    useState<FoodItemModalState>({
      show: false,
      item: undefined,
    });
  const [showBarcodeModal, onDisplayBarcodeModal, onHideBarcodeModal] =
    useBooleanState(false);

  const [markOrderReceivedProcessing, setMarkOrderReceivedProcessing] =
    useState(false);

  const onMarkOrderReceived = useCallback(async () => {
    if (markOrderReceivedProcessing) return;
    setMarkOrderReceivedProcessing(true);
    try {
      await SentOrdersApi.markOrderReceived(id);
    } finally {
      setMarkOrderReceivedProcessing(false);
    }
    navigate(SentOrdersRouteBuilder.buildSentOrdersRoute());
  }, [
    id,
    navigate,
    markOrderReceivedProcessing,
    setMarkOrderReceivedProcessing,
  ]);

  const onOpenFoodItemModal = useCallback((item: SentOrderItemGet) => {
    setFoodItemModalState({ show: true, item });
  }, []);

  const onHideFoodItemModal = useCallback(() => {
    setFoodItemModalState({ show: false, item: undefined });
  }, []);

  const onFoodItemModalSave = useCallback(
    async (modifiedItem: SentOrderItemGet) => {
      await SentOrdersApi.saveSentOrder({
        ...data!,
        items: data!.items.map((item) => {
          if (item.id !== modifiedItem.id) {
            return item;
          } else {
            return modifiedItem;
          }
        }),
      });
      onHideFoodItemModal();
    },
    [data, onHideFoodItemModal]
  );

  const params: ReactIdSwiperProps = {
    rtl: ".swiper-container-rtl",
    slidesPerView: "auto",
    spaceBetween: 30,
    slidesPerColumn: 1,
    grabCursor: true,
    centeredSlides: true,
    effect: "coverflow",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    containerClass: "swiper-container swiper-sent-order-ingredients",
  };

  if (!data) {
    return null;
  }

  const { items: ingredients } = data;

  return (
    <>
      <div>
        <Button onClick={onDisplayBarcodeModal} disabled={isOrderClosed}>
          סריקת ברקוד
        </Button>
      </div>
      <Swiper {...params}>
        {ingredients.map((item) => {
          const isItemFilled = OrderHelper.isFilled(
            item,
            allowedWeightDeviation
          );

          return (
            <div
              className={`swiper-item ${isItemFilled && "done"}`}
              key={item.id}
              onClick={() => onOpenFoodItemModal(item)}
            >
              <BaseImage
                fileName={item.imageName}
                className="swiper-item-image"
              />
              <Typography
                variant="description"
                fontSize={20}
                bold
                className="text-truncate w-100"
              >
                {item.name}
              </Typography>
              <Typography variant="description" fontSize={20} rtl={false}>
                {OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.receivedWeight)}
                {!OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.receivedAmount)}{" "}
                /{" "}
                {OrderHelper.isWeightable(item) &&
                  NumberFormatter.default.formatAmount(
                    OrderHelper.calcTotalAmount(
                      item.requestedAmount,
                      item.requestedSupplyMethod,
                      item.prepareUnitType
                    )
                  )}
                {!OrderHelper.isWeightable(item) &&
                  NumberFormatter.formatAmount(item.requestedAmount)}
              </Typography>
              <Typography variant="description" fontSize={18}>
                {!OrderHelper.isWeightable(item) &&
                  SupplyMethodDescFormatter.format(
                    item.requestedSupplyMethod,
                    item.prepareUnitType,
                    unitKinds,
                    packageKinds
                  )}
                {OrderHelper.isWeightable(item) &&
                  UnitTypeFormatter.format(UnitType.KG)}
              </Typography>
              {isItemFilled && (
                <div className="done-mark">
                  <i className="fas fa-check px-1" aria-hidden="true" />
                </div>
              )}
              <div className="floating-actions">
                {OrderHelper.isWeightable(item) && (
                  <RoundButton
                    size={32}
                    onClick={() => {}}
                    className="checked-floating-action"
                  >
                    <FontAwesomeIcon icon={faBalanceScaleRight} size="sm" />
                  </RoundButton>
                )}
              </div>
            </div>
          );
        })}
      </Swiper>
      <div className="text-center my-5">
        <Button
          size="lg"
          onClick={onMarkOrderReceived}
          disabled={markOrderReceivedProcessing}
        >
          <Spinner
            size="sm"
            animation="border"
            style={{ marginInlineStart: "10px", visibility: "hidden" }}
          />
          סיימתי
          <Spinner
            size="sm"
            animation="border"
            style={{
              marginInlineStart: "10px",
              visibility: markOrderReceivedProcessing ? "visible" : "hidden",
            }}
          />
        </Button>
      </div>
      <SentOrderFoodItemModal
        show={foodItemModalState.show}
        item={foodItemModalState.item}
        onCancel={onHideFoodItemModal}
        onDone={onFoodItemModalSave}
        disabled={isOrderClosed}
      />
      <BarcodeModal
        show={showBarcodeModal}
        onHide={onHideBarcodeModal}
        onBarcodeReceived={(barcode) => {
          console.log("Received barcode", barcode);

          const matchingItem = ingredients.find(
            (x) => barcode === x.receivedSupplyMethod.barCode
          );

          if (matchingItem) {
            onFoodItemModalSave(
              produce(matchingItem, (draft) => {
                draft.receivedAmount += 1;
              })
            );
            success({
              text: TextFormatter.format("$1 - $2: $3 $4", [
                matchingItem.name,
                "כמות עדכנית",
                matchingItem.receivedAmount + 1,
                SupplyMethodDescFormatter.formatTopPackage(
                  matchingItem.receivedSupplyMethod,
                  matchingItem.prepareUnitType,
                  unitKinds,
                  packageKinds
                ),
              ]),
              delay: 1000,
            });
          } else {
            error({ text: "לא נמצא מוצר עם ברקוד תואם", delay: 1500 });
          }
        }}
      />
    </>
  );
};
