import React from "react";
import { Field } from "react-final-form";
import { NumberInputFieldInner } from "./NumberInputFieldInner";
import { FieldValidator } from "final-form";
import { NumberInputProps } from "base/components/NumberInput";

export type FormNumberInputProps = {
  name: string;
  required?: boolean;
  validate?: FieldValidator<any>;
} & Omit<NumberInputProps, "value" | "onChange">;

export const FormNumberInput: React.FC<FormNumberInputProps> = ({
  name,
  required,
  validate,
  ...numberInputProps
}) => {
  return (
    <Field
      name={name}
      component={NumberInputFieldInner}
      validate={
        validate ??
        ((x, _, meta) => {
          if (required && x === undefined) {
            return Promise.resolve("שדה חובה");
          }
        })
      }
      {...numberInputProps}
    />
  );
};
