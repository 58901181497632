import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { UnitTypeFormatter } from "base/utils/formatters";
import { ScalesApi } from "./ScalesApi";
import "./styles.scss";
import { useScaleType } from "app/context/hooks/useScaleType";
import { useQuery } from "react-query";

export type RemoteWeightModalProps = {
  show: boolean;
  onClose: () => void;
  prepareUnitType: number;
  onWeightReceived: (weight: number) => void;
};

export const RemoteWeightModal: React.FC<RemoteWeightModalProps> = ({
  show,
  prepareUnitType,
  onClose,
  onWeightReceived,
}) => {
  const pollInterval = 500;
  const [receivedWeight, setReceivedWeight] = useState(0);
  const [isWeighting, setIsWeighting] = useState(false);
  const scaleType = useScaleType();

  const refs = useRef({
    onWeightReceived,
  });
  refs.current.onWeightReceived = onWeightReceived;

  const { data, isLoading, isFetching } = useQuery(
    ScalesApi.weightQuery.build(scaleType, {
      refetchInterval: isWeighting && !receivedWeight ? pollInterval : false,
      enabled: isWeighting,
    })
  );

  // Handle state cleanup & reset of weight.
  useEffect(() => {
    ScalesApi.weightQuery.updateCache(0, scaleType);
    setReceivedWeight(0);

    // Also make sure to reset weight on unmount.
    return () => {
      ScalesApi.weightQuery.updateCache(0, scaleType);
    };
  }, [isWeighting, scaleType]);

  // handle receiving data.
  useEffect(() => {
    if (isWeighting && !isLoading && !isFetching && !receivedWeight && data) {
      const weight = Number.parseFloat(data!["result"]);
      if (weight > 0) {
        setReceivedWeight(weight);
        refs.current.onWeightReceived(weight);
      }
    }
  }, [isWeighting, isLoading, isFetching, data, receivedWeight]);

  // closing with fade effect.
  useEffect(() => {
    if (receivedWeight > 0 && isWeighting) {
      const delayId = setTimeout(onClose, 1000);
      return () => clearTimeout(delayId);
    }
  }, [onClose, isWeighting, receivedWeight]);

  useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        setIsWeighting(true);
      }, 50);
      return () => clearTimeout(timeoutId);
    } else {
      setIsWeighting(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="RemoteWeightModal"
      onHide={onClose}
    >
      <div className="content">
        <div className="weight-title">המתן לשקילה</div>
        {receivedWeight > 0 && (
          <div className="received-data">
            סה"כ נקלט:
            <span>{receivedWeight}</span>
            <span>{UnitTypeFormatter.format(prepareUnitType)}</span>
          </div>
        )}
        {receivedWeight <= 0 && (
          <div>
            <button
              className="close-weight btn btn-warning btn-lg"
              onClick={onClose}
            >
              ביטול
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
