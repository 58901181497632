import React, { useEffect, useRef } from "react";

export type EnterKeyCodeListenerProps = {
  active?: boolean;
  preventDefault?: boolean;
  onEnter: (e: KeyboardEvent) => void;
};

export const EnterKeyCodeListener: React.FC<EnterKeyCodeListenerProps> = ({
  active = true,
  preventDefault = true,
  onEnter: onKeyup,
  children,
}) => {
  const callbackRef = useRef<EnterKeyCodeListenerProps["onEnter"]>(onKeyup);
  callbackRef.current = onKeyup;

  useEffect(() => {
    if (active) {
      const fn = function (event: KeyboardEvent) {
        if (event.keyCode === 13) {
          if (preventDefault) {
            event.preventDefault();
          }

          callbackRef.current!(event);
        }
      };

      document.addEventListener("keyup", fn);

      return () => document.removeEventListener("keyup", fn);
    }
  }, [active, preventDefault]);

  return <>{children}</>;
};
