import {
  SpeechAmountItemMatcherMatchResult,
  SpeechAmountItemMatcherParseResult,
} from "./types";

export class SpeechAmountItemMatcher {
  static parseAndMatch<T>(
    speech: string,
    items: T[] | undefined = [],
    extractText: (x: T) => string
  ): SpeechAmountItemMatcherMatchResult<T> {
    return SpeechAmountItemMatcher.match(
      SpeechAmountItemMatcher.parse(speech),
      items,
      extractText
    );
  }

  static match<T>(
    { itemText, amount }: SpeechAmountItemMatcherParseResult,
    items: T[] | undefined = [],
    extractText: (x: T) => string
  ): SpeechAmountItemMatcherMatchResult<T> {
    const formattedPhrase = itemText.trim().toLowerCase();
    const matches = items.filter((x) =>
      extractText(x).trim().toLowerCase().includes(formattedPhrase)
    );

    return {
      itemText,
      matches,
      amount,
    };
  }

  static parse(speech: string): SpeechAmountItemMatcherParseResult {
    const speechWords = speech.split(" ");

    let itemText = "";
    let amount: number | undefined;

    for (let speechWord of speechWords) {
      const possibleAmount = parseFloat(speechWord);
      if (!isNaN(possibleAmount)) {
        amount = possibleAmount;
        // Entirely skip unit type mentioning in the item name for now.
      } else if (!["גרם", "מיליגרם", "יחידות"].includes(speechWord)) {
        const prefix = itemText === "" ? "" : " ";
        itemText += `${prefix}${speechWord}`;
      }
    }

    return {
      itemText,
      amount,
    };
  }
}
