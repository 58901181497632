import { Recipe, RecipeEditorFormValues } from "./types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import { ApiQuery } from "base/api/ApiQuery";
import Axios from "axios";

export class RecipeApi {
  static itemQuery = new ApiQuery<Recipe, number>((id) => `/api/recipes/${id}`);

  static async saveItem(recipe: RecipeEditorFormValues) {
    const { data: savedRecipe } = await Axios.post<Recipe>(
      `/api/recipes/${recipe.id}`,
      BodyFormDataBuilder.build2(recipe)
    );

    RecipeApi.itemQuery.updateCache(savedRecipe, savedRecipe.id);
  }
}
