export class EmployeesRouteBuilder {
  static buildEmployeesRoute() {
    return `/employees`;
  }

  static buildAddEmployeeRoute() {
    return `/employees/new`;
  }

  static buildEditEmployeeRoute(employeeId: string) {
    return `/employees/${employeeId}`;
  }

  static buildshiftsPresenceRoute() {
    return `/employees/shifts-presence`;
  }
}
