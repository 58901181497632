import { Modal, Button, Alert } from "react-bootstrap";
import React, { useMemo, useState, useEffect } from "react";
import { UnitType, BaseSelectListItem } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { SuppliersApi } from "supplier/SuppliersApi";
import { ProductSupplyMethod } from "product/supply-method/types";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { useQuery } from "react-query";

export type SupplyMethodPickerProps = {
  show: boolean;
  supplyMethods?: ProductSupplyMethod[];
  prepareUnitType?: UnitType;
  supplierIdFilter?: number;
  onHide: () => void;
  onPick: (
    supplyMethod: BaseSupplyMethod,
    supplierId?: number,
    requestedPrice?: number
  ) => void;
};

export const SupplyMethodPicker: React.FC<SupplyMethodPickerProps> = ({
  show,
  supplyMethods,
  prepareUnitType,
  supplierIdFilter,
  onHide,
  onPick,
}) => {
  const p_show = Boolean(show && supplyMethods);

  const [selectedSupplyMet, setSelectedSupplyMet] = useState<
    ProductSupplyMethod
  >();
  const { data: suppliers } = useQuery(SuppliersApi.listQuery.build());

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds, {
        enabled: p_show,
      })
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds, {
        enabled: p_show,
      })
  );

  const p_supplyMethods = useMemo(() => {
    if (!supplyMethods) return [];
    if (supplierIdFilter === undefined) return supplyMethods;
    return supplyMethods.filter((sm) => sm.supplierId === supplierIdFilter);
  }, [supplierIdFilter, supplyMethods]);

  useEffect(() => {
    if (!p_show) {
      setSelectedSupplyMet(undefined);
    }
  }, [p_show]);

  useEffect(() => {
    if (selectedSupplyMet && selectedSupplyMet.suppliers.length === 0) {
      onPick(selectedSupplyMet);
    }

    if (selectedSupplyMet && selectedSupplyMet.suppliers.length === 1) {
      onPick(
        selectedSupplyMet,
        selectedSupplyMet.suppliers[0].supplierId,
        selectedSupplyMet.suppliers[0].price
      );
    }
  }, [onPick, selectedSupplyMet]);

  return (
    <Modal show={p_show} onHide={onHide} centered>
      <ModalTitleBar title="בחרו צורת אספקה" onClose={onHide} />
      {supplierIdFilter !== undefined &&
        (supplyMethods ?? []).length > 0 &&
        p_supplyMethods.length === 0 && (
          <Alert variant="danger" className="text-center mt-1">
            <h5 className="m-0">
              יש לאפס את הכמות בהזמנה בכדי לראות צורות אספקה נוספות
            </h5>
          </Alert>
        )}
      {p_supplyMethods.map((item) => (
        <React.Fragment key={item.id}>
          <Button
            className="m-2 btn-lg"
            onClick={() => setSelectedSupplyMet(item)}
          >
            {`${SupplyMethodDescFormatter.format(
              item,
              prepareUnitType!,
              unitKinds,
              packageKinds
            )}${
              item.supplierId !== undefined && suppliers
                ? ` - ${suppliers.find((s) => s.id === item.supplierId)!.name}`
                : ""
            }`}
          </Button>
          {selectedSupplyMet === item &&
            item.suppliers.map((itemSupplier) => (
              <Button
                variant="success"
                className="m-2"
                onClick={() =>
                  onPick(item, itemSupplier.supplierId, itemSupplier.price)
                }
              >
                {suppliers?.find((s) => s.id === itemSupplier.supplierId)?.name}
              </Button>
            ))}
        </React.Fragment>
      ))}
    </Modal>
  );
};
