import React from "react";
import "../styles/layout.scss";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { Typography } from "base/components/Typography";
import { Button, Modal } from "react-bootstrap";
import { useAppContext } from "app/context/hooks/useAppContext";
import { useRouteMatch } from "react-router-dom";
import { AuthRouteBuilder } from "auth/AuthRouteBuilder";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useBooleanState } from "base/hooks/useBooleanState";
import { EmployeesShiftsPresenceScreen } from "employee/screens/EmployeesShiftsPresenceScreen";

export const TopBar: React.FC = () => {
  const payload = useTokenPayload();
  const { onSignOut } = useAppContext();
  // TODO - not accurate
  const isAnonymous = useRouteMatch([
    AuthRouteBuilder.buildLoginRoute(),
    SentOrdersRouteBuilder.templates.Viewer,
  ]);
  const [
    showShiftsPresence,
    onShowShiftsPresence,
    onHideShiftsPresence,
  ] = useBooleanState(false);

  return (
    <div className="TopBar">
      {payload && !isAnonymous && (
        <div className="user-text-section">
          <Typography
            className="text-white"
            variant="description"
            fontSize={20}
          >
            שלום {payload?.name}
          </Typography>
          <div className="mx-2">
            <Button variant="danger" className="btn-block" onClick={onSignOut}>
              התנתקות
            </Button>
          </div>
        </div>
      )}
      <div className="actions-section">
        <i
          className="fas fa-user-clock text-white"
          role="button"
          onClick={onShowShiftsPresence}
        />
      </div>
      <Modal
        show={showShiftsPresence}
        onHide={onHideShiftsPresence}
        centered
        size="xl"
      >
        <EmployeesShiftsPresenceScreen />
      </Modal>
    </div>
  );
};
