import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { ReactComponent as SentOrderIcon } from "route-content/assets/RouteContentSentOrder.svg";
import "sent-order/styles/SentOrderScanScreen.scss";
import { useQuery } from "react-query";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { useParams, useHistory } from "react-router-dom";
import { RouteParams, FileDir } from "base/types";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";
import { BaseImage } from "base/components/BaseFoodImage";
import { Form } from "form/components/Form";
import { FormSelect } from "form/components/FormSelect";
import { OrderDocumentTypeOptions } from "sent-order/constants";
import { FormTextInput } from "form/components/FormTextInput";
import { SentOrderFormValues } from "sent-order/types";
import { Button } from "react-bootstrap";
import { ReactComponent as WhiteCameraIcon } from "base/assets/WhiteCamera.svg";
import { Field } from "react-final-form";
import { FormMetaError } from "form/components/FormMetaError";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";

//TODO probably not used anymore
export const SentOrderScanScreen: React.FC = () => {
  const history = useHistory();
  const { orderId: idStr } = useParams<RouteParams<"orderId">>();
  const id = Number(idStr);

  const { data } = useQuery(SentOrdersApi.itemQuery.build(id));

  const fieldName = (property: keyof SentOrderFormValues) => property;

  return (
    <RouteContent className="SentOrderScanScreen">
      <RouteContentTopBar
        title="קליטת הזמנה - הזמנות להיום"
        titleIcon={<SentOrderIcon height={24} />}
      />
      <div className="card">
        <div className={"header d-flex align-items-start"}>
          <div className="mx-2">
            <div className="label font-weight-bolder h4 mt-1 mb-0">
              {data?.supplierName}
            </div>
            <div>
              <span className="label">מספר פריטים בהזמנה: </span>{" "}
              <span className="label font-weight-bolder">
                {data?.items.length}
              </span>
            </div>
          </div>
          <ImageBackgroundMix key={data?.supplierImageName} className="mr-auto">
            <BaseImage
              fileDir={FileDir.Supplier}
              fileName={data?.supplierImageName}
            />
          </ImageBackgroundMix>
        </div>
        <Form<SentOrderFormValues> onSubmit={() => {}}>
          {({ handleSubmit }) => (
            <div className="sectionContent">
              <div className="label">בחירת סוג מסמך</div>
              <FormSelect
                name={fieldName("orderDocumentType")}
                options={OrderDocumentTypeOptions}
                variant="react-select"
                emptyOptionLabel="סוג מסמך"
              />
              <div className="mt-3 label">הקלדת מספר אסמכתא</div>
              <FormTextInput
                name={fieldName("referenceNo")}
                placeholder="מספר אסמכתא"
              />
              <div className="mt-3 label">צילום חשבונית</div>
              <Field
                name={fieldName("receiptPhoto")}
                validate={FieldsValidator.required}
                render={({ input, meta }) => (
                  <>
                    <Button className="cameraButton">
                      <WhiteCameraIcon height={24} />
                      &nbsp; צילום חשבונית
                      <input
                        type="file"
                        capture
                        accept="image/*"
                        onChange={(x) => {
                          if (x.target.files!.length > 0) {
                            input.onChange(x.target.files![0]);
                          }
                        }}
                      />
                    </Button>
                    <FormMetaError meta={meta} />
                    <div
                      className="mt-1 h6"
                      role="button"
                      onClick={() => {
                        history.push(
                          SentOrdersRouteBuilder.buildEditSentOrderRoute(id)
                        );
                      }}
                    >
                      <u>המשך ללא צילום החשבונית</u>
                    </div>
                  </>
                )}
              />
              <div className="mt-5 d-flex justify-content-center">
                <Button
                  // onClick={handleSubmit}
                  onClick={() => {
                    history.push(
                      SentOrdersRouteBuilder.buildEditSentOrderRoute(id)
                    );
                  }}
                >
                  סיימתי!
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </RouteContent>
  );
};
