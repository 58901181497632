import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import { OrdersApi } from "order/OrdersApi";
import { PickIngredients } from "order/components/PickIngredients";
import { Button } from "react-bootstrap";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { useObserver } from "mobx-react";
import { OrderSupplierInfoCard } from "order/components/OrderSupplierInfoCard";
import { SpeechAmountItemMatcher } from "speech/SpeechAmountItemMatcher";
import { IngredientsHelper } from "ingredient/IngredientsHelper";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { useQuery } from "react-query";

export const SupplierScreen: React.FC = () => {
  const { supplierId: supplierIdStr } = useParams();
  const supplierId = Number(supplierIdStr);
  const location = useLocation();

  const navigate = useNavigateCallback();

  const { store, setRouteSearchPhrase } = useAddOrderContext();
  const [searchPhrase, itemSpeechPhrase] = useObserver(() => [
    store.routeSearchPhrases[location.pathname] ?? "",
    store.itemSpeechPhrase,
  ]);

  const { data, isLoading } = useQuery(
    OrdersApi.itemBySupplierQuery.build(supplierId)
  );

  // Also Prefetch data for better UX when navigation to OrdersStatusScreen.
  useQuery(
    OrdersApi.detailedListQuery.build({
      enabled: data?.orderId !== undefined,
    })
  );

  const [totalOrderPrice, setTotalOrderPrice] = useState(0);

  const filteredIngredients = useMemo(() => {
    if (!searchPhrase) return data?.ingredients;

    const formattedPhrase = searchPhrase.trim().toLowerCase();

    return data?.ingredients.filter(
      (item) =>
        IngredientsHelper.concatNameAndAlternativeNames(item)
          .trim()
          .toLowerCase()
          .indexOf(formattedPhrase) !== -1
    );
  }, [data, searchPhrase]);

  useEffect(() => {
    if (itemSpeechPhrase) {
      const {
        itemText,
        matches,
        amount,
      } = SpeechAmountItemMatcher.parseAndMatch(
        itemSpeechPhrase,
        data?.ingredients,
        (x) => IngredientsHelper.concatNameAndAlternativeNames(x)
      );

      // If no matches / more matches than 1, or amount not stated, set the search.
      /* The part that takes care of picking specific item and setting its amount via speech
          located at PickIngredientItem(sadly) */
      if (matches.length !== 1 || !amount) {
        setRouteSearchPhrase(location.pathname, itemText);
      }
    }
  }, [data, location.pathname, setRouteSearchPhrase, itemSpeechPhrase]);

  return (
    <RouteContent className="OrderSupplierScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      <OrderSupplierInfoCard
        supplierId={supplierId}
        totalOrderPrice={totalOrderPrice}
      />
      {data?.orderId !== undefined && (
        <div className="text-center my-2">
          <Button
            onClick={() =>
              navigate(OrdersRouteBuilder.buildOrdersStatusRoute(data.orderId!))
            }
          >
            סיימתי ללקט
          </Button>
        </div>
      )}
      <PickIngredients
        ingredients={filteredIngredients}
        onTotalOrderPriceCalc={setTotalOrderPrice}
        isLoading={isLoading}
      />
    </RouteContent>
  );
};
