import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { RouteContent } from "route-content/components/RouteContent";
import { PreparationTasksTab } from "./preparation-tasks/PreparationTasksTab";
import { Typography } from "base/components/Typography";

enum NavItem {
  Cleaning = "Cleaning",
  PreparationTasks = "PreparationTasks",
  Tasks = "Tasks",
}

export const ChefsHomeScreen: React.FC = () => {
  const [selectedNavItem, setSelectedNavItem] = useState(
    NavItem.PreparationTasks
  );

  return (
    <RouteContent>
      <div className="text-center">
        <Typography variant="route-title" fontSize={70}>
          המשימות שלי
        </Typography>
        <Tabs
          activeKey={selectedNavItem}
          id="ChefsHomeScreen_Tabs"
          onSelect={setSelectedNavItem as any}
          className="mt-2 justify-content-center"
          style={{ fontSize: 34 }}
        >
          <Tab title="ניקיון" eventKey={NavItem.Cleaning}>
            ניקיון
          </Tab>
          <Tab title="הכנות" eventKey={NavItem.PreparationTasks}>
            <PreparationTasksTab />
          </Tab>
          <Tab title="הזמנות" eventKey={NavItem.Tasks}>
            הזמנות
          </Tab>
        </Tabs>
      </div>
    </RouteContent>
  );
};
