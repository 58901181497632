import React from "react";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { ContractsApi } from "contract/ContractsApi";
import { ContractForm } from "./ContractForm";
import { Form } from "form/components/Form";
import { Contract } from "contract/types";
import { useSaveContractCallback } from "contract/hooks/useSaveContractCallback";
import { useQuery } from "react-query";

export type ContractExistingItemEditorProps = {
  id: number;
};

export const ContractExistingItemEditor: React.FC<ContractExistingItemEditorProps> = ({
  id,
}) => {
  const { data, isLoading, isFetching } = useQuery(
    ContractsApi.itemQuery.build(id)
  );

  useFullScreenLoader(isLoading || isFetching);

  const onSave = useSaveContractCallback();

  return (
    <Form<Contract>
      onSubmit={onSave}
      initialValues={data}
      component={ContractForm}
    />
  );
};
