import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ScrollToTopHandler = () => {
  const { pathname, state } = useLocation<
    { preventScrollTop?: boolean } | undefined
  >();

  useEffect(() => {
    if (state?.preventScrollTop) return;

    window.scrollTo(0, 0);
  }, [pathname, state?.preventScrollTop]);

  return null;
};
