import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { pick } from "lodash";

export class SupplyMethodUtils {
  static generateOptionValue(baseSupplyMethod: BaseSupplyMethod) {
    return JSON.stringify(
      pick(
        baseSupplyMethod,
        "isLargeUnit",
        "supplyUnitType",
        "unitKindId",
        "unitWeight"
      )
    );
  }
}
