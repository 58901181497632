export class ProductsRouteBuilder {
  static buildListRoute() {
    return "/products";
  }

  static buildAddRoute(initialIngredientId?: number) {
    const searchQuery = initialIngredientId
      ? `?initialIngredientId=${initialIngredientId}`
      : "";

    return `/products/new${searchQuery}`;
  }

  static buildEditRoute(id: number) {
    return `/products/${id}`;
  }
}
