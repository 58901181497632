import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import {
  SimpleGlobalListRoutePath,
  PackageWeightListItem,
} from "global-list/types";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { Typography } from "base/components/Typography";
import { useBooleanState } from "base/hooks/useBooleanState";
import { NumpadModal } from "base/components/NumpadModal/NumpadModal";
import { useQuery } from "react-query";

export interface PackageWeightsPickerProps {
  show: boolean;
  onPick: (listItem: PackageWeightListItem, amount: number) => void;
  onHide: () => void;
}

export const PackageWeightsPicker: React.FC<PackageWeightsPickerProps> = ({
  show,
  onPick,
  onHide,
}) => {
  const { data: packageWeightList } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<PackageWeightListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageWeights)
  );

  const [selectedItem, setSelectedItem] = useState<PackageWeightListItem>();
  const [showNumpad, onOpenNumpad, onHideNumpad] = useBooleanState(false);

  /* Open numpad once item selected. */
  useEffect(() => {
    if (selectedItem) {
      onOpenNumpad();
    }
  }, [onOpenNumpad, selectedItem]);

  /* Handle reset numpad state. */
  useEffect(() => {
    if (!show) {
      onHideNumpad();
    }
  }, [onHideNumpad, show]);

  /* Handle reset selected item state. */
  useEffect(() => {
    if (!showNumpad) {
      setSelectedItem(undefined);
    }
  }, [showNumpad]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <ModalTitleBar title="בחר אריזה" onClose={onHide} />
        <div className="p-2 d-flex">
          {packageWeightList?.map((weightItem) => (
            <div
              key={weightItem.id}
              role="button"
              onClick={() => setSelectedItem(weightItem)}
              className="m-2"
            >
              <BaseImage
                fileDir={FileDir.PackageWeight}
                fileName={weightItem.imageName}
                style={{ width: 120, height: 120 }}
              />
              <div className="text-center">
                <Typography variant="description">{weightItem.name}</Typography>
              </div>
              <div className="text-center">
                <Typography variant="description">
                  {weightItem.weight} גרם
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <NumpadModal
        title={`כמה אריזות? (${selectedItem?.name})`}
        show={showNumpad}
        onCancel={onHideNumpad}
        onDone={(amount) => {
          onPick(selectedItem!, amount);
          onHide();
        }}
      />
    </>
  );
};
