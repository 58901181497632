import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import { OrdersApi } from "order/OrdersApi";
import { PickIngredients } from "order/components/PickIngredients";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import { OrderDepartmentInfoCard } from "order/components/OrderDepartmentInfoCard";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { useObserver } from "mobx-react";
import { SpeechAmountItemMatcher } from "speech/SpeechAmountItemMatcher";
import { IngredientsHelper } from "ingredient/IngredientsHelper";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { useQuery } from "react-query";

export const DepartmentScreen: React.FC = () => {
  const { departmentId: departmentIdStr } = useParams();
  const departmentId = Number(departmentIdStr);
  const location = useLocation();

  const { store, setRouteSearchPhrase } = useAddOrderContext();
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);

  const [searchPhrase, itemSpeechPhrase] = useObserver(() => [
    store.routeSearchPhrases[location.pathname] ?? "",
    store.itemSpeechPhrase,
  ]);

  const { data, isLoading } = useQuery(
    OrdersApi.departmentQuery.build(departmentId)
  );

  const filteredIngredients = useMemo(() => {
    if (!searchPhrase) return data?.ingredients;

    const formattedPhrase = searchPhrase.trim().toLowerCase();

    return data?.ingredients.filter(
      (item) =>
        IngredientsHelper.concatNameAndAlternativeNames(item)
          .trim()
          .toLowerCase()
          .indexOf(formattedPhrase) !== -1
    );
  }, [data, searchPhrase]);

  useEffect(() => {
    if (itemSpeechPhrase) {
      const {
        itemText,
        matches,
        amount,
      } = SpeechAmountItemMatcher.parseAndMatch(
        itemSpeechPhrase,
        data?.ingredients as any[],
        (x) => IngredientsHelper.concatNameAndAlternativeNames(x)
      );

      // If no matches / more matches than 1, or amount not stated, set the search.
      /* The part that takes care of picking specific item and setting its amount via speech
          located at PickIngredientItem(sadly) */
      if (matches.length !== 1 || !amount) {
        setRouteSearchPhrase(location.pathname, itemText);
      }
    }
  }, [data, location.pathname, setRouteSearchPhrase, itemSpeechPhrase]);

  return (
    <RouteContent className="OrderDepartmentScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      <OrderDepartmentInfoCard
        departmentId={departmentId}
        totalOrderPrice={totalOrderPrice}
      />
      <PickIngredients
        ingredients={filteredIngredients}
        onTotalOrderPriceCalc={setTotalOrderPrice}
        isLoading={isLoading}
      />
    </RouteContent>
  );
};
