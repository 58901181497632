import React, { useMemo, useState } from "react";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { PreparationTasksApi } from "./PreparationTasksApi";
import { PreparationTaskStatus, AddPreparationTaskFormValues } from "./types";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { Typography } from "base/components/Typography";
import { MaterialsApi } from "material/MaterialsApi";
import { UnitTypeFormatter } from "base/utils/formatters";
import { RoundPlusButton } from "base/components/RoundPlusButton";
import { useBooleanState } from "base/hooks/useBooleanState";
import { Form } from "form/components/Form";
import "./style.scss";
import { FormSelect } from "form/components/FormSelect";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { FormNumberInput } from "form/components/FormNumberInput";
import { PreparationTasksRouteBuilder } from "./PreparationTasksRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { FormSpy, Field } from "react-final-form";
import { FieldsValidator } from "form/validators/FieldsValidator";
import { DatePicker } from "base/components/DatePicker";
import { FormMetaError } from "form/components/FormMetaError";
import { useTokenPayload } from "auth/hooks/useTokenPayload";
import { Role } from "auth/types";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { useQuery } from "react-query";
import { skipToken } from "base/api/ApiQuery";

export type PreparationTasksTabProps = {};

export const PreparationTasksTab: React.FC<PreparationTasksTabProps> = () => {
  const navigate = useNavigateCallback();
  const tokenPayload = useTokenPayload();
  const [selectedModalMaterialId, setSelectedModalMaterialId] = useState<
    number
  >();

  const { data: items } = useQuery(PreparationTasksApi.listQuery.build());

  const { data: materialList } = useQuery(MaterialsApi.listQuery.build());

  const { data: selectedMaterial } = useQuery(
    MaterialsApi.itemQuery.build(selectedModalMaterialId ?? skipToken)
  );

  const materialOptions = useSelectOptionsAdapter(materialList);

  const [displayAddModal, onShowAddModal, onHideAddModal] = useBooleanState(
    false
  );

  const openTasks = useMemo(
    () =>
      items?.filter((item) => item.status !== PreparationTaskStatus.Completed),
    [items]
  );

  return (
    <div className="preparation-task-screen">
      <div className="d-flex align-items-center my-3 justify-content-center">
        <Typography variant="description" bold fontSize={24}>
          {items !== undefined
            ? TextFormatter.format("רק עוד $1 מתוך $2 הכנות", [
                openTasks!.length,
                items.length,
              ])
            : ""}
        </Typography>
        <RoundPlusButton onClick={onShowAddModal} />
      </div>
      <Row noGutters>
        {items?.map((item) => (
          <Col key={item.id} xs={6} sm={4} className="p-1">
            <Card
              className="d-flex flex-column align-items-center"
              role="button"
              onClick={() =>
                navigate(
                  PreparationTasksRouteBuilder.buildPreparationTaskRoute(
                    item.id
                  )
                )
              }
            >
              <div
                className="position-absolute d-flex w-100 mt-2 px-2"
                style={{ fontSize: 22 }}
              >
                <i
                  className="fas fa-trash text-danger ml-auto"
                  aria-hidden="true"
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (window.confirm("האם אתה בטוח?")) {
                      await PreparationTasksApi.deleteItem(item.id);
                    }
                  }}
                />
                {item.status === PreparationTaskStatus.Created && (
                  <i className="fas fa-smile mr-auto" aria-hidden="true" />
                )}
                {item.status === PreparationTaskStatus.InProcess && (
                  <i className="fas fa-user-clock mr-auto" aria-hidden="true" />
                )}
              </div>
              <BaseImage
                fileName={item.imageName}
                fileDir={FileDir.BaseFood}
                height={150}
                className="mt-5"
              />
              <Typography variant="description" fontSize={32} bold>
                {item.materialName}
              </Typography>
              <Typography variant="description" fontSize={32}>
                {TextFormatter.format("$1 $2", [
                  item.requestedAmount,
                  UnitTypeFormatter.format(item.unitType),
                ])}
              </Typography>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        dialogClassName="preparation-task-screen-modal"
        show={displayAddModal}
        onHide={onHideAddModal}
        centered
        size="sm"
      >
        <ModalTitleBar title="הכנה חדשה" onClose={onHideAddModal} />
        <Form
          onSubmit={async (values: AddPreparationTaskFormValues) => {
            await PreparationTasksApi.addItem(values);
            onHideAddModal();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Row noGutters>
                <Col xs={12} className="p-1">
                  <FormSelect
                    name="materialId"
                    emptyOptionLabel="חומר גלם מעובד"
                    options={materialOptions}
                    variant="react-select"
                    validate={FieldsValidator.required}
                  />
                </Col>
                {tokenPayload?.role === Role.Manager && (
                  <Col xs={12} className="p-1">
                    <Field
                      name="transactionDate"
                      render={({ input, meta }) => (
                        <>
                          <DatePicker {...input} />
                          <FormMetaError meta={meta} />
                        </>
                      )}
                    />
                  </Col>
                )}
                <Col xs={12} className="p-1">
                  <FormNumberInput
                    name="requestedAmount"
                    inputMode="numeric"
                    placeholder={
                      selectedMaterial?.unitType !== undefined
                        ? TextFormatter.format("כמות להכנה ($1)", [
                            UnitTypeFormatter.format(selectedMaterial.unitType),
                          ])
                        : "כמות להכנה"
                    }
                    disabled={selectedMaterial === undefined}
                    dir="rtl"
                    validate={FieldsValidator.required}
                  />
                </Col>
                <Col xs={12} className="p-1 mt-3">
                  <Button
                    size="lg"
                    color="primary"
                    onClick={handleSubmit}
                    className="w-100"
                  >
                    הוספה
                  </Button>
                </Col>
              </Row>
              <FormSpy<AddPreparationTaskFormValues>
                subscription={{ values: true }}
                onChange={({ values: { materialId } }) => {
                  setSelectedModalMaterialId(materialId);
                }}
              />
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};
