import React, { useState, useEffect, useCallback } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import {
  FormControl,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "stock/styles.scss";
import { StocksApi } from "stock/StocksApi";
import { SpeechModal } from "speech/SpeechModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { SpeechAmountItemMatcherParseResult } from "speech/types";
import { SpeechAmountItemMatcher } from "speech/SpeechAmountItemMatcher";
import { useImmer } from "use-immer";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import {
  StockRemovalSearchItem,
  StockRemovalRequestReason,
  StockRemovalRequest,
} from "stock/types";
import { ScalesButton } from "base/components/ScalesButton";
import { RemoteWeightModal } from "scales/RemoteWeightModal";
import { NumberInput } from "base/components/NumberInput";
import { UnitTypeFormatter } from "base/utils/formatters";
import { Select } from "base/components/Select";
import { StockRemovalRequestReasonOptions } from "stock/constants";
import { success } from "@pnotify/core";
import { useQuery } from "react-query";

type ModalState = {
  show: boolean;
  type: "amount" | "reason";
  item: StockRemovalSearchItem | undefined;
  reason?: StockRemovalRequestReason;
  amount?: number;
};

export const StockRemovalRequestScreen: React.FC = () => {
  const [inputSearchPhrase, setInputSearchPhrase] = useState("");

  const [speechParseResults, setSpeechParseResults] = useState<
    SpeechAmountItemMatcherParseResult
  >();

  const [searchPhrase, setSearchPhrase] = useState("");

  const [
    showSpeechModal,
    onShowSpeechModal,
    onHideSpeechModal,
  ] = useBooleanState(false);

  const [
    showRemoteWeight,
    onShowRemoteWeight,
    onHideRemoteWeight,
  ] = useBooleanState(false);

  const [modalState, updateModalState] = useImmer<ModalState>({
    show: false,
    type: "amount",
    item: undefined,
  });

  const onHideModal = useCallback(
    () =>
      updateModalState((m) => {
        m.show = false;
      }),
    [updateModalState]
  );

  const {
    data: searchResults,
    isLoading: isLoadingSearch,
    isFetching: isFetchingSearch,
  } = useQuery(
    StocksApi.stockRemovalSearchQuery.build(searchPhrase, {
      enabled: searchPhrase.length > 0,
    })
  );

  const onPickItem = useCallback(
    (item: StockRemovalSearchItem) => {
      updateModalState((m) => {
        m.show = true;
        m.item = item;
      });
    },
    [updateModalState]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchPhrase(inputSearchPhrase);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [inputSearchPhrase]);

  // Handle speech results actions
  useEffect(() => {
    if (speechParseResults) {
      setInputSearchPhrase(speechParseResults.itemText);
      setSearchPhrase(speechParseResults.itemText); // Set search phrase right away.
      updateModalState((m) => {
        m.amount = speechParseResults.amount;
        if (speechParseResults.amount) {
          m.type = "reason";
        }
      });
    }
  }, [speechParseResults, updateModalState]);

  // Handle auto open modal when single item is found via speech
  useEffect(() => {
    if (
      searchResults?.length === 1 &&
      speechParseResults &&
      speechParseResults.itemText === searchPhrase &&
      !isLoadingSearch &&
      !isFetchingSearch
    ) {
      onPickItem(searchResults[0]);
    }
  }, [
    isLoadingSearch,
    isFetchingSearch,
    searchPhrase,
    searchResults,
    speechParseResults,
    onPickItem,
  ]);

  // Handle reset modal state uppon hide
  useEffect(() => {
    if (!modalState.show) {
      updateModalState((m) => {
        m.type = "amount";
        m.amount = undefined;
        m.reason = undefined;
      });
    }
  }, [modalState.show, updateModalState]);

  return (
    <RouteContent className="StockRemovalRequestScreen">
      <Typography variant="route-title">זריקת מלאי</Typography>
      <div className="d-flex align-items-center position-relative">
        <i className="fas fa-search input-icon right-side" />
        <FormControl
          value={inputSearchPhrase}
          onChange={(e) => {
            setInputSearchPhrase(e.target.value);
          }}
          placeholder="חיפוש מרכיבים / חומרים"
          className="filter-input"
        />
        <i
          className="fas fa-microphone input-icon left-side"
          role="button"
          onClick={onShowSpeechModal}
        />
      </div>
      <div className="mt-2 d-flex flex-wrap">
        {searchResults?.map((item, i) => (
          <OverlayTrigger
            key={i}
            placement="bottom-start"
            overlay={
              <Tooltip id={`search-item-tooltip-${i}`}>{item.name}</Tooltip>
            }
          >
            <div
              onClick={() => onPickItem(item)}
              className="searchItem d-flex flex-column align-items-center m-2"
              role="button"
            >
              <BaseImage
                fileDir={FileDir.BaseFood}
                fileName={item.imageName}
                style={{ width: "100%", height: 120 }}
              />

              <div className="text-truncate w-100 text-center p-2">
                {item.name}
              </div>
            </div>
          </OverlayTrigger>
        ))}
      </div>
      <SpeechModal
        show={showSpeechModal}
        onHide={onHideSpeechModal}
        onSpeech={(speechPhrase) => {
          if (speechPhrase) {
            setSpeechParseResults(SpeechAmountItemMatcher.parse(speechPhrase));
          } else {
            setSpeechParseResults(undefined);
          }
        }}
        loadingText={"נא לומר את שם המרכיב או החומר והכמות הרצויה"}
      />
      <Modal
        show={modalState.show && modalState.type === "amount"}
        onHide={onHideModal}
        centered
      >
        <ModalTitleBar
          title="הזנת משקל"
          icon={
            <BaseImage
              fileDir={FileDir.BaseFood}
              fileName={modalState.item?.imageName}
            />
          }
          onClose={onHideModal}
        />
        <div className="p-3 text-center">
          <NumberInput
            value={modalState.amount}
            onChange={(amount) => {
              updateModalState((m) => {
                m.amount = amount;
              });
            }}
            placeholder={`הזנת כמות (${UnitTypeFormatter.format(
              modalState.item?.prepareUnitType
            )})`}
            selectOnFocus
          />
          <div className="my-3">
            <ScalesButton text="לחצו לשקילה" onClick={onShowRemoteWeight} />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              disabled={!modalState.amount}
              onClick={() => {
                updateModalState((m) => {
                  m.type = "reason";
                });
              }}
            >
              הבא
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={modalState.show && modalState.type === "reason"}
        onHide={onHideModal}
        centered
      >
        <ModalTitleBar
          title="סיבת הוצאה מהמלאי"
          icon={
            <BaseImage
              fileDir={FileDir.BaseFood}
              fileName={modalState.item?.imageName}
            />
          }
          onClose={onHideModal}
        />
        <div className="p-3">
          <Select
            options={StockRemovalRequestReasonOptions}
            value={modalState.reason}
            onChange={(reason) => {
              updateModalState((m) => {
                m.reason = reason;
              });
            }}
            sortAsc={false}
          />
          <div className="d-flex justify-content-end mt-3">
            <Button
              className="mx-2"
              onClick={() => {
                updateModalState((m) => {
                  m.type = "amount";
                });
              }}
            >
              הקודם
            </Button>
            <Button
              disabled={modalState.reason === undefined}
              variant="danger"
              onClick={async () => {
                const data: StockRemovalRequest = {
                  amount: modalState.amount!,
                  reason: modalState.reason!,
                  ingredientId: modalState.item!.ingredientId,
                  materialId: modalState.item!.materialId,
                };
                onHideModal();
                await StocksApi.removeFromStock(data);
                success("המלאי נזרק");
              }}
            >
              אישור זריקת מלאי
            </Button>
          </div>
        </div>
      </Modal>
      <RemoteWeightModal
        show={showRemoteWeight}
        prepareUnitType={modalState.item?.prepareUnitType ?? 0}
        onClose={onHideRemoteWeight}
        onWeightReceived={(weight) => {
          updateModalState((m) => {
            m.amount = weight;
          });
        }}
      />
    </RouteContent>
  );
};
