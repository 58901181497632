import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RouteParams, TreeType, UnitType } from "base/types";
import { RouteContent } from "route-content/components/RouteContent";
import { PreparationTasksApi } from "./PreparationTasksApi";
import { PreparationTaskStatus } from "./types";
import { useRefreshInterval } from "base/hooks/useRefreshInterval";
import { differenceInMilliseconds, format, setMilliseconds } from "date-fns";
import { RecipeModal } from "recipe/components/RecipeModal";
import { useBooleanState } from "base/hooks/useBooleanState";
import { startOfDay } from "date-fns";
import classNames from "classnames";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { UnitTypeFormatter } from "base/utils/formatters";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { Form } from "form/components/Form";
import { FormNumberInput } from "form/components/FormNumberInput";
import { ScalesButton } from "base/components/ScalesButton";
import { RemoteWeightModal } from "scales/RemoteWeightModal";
import { ChefsRouteBuilder } from "chefs/ChefsHomeRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { LabelsPrinterModal } from "labels-printer/LabelsPrinterModal";
import { useQuery } from "react-query";

export const PreparationTaskScreen: React.FC = () => {
  const history = useHistory();
  const navigate = useNavigateCallback();

  const { id: idStr } = useParams<RouteParams<"id">>();
  const id = Number(idStr);
  const { data: list } = useQuery(PreparationTasksApi.listQuery.build());

  const item = useMemo(() => list?.find((x) => x.id === id), [id, list]);

  const [doneItemFinalAmount, setDoneItemFinalAmount] = useState<number>();

  const [showRecipe, onShowRecipe, onHideRecipe] = useBooleanState(false);

  const [
    showLabelsPrinter,
    onShowLabelsPrinter,
    onHideLablesPrinter,
  ] = useBooleanState(false);

  const [showSetUnits, onShowSetUnits, onHideSetUnits] = useBooleanState(false);

  const [showSetWeight, onShowSetWeight, onHideSetWeight] = useBooleanState(
    false
  );

  const [
    showRemoteWeight,
    onShowRemoteWeight,
    onHideRemoteWeight,
  ] = useBooleanState(false);

  // refresh every second if status is completed.
  useRefreshInterval(1000, item?.status === PreparationTaskStatus.InProcess);

  // Handle done rest call.
  useEffect(() => {
    if (doneItemFinalAmount !== undefined) {
      let active = true;
      (async () => {
        await PreparationTasksApi.setDone(id, doneItemFinalAmount);

        if (active) {
          navigate(ChefsRouteBuilder.buildChefsHomeRoute(), {
            replace: true,
          });
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [doneItemFinalAmount, id, navigate]);

  return (
    <RouteContent>
      {history.length > 2 && (
        <i
          className="fas fa-arrow-circle-right fa-3x text-primary"
          role="button"
          onClick={history.goBack}
        />
      )}
      <p className="h3 text-center">{item?.materialName}</p>
      <p className="h5 text-center">
        {TextFormatter.format("$1 $2", [
          item?.requestedAmount ?? "",
          UnitTypeFormatter.format(item?.unitType),
        ])}
      </p>
      <div
        className={classNames(
          "flex-grow-1",
          "d-flex",
          "flex-column",
          "justify-content-around",
          "align-items-center"
        )}
      >
        {item?.status === PreparationTaskStatus.Created && (
          <div
            role="button"
            className="p-5 rounded bg-primary text-white text-center"
            style={{ width: 350 }}
            onClick={() => PreparationTasksApi.setStart(id)}
          >
            <h2>יאללה, התחלתי</h2>
          </div>
        )}
        {item?.status === PreparationTaskStatus.InProcess && (
          <div
            role="button"
            className="p-4 rounded bg-primary text-white text-center"
            style={{ width: 400 }}
            onClick={
              item.unitType === UnitType.Yeh ? onShowSetUnits : onShowSetWeight
            }
          >
            <h3>סיימתי להכין</h3>
            <h2>
              {format(
                setMilliseconds(
                  startOfDay(Date.now()),
                  differenceInMilliseconds(
                    Date.now(),
                    new Date(item.startDateTime!)
                  )
                ),
                "HH:mm:ss"
              )}
            </h2>
          </div>
        )}
        {item?.recipeId !== undefined && (
          <>
            <h2
              role="button"
              className="p-5 rounded bg-primary text-white text-center"
              style={{ width: 350 }}
              onClick={onShowRecipe}
            >
              לצפיה במתכון
            </h2>
            <RecipeModal
              treeType={TreeType.Material}
              show={showRecipe}
              onHide={onHideRecipe}
              id={item.recipeId}
              isCanEdit={false}
              materialData={{
                id: item.materialId,
                initialTotalAmount: item.requestedAmount * 1000,
              }}
            />
          </>
        )}
      </div>
      <LabelsPrinterModal
        show={showLabelsPrinter}
        onHide={onHideLablesPrinter}
        itemName={"[NAME]"}
        variant="preparationTasks"
      />
      <Modal show={showSetUnits} onHide={onHideSetUnits} centered>
        <ModalTitleBar
          title={`כמה יחידות של ${item?.materialName} הכנת?`}
          onClose={onHideSetUnits}
        />
        <div className="p-2">
          <Form
            onSubmit={({ amount }: { amount: number }) => {
              setDoneItemFinalAmount(amount);
            }}
            subscription={{ valid: true }}
          >
            {({ handleSubmit, valid }) => (
              <>
                <Row noGutters className="justify-content-center">
                  <Col xs={12} sm={8}>
                    <FormNumberInput
                      name="amount"
                      placeholder="כמות יחידות"
                      required
                    />
                  </Col>
                </Row>
                <div className="m-3 text-center">
                  <Button onClick={onShowLabelsPrinter}>הדפסת מדבקות</Button>
                </div>
                <div className="my-3 text-center">
                  <Button
                    variant="warning"
                    onClick={handleSubmit}
                    disabled={!valid}
                  >
                    סיימתי
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
      <Modal show={showSetWeight} onHide={onHideSetWeight} centered size="lg">
        <ModalTitleBar
          title={`נא להניח את ה${item?.materialName} וללחוץ שקילה`}
          onClose={onHideSetWeight}
        />
        <div className="p-2 text-center">
          <Form<{
            receivedWeights: number[];
          }>
            onSubmit={({ receivedWeights }) => {
              setDoneItemFinalAmount(
                receivedWeights.reduce((sum, x) => sum + x, 0)
              );
            }}
            validate={(values) => {
              if (
                !values.receivedWeights ||
                values.receivedWeights.length === 0
              ) {
                return { receivedWeights: "ערך לא תקין" };
              }
              return {};
            }}
            subscription={{ valid: true, values: true }}
          >
            {({ handleSubmit, valid, form, values }) => (
              <>
                <div className="my-3">
                  <ScalesButton text="שקילה" onClick={onShowRemoteWeight} />
                </div>
                <div className="my-3">
                  <div className="font-weight-bold h4">משקלים שנקלטו</div>
                  <div className="d-flex flex-column align-items-center">
                    {values.receivedWeights?.map((weight, index) => (
                      <div
                        className="d-flex justify-content-between h6 my-1"
                        style={{ width: 200 }}
                      >
                        <div>
                          <span data-weight>{weight}</span>
                          <span>
                            {UnitTypeFormatter.format(item?.unitType)}
                          </span>
                        </div>
                        <div>
                          <i
                            className="fa fa-minus-circle"
                            onClick={() => {
                              const receivedWeights =
                                form.getState().values.receivedWeights ?? [];

                              form.change(
                                "receivedWeights",
                                receivedWeights.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="my-3 text-center">
                  <Button onClick={onShowLabelsPrinter}>הדפסת מדבקות</Button>
                </div>
                <div className="my-3 text-center">
                  <Button
                    variant="warning"
                    onClick={handleSubmit}
                    disabled={!valid}
                  >
                    סיימתי
                  </Button>
                </div>
                <RemoteWeightModal
                  show={showRemoteWeight}
                  prepareUnitType={item?.unitType!}
                  onClose={onHideRemoteWeight}
                  onWeightReceived={(weight) => {
                    const receivedWeights =
                      form.getState().values.receivedWeights ?? [];

                    form.change("receivedWeights", [
                      ...receivedWeights,
                      weight,
                    ]);
                  }}
                />
              </>
            )}
          </Form>
        </div>
      </Modal>
    </RouteContent>
  );
};
