import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { RouteContent } from "route-content/components/RouteContent";
import { PickIngredients } from "order/components/PickIngredients";
import { OrdersApi } from "order/OrdersApi";
import { AddOrderTopBar } from "order/components/AddOrderTopBar";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { useObserver } from "mobx-react";
import { SpeechAmountItemMatcher } from "speech/SpeechAmountItemMatcher";
import { SpeechAmountItemMatcherParseResult } from "speech/types";
import { useLocation } from "react-router-dom";
import { RouteContentTopBar } from "route-content/components/RouteContentTopBar";
import { useQuery } from "react-query";

export const OrderSearchProductsScreen: React.FC = () => {
  const location = useLocation();
  const { store, setRouteSearchPhrase } = useAddOrderContext();
  const [searchPhrase, itemSpeechPhrase] = useObserver(() => [
    store.routeSearchPhrases[location.pathname] ?? "",
    store.itemSpeechPhrase,
  ]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [speechParseResults, setSpeechParseResults] =
    useState<SpeechAmountItemMatcherParseResult>();

  // Fetch ingredients, skip if less than 2 letters are being searched.
  const {
    data: ingredients,
    isLoading,
    isFetching,
  } = useQuery(
    OrdersApi.searchIngredientsQuery.build(debouncedSearch, {
      enabled: debouncedSearch.length > 1,
    })
  );

  const isLoadingViaSpeech =
    speechParseResults?.itemText &&
    (speechParseResults?.itemText !== debouncedSearch ||
      isLoading ||
      isFetching ||
      !ingredients);

  // Make sure we set debounceSearch once per 500 mils.
  const onSetDebouncedSearch = useMemo(
    () => debounce(setDebouncedSearch, 500),
    []
  );

  // Store the speech parse results.
  useEffect(() => {
    if (itemSpeechPhrase) {
      setSpeechParseResults(SpeechAmountItemMatcher.parse(itemSpeechPhrase));
    } else {
      setSpeechParseResults(undefined);
    }
  }, [itemSpeechPhrase]);

  // Sync debouncedSearch.
  useEffect(() => {
    onSetDebouncedSearch(speechParseResults?.itemText ?? searchPhrase);
  }, [onSetDebouncedSearch, searchPhrase, speechParseResults]);

  useEffect(() => {
    if (speechParseResults && !isLoadingViaSpeech) {
      const { itemText } = speechParseResults;

      /* The part that takes care of picking specific item and setting its amount via speech
          located at PickIngredientItem(sadly) */
      setRouteSearchPhrase(location.pathname, itemText);
    }
  }, [
    isLoadingViaSpeech,
    location.pathname,
    setRouteSearchPhrase,
    speechParseResults,
  ]);

  return (
    <RouteContent className="OrderSearchProductsScreen">
      <RouteContentTopBar>
        <AddOrderTopBar />
      </RouteContentTopBar>
      {searchPhrase !== "" && (
        <PickIngredients
          ingredients={isLoadingViaSpeech ? [] : ingredients}
          isLoading={isLoading}
        />
      )}
      {!searchPhrase && (
        <div style={{ alignSelf: "center" }}>אנא הקלד שם מוצר לחיפוש</div>
      )}
    </RouteContent>
  );
};
