import "./style.scss";
import React, { useMemo, useCallback } from "react";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { SupplierProductSupplyListItem } from "supplier/types";
import { SuppliersApi } from "supplier/SuppliersApi";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { NumberFormatter } from "base/utils/formatters";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { ProductsRouteBuilder } from "product/ProductsRouteBuilder";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { BaseSelectListItem } from "base/types";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export type SupplierProductsTabProps = {
  supplierId: number;
};

export const SupplierProductsSupplyTab: React.FC<SupplierProductsTabProps> = ({
  supplierId,
}) => {
  const navigate = useNavigateCallback();

  const { data } = useQuery(
    SuppliersApi.itemProductsSupplyQuery.build(supplierId)
  );

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const onEditRowClick = useCallback(
    (data: SupplierProductSupplyListItem) => {
      navigate(ProductsRouteBuilder.buildEditRoute(data.productId));
    },
    [navigate]
  );

  const columns = useMemo<
    CardsTableProps<SupplierProductSupplyListItem>["columns"]
  >(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <FileViewer type="image" fileDir={FileDir.Product} fileName={value} />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "name",
        accessor: "name",
        Header: "שם המוצר",
      },
      {
        id: "supplierCatalogId",
        accessor: "supplierCatalogId",
        Header: 'מק"ט',
      },
      {
        id: "supplyMethod",
        accessor: "supplyMethod",
        Header: "צורת אספקה",
        cellProps: {},
        Cell: ({ value, row }) => (
          <>
            {SupplyMethodDescFormatter.format(
              value,
              row.original.prepareUnitType,
              unitKinds,
              packageKinds
            )}
          </>
        ),
      },
      {
        id: "price",
        accessor: "price",
        Header: "מחיר",
        cellProps: {},
        Cell: ({ value }) => <>{NumberFormatter.format(value)}</>,
      },
      {
        id: "discountPercentage",
        accessor: "discountPercentage",
        Header: "אחוז הנחה",
        cellProps: {},
        Cell: ({ value }) => <>{NumberFormatter.format(value)}</>,
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    [packageKinds, unitKinds]
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      localStorageKey={LocalStorageKeysHelper.dataTable(
        "SupplierProductsSupplyTab"
      )}
      globalFilterAllowedCols={["name"]}
    />
  );
};
