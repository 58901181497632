import React, { useCallback } from "react";
import { Modal, FormGroup, Col, FormLabel } from "react-bootstrap";
import { DateInputFormatter } from "base/utils/formatters/DateInputFormatter";
import { RoundCheckButton } from "base/components/RoundCheckButton";
import { RoundTrashButton } from "base/components/RoundTrashButton";
import { Form } from "form/components/Form";
import { FormTextInput } from "form/components/FormTextInput";
import { FormNumberInput } from "form/components/FormNumberInput";
import { FormSelect } from "form/components/FormSelect";
import { EnterKeyCodeListener } from "base/components/DocumentKeyupListener";
import {
  SimpleGlobalListFieldDef,
  SimpleGlobalListRoutePath,
} from "global-list/types";
import { GlobalListsDefinitions } from "global-list/constants";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormFileViewer } from "form/components/FormFileViewer";
import { FormFileUpload } from "form/components/FormFileUpload";
import { FormSpy } from "react-final-form";

export type SimpleGlobalListItemModalProps = {
  show: boolean;
  routeApiPath: SimpleGlobalListRoutePath;
  onHide: () => void;
  initialValues?: any;
  onItemSaved?: (values: any) => void;
};

const TempDefaultListProvider: SimpleGlobalListFieldDef["ListProvider"] = ({
  render,
}) => {
  return render([]);
};

export const SimpleGlobalListItemModal: React.FC<SimpleGlobalListItemModalProps> = ({
  show,
  routeApiPath,
  onHide,
  initialValues,
  onItemSaved,
}) => {
  const {
    title,
    isDisableDelete,
    fields: fieldsDef,
    isSaveMethodFormData,
  } = GlobalListsDefinitions[routeApiPath];

  const idAccessor = fieldsDef.find((f) => f.isIdColumn)!.accessor;

  const onSave = useCallback(
    async (item: any) => {
      const savedItem = await SimpleGlobalListApi.saveItem(
        routeApiPath,
        item,
        idAccessor,
        isSaveMethodFormData
      );
      onHide();
      onItemSaved?.(savedItem);
    },
    [routeApiPath, idAccessor, isSaveMethodFormData, onHide, onItemSaved]
  );

  const onDelete = useCallback(async () => {
    await SimpleGlobalListApi.deleteItem(
      routeApiPath,
      initialValues[idAccessor],
      idAccessor
    );
    onHide();
  }, [routeApiPath, initialValues, idAccessor, onHide]);

  return (
    <Modal show={show} onHide={onHide} centered animation={false}>
      <h3 className="text-center mt-3 mb-0">
        {TextFormatter.format("$1 $2", [
          initialValues?.[idAccessor] !== undefined ? "הוספת" : "עריכת",
          title,
        ])}
      </h3>
      <hr className="w-100" />
      <Form onSubmit={onSave} initialValues={initialValues}>
        {({ handleSubmit, form }) => (
          <FormGroup className="d-flex p-2 flex-wrap">
            <>
              {fieldsDef
                .filter((def) => !def.isFormColumnHidden)
                .map((def, index) => (
                  <Col md={6} key={def.accessor} className="p-1">
                    {(() => {
                      switch (def.type) {
                        case "text":
                          return (
                            <FormTextInput
                              name={def.accessor}
                              placeholder={def.title}
                              readOnly={def.isReadOnly}
                              autoFocus={index === 0}
                            />
                          );
                        case "boolean":
                          return (
                            <div>
                              <label>
                                {def.title}
                                <FormCheckbox name={def.accessor} />
                              </label>
                            </div>
                          );
                        case "number":
                          return (
                            <FormNumberInput
                              name={def.accessor}
                              placeholder={def.title}
                              autoFocus={index === 0}
                            />
                          );
                        case "date":
                          return (
                            <FormTextInput
                              type="date"
                              name={def.accessor}
                              format={DateInputFormatter.format}
                              readOnly={def.isReadOnly}
                            />
                          );
                        case "select": {
                          const {
                            ListProvider = TempDefaultListProvider,
                          } = def;
                          return (
                            <ListProvider
                              render={(list) => (
                                <FormSelect
                                  name={def.accessor}
                                  emptyOptionLabel={def.title}
                                  options={list}
                                />
                              )}
                            />
                          );
                        }
                        case "enum":
                          return (
                            <FormSelect
                              name={def.accessor}
                              emptyOptionLabel={def.title}
                              options={def.enumOptions!}
                            />
                          );
                        case "image": {
                          const {
                            imageUploadName = "image",
                            imageDeleteName = "isDeleteImage",
                          } = def;

                          return (
                            <>
                              <FormLabel>{def.title}</FormLabel>
                              <FormFileUpload
                                name={imageUploadName}
                                onUploaded={() => {
                                  form.change(imageDeleteName, false);
                                }}
                              />
                              <FormSpy
                                subscription={{ values: true }}
                                render={({ values }) => (
                                  <FormFileViewer
                                    fileDir={def.imageDir}
                                    pathName={def.accessor}
                                    uploadName={imageUploadName}
                                    imageProps={{
                                      style: {
                                        width: 200,
                                      },
                                    }}
                                    onDelete={() => {
                                      if (form.getState().values.image) {
                                        form.change(imageUploadName, undefined);
                                      } else {
                                        form.change(imageDeleteName, true);
                                      }
                                    }}
                                    isDeleted={
                                      values[imageDeleteName] &&
                                      !values[imageUploadName]
                                    }
                                  />
                                )}
                              />
                            </>
                          );
                        }
                        default:
                          throw new Error("Field def is not implemented yet.");
                      }
                    })()}
                  </Col>
                ))}
            </>
            <Col xs={12} className="mt-3 d-flex justify-content-between p-0">
              <EnterKeyCodeListener active={show} onEnter={handleSubmit}>
                <RoundCheckButton onClick={handleSubmit} />
              </EnterKeyCodeListener>
              {!isDisableDelete &&
                initialValues?.[idAccessor] !== undefined && (
                  <RoundTrashButton onClick={onDelete} />
                )}
            </Col>
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
};
