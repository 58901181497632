import React from "react";
import { Button, Collapse, Modal } from "react-bootstrap";
import classNames from "classnames";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { NumberFormatter } from "base/utils/formatters";
import "sent-order/styles/SentOrderCard.scss";
import { SentOrderListItem } from "sent-order/types";
import {
  differenceInHours,
  differenceInMinutes,
  format as dateFormat,
} from "date-fns";
import { SentOrdersRouteBuilder } from "sent-order/SentOrdersRouteBuilder";
import { useHistory } from "react-router-dom";
import { useBooleanState } from "base/hooks/useBooleanState";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { differenceInDays } from "date-fns/esm";

export interface SentOrderCardProps {
  data: SentOrderListItem;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const SentOrderCard: React.FC<SentOrderCardProps> = ({
  data,
  isExpanded,
  onToggleExpand,
}) => {
  const history = useHistory();

  const [showOrderLate, onShowOrderLate, onHideOrderLate] = useBooleanState();

  const supplyDateDaysDiff = differenceInDays(
    Date.now(),
    new Date(data.dateCreated)
  );

  const supplyDateHoursDiff =
    differenceInHours(Date.now(), new Date(data.dateCreated)) -
    supplyDateDaysDiff * 24;

  const supplyDateMinsDiff =
    differenceInMinutes(Date.now(), new Date(data.dateCreated)) -
    supplyDateDaysDiff * 24 * 60 -
    supplyDateHoursDiff * 60;

  return (
    <div
      className={classNames("SentOrderCard", {
        isExpanded,
      })}
    >
      <div
        className={"header d-flex align-items-start"}
        onClick={onToggleExpand}
      >
        <i
          className={classNames("fas fa-chevron-left mt-1 mt-md-3 mr-3", {
            "fa-rotate-270": isExpanded,
          })}
          style={{
            position: "relative",
            top: 4,
          }}
        />
        <div className="mx-2">
          <div className="label font-weight-bolder h4 mt-1 mt-md-3 mb-0">
            {data.supplierName}
          </div>
          <div>
            <span className="label">מספר פריטים בהזמנה:</span>{" "}
            <span className="label font-weight-bolder">{data.itemsCount}</span>
          </div>
        </div>
        <ImageBackgroundMix key={data.imageName} className="mr-auto">
          <BaseImage fileDir={FileDir.Supplier} fileName={data.imageName} />
        </ImageBackgroundMix>
      </div>
      <Collapse in={isExpanded}>
        <div className="expandedSectionContent">
          <div>
            <span className="label">סך עלות ההזמנה:</span>{" "}
            <span className="label font-weight-bolder">
              ₪{NumberFormatter.format(data.amount)}
            </span>
          </div>
          <div>
            <span className="label">מספר הזמנה:</span>{" "}
            <span className="label font-weight-bolder">{data.id}</span>
          </div>
          <div>
            <span className="label">תאריך ביצוע הזמנה:</span>{" "}
            <span className="label font-weight-bolder">
              {dateFormat(new Date(data.dateCreated), "dd/MM/yyyy")}
            </span>
          </div>
          <div>
            <span className="label">מבצע ההזמנה:</span>{" "}
            <span className="label font-weight-bolder">
              {data.submitterName}
            </span>
          </div>
          {/*<div role="button">
            <span className="label">
              <i className="far fa-eye" />
              <u className="mx-1">לצפיה בהזמנה</u>
            </span>
        </div>*/}
        </div>
      </Collapse>
      <div className="footer">
        <div className="d-flex">
          <Button
            onClick={() => {
              history.push(
                SentOrdersRouteBuilder.buildEditSentOrderRoute(data.id)
              );
            }}
          >
            <i className="fas fa-stopwatch" /> קליטת הזמנה
          </Button>
          {data.supplierSeenDate && (
            <div className="mr-auto text-success p-2">
              <i className="fas fa-check" /> אושרה על ידי הספק
            </div>
          )}
          {/*<div className="mr-auto">
            <Button variant="danger" onClick={onShowOrderLate}>
              <i className="fas fa-phone" /> הזמנה באיחור
            </Button>
          </div>*/}
        </div>
      </div>
      <Modal
        show={showOrderLate}
        onHide={onHideOrderLate}
        centered
        dialogClassName="sentOrderLateModal"
      >
        <ModalTitleBar
          icon={<i className="fas fa-exclamation-triangle text-danger" />}
          onClose={onHideOrderLate}
          isDisplayIndent={false}
        />
        <div className="h3 font-weight-bolder text-center title">
          הזמנה זו מאחרת ב-
        </div>
        <div className="mt-1 d-flex justify-content-center text-center">
          <div>
            <div className="h4 text-danger mb-0 px-1">
              {supplyDateMinsDiff < 10
                ? `0${supplyDateMinsDiff}`
                : supplyDateMinsDiff}
            </div>
            <div className="h7 text-danger">דקות</div>
          </div>
          <div
            className="h4 text-danger"
            style={{ position: "relative", top: -1 }}
          >
            :
          </div>
          <div>
            <div className="h4 text-danger mb-0 px-1">
              {supplyDateHoursDiff < 10
                ? `0${supplyDateHoursDiff}`
                : supplyDateHoursDiff}
            </div>
            <div className="h7 text-danger">שעות</div>
          </div>
          <div
            className="h4 text-danger"
            style={{ position: "relative", top: -1 }}
          >
            :
          </div>
          <div>
            <div className="h4 text-danger mb-0 px-1">
              {supplyDateDaysDiff < 10
                ? `0${supplyDateDaysDiff}`
                : supplyDateDaysDiff}
            </div>
            <div className="h7 text-danger">ימים</div>
          </div>
        </div>
        <div className="p-3 d-flex">
          <div>
            <Button as="a" className="text-white" href="tel:+972525386888">
              <i className="fas fa-phone" /> חייג לספק
            </Button>
          </div>
          <div className="mr-auto">
            <Button onClick={onHideOrderLate}>
              <i className="fas fa-undo" /> חזור
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
