import React, { useMemo, useState } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { IngredientInfluance, FoodInfluance } from "sent-order/types";
import { Typography } from "base/components/Typography";
import {
  CardsTableColumn,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import { BaseImage } from "base/components/BaseFoodImage";
import { Modal } from "react-bootstrap";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { FileDir, BaseSelectListItem, RouteParams } from "base/types";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { PriceReportChange } from "sent-order/components/PriceReportChange";
import { format as dateFormat } from "date-fns";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

export const OrderPriceReportScreen: React.FC = () => {
  const { orderId: orderIdStr } = useParams<RouteParams<"orderId">>();
  const orderId = Number(orderIdStr);

  const [selectedIng, setSelectedIng] = useState<IngredientInfluance>();

  const { data: reportData } = useQuery(
    SentOrdersApi.priceReportQuery.build(orderId)
  );

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const columns = useMemo<CardsTableColumn<IngredientInfluance>[]>(
    () => [
      {
        id: "imageName",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="מרכיב" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "ingredientName",
        accessor: "ingredientName",
        Header: "שם המוצר",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "change",
        accessor: ({ requestedPrice, receivedPrice }) => {
          return requestedPrice !== 0
            ? (receivedPrice / requestedPrice) * 100 - 100
            : 0;
        },
        Cell: PriceReportChange,
        Header: "% שינוי",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const ingColumns = useMemo<CardsTableColumn<IngredientInfluance>[]>(
    () => [
      {
        id: "requestedPrice",
        accessor: "requestedPrice",
        Header: "מחיר צפוי",
      },
      {
        id: "receivedPrice",
        accessor: "receivedPrice",
        Header: "מחיר בפועל",
        Cell: ({ value, row }) => (
          <PriceReportChange
            value={value}
            zeroValue={row.original.requestedPrice}
            variant="default"
          />
        ),
      },
      {
        id: "change",
        accessor: ({ requestedPrice, receivedPrice }) => {
          return requestedPrice !== 0
            ? (receivedPrice / requestedPrice) * 100 - 100
            : 0;
        },
        Cell: PriceReportChange,
        Header: "% שינוי",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const dishColumns = useMemo<CardsTableColumn<FoodInfluance>[]>(
    () => [
      {
        id: "foodName",
        accessor: "foodName",
        Header: "שם המנה",
      },
      {
        id: "previousFC",
        accessor: "previousFC",
        Cell: ({ value }) => (
          <PriceReportChange
            value={value * 100}
            zeroValue={value * 100}
            useSign={false}
          />
        ),
        Header: "FoodCost% קודם",
        cellProps: {},
      },
      {
        id: "updatedFC",
        accessor: "updatedFC",
        Cell: ({ value }) => (
          <PriceReportChange value={value * 100} useSign={false} />
        ),
        Header: "FoodCost% חדש",
        cellProps: {},
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <RouteContent>
      <Typography variant="route-title">
        דוח מחירים חריגים - {reportData?.supplier.name}
      </Typography>
      <Typography variant="description" className="text-center my-2">
        לתשומת ה❤! בהזמנה שהתקבלה בתאריך{" "}
        {reportData?.orderDateCreated &&
          dateFormat(new Date(reportData?.orderDateCreated), "dd/MM/yyyy")}{" "}
        השתנו המחירים הבאים
      </Typography>
      <CardsTable
        columns={columns}
        data={reportData?.ingredients}
        globalFilterAllowedCols={["ingredientName"]}
        onEditRowClick={(row) => {
          setSelectedIng(row);
        }}
      />
      <Modal
        show={selectedIng !== undefined}
        onHide={() => setSelectedIng(undefined)}
        centered
      >
        <div style={{ backgroundColor: "#F2F2FD" }}>
          <ModalTitleBar
            icon={
              <BaseImage
                fileDir={FileDir.BaseFood}
                fileName={selectedIng?.imageName}
              />
            }
            title={
              <>
                <Typography variant="description" fontSize={16}>
                  {selectedIng?.ingredientName}
                </Typography>
                <br />
                <Typography variant="description" fontSize={16}>
                  {SupplyMethodDescFormatter.format(
                    selectedIng?.receivedSupplyMethod,
                    selectedIng?.prepareUnitType,
                    unitKinds,
                    packageKinds
                  )}
                </Typography>
              </>
            }
            onClose={() => setSelectedIng(undefined)}
          />
          <div className="p-2">
            <CardsTable
              columns={ingColumns}
              data={selectedIng ? [selectedIng] : []}
              searchable={false}
              hasPagination={false}
            />
            <CardsTable
              columns={dishColumns}
              data={selectedIng?.foods}
              searchable={false}
              hasPagination={false}
            />
          </div>
        </div>
      </Modal>
    </RouteContent>
  );
};
