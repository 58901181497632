import { useIsLoggedIn } from "auth/hooks/useIsLoggedIn";
import { useStandType } from "auth/hooks/useStandType";
import { useAppContext } from "app/context/hooks/useAppContext";
import { useRef, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { useRefreshInterval } from "base/hooks/useRefreshInterval";
import { AuthStandType } from "auth/types";

const AutoLogOffStandTypes: AuthStandType[] = ["chef", "receiveOrder"];
const ONE_MINUTE = 60 * 1000;

export const AutoLogOffHandler: React.FC = () => {
  const isLoggedIn = useIsLoggedIn();
  const standType = useStandType();
  const { onSignOut } = useAppContext();
  const refs = useRef({
    activityTimestamp: Date.now(),
  });

  const { data: systemSettings } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<Array<{ key: string; value: number }>>()
      .build(SimpleGlobalListRoutePath.SystemSettings)
  );

  const logOffTime =
    (systemSettings?.find((x) => x.key === "AutoLogoffTime")?.value ?? 3) *
    ONE_MINUTE;

  const shouldEnforceAutoLogOff =
    isLoggedIn && AutoLogOffStandTypes.includes(standType);

  const signOutIfRequired = useCallback(() => {
    if (Date.now() - refs.current.activityTimestamp > logOffTime) {
      onSignOut();
    }
  }, [logOffTime, onSignOut]);

  const restartInterval = useRefreshInterval(
    logOffTime + 1,
    shouldEnforceAutoLogOff,
    signOutIfRequired
  );

  useEffect(() => {
    refs.current.activityTimestamp = Date.now();
    if (!shouldEnforceAutoLogOff) return;

    const logActivity = () => {
      signOutIfRequired();
      restartInterval();
      refs.current.activityTimestamp = Date.now();
    };

    const activityEvents: (keyof DocumentEventMap)[] = [
      "mousemove",
      "wheel",
      "pointerdown",
    ];
    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, logActivity);
    });

    return () => {
      activityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, logActivity);
      });
    };
  }, [restartInterval, shouldEnforceAutoLogOff, signOutIfRequired]);

  return null;
};
