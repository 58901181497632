import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { ProductEditor } from "./components/ProductEditor";
import { useParams } from "react-router-dom";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { ProductsApi } from "./ProductsApi";
import { useQuery } from "react-query";
import { RouteParams } from "base/types";

export const EditProductScreen: React.FC = () => {
  const { productId: productIdStr } = useParams<RouteParams<"productId">>();
  const productId = Number(productIdStr);

  const { data, isLoading, isFetching } = useQuery(
    ProductsApi.itemQuery.build(productId)
  );

  useFullScreenLoader(isLoading || isFetching);

  return (
    <RouteContent>
      <Typography variant="route-title">עריכה</Typography>
      <ProductEditor mode="edit" data={data} />
    </RouteContent>
  );
};
