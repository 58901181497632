import Axios from "axios";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import {
  IngredientListItem,
  Ingredient,
  IngredientSelectListItem,
} from "./types";
import { BaseSelectListItem } from "base/types/BaseSelectListItem";
import {
  IngredientRelatedTree,
  IngredientRelatedProduct,
  IngredientFormData,
} from "./types";
import { TaklitSelectListItem } from "base/types/TaklitSelectListItem";
import { ApiQuery } from "base/api/ApiQuery";

export class IngredientsApi {
  static tags = {
    Lists: ApiQuery.buildTag("IngredientsApi", "Lists"),
  };

  static listQuery = new ApiQuery<IngredientListItem[]>(
    "/api/ingredients/items",
    [IngredientsApi.tags.Lists]
  );

  static itemQuery = new ApiQuery<Ingredient, number>(
    (id) => `/api/ingredients/${id}`
  );

  static selectListQuery = new ApiQuery<IngredientSelectListItem[]>(
    "/api/ingredients/selectlist",
    [IngredientsApi.tags.Lists]
  );

  static itemDefaultsQuery = new ApiQuery<Partial<Ingredient>>(
    "/api/ingredients/new"
  );

  static departmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/ingredients/departments"
  );

  static suppliersQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/ingredients/suppliers"
  );

  static taklitSelectListQuery = new ApiQuery<TaklitSelectListItem[]>(
    "/api/ingredients/taklitSelectList"
  );

  static itemTreesQuery = new ApiQuery<IngredientRelatedTree[], number>(
    (id) => `/api/ingredients/${id}/trees`
  );

  static itemProductsQuery = new ApiQuery<IngredientRelatedProduct[], number>(
    (id) => `/api/ingredients/${id}/products`
  );

  static async saveItem(item: WithPartialProperties<IngredientFormData, "id">) {
    const { data: savedItem } = await Axios.post<Ingredient>(
      "/api/ingredients",
      BodyFormDataBuilder.build2({ ...item }),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    IngredientsApi.itemQuery.updateCache(savedItem, savedItem.id);

    ApiQuery.invalidateByTags([IngredientsApi.tags.Lists]);

    return savedItem;
  }

  static async saveFromSelect(
    item: WithPartialProperties<IngredientFormData, "id">
  ) {
    const { data: savedItem } = await Axios.post<Ingredient>(
      "/api/ingredients/addFromSelect",
      BodyFormDataBuilder.build(item),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    IngredientsApi.itemQuery.updateCache(savedItem, savedItem.id);

    ApiQuery.invalidateByTags([IngredientsApi.tags.Lists]);

    return savedItem;
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/ingredients/${id}`);

    ApiQuery.invalidateByTags([IngredientsApi.tags.Lists]);
  }
}
