import React from "react";
import { useParams } from "react-router-dom";
import { RouteContent } from "route-content/components/RouteContent";
import DepartmentTree from "department/components/DepartmentTree/DepartmentTree";

export const DepartmentsTreeScreen: React.FC = () => {
  const { departmentId } = useParams();

  return (
    <RouteContent>
      <DepartmentTree id={parseInt(departmentId, 10)} />
    </RouteContent>
  );
};
