import { useState, useCallback } from "react";

export const useBooleanState = (initialValue = false) => {
  const [bool, setBool] = useState(initialValue);
  const onSetTrue = useCallback(() => {
    setBool(true);
  }, []);
  const onSetFalse = useCallback(() => {
    setBool(false);
  }, []);
  const onToggle = useCallback(() => {
    setBool((bool) => !bool);
  }, []);

  return [bool, onSetTrue, onSetFalse, onToggle, setBool] as const;
};
