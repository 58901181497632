import React, { useCallback } from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { Form } from "form/components/Form";
import { Button, Col, Row } from "react-bootstrap";
import { FormSelect } from "form/components/FormSelect";
import { AuthStandType } from "auth/types";
import { useStandType } from "auth/hooks/useStandType";
import { useAppContext } from "app/context/hooks/useAppContext";
import { FormCheckbox } from "form/components/FormCheckbox";
import { FormSpy } from "react-final-form";
import { useScaleType } from "app/context/hooks/useScaleType";
import { useNotificationPermission } from "app/context/hooks/useNotificationPermission";
import Axios from "axios";
import { FormTextInput } from "form/components/FormTextInput";

type StandSettingsFormValues = {
  standType: AuthStandType;
  scaleType: number;
  isEnablePushNotifications: boolean;
  scaleNetworkAddress: string;
};

const StandTypeOptions: Array<{ label: string; value: AuthStandType }> = [
  { label: "עמדה רגילה", value: "default" },
  { label: "עמדת טבחים", value: "chef" },
  { label: "עמדת שקילה", value: "receiveOrder" },
  { label: "עורך מנה", value: "dish-editor" },
];

const ScaleTypeOptions: Array<{ label: string; value: number }> = [
  { label: "משקל קליטת הזמנה", value: 0 },
  { label: "משקל הכנות טבחים", value: 1 },
];

export const StandSettingsScreen: React.FC = () => {
  const { store } = useAppContext();
  const standType = useStandType();
  const scaleType = useScaleType();
  const notificationPermission = useNotificationPermission();

  const onSubmit = useCallback(
    ({
      standType,
      scaleType,
      isEnablePushNotifications,
      scaleNetworkAddress,
    }: StandSettingsFormValues) => {
      store.setStandType(standType);
      store.setScaleType(scaleType);
      store.setNotificationPermission(
        isEnablePushNotifications ? "granted" : "denied"
      );
      localStorage.setItem("scaleNetworkAddress", scaleNetworkAddress);
    },
    [store]
  );

  const fieldName = (property: keyof StandSettingsFormValues) => property;

  return (
    <RouteContent>
      <Typography variant="route-title">הגדרות עמדה</Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          standType,
          scaleType,
          isEnablePushNotifications: notificationPermission === "granted",
          scaleNetworkAddress:
            localStorage.getItem("scaleNetworkAddress") || "",
        }}
      >
        {({ handleSubmit, form }) => (
          <>
            <Row noGutters>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <div>בחירת סוג עמדה</div>
              </Col>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <FormSelect
                  name={fieldName("standType")}
                  displayEmptyOption={false}
                  options={StandTypeOptions}
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <div>בחירת סוג משקל</div>
              </Col>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <FormSelect
                  name={fieldName("scaleType")}
                  displayEmptyOption={false}
                  options={ScaleTypeOptions}
                  sortAsc={false}
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <div>כתובת רשת של המשקל</div>
              </Col>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <FormTextInput name={fieldName("scaleNetworkAddress")} />
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <div>איפשור נוטיפיקציות</div>
              </Col>
              {"serviceWorker" in navigator && "PushManager" in window && (
                <Col xs="auto" className="p-1 d-flex align-items-center">
                  <FormCheckbox
                    name={fieldName("isEnablePushNotifications")}
                    width={24}
                  />
                  <FormSpy<StandSettingsFormValues>
                    subscription={{ values: true }}
                    onChange={({ values }) => {
                      if (
                        values.isEnablePushNotifications &&
                        window.Notification.permission === "denied"
                      ) {
                        alert(
                          "הרשאת נוטיפיקציות נחסמה על ידי המשתמש, עליך לאפשר ידנית את האופציה דרך הגדרות הדפדפן"
                        );
                        form.change("isEnablePushNotifications", false);
                      } else if (
                        values.isEnablePushNotifications &&
                        window.Notification.permission !== "granted"
                      ) {
                        window.Notification.requestPermission().then(() => {
                          if (window.Notification.permission !== "granted") {
                            form.change("isEnablePushNotifications", false);
                          }
                        });
                      }
                    }}
                  />
                </Col>
              )}
              {!("Notification" in window) && (
                <Col xs="auto" className="p-1 d-flex align-items-center">
                  <span>הדפדפן אינו תומך בנוטיפיקציות</span>
                </Col>
              )}
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <Button onClick={() => Axios.get("/api/webpush/test")}>
                  בדיקה
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <Button onClick={handleSubmit}>שמירה</Button>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </RouteContent>
  );
};
