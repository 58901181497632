import React from "react";
import { FieldRenderProps } from "react-final-form";
import { FormErrorMessage } from "../../FormErrorMessage";
import { NumberInput, NumberInputProps } from "base/components/NumberInput";
import { useStandType } from "auth/hooks/useStandType";

export type NumberInputFieldInnerProps = FieldRenderProps<any> &
  NumberInputProps;

export const NumberInputFieldInner: React.FC<NumberInputFieldInnerProps> = ({
  input,
  meta,
  ...numberInputProps
}) => {
  const standType = useStandType();

  return (
    <>
      <NumberInput
        {...numberInputProps}
        {...input}
        displayNumpadOnFocus={
          numberInputProps.displayNumpadOnFocus ?? standType === "receiveOrder"
        }
      />
      <div>
        <FormErrorMessage>
          {meta.touched ? meta.error : meta.submitError}
        </FormErrorMessage>
      </div>
    </>
  );
};
