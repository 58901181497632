import Axios from "axios";
import { WeeklyWorkScheduleType } from "./types";
import { ApiQuery } from "base/api/ApiQuery";

export class WeeklyWorkScheduleApi {
  static itemsQuery = new ApiQuery<WeeklyWorkScheduleType[], string>(
    (formattedDateStr) =>
      `/api/workSchedules?startOfWeekDay=${formattedDateStr}`
  );

  static async save(
    formattedDateStr: string,
    schedules: WeeklyWorkScheduleType[]
  ) {
    const { data: savedWeeklySchedules } = await Axios.post<
      WeeklyWorkScheduleType[]
    >(`/api/workSchedules?startOfWeekDay=${formattedDateStr}`, schedules);

    WeeklyWorkScheduleApi.itemsQuery.updateCache(
      savedWeeklySchedules,
      formattedDateStr
    );
  }
}
