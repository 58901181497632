import React, { useMemo, useCallback } from "react";
import { UnitTypeFormatter } from "base/utils/formatters";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { BaseImage } from "base/components/BaseFoodImage";
import { YesNoTableFormatter } from "base/components/tables/utils/formatters/YesNoTableFormatter";
import {
  CardsTable,
  CardsTableProps,
} from "base/components/tables/components/CardsTable";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { TreeInfoRouteBuilder } from "tree-info/TreeInfoRouteBuilder";
import { MaterialListItem } from "material/types";
import { MaterialsRouteBuilder } from "material/MaterialsRouteBuilder";
import { MaterialsApi } from "material/MaterialsApi";
import { TreeType } from "base/types/TreeType";
import { SelectColumnFilter } from "base/components/tables/components/SelectColumnFilter";
import { TableAmountCell } from "base/components/tables/components/TableAmountCell";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export const MaterialsTable = () => {
  const { data } = useQuery(MaterialsApi.listQuery.build());
  const navigate = useNavigateCallback();

  const onAddRowClick = useCallback(() => {
    navigate(MaterialsRouteBuilder.buildAddMaterialRoute());
  }, [navigate]);

  const onEditRowClick = useCallback(
    (data: MaterialListItem) => {
      navigate(
        TreeInfoRouteBuilder.buildTreeInfoRoute(TreeType.Material, data.id)
      );
    },
    [navigate]
  );

  const columns = useMemo<CardsTableProps<MaterialListItem>["columns"]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => <BaseImage alt="מרכיב" fileName={value} />,
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "Name",
        accessor: "name",
        Header: "שם חומר הגלם",
      },
      {
        id: "Id",
        accessor: "id",
        Header: "קוד ישות",
      },
      {
        id: "unitType",
        accessor: (row) => UnitTypeFormatter.default.format(row.unitType, true),
        Header: "יח׳ מידה הכנה",
        cellProps: {},
      },
      {
        id: "DepartmentName",
        accessor: "departmentName",
        Header: "מחלקה",
        cellProps: {},
        Filter: SelectColumnFilter,
      },
      {
        id: "CurrentStock",
        accessor: "currentStock",
        Header: "מלאי",
        cellProps: {},
        Cell: (a, b) => (
          <TableAmountCell
            {...a}
            options={{ fractionDigits: 3, zeroValue: undefined }}
          />
        ),
      },
      {
        id: "IsActive",
        accessor: "isActive",
        Header: "פעיל?",
        cellProps: {},
        Cell: ({ value }) => YesNoTableFormatter.default.format(value),
        sortType: "boolean",
      },
      {
        id: "DateCreated",
        accessor: (row) => row.dateCreated,
        Header: "נוצר",
        cellProps: {},
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={data}
      onEditRowClick={onEditRowClick}
      onAddRowClick={onAddRowClick}
      localStorageKey={LocalStorageKeysHelper.dataTable("materials")}
      globalFilterAllowedCols={["Name"]}
    />
  );
};
