export interface NumberFormatterOptions {
  fractionDigits?: number;
  zeroValue?: string | undefined;
  applyUSLocaleStr?: boolean;
}

export class NumberFormatter {
  static default = new NumberFormatter();

  constructor(private options?: NumberFormatterOptions) {}

  formatWithTrim(value: number | undefined, fractionDigits: number = 3) {
    return parseFloat(NumberFormatter.format(value, { fractionDigits }));
  }

  formatAmount(value: number | undefined, isMaxDigits: boolean = false) {
    return NumberFormatter.formatAmount(value, isMaxDigits);
  }

  formatPrice(value: number | undefined) {
    return NumberFormatter.format(value, this.options);
  }

  static formatAmount(
    value: number | undefined,
    isMaxDigits: boolean = false,
    options: NumberFormatterOptions = {}
  ) {
    const res = NumberFormatter.format(value, {
      fractionDigits: 3,
      ...options,
    });

    if (isMaxDigits) {
      return parseFloat(res);
    }

    return res;
  }

  static format(
    value: number | undefined,
    options: NumberFormatterOptions = {}
  ) {
    if (value === undefined) {
      return "";
    }
    const { fractionDigits = 2, zeroValue } = options;

    if (value === 0 && zeroValue !== undefined) {
      return zeroValue;
    }

    if (options.applyUSLocaleStr) {
      return value.toLocaleString("en-US", {
        maximumFractionDigits: fractionDigits,
      });
    } else {
      return value.toFixed(fractionDigits);
    }
  }

  static formatPrice(
    value: number | undefined,
    options: NumberFormatterOptions = {}
  ) {
    const res = NumberFormatter.format(value, options);
    return res;
  }
}

export function formatAmount(n: number | undefined) {
  return NumberFormatter.default.formatAmount(n);
}
export function formatPrice(n: number | undefined) {
  return NumberFormatter.default.formatPrice(n);
}
