import React from "react";
import { NumberFormatter, NumberFormatterOptions } from "base/utils/formatters";

export type TableAmountCellProps = {
  value: any;
  options?: NumberFormatterOptions;
};

export const TableAmountCell: React.FC<TableAmountCellProps> = ({
  value,
  options,
}) => {
  const optionalNumber = parseInt(value, 10);
  if (isNaN(optionalNumber)) {
    return null;
  }

  return (
    <>{new NumberFormatter({ zeroValue: "", ...options }).formatPrice(value)}</>
  );
};
