import React, { useMemo } from "react";
import {
  CardsTable,
  CardsTableColumn,
} from "base/components/tables/components/CardsTable";
import { DailyRecommendListItem } from "sent-order/types";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";
import { BaseImage } from "base/components/BaseFoodImage";
import { Modal } from "react-bootstrap";
import { useImmer } from "use-immer";
import { ModalTitleBar } from "modal/ModalTitleBar";
import { FileDir } from "base/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";

export interface DailyRecommendTableProps {}

export const DailyRecommendTable: React.FC<DailyRecommendTableProps> = () => {
  const { data } = useQuery(SentOrdersApi.dailyRecommendQuery.build());
  const [suppliersModal, updateSuppliersModal] = useImmer({
    show: false,
    listItem: undefined as DailyRecommendListItem | undefined,
  });

  const columns = useMemo<CardsTableColumn<DailyRecommendListItem>[]>(
    () => [
      {
        id: "image",
        accessor: "imageName",
        Header: "",
        Cell: ({ value }) => (
          <BaseImage alt="מרכיב" fileName={value} fileDir={FileDir.BaseFood} />
        ),
        disableSortBy: true,
        disableGlobalFilter: true,
      },
      {
        id: "name",
        accessor: "name",
        Header: "מרכיב",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "amount",
        accessor: "amount",
        Header: "כמות",
        Cell: ({ value, row }) => (
          <>
            {NumberFormatter.formatPrice(value)} {UnitTypeFormatter.format(row.original.unitType)}
          </>
        ),
      },
      {
        id: "moreInfo",
        Header: "מידע נוסף",
        Cell: ({ value, row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <i
              className="fas fa-info-circle fa-2x"
              style={{ color: "#0d477c", padding: 8 }}
              onClick={() =>
                updateSuppliersModal((draft) => {
                  draft.show = true;
                  draft.listItem = row.original;
                })
              }
            />
          </div>
        ),
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    [updateSuppliersModal]
  );

  return (
    <>
      <CardsTable
        columns={columns}
        data={data}
        localStorageKey={LocalStorageKeysHelper.dataTable("dailyRecommend")}
        globalFilterAllowedCols={["name"]}
      />
      <Modal
        centered
        size="lg"
        show={suppliersModal.show}
        onHide={() =>
          updateSuppliersModal((draft) => {
            draft.show = false;
          })
        }
      >
        <ModalTitleBar
          title={suppliersModal.listItem?.name}
          onClose={() =>
            updateSuppliersModal((draft) => {
              draft.show = false;
            })
          }
        />
        <div className="p-2">
          <table>
            <thead>
              <tr>
                <th style={{ width: "15%", fontSize: 18 }} />
                <th style={{ width: "25%", fontSize: 18 }}>ספק</th>
                <th style={{ width: "15%", fontSize: 18 }} />
                <th style={{ width: "12%", fontSize: 18 }}>
                  מחיר ל
                  {UnitTypeFormatter.format(suppliersModal.listItem?.unitType)}
                </th>
                <th style={{ width: "12%", fontSize: 18 }}>כמות</th>
                <th style={{ width: "12%", fontSize: 18 }}>מחיר לכמות</th>
              </tr>
            </thead>
            <tbody>
              {suppliersModal.listItem?.suppliers.map((supplier) => (
                <tr key={supplier.id}>
                  <td>
                    <BaseImage
                      alt="מרכיב"
                      fileName={supplier.imageName}
                      fileDir={FileDir.Supplier}
                      height="40px"
                    />
                  </td>
                  <td>{supplier.name}</td>
                  <td>
                    <img
                      alt="rate"
                      src={`/content/images/supplierbar_${Math.ceil(
                        supplier.rate / 2
                      )}.png`}
                      height="40px"
                    />
                  </td>
                  <td>{NumberFormatter.formatPrice(supplier.price)}</td>
                  <td>
                    {NumberFormatter.formatPrice(suppliersModal.listItem!.amount)}{" "}
                    {UnitTypeFormatter.format(
                      suppliersModal.listItem!.unitType
                    )}
                  </td>
                  <td>
                    {NumberFormatter.formatPrice(
                      supplier.price * suppliersModal.listItem!.amount
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
