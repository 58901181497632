import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "./styles.scss";
import { OrderInlineAmounter } from "order/components/PickIngredientItem/OrderInlineAmounter/OrderInlineAmounter";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { BaseImage } from "base/components/BaseFoodImage";
import { debounce, uniqBy } from "lodash";
import Axios from "axios";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useSelectOptionsAdapter } from "base/components/Select/hooks/useSelectOptionsAdapter";
import { Select } from "base/components/Select";
import { FileDir, BaseSelectListItem } from "base/types";
import { OrderIngredientItem, Order } from "order/types";
import { useBooleanState } from "base/hooks/useBooleanState";
import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { SupplyMethodDescFormatter } from "order/SupplyMethodDescFormatter";
import classNames from "classnames";
import { SimpleGlobalListApi } from "global-list/SimpleGlobalListApi";
import { SimpleGlobalListRoutePath } from "global-list/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import { TextFormatter } from "base/utils/formatters/TextFormatter";
import { useImmer } from "use-immer";
import produce, { current } from "immer";
import { Collapse } from "react-bootstrap";
import { OrdersApi } from "order/OrdersApi";
import {
  ProductSupplyMethod,
  ProductSupplyMethodSupplier,
} from "product/supply-method/types";
import { SupplyMethodUtils } from "supply-methods/SupplyMethodUtils";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { useObserver } from "mobx-react";
import { SpeechAmountItemMatcher } from "speech/SpeechAmountItemMatcher";
import { usePrevious } from "base/hooks/usePrevious";
import { IngredientsHelper } from "ingredient/IngredientsHelper";
import { useQuery } from "react-query";
import { SupplierListItem } from "../../../supplier/types";

export type PickIngredientItemProps = {
  item: OrderIngredientItem;
  allItems: OrderIngredientItem[];
  onChanged?: (arg: {
    amount: number;
    supplierId: number;
    supplyMethod: BaseSupplyMethod | undefined;
  }) => void;
  showSupplierSelect?: boolean;
};

export const PickIngredientItem: React.FC<PickIngredientItemProps> = ({
  item,
  allItems,
  onChanged,
  showSupplierSelect = true,
}) => {
  console.log(item);
  const { store } = useAddOrderContext();

  const itemSpeechPhrase = useObserver(() => store.itemSpeechPhrase);

  const refs = useRef({
    onChanged,
  });
  refs.current.onChanged = onChanged;

  const {
    productId,
    requestedAmount: amount,
    supplyMethod: initialSupplyMethod,
    requestedPrice: initialRequestedPrice,
  } = item;
  const initialSupplierId = item.supplierModel.id;
  const [isExpanded, , , onToggle] = useBooleanState(false);

  const { data: suppliers } = useQuery(SuppliersApi.listQuery.build());
  const suppliersForItem = useMemo(() => {
    const r: SupplierListItem[] = [];
    if (suppliers) {
      if (item?.supplierModel?.id) {
        let firstSup = suppliers.find((s) => s.id === item?.supplierModel.id);
        if (firstSup) r.push(firstSup);
      }
      r.push(
        ...suppliers.filter(
          (sup) =>
            (r.length === 0 || sup.id !== r[0].id) &&
            item.supplyMethods.find((m) =>
              m.suppliers.find((ms) => ms.supplierId === sup.id)
            )
        )
      );
    }
    return r;
  }, [suppliers, item]);
  const suppliersOptions = useSelectOptionsAdapter(suppliersForItem);

  const { data: unitKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.UnitKinds)
  );

  const { data: packageKinds } = useQuery(
    SimpleGlobalListApi.itemQuery
      .dataCast<BaseSelectListItem[]>()
      .build(SimpleGlobalListRoutePath.PackageKinds)
  );

  const orderHistoryAverage = useMemo(() => {
    if (item.orderHistory.length === 0) return 0;

    const sumAmount = item.orderHistory.reduce(
      (sum, his) => sum + his.amount,
      0
    );

    return sumAmount / item.orderHistory.length;
  }, [item.orderHistory]);

  const [state, updateState] = useImmer({
    amount,
    supplierId: initialSupplierId,
    supplyMethod: initialSupplyMethod,
    requestedPrice: initialRequestedPrice,
  });
  const prevState = usePrevious(state);

  const { supplyMethod } = state;

  const supplyMethodsOptions = useMemo(() => {
    return uniqBy(
      [
        ...item.supplyMethods,
        {
          ...state.supplyMethod,
          suppliers: [] as ProductSupplyMethodSupplier[],
        },
      ]
        .sort((a, b) => {
          const aMatchingSupplier = a.suppliers.find(
            (x) => x.supplierId === state.supplierId
          );

          const bMatchingSupplier = b.suppliers.find(
            (x) => x.supplierId === state.supplierId
          );

          if (aMatchingSupplier) return -1;
          if (bMatchingSupplier) return 1;
          return 0;
        })
        .map((supplyMethod) => {
          const matchingSupplier = supplyMethod.suppliers.find(
            (x) => x.supplierId === state.supplierId
          );

          const labelPrefix = matchingSupplier ? "* " : "";
          const labelSupplyMethodText = SupplyMethodDescFormatter.format(
            supplyMethod,
            item.prepareUnitType,
            unitKinds,
            packageKinds
          );
          const labelSuffix = matchingSupplier
            ? ` (${matchingSupplier.price})`
            : "";

          return {
            value: SupplyMethodUtils.generateOptionValue(supplyMethod),
            label: `${labelPrefix}${labelSupplyMethodText}${labelSuffix}`,
          };
        }),
      "value"
    );
  }, [
    item.prepareUnitType,
    item.supplyMethods,
    packageKinds,
    state.supplyMethod,
    state.supplierId,
    unitKinds,
  ]);

  const updateServer = useMemo(
    () =>
      debounce(
        async (nextState: typeof state) => {
          refs.current.onChanged?.(nextState);

          await Axios.post("/api/orders/addToOrder", {
            productId,
            ...nextState,
          });

          const { data: orderIdOrEmptyStr } = await Axios.get<number | "">(
            `/api/suppliers/${nextState.supplierId}/getOpenOrder`
          );

          OrdersApi.itemBySupplierQuery.updateCache(
            produce((order) => {
              if (!order) return;

              order.orderId =
                orderIdOrEmptyStr === "" ? undefined : orderIdOrEmptyStr;
            }),
            nextState.supplierId
          );
        },
        250,
        { trailing: true, maxWait: 700 }
      ),
    [productId]
  );

  const onAmountChange = useCallback(
    (nextAmount: number) => {
      if (nextAmount >= 0) {
        updateState((s) => {
          s.amount = nextAmount;

          // no await. update in background.
          updateServer(current(s));
        });
      }
    },
    [updateServer, updateState]
  );

  const findItemProductByGenOptionValue = useCallback(
    (genOptionValue: string) => {
      return item.supplyMethods.find(
        (x) => SupplyMethodUtils.generateOptionValue(x) === genOptionValue
      );
    },
    [item.supplyMethods]
  );

  const calcNextRequestedPrice = useCallback(
    (supplierId: number, supplyMethod: ProductSupplyMethod | undefined) => {
      const matchingSupplier = supplyMethod?.suppliers.find(
        (x) => x.supplierId === supplierId
      );

      return matchingSupplier?.price ?? 0;
    },
    []
  );

  const onSupplierChange = useCallback(
    (nextSupplierId) => {
      updateState((s) => {
        s.supplierId = nextSupplierId;
        s.requestedPrice = calcNextRequestedPrice(
          nextSupplierId,
          findItemProductByGenOptionValue(
            SupplyMethodUtils.generateOptionValue(s.supplyMethod)
          )
        );
        // no await. update in background.
        updateServer(current(s));
      });
    },
    [
      calcNextRequestedPrice,
      findItemProductByGenOptionValue,
      updateServer,
      updateState,
    ]
  );

  const onSupplyMethodChange = (
    nextSelectedSupplyMethod: ProductSupplyMethod
  ) => {
    updateState((s) => {
      s.supplyMethod = nextSelectedSupplyMethod;

      s.requestedPrice = calcNextRequestedPrice(
        s.supplierId,
        nextSelectedSupplyMethod
      );

      // no await. update in background.
      updateServer(current(s));
    });
  };

  useEffect(() => {
    updateState((s) => {
      s.amount = amount;
      s.supplierId = initialSupplierId;
    });
  }, [amount, initialSupplierId, updateState]);

  // Handle update amount by speech from context. (Not ideal to write this code here, needs refactor.)
  useEffect(() => {
    if (itemSpeechPhrase) {
      const { matches, amount } = SpeechAmountItemMatcher.parseAndMatch(
        itemSpeechPhrase,
        allItems,
        (x) => IngredientsHelper.concatNameAndAlternativeNames(x)
      );

      if (
        matches.length === 1 &&
        matches[0].productId === item.productId &&
        amount !== undefined
      ) {
        onAmountChange(amount);
      }
    }
  }, [allItems, item, onAmountChange, itemSpeechPhrase]);

  // Handle some effected Apis. (Not ideal to write this code here, needs refactor.)
  useEffect(() => {
    if (prevState !== undefined && prevState !== state) {
      const updateFn = (order: Order | undefined) => {
        if (!order) return undefined;

        const matchingIndex = order.ingredients.findIndex((x) => {
          return x.productId === item.productId;
        });

        if (matchingIndex !== -1) {
          return produce(order, (draft) => {
            draft.ingredients[matchingIndex].requestedAmount = state.amount;
            draft.ingredients[matchingIndex].supplyMethod = state.supplyMethod;
            draft.ingredients[matchingIndex].requestedPrice =
              state.requestedPrice;
          });
        } else {
          return order;
        }
      };

      OrdersApi.itemBySupplierQuery.updateCache(updateFn, state.supplierId);

      OrdersApi.itemBySupplierQuery.updateCache(
        updateFn,
        item.supplierModel.id
      );
    }
  }, [
    item,
    prevState,
    state,
    state.amount,
    state.requestedPrice,
    state.supplierId,
    state.supplyMethod,
  ]);

  return (
    <>
      <div
        className={classNames("pickIngredientItem", {
          inactive: false,
        })}
      >
        <div>
          <div>
            <div className="item-header">{item.name}</div>
            <div className="item-image">
              <BaseImage
                fileName={item.imageName}
                fileDir={FileDir.BaseFood}
                height="50"
              />
            </div>
          </div>
          <div>
            <div className="info-row">
              <div>מלאי מינימום:</div>
              <div>
                {NumberFormatter.formatAmount(item.minStock, false, {
                  applyUSLocaleStr: true,
                })}{" "}
                {UnitTypeFormatter.format(item.prepareUnitType, true)}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="supplyInfo">
            <div className="text-center my-1 font-weight-bold  w-100 align-items-center ">
              {showSupplierSelect && (
                <div
                  className={`flex-grow-1 mb-1 ${
                    state.amount > 0 ? "disabled" : ""
                  }`}
                >
                  <Select
                    onChange={onSupplierChange}
                    options={suppliersOptions}
                    value={state.supplierId}
                    displayEmptyOption={false}
                  />
                </div>
              )}
              <div className="flex-grow-1">
                <Select
                  options={supplyMethodsOptions}
                  value={SupplyMethodUtils.generateOptionValue(
                    state.supplyMethod
                  )}
                  onChange={(nextValue) => {
                    const nextSupplyMethod =
                      findItemProductByGenOptionValue(nextValue);

                    if (!nextSupplyMethod) {
                      return;
                    }

                    onSupplyMethodChange(nextSupplyMethod);
                  }}
                  displayEmptyOption={false}
                />
              </div>
            </div>
          </div>
          <OrderInlineAmounter
            value={state.amount}
            supplyMethod={supplyMethod}
            unitKinds={unitKinds}
            packageKinds={packageKinds}
            prepareUnitType={item.prepareUnitType}
            onChange={onAmountChange}
          />

          <div className="info-row mt-2">
            <div>סה"כ עלות:</div>
            <div>
              {NumberFormatter.default.formatAmount(
                state.amount * state.requestedPrice
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
