import React, { useCallback, useEffect } from "react";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { Typography } from "base/components/Typography";
import { useObserver } from "mobx-react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { toJS } from "mobx";
import "order/styles/styles.scss";
import { useBooleanState } from "base/hooks/useBooleanState";
import { SpeechModal } from "speech/SpeechModal";
import { TextInput } from "base/components/TextInput";

export const AddOrderTopBar = () => {
  const navigate = useNavigateCallback();
  const { store } = useAddOrderContext();
  const location = useLocation();
  const history = useHistory();
  const { focusTopBarInput = false } = (history.location.state ?? {}) as {
    focusTopBarInput?: boolean;
  };

  const isAddOrderHomeRoute =
    useRouteMatch([OrdersRouteBuilder.templates.AddOrderHomeRoute])?.isExact ??
    false;

  const isItemsSearchingScreen =
    useRouteMatch([
      OrdersRouteBuilder.templates.DepartmentRoute,
      OrdersRouteBuilder.templates.OrderSearchProductsRoute,
      OrdersRouteBuilder.templates.SupplierRoute,
    ])?.isExact ?? false;

  const [
    showSpeechModal,
    onShowSpeechModal,
    onHideSpeechModal,
  ] = useBooleanState(false);

  const observables = useObserver(() => ({
    routeSearchPhrases: toJS(store.routeSearchPhrases),
  }));

  const phrase = observables.routeSearchPhrases[location.pathname];

  const onPhraseChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = e.target.value;
      store.setRouteSearchPhrase(location.pathname, nextValue);
    },
    [location.pathname, store]
  );

  useEffect(() => {
    if (phrase && isAddOrderHomeRoute) {
      const timeoutId = setTimeout(() => {
        store.setRouteSearchPhrase(
          OrdersRouteBuilder.buildOrderProductsSearchRoute(),
          phrase
        );
        navigate(OrdersRouteBuilder.buildOrderProductsSearchRoute(), {
          state: { focusTopBarInput: true },
        });
        store.setRouteSearchPhrase(
          OrdersRouteBuilder.buildAddOrderHomeRoute(),
          ""
        );
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isAddOrderHomeRoute, navigate, phrase, store]);

  // Reset speech phrase when moving between routes or search phrase changes.
  useEffect(() => {
    store.setSpeechPhrase(undefined);

    // Also make sure to reset when unmounting.
    return () => {
      store.setSpeechPhrase(undefined);
    };
  }, [location.pathname, store, phrase]);

  return (
    <div className="AddOrderTopBar">
      <Typography variant="title" className="text-white p-0 m-0">
        יצירת הזמנה חדשה
      </Typography>
      <div className="d-flex mt-2 justify-content-between">
        <div className="d-flex align-items-center input-col">
          <i className="fas fa-search input-icon right-side" />
          <TextInput
            value={phrase}
            onChange={onPhraseChange}
            placeholder="סינון לפי שם מוצר"
            className="filter-input"
            selectOnFocus={!focusTopBarInput}
            autoFocus={focusTopBarInput}
          />
          {isItemsSearchingScreen && (
            <i
              className="fas fa-microphone input-icon left-side"
              role="button"
              onClick={onShowSpeechModal}
            />
          )}
          {!isItemsSearchingScreen && (
            <i className="fas fa-chevron-left input-icon left-side" />
          )}
        </div>
        {/*
        <div className="d-flex align-items-center mr-3">
          <i className="fas fa-filter active action-icon" role="button" />
          <i className="fas fa-th action-icon" role="button" />
          <i className="fas fa-th-list active action-icon" role="button" />
        </div>
          */}
      </div>
      <SpeechModal
        show={showSpeechModal}
        onHide={onHideSpeechModal}
        onSpeech={store.setSpeechPhrase}
        loadingText={"נא לומר את שם המוצר והכמות הרצויה"}
      />
    </div>
  );
};
