import React, {
  useCallback,
  useEffect,
  useRef,
  InputHTMLAttributes,
} from "react";
import { FormControl, FormControlProps } from "react-bootstrap";

export type TextInputProps = {
  selectOnFocus?: boolean;
} & Partial<FormControlProps> &
  InputHTMLAttributes<HTMLInputElement>;

export const TextInput: React.FC<TextInputProps> = ({
  selectOnFocus = false,
  ...formControlProps
}) => {
  const { autoFocus } = formControlProps;
  const ref = useRef<HTMLInputElement>(null);

  const { onFocus } = formControlProps;

  const onSelectOnFocus = useCallback<
    React.FocusEventHandler<HTMLInputElement>
  >(
    (event) => {
      const inputElement = event.target;
      onFocus?.(event);
      setTimeout(() => inputElement.select());
    },
    [onFocus]
  );

  // A fix added for text input inside modals.
  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  return (
    <FormControl
      ref={ref}
      autoComplete="off"
      type="text"
      {...formControlProps}
      onFocus={selectOnFocus ? onSelectOnFocus : onFocus}
    />
  );
};
