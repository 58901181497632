import { useCallback } from "react";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { Contract } from "contract/types";
import { ContractsApi } from "contract/ContractsApi";
import { ContractsRouteBuilder } from "contract/ContractsRouteBuilder";

export const useSaveContractCallback = () => {
  const navigate = useNavigateCallback();

  const onSave = useCallback(
    async (contract: Contract) => {
      await ContractsApi.saveItem(contract);
      navigate(ContractsRouteBuilder.buildContractsRoute());
    },
    [navigate]
  );

  return onSave;
};
