import React, {
  useState,
  useCallback,
  ImgHTMLAttributes,
  useEffect,
} from "react";
import { FileDir } from "base/types";
import "./styles.scss";
import classNames from "classnames";

export type BaseImageProps = {
  fileName?: string;
  fileDir?: FileDir;
  rounded?: boolean;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

const placeholderFileName = "placeholder.png";
const defaultBaseUrl = FileDir.BaseFood;

export const BaseImage: React.FC<BaseImageProps> = ({
  fileName: fileNameProp = placeholderFileName,
  fileDir: baseUrlProp = defaultBaseUrl,
  rounded = false,
  containerStyle,
  containerClassName,
  ...imgProps
}) => {
  const [fileName, setFileName] = useState(fileNameProp || placeholderFileName);
  const onError = useCallback(() => {
    setFileName(placeholderFileName);
  }, []);

  useEffect(() => {
    setFileName(fileNameProp || placeholderFileName);
  }, [fileNameProp]);

  return (
    <div
      className={classNames("base-image", { rounded }, containerClassName)}
      style={containerStyle}
    >
      <img
        alt=""
        src={`${baseUrlProp}${fileName}`}
        onError={onError}
        {...imgProps}
      />
    </div>
  );
};
