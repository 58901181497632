import React, { useCallback, useMemo } from "react";
import { OrdersApi } from "order/OrdersApi";
import { useNavigateCallback } from "base/hooks/useNavigateCallback";
import { OrdersRouteBuilder } from "order/OrdersRouteBuilder";
import { FileViewer } from "base/components/FileViewer";
import { FileDir } from "base/types/Filedir";
import { useObserver } from "mobx-react";
import { useAddOrderContext } from "order/context/hooks/useAddOrderContext";
import { ImageBackgroundMix } from "base/components/ImageBackgroundMix";
import { useLocation } from "react-router-dom";
import "order/styles/styles.scss";
import { useQuery } from "react-query";

export const SupplierList: React.FC = () => {
  const { store } = useAddOrderContext();
  const location = useLocation();

  const { data } = useQuery(OrdersApi.supplierListQuery.build());
  const searchPhrase = useObserver(
    () => store.routeSearchPhrases[location.pathname] ?? ""
  );

  const navigate = useNavigateCallback();

  const onItemClick = useCallback(
    (id: number) => {
      navigate(OrdersRouteBuilder.buildSupplierRoute(id));
    },
    [navigate]
  );

  const filteredData = useMemo(() => {
    if (!data || !searchPhrase) return data;

    const formattedFilter = searchPhrase.toLowerCase().trim();
    return data.filter(
      (item) => item.name.toLowerCase().trim().indexOf(formattedFilter) !== -1
    );
  }, [data, searchPhrase]);

  return (
    <div className="OrderSupplierList">
      {filteredData?.map((listItem) => (
        <div
          key={listItem.id}
          className="item"
          onClick={() => onItemClick(listItem.id)}
        >
          <ImageBackgroundMix className="item-background">
            <FileViewer
              fileDir={FileDir.Supplier}
              type="image"
              fileName={listItem.logoFileName}
            />
          </ImageBackgroundMix>
          <div className="pick-title">{listItem.name}</div>
        </div>
      ))}
    </div>
  );
};
