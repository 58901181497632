import { BaseSupplyMethod } from "base/types/BaseSupplyMethod";
import { UnitType } from "base/types";
import { ProductSupplyMethod } from "product/supply-method/types";
import { ProductOrderHistoryModel } from "product/types";

export interface BasicPickListItem {
  id: number;
  name: string;
  logoFileName: string;
}

export interface DepartmentListItem {
  id: number;
  name: string;
  imageName: string;
}

export interface OrderIngredientItem {
  productId: number; // todo: productId
  name?: string;
  alternativeNames?: string;
  prepareUnitType: UnitType;
  requestedPrice: number;
  currentStock: number;
  minStock: number;
  requestedAmount: number;
  imageName: string;
  supplierModel: {
    id: number;
    name: string;
  };
  orderHistory: ProductOrderHistoryModel[];
  supplyMethod: BaseSupplyMethod;
  supplyMethods: ProductSupplyMethod[];
}

export interface Order {
  ingredients: OrderIngredientItem[];
  supplier: {
    id: number;
    name: string;
    logoFileName: string;
    minimunOrderPrice: number;
    submitOrderMessage?: string;
  };
  notes?: string;
  orderId?: number;
}

export enum OrderAnnouncementType {
  Email = 1,
  Sms = 2,
}

export type AddOrderDisplayVariant = "list" | "grid";

/* DetailedOrder */
export interface DetailedOrderItem {
  productId: number;
  name: string;
  imageName: string;
  requestedAmount: number;
  weight: number;
  prepareUnitType: number;
  requestedPrice: number;
  supplyMethod: ProductSupplyMethod;
  notes?: string;
}

export interface OrderSupplier {
  id: number;
  name: string;
  logoFileName: string;
  minimunOrderPrice: number;
  submitOrderMessage: string;
}

export interface DetailedOrder {
  orderId: number;
  imageName?: string;
  supplierName: string;
  supplierModel: OrderSupplier;
  items: DetailedOrderItem[];
  itemsKindCount: number;
  supplyDateTime: string;
  creatorName: string;
  users: string[];
  notes?: string;
}
/* DetailedOrder */

/* OrdersSimulation */
export interface OrdersSimulationItem {
  materialId?: number;
  foodId?: number;
  amount: number;
}

export interface OrdersSimulationFormValues {
  items: OrdersSimulationItem[];
}

export interface OrdersSimulationResultItem {
  name: string;
  currentStock: number;
  amount: number;
  prepareUnitType: number;
  imageName: string;
}

/* OrdersSimulation */
