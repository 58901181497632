import React, { useMemo } from "react";
import { SentOrdersApi } from "sent-order/SentOrdersApi";
import {
  CardsTableColumn,
  CardsTable,
} from "base/components/tables/components/CardsTable";
import { SentOrderSTSimulationListItem } from "sent-order/types";
import { NumberFormatter, UnitTypeFormatter } from "base/utils/formatters";
import { TableDateCell } from "base/components/tables/components/TableDateCell";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";
import { useQuery } from "react-query";

export interface SentOrderSimulationTableProps {
  id: number;
}

export const SentOrderSimulationTable: React.FC<SentOrderSimulationTableProps> = ({
  id,
}) => {
  const { data } = useQuery(SentOrdersApi.stSimulationQuery.build(id));

  const columns = useMemo<CardsTableColumn<SentOrderSTSimulationListItem>[]>(
    () => [
      {
        id: "transactionType",
        accessor: "transactionType",
        Header: "סוג תנועה",
      },
      {
        id: "name",
        accessor: "name",
        Header: "שם",
        cellProps: {
          className: "name-col",
        },
      },
      {
        id: "amount",
        accessor: "amount",
        Header: "כמות",
        Cell: ({ value, row }) => (
          <>
            {value} {UnitTypeFormatter.format(row.original.unitType, true)}
          </>
        ),
      },
      {
        id: "price",
        accessor: "price",
        Header: "מחיר",
        Cell: ({ value }) => <>{NumberFormatter.format(value)}</>,
      },
      {
        id: "referenceNo",
        accessor: "referenceNo",
        Header: "אסמכתא",
      },
      {
        id: "transactionDate",
        accessor: "transactionDate",
        Header: "תאריך משלוח",
        sortType: "datetime",
        Cell: TableDateCell,
      },
      {
        id: "table-closer",
        Header: "",
        cellProps: {
          className: "table-closer",
        },
        disableSortBy: true,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  return (
    <CardsTable
      columns={columns}
      data={typeof data === "string" ? [] : data}
      localStorageKey={LocalStorageKeysHelper.dataTable(
        "SentOrderSimulationTable"
      )}
      searchable={false}
      hasPagination={false}
    />
  );
};
