import React from "react";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { SuppliersApi } from "supplier/SuppliersApi";
import { useFullScreenLoader } from "full-screen-loader/hooks/useFullScreenLoader";
import { SupplierForm } from "supplier/components/SupplierForm";
import { useQuery } from "react-query";

export const AddSupplierScreen: React.FC = () => {
  const { data, isLoading, isFetching } = useQuery(
    SuppliersApi.itemDefaultsQuery.build()
  );

  useFullScreenLoader(isLoading || isFetching);

  return (
    <RouteContent>
      <Typography variant="route-title">הוספה</Typography>
      <SupplierForm data={data} />
    </RouteContent>
  );
};
