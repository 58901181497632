import { observable, action } from "mobx";
import { Auth, AuthStandType } from "auth/types";
import { PushNotificationsHandler } from "base/utils/PushNotificationsHandler";
import { LocalStorageKeysHelper } from "base/utils/LocalStorageKeysHelper";

export class AppStore {
  @observable.ref
  auth: Auth | undefined;

  @observable
  isSidebarEnabled: boolean;

  @observable.ref
  standType: AuthStandType;

  @observable.ref
  scaleType: number;

  @observable.ref
  notificationPermission: NotificationPermission;

  @observable.ref
  isBottomNavbarActive: boolean;

  constructor() {
    const token: string | undefined =
      localStorage.getItem(LocalStorageKeysHelper.token()) ?? undefined;

    const standType: AuthStandType =
      (localStorage.getItem(
        LocalStorageKeysHelper.standType()
      ) as AuthStandType) ?? "default";

    const scaleType = parseInt(
      localStorage.getItem(LocalStorageKeysHelper.scaleType()) ?? "0"
    );

    const notificationPermission =
      (localStorage.getItem(
        LocalStorageKeysHelper.notificationPermission()
      ) as NotificationPermission | null) ??
      window.Notification?.permission ??
      "denied";

    this.auth = token ? { token } : undefined;
    this.standType = standType;
    this.scaleType = scaleType;
    this.notificationPermission = notificationPermission;
    this.isSidebarEnabled = false;
    this.isBottomNavbarActive = false;
  }

  @action
  setAuth = (auth: Auth | undefined) => {
    this.auth = auth;
    if (auth) {
      localStorage.setItem("token", auth.token);
    } else {
      localStorage.removeItem("token");
    }
  };

  @action
  setStandType = (standType: AuthStandType) => {
    this.standType = standType;
    localStorage.setItem("standType", standType);
  };

  @action
  setScaleType = (scaleType: number) => {
    this.scaleType = scaleType;
    localStorage.setItem("scaleType", String(scaleType));
  };

  @action
  setNotificationPermission = (
    notificationPermission: NotificationPermission
  ) => {
    this.notificationPermission = notificationPermission;
    localStorage.setItem("notificationPermission", notificationPermission);

    if (notificationPermission === "granted") {
      PushNotificationsHandler.start();
    } else {
      PushNotificationsHandler.stop();
    }
  };

  @action
  setIsSidebarEnabled = (isSidebarEnabled: boolean) => {
    this.isSidebarEnabled = isSidebarEnabled;
  };

  @action
  setIsBottomNavbarActive = (isBottomNavbarActive: boolean) => {
    this.isBottomNavbarActive = isBottomNavbarActive;
  };
}
