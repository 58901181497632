import React from "react";
import { FieldValidator } from "final-form";
import { Field } from "react-final-form";
import { DatePicker } from "base/components/DatePicker";
import { FormMetaError } from "./FormMetaError";

export interface FormDatePickerProps {
  name: string;
  defaultValue?: string;
  validate?: FieldValidator<any>;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  defaultValue,
  validate,
}) => {
  return (
    <Field
      name={name}
      defaultValue={defaultValue}
      validate={validate}
      render={({ input, meta }) => (
        <>
          <DatePicker {...input} />
          <FormMetaError meta={meta} />
        </>
      )}
    />
  );
};
