export class StockRouteBuilder {
  static templates = {
    AddToStock: `/stock/add`,
    StockTaking: "/stock/stocktaking",
    StockRemovalRequest: "/stock/stockRemovalRequest",
  };

  static buildAddToStockRoute() {
    return StockRouteBuilder.templates.AddToStock;
  }

  static buildStockTakingRoute() {
    return StockRouteBuilder.templates.StockTaking;
  }

  static buildStockRemovalRequestRoute() {
    return StockRouteBuilder.templates.StockRemovalRequest;
  }
}
