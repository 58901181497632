import React from "react";
import { Typography } from "base/components/Typography";
import { DishVEStepType, DishVEWizardFormValues } from "dish/types";
import { FormNumberInput } from "form/components/FormNumberInput";
import { Button } from "react-bootstrap";

export interface DishVEMenuPriceStepProps {
  onStepChange: (nextStep: DishVEStepType) => void;
}

export const DishVEMenuPriceStep: React.FC<DishVEMenuPriceStepProps> = ({
  onStepChange,
}) => {
  const fieldName = (property: keyof DishVEWizardFormValues) => property;

  return (
    <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
      <Typography variant="description" className="mb-3" fontSize={36}>
        מה מחיר היעד למנה? (לא כולל מעמ)
      </Typography>
      <div style={{ width: 300 }} className="mb-3">
        <FormNumberInput name={fieldName("menuPrice")} />
      </div>
      <Button onClick={() => onStepChange(DishVEStepType.VisualEditor)}>
        הבא
      </Button>
    </div>
  );
};
