import React, { PropsWithChildren, ReactNode } from "react";
import "./styles.scss";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import classNames from "classnames";

export interface ItemCardComponentProps {
  name: string;
  imageName?: string;
  belowName?: ReactNode;
  bottomPart?: ReactNode;
  className?: string;
}
export const ItemCardComponent: React.FC<
  PropsWithChildren<ItemCardComponentProps>
> = ({ name, imageName, belowName, className, children, bottomPart }) => {
  return (
    <div
      className={classNames(
        "pickIngredientItem1",
        {
          inactive: false,
        },
        className
      )}
    >
      <div>
        <div style={{ flexDirection: "column" }}>
          <div>
            <div className="item-header">{name}</div>
            <div className="item-image">
              <BaseImage
                fileName={imageName}
                fileDir={FileDir.BaseFood}
                height="50"
              />
            </div>
          </div>
          <div role="button">{belowName}</div>
        </div>
        <div>{children}</div>
      </div>
      <div>{bottomPart}</div>
    </div>
  );
};
