import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { RouteContent } from "route-content/components/RouteContent";
import { Typography } from "base/components/Typography";
import { EmployeesApi } from "employee/EmployeesApi";
import { BaseImage } from "base/components/BaseFoodImage";
import { FileDir } from "base/types";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useBooleanState } from "base/hooks/useBooleanState";
import { AuthApi } from "auth/AuthApi";
import { useQuery } from "react-query";

export const EmployeesShiftsPresenceScreen: React.FC = () => {
  const [
    displayingEntranceModal,
    onShowEntranceModal,
    onHideEntranceModal,
  ] = useBooleanState(false);
  const [entranceModalData, setEntranceModalData] = useState<{
    variant: "enter" | "leave";
    time: Date;
  }>();
  const { data: accountUsers } = useQuery(AuthApi.accountUsersQuery.build());
  const { data: shiftsPresence } = useQuery(
    EmployeesApi.shiftsPresenceQuery.build({
      refetchInterval: 30 * 60 * 1000, // 30 mins
    })
  );

  useEffect(() => {
    if (displayingEntranceModal) {
      const timeoutId = setTimeout(() => {
        onHideEntranceModal();
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [displayingEntranceModal, onHideEntranceModal]);

  return (
    <RouteContent>
      <Typography variant="route-title">כניסת עובדים</Typography>
      {shiftsPresence?.map((shiftPresence) => {
        const employee = accountUsers?.find(
          (u) => u.id === shiftPresence.employeeId
        );

        return (
          <Row noGutters key={shiftPresence.employeeId}>
            <Col xl={1} md={2} className="px-2">
              <BaseImage
                fileDir={FileDir.User}
                fileName={employee?.imageName}
                style={{ height: 60 }}
              />
            </Col>
            <Col xl={2} md={4} className="px-2 d-flex align-items-center">
              <Typography variant="description" fontSize={24}>
                {employee?.firstName} {employee?.lastName}
              </Typography>
            </Col>
            <Col md={5} className="px-2 d-flex align-items-center">
              {
                <Button
                  className="ml-4"
                  variant="primary"
                  onClick={async () => {
                    await EmployeesApi.reportEnterShift(
                      shiftPresence.employeeId
                    );
                    setEntranceModalData({
                      variant: "enter",
                      time: new Date(),
                    });
                    onShowEntranceModal();
                  }}
                >
                  כניסה למשמרת
                </Button>
              }
              {
                <Button
                  variant={"danger"}
                  onClick={async () => {
                    await EmployeesApi.reportLeaveShift(
                      shiftPresence.employeeId
                    );
                    setEntranceModalData({
                      variant: "leave",
                      time: new Date(),
                    });
                    onShowEntranceModal();
                  }}
                >
                  יציאה מהמשמרת
                </Button>
              }
            </Col>
          </Row>
        );
      })}
      <Modal
        show={displayingEntranceModal && entranceModalData}
        centered
        onHide={onHideEntranceModal}
      >
        <div className="p-4 text-center">
          <div>
            {entranceModalData?.variant === "enter" && (
              <Typography variant="description">
                דיווח כניסה למערכת נקלט בהצלחה
              </Typography>
            )}
            {entranceModalData?.variant === "leave" && (
              <Typography variant="description">
                דיווח יציאה מהמערכת נקלט בהצלחה
              </Typography>
            )}
          </div>
          <div dir="ltr" className="my-3">
            {entranceModalData?.variant === "enter" && (
              <Typography variant="description" fontSize={30}>
                {format(
                  entranceModalData?.time ?? Date.now(),
                  "dd/MM/yy HH:mm"
                )}
              </Typography>
            )}
            {entranceModalData?.variant === "leave" && (
              <>
                <Typography variant="description" fontSize={30}>
                  {format(Date.now(), "dd/MM/yy HH:mm")}
                </Typography>
              </>
            )}
          </div>
        </div>
      </Modal>
    </RouteContent>
  );
};
