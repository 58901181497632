import { BaseSelectListItem } from "base/types";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import {
  SupplierListItem,
  Supplier,
  SupplierFormData,
  SupplierOrderListItem,
  SupplierContact,
  DistributionDay,
  IngredientPriceAgreement,
  SupplierProductSupplyListItem,
} from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";
import Axios from "axios";

export class SuppliersApi {
  static listQuery = new ApiQuery<SupplierListItem[]>("/api/suppliers/items");

  static itemQuery = new ApiQuery<Supplier, number>(
    (id) => `/api/suppliers/${id}`
  );

  static itemDefaultsQuery = new ApiQuery<Partial<Supplier>>(
    `/api/suppliers/new`
  );

  static itemOrdersListQuery = new ApiQuery<SupplierOrderListItem[], number>(
    (id) => `/api/suppliers/${id}/orders`
  );

  static itemIngredientPriceAgreementsQuery = new ApiQuery<
    IngredientPriceAgreement[],
    number
  >((id) => `/api/suppliers/${id}/ingredientPriceAgreements`);

  static itemDistributionDaysQuery = new ApiQuery<DistributionDay[], number>(
    (id) => `/api/suppliers/${id}/distributionDays`
  );

  static itemProductsSupplyQuery = new ApiQuery<
    SupplierProductSupplyListItem[],
    number
  >((id) => `/api/suppliers/${id}/getProductsSupply`);

  static itemContactsQuery = new ApiQuery<SupplierContact[], number>(
    (id) => `/api/suppliers/${id}/contacts`
  );

  static departmentsQuery = new ApiQuery<BaseSelectListItem[]>(
    "/api/suppliers/departments"
  );

  static async saveItem(data: SupplierFormData) {
    const { data: savedItem } = await Axios.post<Supplier>(
      "/api/suppliers",
      BodyFormDataBuilder.build2(data),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    SuppliersApi.itemQuery.updateCache(savedItem, savedItem.id);

    SuppliersApi.listQuery.invalidate();
  }

  static async deleteItem(id: number) {
    await Axios.delete(`/api/suppliers/${id}`);

    SuppliersApi.listQuery.invalidate();
  }

  static async deleteContact(id: number) {
    await Axios.delete(`/api/contacts/${id}`);
  }

  static async saveContact(
    supplierId: number,
    contact: WithPartialProperties<SupplierContact, "id">
  ) {
    const { data: savedContact } = await Axios.post<SupplierContact>(
      `/api/contacts`,
      { ...contact, supplierId }
    );

    SuppliersApi.itemContactsQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedContact, "id"),
      supplierId
    );
  }

  static async saveDistributionDay(
    distributionDay: WithPartialProperties<DistributionDay, "id">
  ) {
    const { data: savedDay } = await Axios.post<DistributionDay>(
      `/api/distributionDays`,
      distributionDay
    );

    SuppliersApi.itemDistributionDaysQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedDay, "id"),
      savedDay.supplierId
    );
  }

  static async saveIngredientPriceAgreement(
    item: WithPartialProperties<IngredientPriceAgreement, "id">
  ) {
    const { data: savedItem } = await Axios.post<IngredientPriceAgreement>(
      `/api/IngredientPriceAgreements`,
      item
    );

    SuppliersApi.itemIngredientPriceAgreementsQuery.updateCache(
      QueryCacheHelper.buildSaveListItemUpdater(savedItem, "id"),
      savedItem.supplierId
    );
  }

  static async deleteIngredientPriceAgreement(supplierId: number, id: number) {
    await Axios.delete(`/api/IngredientPriceAgreements/${id}`);

    SuppliersApi.itemIngredientPriceAgreementsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(id, "id"),
      supplierId
    );
  }
}
