import axios from "axios";
import { BaseFood, TreeType } from "base/types";
import { TreeApiPathFormatter } from "base/utils/formatters/TreeApiPathFormatter";
import {
  TreeFoodWork,
  TreePermanentFoodWork,
  TreeRequirableFoodWork,
} from "./types";
import { ApiQuery } from "base/api/ApiQuery";
import { TreeTypeFormatter } from "base/utils/formatters/TreeTypeFormatter";
import { QueryCacheHelper } from "base/api/QueryCacheHelper";

/* TODO - Foodworks structural refactor is required. */
export class TreeFoodWorksApi {
  static itemsQuery = new ApiQuery<
    TreeFoodWork[],
    { type: TreeType; id: number }
  >(
    ({ type, id }) =>
      `/api/${TreeTypeFormatter.formatApiPath(type)}/${id}/foodworks`
  );

  // Todo - Move to separate Api file, maybe add a generic BaseFoodApi.
  // Equals to MaterialsApi.itemQuery / DishesApi.itemQuery.
  static foodItemQuery = new ApiQuery<BaseFood, { type: TreeType; id: number }>(
    ({ type, id }) => `/api/${TreeTypeFormatter.formatApiPath(type)}/${id}`
  );

  static async addFoodWork(
    type: TreeType,
    id: number,
    foodWork:
      | Omit<TreePermanentFoodWork, "id">
      | Omit<TreeRequirableFoodWork, "id">
  ) {
    const { data: newFoodWork } = await axios.post<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks`,
      foodWork
    );

    TreeFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildAddListItemUpdater(newFoodWork),
      { type, id }
    );
  }

  static async updateFoodWork(
    type: TreeType,
    id: number,
    foodWork: TreeFoodWork
  ) {
    const { data: modifiedFoodWork } = await axios.post<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks`,
      foodWork
    );

    TreeFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildUpdateListItemUpdater(modifiedFoodWork, "id"),
      { type, id }
    );
  }

  static async deleteFoodWork(type: TreeType, id: number, foodWorkId: number) {
    await axios.delete<TreeFoodWork>(
      `/api/${TreeApiPathFormatter.format(type)}/${id}/foodworks/${foodWorkId}`
    );

    TreeFoodWorksApi.itemsQuery.updateCache(
      QueryCacheHelper.buildDeleteListItemUpdater(foodWorkId, "id"),
      { type, id }
    );
  }
}
