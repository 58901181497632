import Axios from "axios";
import { BodyFormDataBuilder } from "base/api/BodyFormDataBuilder";
import {
  POSUploadFormValues,
  POSUploadResult,
  POSHistoryListItem,
  POSHistoryItem,
} from "./types";
import { ApiQuery } from "base/api/ApiQuery";

export class POSApi {
  static historyListQuery = new ApiQuery<POSHistoryListItem[]>(
    "/api/poshistory/items"
  );

  static historyItemQuery = new ApiQuery<POSHistoryItem, string>(
    (fileName) => `/api/poshistory/${fileName}`
  );

  static async upload(values: POSUploadFormValues) {
    const { data: uploadResult } = await Axios.post<POSUploadResult>(
      "/api/bi/pos",
      BodyFormDataBuilder.build2(values),
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return uploadResult;
  }

  static async mapItem(foodId: number, mappingName: string) {
    await Axios.post<POSUploadResult>("/api/POSItemMappings", {
      foodId,
      name: mappingName,
    });
  }
}
