import { ApiQuery } from "base/api/ApiQuery";

export class ScalesApi {
  static weightQuery = new ApiQuery<number, number>(
    (scaleType) =>
      `http://127.0.0.1:5000/current-weight?ip=${localStorage.getItem(
        "scaleNetworkAddress"
      )}&port=8899`
  );
}
