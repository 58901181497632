import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export type UseNavigateCallbackOptions = {
  replace?: boolean;
  state?: any;
};

export const useNavigateCallback = () => {
  const history = useHistory();
  return useCallback(
    (path: string, options?: UseNavigateCallbackOptions) => {
      if (options?.replace) {
        history.replace(path, options?.state);
      } else {
        history.push(path, options?.state);
      }
    },
    [history]
  );
};
